import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router";
import { useAppSelector } from "../../store/configureStore";
import { ComponentType } from "react";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import useHQmanager from "../../hooks/useHQmanager";

interface Props extends RouteProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

function StoreRoute({ component: Component, ...rest }: Props) {
  const { user } = useAppSelector((state) => state.account);
  const isAdmin = useAdminManager(user);
  const isPicker = usePickerManager(user);
  const isHq = useHQmanager(user);

  return (
    <Route
      {...rest}
      render={(props) =>
        (user && isPicker) || (user && isAdmin) || (user && isHq) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

export default StoreRoute;
