import { Icon } from "../../models/icon";

function Trashcan({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 32,
}: Icon) {
  return (
    <svg viewBox="0 0 67.48 80" style={{ width: width + "px" }}>
      <path
        fill={selected ? outline : "white"}
        d="M22.93,80c-7.96,0-14.61-6.33-15.12-14.41L4.98,21.53c-2.86-.77-4.98-3.39-4.98-6.49,0-3.71,3.02-6.72,6.72-6.72h11.29C18.89,3.59,23.04,0,28.01,0h11.46c4.97,0,9.12,3.59,9.99,8.31h11.29c3.71,0,6.72,3.02,6.72,6.72,0,3.1-2.11,5.72-4.98,6.5l-2.83,44.06c-.52,8.08-7.16,14.41-15.12,14.41H22.93Z"
      />
      <g>
        <path
          fill={selected ? selectedColor : color}
          d="M33.74,22.58c-.95,0-1.72,.77-1.72,1.72V62.75c0,.95,.77,1.72,1.72,1.72s1.72-.77,1.72-1.72V24.3c0-.95-.77-1.72-1.72-1.72Z"
        />
        <path
          fill={selected ? selectedColor : color}
          d="M43.24,24.25l-1.27,38.45c-.03,.95,.71,1.75,1.67,1.78,.02,0,.04,0,.06,0,.92,0,1.69-.73,1.72-1.67l1.27-38.45c.03-.95-.71-1.75-1.67-1.78-.93-.01-1.75,.71-1.78,1.67Z"
        />
        <path
          fill={selected ? selectedColor : color}
          d="M22.46,22.58c-.95,.03-1.7,.83-1.67,1.78l1.28,38.45c.03,.93,.8,1.67,1.72,1.67,.02,0,.04,0,.06,0,.95-.03,1.7-.83,1.67-1.78l-1.28-38.45c-.03-.95-.82-1.68-1.78-1.67Z"
        />
        <path
          fill={selected ? selectedColor : color}
          d="M60.75,13.31h-16.12v-3.15c0-2.85-2.32-5.16-5.16-5.16h-11.46c-2.85,0-5.16,2.32-5.16,5.16v3.15H6.72c-.95,0-1.72,.77-1.72,1.72s.77,1.72,1.72,1.72h2.96l3.11,48.51c.35,5.45,4.8,9.73,10.14,9.73h21.62c5.33,0,9.79-4.27,10.14-9.73l3.11-48.51h2.96c.95,0,1.72-.77,1.72-1.72s-.77-1.72-1.72-1.72Zm-34.46-3.15c0-.95,.77-1.71,1.71-1.71h11.46c.94,0,1.71,.77,1.71,1.71v3.15h-14.89v-3.15Zm24.95,54.89c-.23,3.59-3.23,6.5-6.69,6.5H22.93c-3.46,0-6.46-2.91-6.69-6.5l-3.1-48.29H54.34l-3.1,48.29Z"
        />
      </g>
    </svg>
  );
}

export default Trashcan;
