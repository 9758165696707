interface Props {
  className?: string;
}

function AdcLogo({ className }: Props) {
  return (
    <div className={className ? className : "navbar__logo"}>
      <svg viewBox="0 0 283.52 37.21">
        <g fill="#92002f">
          <path d="M21.27,36.69c-.52-2.17-1.14-5-1.76-7.29H7c-.57,2.22-1.14,4.81-1.61,7.29H0C2.69,25.36,6.21,13,9.94,1.76h7.19c4.14,12.16,7.35,23.4,10.15,34.93ZM13.1,6.89C11.44,12.32,9.47,19.21,8,25.2H18.58C16.93,19.25,15.06,12.89,13.1,6.89Z" />
          <path d="M36.34,37.06c-3.16,0-4.72-.94-4.72-5.13V0H37V29.86c0,3,.36,3.47,1.86,3.47.52,0,1.08,0,1.55,0l.21,3.21A14.85,14.85,0,0,1,36.34,37.06Z" />
          <path d="M53.62,37.21a37.83,37.83,0,0,1-7.91-.93V0H51V13.25a10.92,10.92,0,0,1,6.77-2.84c5.75,0,9,5.43,9,12.42C66.77,33,61.28,37.21,53.62,37.21Zm2.74-22.67A9.08,9.08,0,0,0,51,17.13V32.76a10.4,10.4,0,0,0,3.57.57c3.77,0,6.67-3,6.67-9.63C61.28,18,59.47,14.54,56.36,14.54Z" />
          <path d="M76.7,24.69v.47c0,5.58,2.28,7.91,6.47,7.91a23.47,23.47,0,0,0,7.92-1.5L92,34.68A22.39,22.39,0,0,1,82,37.21c-6.53,0-10.82-3.41-10.82-12.67s4.13-14.13,11.12-14.13c6.32,0,10.36,3.83,9.73,14.28Zm10.2-3.57c0-4.56-1.61-7-4.86-7s-5.13,3.16-5.34,7.19H86.9Z" />
        </g>
        <g fill="#23292b">
          <path d="M113.68,36.85c-2.44,0-5.13-.16-7.36-.16V1.76c3.11,0,5.85-.05,8.86-.05,10.92,0,14.74,7.5,14.74,16.61C129.92,29.86,124.85,36.85,113.68,36.85Zm.93-30.95c-.88,0-1.76,0-2.69,0V32.4c1,.05,2,.11,3,.11,6.11,0,9.21-4.56,9.21-13.52C124.08,11.07,121.59,5.9,114.61,5.9Z" />
          <path d="M139.92,24.69v.47c0,5.58,2.27,7.91,6.47,7.91a23.56,23.56,0,0,0,7.92-1.5l.87,3.11a22.33,22.33,0,0,1-9.94,2.53c-6.52,0-10.81-3.41-10.81-12.67s4.14-14.13,11.12-14.13c6.32,0,10.36,3.83,9.74,14.28Zm10.19-3.57c0-4.56-1.61-7-4.87-7s-5.12,3.16-5.32,7.19h10.19Z" />
          <path d="M178.47,37.21c-8.33,0-12.78-5.79-12.78-17.34,0-12,5.07-18.62,13.4-18.62A9.77,9.77,0,0,1,188.2,7l-2.69,2.38C184,7,182,5.74,179.61,5.74c-5.22,0-8.07,5.44-8.07,13.15,0,9.68,2.85,13.77,8,13.77,2.63,0,4.76-1.14,6.88-3.63l2.49,2.75A12.43,12.43,0,0,1,178.47,37.21Z" />
          <path d="M202.75,37.21c-6.68,0-10.87-3.46-10.87-12.89s4.66-13.91,12-13.91c6.62,0,10.87,3.46,10.87,12.78C214.7,32.71,210,37.21,202.75,37.21Zm.46-22.82c-3.62,0-5.85,2.58-5.85,8.8,0,7.6,2,10,6,10,3.57,0,5.85-2.59,5.85-8.85C209.22,16.82,207.2,14.39,203.21,14.39Z" />
          <path d="M234.37,36.69,226.09,23.5V36.69h-5.34V0h5.34V21.07L233,10.92h6.37L230.9,21.68l9.78,15Z" />
          <path d="M248,24.69v.47c0,5.58,2.28,7.91,6.47,7.91a23.47,23.47,0,0,0,7.92-1.5l.88,3.11a22.36,22.36,0,0,1-9.94,2.53c-6.52,0-10.81-3.41-10.81-12.67s4.14-14.13,11.13-14.13c6.31,0,10.35,3.83,9.72,14.28Zm10.2-3.57c0-4.56-1.61-7-4.87-7s-5.12,3.16-5.33,7.19h10.2Z" />
          <path d="M282.91,15.79a8.86,8.86,0,0,0-2.18-.37c-1.5,0-2.89,1.14-5.43,4.3v17H270V10.92h4.41l.46,4.35c2.79-3.63,4.56-4.81,6.42-4.81a5,5,0,0,1,2.27.56Z" />
        </g>
      </svg>
    </div>
  );
}

export default AdcLogo;
