import { FieldValues, useForm } from "react-hook-form";
import { Fragment } from "react";
import { toast } from "react-toastify";
import SquareButton from "../../../components/genericButtons/squareButton/SquareButton";
import agent from "../../../api/agent";
import "./TranslationManagerExcelLoader.css";

interface Props {
  closeModal: () => void;
  downloaded: boolean;
  setDownloaded: (value: boolean) => void;
}

const TranslationManagerExcelLoader = ({
  closeModal,
  downloaded,
  setDownloaded,
}: Props) => {
  //#region SETUP
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });
  //#endregion

  //#region LOGIC
  async function submitForm(data: FieldValues) {
    let formData = new FormData();
    formData.append("file", data.excellFile[0]);

    try {
      await agent.Translations.createFromDataSource(formData);
      toast.success("Data imported");
      closeModal();
    } catch (error) {
      toast.error(`${error}`);
    }
  }

  // Download current translations
  const downloadCurrentTranslationsHandler = async () => {
    try {
      await agent.Translations.getCurrentListInExcel().then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "current-translations.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
      setDownloaded(true);
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  //#endregion

  //#region RENDER
  return (
    <div className="translation-manager__excelloader">
      <div className="translation-manager__excelloader__container">
        {!downloaded ? (
          <Fragment>
            <p>
              The excel structure must be correct. Please download the current
              translations and add the new ones...
            </p>
            <SquareButton
              value="download"
              marginTop="1rem"
              marginBottom="0"
              clickHandler={downloadCurrentTranslationsHandler}
            />
          </Fragment>
        ) : (
          <form
            onSubmit={handleSubmit(submitForm)}
            className="translation-manager__excelloader__form"
          >
            {/* file upload */}
            <fieldset className="translation-manager__excelloader__form__fieldset">
              <div className="translation-manager__excelloader__form__fieldset__group">
                <label>choose excell file</label>
                <input
                  type="file"
                  autoFocus
                  placeholder="choose main promo excell"
                  {...register("excellFile", {
                    required: "excell file is required",
                  })}
                />
                <p className="translation-manager__excelloader__form__fieldset__error">
                  {errors.excellFile && errors?.excellFile?.message}
                </p>
              </div>
            </fieldset>

            <div className="translation-manager__excelloader__actionbuttons">
              <button
                disabled={!isValid}
                type="submit"
                className="translation-manager__excelloader__actionbuttons__save"
                style={{ backgroundColor: !isValid ? "#f5bfbf" : "#9eab80" }}
              >
                {isSubmitting ? "importing..." : "import"}
              </button>
            </div>
          </form>
        )}
      </div>
      <div className="translation-manager__excelloader__overlay" onClick={closeModal}/>
    </div>
  );
  //#endregion
};

export default TranslationManagerExcelLoader;
