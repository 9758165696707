import { Fragment } from "react";
import { motion } from "framer-motion";
import "./RedirectButton.css";

interface Props {
  address: string | null;
  value: string;
  small?: boolean;
  externalClickHandler?: (e: any) => void;
}

const RedirectButton = ({
  address,
  value,
  small = false,
  externalClickHandler,
}: Props) => {

  const openWindow = (e: any) => {
    e.preventDefault();
    address && window.open(address, "_blank");
  };

  const handleClick = (e: any) => {
    externalClickHandler ? externalClickHandler(e) : openWindow(e);
  };

  return address ? (
    <Fragment>
      <motion.button
        onClick={(e: any) => handleClick(e)}
        className={small ? "redirect-button--small" : "redirect-button"}
        whileHover={{
          scale: 1.1,
        }}
        whileTap={{ scale: 0.9 }}
      >
        {value}
      </motion.button>
    </Fragment>
  ) : (
    <span>{value}</span>
  );
};

export default RedirectButton;
