//#region IMPORTS
import { useAppDispatch } from "../../store/configureStore";
import { StoreFormat } from "../../models/storeFormat";
import { TrashIcon } from "@heroicons/react/20/solid";
import { deleteStoreFormatAsync } from "../../features/storeFormats/storeFormatSlice";
//#endregion

//#region INTERFACE
interface Props {
  storeFormat: StoreFormat;
}
//#endregion

export default function StoreFormatListItem({ storeFormat }: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  //#endregion

  //#region LOGIC
  const deleteHandler = async () => {
    await dispatch(deleteStoreFormatAsync({ id: storeFormat.id }));
  };
  //#endregion

  //#region RENDER
  return (
    <li className="flex items-center justify-between py-1">
      {/* Name */}
      <p className="text-sm leading-6 text-gray-800">{storeFormat.name}</p>
      <button onClick={deleteHandler}>
        <TrashIcon className="w-4 h-4 text-red-800" />
      </button>
    </li>
  );
  //#endregion
}
