//#region IMPORTS
import {
  setPromoParams,
  promosSelectors,
  fetchPromosAsync,
} from "../promos/promosSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useEffect } from "react";
import usePickerManager from "../../hooks/usePickerManager";
import HomeProjectItems from "../../components/home/HomeProjectItems";
import useAdminManager from "../../hooks/useAdminManager";
import HomeInfoItems from "../../components/home/HomeInfoItems";
import MainContainer from "../../components/_layout/MainContainer";
import useHQmanager from "../../hooks/useHQmanager";
import HomeFooter from "../../components/home/HomeFooter";
import HomeHeader from "../../components/home/HomeHeader";
import "./Home.css";
//#endregion

export default function Home() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);
  const { basket } = useAppSelector((state) => state.basket);
  const { promosLoaded } = useAppSelector((state) => state.promos);
  const promos = useAppSelector(promosSelectors.selectAll);

  // Roles
  const isPicker = usePickerManager(user);
  const isAdmin = useAdminManager(user);
  const HQTeam = useHQmanager(user);
  //#endregion

  //#region LOGIC
  // Set promo pagination to last 3 promos
  useEffect(() => {
    dispatch(
      setPromoParams({
        limit: 3,
        page: 1,
      })
    );
  }, [dispatch]);

  // Load promos
  useEffect(() => {
    const fetchOnload = async () => {
      if (!promosLoaded) {
        await dispatch(fetchPromosAsync());
      }
    };
    fetchOnload();
  }, [promosLoaded, dispatch]);
  //#endregion

  //#region RENDER
  return (
    <MainContainer classNamesExtensions="home">
      <HomeHeader user={user} />

      <HomeProjectItems
        projects={promos}
        isPicker={isPicker}
        isAdmin={isAdmin}
        HQTeam={HQTeam}
        basket={basket}
      />

      <HomeInfoItems isPicker={isPicker} isAdmin={isAdmin} />

      <HomeFooter
        isPicker={isPicker}
        isAdmin={isAdmin}
        HQTeam={HQTeam}
        basket={basket}
      />
    </MainContainer>
  );
  //#endregion
}
