import { useHistory, useLocation } from "react-router-dom";

function ServerError() {
  const history = useHistory();
  const { state } = useLocation<any>();

  return (
    <div>
      {state?.error ? (
        <>
          <h2>{state.error.title}</h2>
          <p>{state.error.detail || "internal server error"}</p>
        </>
      ) : (
        <h1>Server error</h1>
      )}
      <button onClick={() => history.push("/")}>terug to home</button>
    </div>
  );
}

export default ServerError;
