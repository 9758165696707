//#region IMPORTS
import {
  createPromoAsync,
  updatePromoAsync,
} from "../../features/promos/promosSlice";
import { FieldValues, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/configureStore";
import { PhotoIcon } from "@heroicons/react/20/solid";
import { Promo } from "../../models/promo";
import { toast } from "react-toastify";
import InputFieldErrorMark from "../errors/InputFieldErrorMark";
import SubmitButton from "../genericButtons/submitButton/SubmitButton";
//#endregion

//#region INTERFACE
interface Props {
  cancelCUD: () => void;
  isAdmin: boolean;
  promo?: Promo;
}
//#endregion

export default function PromoForm({ cancelCUD, promo, isAdmin }: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();

  // Local state
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  // UseForm
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });

  //#endregion

  //#region LOGIC
  // Fetch promo data
  useEffect(() => {
    if (promo) reset(promo);
  }, [promo, reset]);

  // Submit form
  async function submitForm(data: FieldValues) {
    const dataWithoutImage = (({ file, ...d }) => d)(data);

    let formData = new FormData();
    // VERY IMPORTANT - only way to acces the FileList in data object
    formData.append("file", data.file[0]);

    if (!isValid) {
      toast.error("Please fill in all required fields");
    }

    if (isAdmin) {
      try {
        if (promo) {
          await dispatch(
            updatePromoAsync({
              id: promo.id,
              values: { ...dataWithoutImage },
              file: data.file[0] ? formData : null,
            })
          );
        } else {
          await dispatch(
            createPromoAsync({
              values: { ...dataWithoutImage },
              file: data.file[0] ? formData : null,
            })
          );
        }
        cancelCUD();
      } catch (error) {
        toast.error(`${error}`);
      }
    } else {
      toast.error("This is a admin only feature");
    }
  }

  // Choose image and set preview
  const handleImagePreviewChange = (e: any) => {
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };
  //#endregion

  //#region RENDER
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="space-y-12">
        <div className="pb-12">
          {/* Header */}
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {promo && !isAdmin
              ? "Project details"
              : promo && isAdmin
              ? "Update project"
              : "Create project"}
          </h2>

          {/* Form body */}
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            {/* Code */}
            <fieldset className="sm:col-span-3">
              <label
                htmlFor="code"
                className={
                  errors.code
                    ? "block text-sm font-medium leading-6 text-red-900"
                    : "block text-sm font-medium leading-6 text-gray-900"
                }
              >
                {errors.code ? errors?.code?.message : "promo name"}
              </label>
              <div className="relative mt-2">
                <input
                  placeholder="e.g. 0524MAY"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                  disabled={!isAdmin}
                  type="text"
                  id="code"
                  {...register("code", {
                    required: "The name is a required field",
                    minLength: {
                      value: 6,
                      message: "at least 6 characters please",
                    },
                  })}
                />
                <InputFieldErrorMark show={errors.code} />
              </div>
            </fieldset>

            {/* Short_code */}
            <fieldset className="sm:col-span-3">
              <label
                htmlFor="short_code"
                className={
                  errors.short_code
                    ? "block text-sm font-medium leading-6 text-red-900"
                    : "block text-sm font-medium leading-6 text-gray-900"
                }
              >
                {errors.short_code ? errors?.short_code?.message : "short code"}
              </label>
              <div className="relative mt-2">
                <input
                  placeholder="e.g. MOTH"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                  disabled={!isAdmin}
                  type="text"
                  id="short_code"
                  {...register("short_code", {
                    required: "The shortcode is a required field",
                    minLength: { value: 3, message: "min 3 characters" },
                    maxLength: { value: 3, message: "max 3 characters" },
                  })}
                />
                <InputFieldErrorMark show={errors.short_code} />
              </div>
            </fieldset>

            {/* MP_code */}
            <fieldset className="sm:col-span-6">
              <label
                htmlFor="mp_code"
                className={
                  errors.mp_code
                    ? "block text-sm font-medium leading-6 text-red-900"
                    : "block text-sm font-medium leading-6 text-gray-900"
                }
              >
                {errors.mp_code ? errors?.mp_code?.message : "promo code"}
              </label>
              <div className="relative mt-2">
                <input
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                  disabled={!isAdmin}
                  type="text"
                  id="mp_code"
                  placeholder="e.g. 124666"
                  {...register("mp_code", {
                    required: "This is a required field",
                    minLength: {
                      value: 3,
                      message: "min 3 digits",
                    },
                    maxLength: {
                      value: 6,
                      message: "max 6 digits",
                    },
                  })}
                />
                <InputFieldErrorMark show={errors.mp_code} />
              </div>
            </fieldset>

            {/* Active_from_date */}
            <fieldset className="sm:col-span-3">
              <label
                htmlFor="active_from_date"
                className={
                  errors.active_from_date
                    ? "block text-sm font-medium leading-6 text-red-900"
                    : "block text-sm font-medium leading-6 text-gray-900"
                }
              >
                {errors.active_from_date
                  ? errors?.active_from_date?.message
                  : "start date"}
              </label>
              <div className="relative mt-2">
                <input
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                  disabled={!isAdmin}
                  type="date"
                  id="active_from_date"
                  {...register("active_from_date")}
                />
              </div>
            </fieldset>

            {/* Active_to_date */}
            <fieldset className="sm:col-span-3">
              <label
                htmlFor="active_to_date"
                className={
                  errors.active_to_date
                    ? "block text-sm font-medium leading-6 text-red-900"
                    : "block text-sm font-medium leading-6 text-gray-900"
                }
              >
                {errors.active_to_date
                  ? errors?.active_to_date?.message
                  : "end date"}
              </label>
              <div className="relative mt-2">
                <input
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                  disabled={!isAdmin}
                  type="date"
                  id="active_to_date"
                  {...register("active_to_date")}
                />
              </div>
            </fieldset>

            {/* Available */}
            <fieldset className="sm:col-span-6">
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    className="h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                    id="back_orderable"
                    disabled={!isAdmin}
                    type="checkbox"
                    {...register("back_orderable")}
                  />
                </div>
                <div className="text-sm leading-6">
                  <label
                    htmlFor="back_orderable"
                    className="font-medium text-gray-900"
                  >
                    available
                  </label>
                  <p className="text-gray-500">
                    This overides the start date option.
                  </p>
                </div>
              </div>
            </fieldset>

            {/* Image */}
            {isAdmin && (
              <fieldset className="col-span-full">
                <label
                  htmlFor="file"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {errors.file
                    ? errors?.file?.message
                    : `${imagePreview ? "Edit" : "Choose"} image`}
                </label>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    {promo?.picture_url ? (
                      <img
                        src={imagePreview ? imagePreview : promo.picture_url}
                        alt={promo.code}
                        className="h-24 w-24 flex-none rounded-full bg-gray-50"
                      />
                    ) : imagePreview ? (
                      <img
                        src={imagePreview}
                        alt={promo?.code}
                        className="h-24 w-24 flex-none rounded-full bg-gray-50"
                      />
                    ) : (
                      <PhotoIcon
                        className="mx-auto h-24 w-24 text-gray-300"
                        aria-hidden="true"
                      />
                    )}
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file"
                        className="w-full relative cursor-pointer rounded-md bg-white font-semibold text-emerald-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-emerald-600 focus-within:ring-offset-2 hover:text-emerald-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="file"
                          type="file"
                          className="sr-only"
                          {...register("file", {
                            onChange: (e) => handleImagePreviewChange(e),
                          })}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
            )}
          </div>
        </div>
      </div>

      {/* Actions */}
      {isAdmin && (
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            onClick={cancelCUD}
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>

          <SubmitButton conditionals={[isValid]} isSubmitting={isSubmitting} />
        </div>
      )}
    </form>
  );
  //#endregion
}
