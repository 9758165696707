//#region IMPORTS
import { useTranslation } from "react-i18next";
import { Order } from "../../models/order";
import ConditionalParagraph from "../genericTextLine/ConditionalParagraph";
// import { useEffect, useState } from "react";
// import { Store } from "../../models/store";
// import { toast } from "react-toastify";
// import agent from "../../api/agent";
//#endregion

//#region INTERFACE
interface Props {
  order: Order;
}
//#endregion

export default function OrderDetailPageHeaderStoreInfo({ order }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Local state
//   const [storeData, setStoreData] = useState<Store>();
  //#endregion

  //#region LOGIC
//   useEffect(() => {
//     const getStoreDetail = async () => {
//       try {
//         const { data } = await agent.StoreManagement.detail(order.store.id);
//         setStoreData(data);
//       } catch (error) {
//         toast.error("store data unavailable");
//       }
//     };
//     order.store.id && getStoreDetail();
//   }, [order]);
  //#endregion

  //#region RENDER
  return (
    <div className="orders-detail-page__header__info__description">
      <p>
        <strong>{order.promo_name}</strong>
      </p>
      <p>Rituals Cosmetics / {order.store.external_store_id}</p>
      <ConditionalParagraph value={order.store.delivery_address_address_1} />
      <ConditionalParagraph value={order.store.delivery_address_address_2} />
      <ConditionalParagraph value={order.store.delivery_address_address_3} />
      <ConditionalParagraph
        inline
        value={order.store.delivery_address_postal_code}
      />{" "}
      <ConditionalParagraph inline value={order.store.delivery_address_city} />

      <p>{order.store.country_name}</p>
      <p>
        {t("rituals.orderDate")}:{" "}
        {order.order_date && order.order_date.toString().split(" ")[0]}
      </p>
    </div>
  );
  //#endregion
}
