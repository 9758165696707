import "./SquareButton.css";

interface Props {
  overrideWidth?: string | null;
  clickHandler?: () => void;
  marginBottom?: string;
  description?: string | null;
  className?: string;
  marginTop?: string;
  fixed?: boolean
  color?: string;
  value: string;
}

function SquareButton({
  overrideWidth = null,
  clickHandler,
  marginBottom,
  description = null,
  className,
  marginTop,
  color = "black",
  fixed = false,
  value,
}: Props) {
  return (
    <div style={{position: fixed ? "fixed" : "static"}}>
      {description && (
        <h2 className="squarebutton__description">{description}</h2>
      )}
      <button
        onClick={clickHandler}
        className={`squarebutton${className ? " " + className : ""}`}
        style={{
          backgroundColor: color,
          marginTop: marginTop,
          marginBottom: marginBottom,
          width: overrideWidth ?? "auto",
        }}
      >
        {value}
      </button>
    </div>
  );
}

export default SquareButton;
