import {
  TranslationLanguage,
  TranslationLine,
} from "../../../models/translations";
import { resetTranslationManagerParams } from "../translatioManagerSlice";
import { FieldValues, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store/configureStore";
import { toast } from "react-toastify";
import agent from "../../../api/agent";

interface Props {
  translationLine?: TranslationLine;
  columsAmmount: number;
  resetCreate: () => void;
  updatedTranslationLine?: (updatedTranslationLine: TranslationLine) => void;
}
function TranslationManagerForm({
  translationLine,
  columsAmmount,
  resetCreate,
}: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();

  //Local state
  const [languages, setLanguages] = useState<TranslationLanguage[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);

  // UseForm configuration
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });
  //#endregion

  //#region LOGIC
  // Check if there is a translationline selected
  useEffect(() => {
    if (translationLine) reset(translationLine);
  }, [translationLine, reset]);

  // Fetch Languages localy
  useEffect(() => {
    const fetchAllTranslationManagerLanguageCodes = async () => {
      try {
        const { languages } = await agent.Translations.getAvailableLanguages();
        const englishIndex = languages.findIndex(
          (lang: any) => lang.name === "English"
        );
        const englishLanguage = languages.splice(englishIndex, 1)[0];
        languages.unshift(englishLanguage);
        setLanguages(languages);

        setLoading(false);
      } catch (error) {
        toast.error(`${error}`);
      }
    };
    fetchAllTranslationManagerLanguageCodes();
  }, []);

  // Check validation
  useEffect(() => {
    if (!isValid && isSubmitting) {
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          const error = errors[key];
          toast.error(error.message);
        }
      }
    }
  }, [errors, isSubmitting, isValid]);

  // OnSubmit
  async function submitForm(data: FieldValues) {
    setSaving(true);
    const translationRequest: any = {
      key: data.key,
      group: "rituals",
      text: {
        de: data.de,
        en: data.en,
        es: data.es,
        fr: data.fr,
        it: data.it,
        nl: data.nl,
        pl: data.pl,
        pt: data.pt,
      },
    };
    try {
      const { message } = await agent.Translations.createOrUpdate(
        translationRequest
      );
      toast.success(`${data.key} ${message}`);
      setSaving(false);
      resetCreate();
      dispatch(resetTranslationManagerParams());
    } catch (error) {
      toast.error(`${error}`);
    }
  }
  //#endregion

  //#region RENDER
  return (
    <form
      id="translation-manager-list__list__item--create"
      onSubmit={handleSubmit(submitForm)}
      className="translation-manager-list__list__item"
    >
      {loading && (
        <div className="translation-manager-list__list__item__loading">
          loading...
        </div>
      )}
      {saving && (
        <div className="translation-manager-list__list__item__saving">
          saving...
        </div>
      )}
      <div
        className="translation-manager-list__list__item__cell"
        style={{ width: `${100 / columsAmmount}%` }}
      >
        <input
          className="translation-manager-list__list__item__cell__inputfield translation-manager-list__list__item__cell__inputfield--create"
          type="text"
          placeholder="key"
          {...register("key", { required: "english is required" })}
        />
      </div>
      {languages &&
        languages.map((lang, index) => (
          <div
            className="translation-manager-list__list__item__cell"
            style={{ width: `${100 / columsAmmount}%` }}
            key={index}
          >
            <input
              className="translation-manager-list__list__item__cell__inputfield translation-manager-list__list__item__cell__inputfield--create"
              key={lang.id}
              type="text"
              placeholder={lang.code}
              {...register(lang.code, {})}
            />
          </div>
        ))}
      <div className="translation-manager-list__list__item__cell--save">
        <button type="submit">Save</button>
      </div>
    </form>
  );
  //#endregion
}

export default TranslationManagerForm;
