//#region IMPORTS
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
//#endregion

//#region INTERFACE
interface Props {
  closeHandler: () => void;
  confirmHandler: () => void;
  message?: string;
  title?: string;
}
//#endregion

export default function AreYouSure({
  closeHandler,
  confirmHandler,
  message,
  title,
}: Props) {
  //#region TRANSLATION
  const { t } = useTranslation();
  //#endregion
  
  //#region RENDER
  return (
    <Fragment>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {title ?? t("are_you_sure")}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {message ??
                t(
                  "are_you_sure_you_want_to_proceed?_This_action_cannot_be_undone"
                )}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={confirmHandler}
        >
          {t("yes")}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={closeHandler}
        >
          {t("cancel")}
        </button>
      </div>
    </Fragment>
  );
  //#endregion
}
