//#region INTERFACE
interface Props {
  titleValueInline?: boolean;
  className?: string;
  marginTop?: string;
  inline?: boolean;
  title?: string;
  value: any;
}
//#endregion

export default function ProfileParagraph({
  titleValueInline = false,
  className = "",
  marginTop = "0rem",
  inline = false,
  title,
  value,
}: Props) {
  //#region RENDER
  return value ? (
    <div
      style={{
        display: titleValueInline ? "flex" : inline ? "inline" : "block",
        gap: titleValueInline ? "0.5rem" : "0rem",
        marginRight: inline ? "0.5rem" : "0rem",
        marginTop,
      }}
    >
      {title && (
        <p
          className={className}
          key={title}
          style={{ color: title ? "#5b5959" : "initial" }}
        >
          {title.replaceAll("_", " ")}:{" "}
        </p>
      )}
      <p style={{ display: inline ? "inline" : "block" }}>
        <strong>{value.toString()}</strong>
      </p>
    </div>
  ) : null;
  //#endregion
}
