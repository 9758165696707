//#region IMPORTS
import { ReactNode } from "react";
import { motion } from "framer-motion";
//#endregion

//#region INTERFACE
interface Props {
  children: ReactNode;
}
//#endregion

export default function MainTableMotionWrapper({ children }: Props) {
  // FramerMotion variants
  const variants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
  };

  return (
    <motion.table
      variants={variants}
      initial="hidden"
      animate="visible"
      className="min-w-full divide-y divide-gray-300"
    >
      {children}
    </motion.table>
  );
}
