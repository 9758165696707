import { Icon } from "../../models/icon";

function HistoryIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 24,
}: Icon) {
  return (
    <svg viewBox="0 0 80 80" style={{ width: width + "px" }}>
      <circle fill={selected ? outline : "white"} cx="40" cy="40" r="40" />
      <g fill={selected ? selectedColor : color}>
        <path d="M45.92,23.7V44.44a1.42,1.42,0,0,1-1.37,1.48H29.63a1.41,1.41,0,0,1-1.48-1.36V41.48A1.42,1.42,0,0,1,29.52,40H40V23.7a1.42,1.42,0,0,1,1.37-1.48h3.07a1.43,1.43,0,0,1,1.48,1.37.2.2,0,0,1,0,.11" />
        <path d="M40,4.44A35.56,35.56,0,1,0,75.56,40,35.56,35.56,0,0,0,40,4.44Zm0,61.12A25.56,25.56,0,1,1,65.56,40,25.57,25.57,0,0,1,40,65.56Z" />
      </g>
    </svg>
  );
}

export default HistoryIcon;
