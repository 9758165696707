import { useTranslation } from "react-i18next";
import { useTable } from "react-table";
import SortButton from "../genericButtons/sortButton/SortButton";
import "./TableBase.css";

interface Props<Type extends object> {
  sortedColumn?: string | null;
  sortHandler?: (column: string) => void;
  descending?: boolean;
  classname?: string;
  children?: any;
  columns: any[];
  action: (row: any) => void;
  deleteAction?: (row: any) => void;
  data: Type[];
}

function TableBase<Type extends object>({
  sortedColumn,
  sortHandler,
  descending,
  classname = "table-base",
  children,
  columns,
  action,
  deleteAction,
  data,
}: Props<Type>) {
  // Translation
  const { t } = useTranslation();

  // React table setup
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table {...getTableProps()} className={classname}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className={`${classname}__row`}
          >
            {headerGroup.headers.map((column: any) => (
              <th
                {...column.getHeaderProps()}
                className={`${classname}__cell--${column.id}`}
              >
                {column.render("Header")}
                {column.sortable && (
                  <span style={{ verticalAlign: "middle" }}>
                    <SortButton
                      selected={sortedColumn === column.id}
                      sorted={sortedColumn !== null}
                      descending={descending}
                      clickHandler={() => sortHandler && sortHandler(column.id)}
                    />
                  </span>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className={`${classname}__body`}>
        {children}
        {rows.map((row: any) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={`${classname}__row`}>
              {row.cells.map((cell: any) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={`${classname}__cell--${cell.column.id}`}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
              <td>
                <button
                  className="inline-flex items-center rounded-full bg-amber-200 px-2 py-1 text-xs font-medium text-amber-900"
                  onClick={() => action(row)}
                >
                  {t("rituals.detail").toUpperCase()}
                </button>
              </td>

              {deleteAction && (
                <td>
                  <button
                    className="inline-flex items-center rounded-full bg-red-200 px-2 py-1 text-xs font-medium text-red-900"
                    onClick={() => deleteAction(row)}
                  >
                    {t("rituals.delete").toUpperCase()}
                  </button>{" "}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TableBase;
