//#region IMPORTS
import { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { Order } from "../../models/order";
import { Item } from "../../models/item";
import OrderDetailPageHeader from "../../components/orderDetail/OrderDetailPageHeader";
import OrderdetailPageTable from "../../components/orderDetail/OrderdetailPageTable";
import DownloadButtonList from "../../components/genericButtons/downloadButton/DownloadButtonList";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import DownLoadButton from "../../components/genericButtons/downloadButton/DownLoadButton";
import RedirectButton from "../../components/genericButtons/redirectButton/RedirectButton";
import useHQmanager from "../../hooks/useHQmanager";
import NotFound from "../../components/errors/NotFound";
import agent from "../../api/agent";
import "./OrderDetailPage.css";
//#endregion

export default function OrderDetailPage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const { user } = useAppSelector((state) => state.account);
  const { id } = useParams<{ id: string }>();

  // Local state
  const [orderLoaded, setOrderLoaded] = useState<boolean>(false);
  const [detailGroup, setDetailGroup] = useState<string[]>([]);
  const [order, setOrder] = useState<Order | null>(null);
  const isHQTeam = useHQmanager(user);
  const isPicker = usePickerManager(user);
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region LOGIC
  // Load order
  useEffect(() => {
    if (!orderLoaded) {
      const fetchorderWithSuborders = async () => {
        const { data } = await agent.Orders.detail(parseInt(id));
        setOrder(data);
        setOrderLoaded(true);
      };
      fetchorderWithSuborders();
    }
  }, [id, order, orderLoaded]);

  // Include checker
  const detailgroupChecker = (filtername: string): boolean => {
    return detailGroup.includes(filtername);
  };

  // Toggle view
  const toggleFilter = (filtername: string) => {
    if (detailgroupChecker(filtername)) setDetailGroup([]);
    else setDetailGroup([filtername]);
  };

  // Track and trace helpers
  const getTrackAndTraceId = (
    order: Order,
    subOrderId: number
  ): string | null => {
    if (order && order.sub_orders.length > 0) {
      const subOrder = order.sub_orders.find((item) => item.id === subOrderId);
      if (subOrder) {
        const url = subOrder.transsmart.track_and_trace_url ?? null;
        const params = new URLSearchParams(url?.split("?")[1]) ?? null;
        const trackingId = params.get("tracking-id") ?? null;
        return trackingId;
      } else return null;
    } else return null;
  };

  const getTrackAndTraceUrl = (
    order: Order,
    subOrderId: number
  ): string | null => {
    if (order && order.sub_orders.length > 0) {
      const subOrder = order.sub_orders.find((item) => item.id === subOrderId);
      if (subOrder) {
        return subOrder.transsmart.track_and_trace_url;
      } else return null;
    } else return null;
  };

  // Delete Orderitems
  const deleteHandler = async (item: Item) => {
    try {
      await agent.Orders.changeItem(item.id, item.quantity, "decrease");
      setOrderLoaded(false);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  //#endregion

  //#region TABLE COLUMN SETUP
  const columns = useMemo(
    () => [
      // preview column
      {
        Header: t("rituals.preview"),
        accessor: "picture_url",
        Cell: (props: any) => (
          <img
            src={props.value}
            alt={props.value}
            className="order-detail-table__row__thumb"
            onClick={() =>
              history.push("/image/" + props.row.original.product_version_id)
            }
          />
        ),
      },
      // tracking code column
      {
        Header: t("rituals.trackingCode"),
        accessor: "sub_order_id",
        Cell: (props: any) => {
          return !props.row.isGrouped ? null : (
            <RedirectButton
              value={
                order &&
                getTrackAndTraceId(
                  order,
                  props.row.subRows[0].original.sub_order_id
                )
                  ? t("rituals.trackingCode")
                  : `${t("rituals.noTrackingCodeAvailable")} ${
                      isAdmin || isPicker || isHQTeam
                        ? `(${
                            props.row.subRows[0].original.produced_by === "Y"
                              ? "Albe De Coker"
                              : props.row.subRows[0].original.produced_by
                          })`
                        : `(parcel ${props.row.index + 1} of ${
                            props.row.subRows.length
                          })`
                    }`
              }
              address={
                order &&
                getTrackAndTraceUrl(
                  order,
                  props.row.subRows[0].original.sub_order_id
                )
              }
            />
          );
        },
      },
      // name column
      {
        Header: t("rituals.name"),
        accessor: "name",
      },
      // quantity column
      {
        Header: t("rituals.quantity"),
        accessor: "quantity",
      },
      // producer column
      {
        Header: t("rituals.producer"),
        accessor: "produced_by",
        Cell: (props: any) =>
          !props.row.isGrouped
            ? props.row.original.produced_by === "Y"
              ? "Albe De Coker"
              : props.row.original.produced_by === "Albe de Coker"
              ? "Albe De Coker"
              : props.row.original.produced_by
            : props.row.subRows[0].original.produced_by === "Y"
            ? "Albe De Coker"
            : props.row.subRows[0].original.produced_by === "Albe de Coker"
            ? "Albe De Coker"
            : props.row.subRows[0].original.produced_by,
      },
      // delete column
      {
        Header: t("rituals.delete"),
        Cell: (props: any) =>
          !props.row.isGrouped &&
          (isAdmin || isHQTeam) &&
          props.row.original.quantity > 0 ? (
            <button onClick={() => deleteHandler(props.row.original)}>
              <TrashIcon className="h-6 w-6 text-red-600" />
            </button>
          ) : null,
        Aggregated: () => null,
      },
    ],
    [t, order, isAdmin, isPicker, isHQTeam, history]
  );
  //#endregion

  //#region FAIL SAFE
  if (!orderLoaded) return <LoadingSpinner />;
  if (!order) return <NotFound />;
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* Download button panel */}
      {(isAdmin || isPicker || isHQTeam) && (
        <DownloadButtonList>
          <DownLoadButton order={order} />
        </DownloadButtonList>
      )}

      {/* Page body */}
      <section className="orders-detail-page">
        {/* Page header*/}
        <OrderDetailPageHeader
          detailgroupChecker={(value: string) => detailgroupChecker(value)}
          toggleFilter={(filterName: string) => toggleFilter(filterName)}
          isHQTeam={isHQTeam}
          isPicker={isPicker}
          isAdmin={isAdmin}
          order={order}
        />

        {/* Table */}
        {order.order_items.length > 0 ? (
          <OrderdetailPageTable
            data={order.order_items}
            columns={columns
              // Don't show delete button if storeUser
              .filter(
                ({ Header }) =>
                  Header !== t("rituals.delete") ||
                  ((isAdmin || isHQTeam) && order.order_type !== "Original")
              )
              // Don't show producer if storeUser
              .filter(
                ({ Header }) =>
                  Header !== t("rituals.producer") ||
                  isAdmin ||
                  isPicker ||
                  isHQTeam
              )

              // Don't show tracking code if not grouped
              .filter(
                ({ Header }) =>
                  Header !== t("rituals.trackingCode") || detailGroup.length > 0
              )}
            group={detailGroup}
            status={orderLoaded}
          />
        ) : orderLoaded ? (
          <h2>0 items</h2>
        ) : null}
      </section>
    </Fragment>
  );
  //#endregion
}
