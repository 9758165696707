//#region IMPORTS
import {
  setOrderParams,
  fetchOrdersAsync,
  fetchShippableOrdersAsync,
} from "./ordersSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { ORDERSTATUS, ORDERTYPE } from "../../models/order";
import { useEffect } from "react";
import OrdersContainer from "../../components/orders/OrdersContainer";
//#endregion

//#region INTERFACE
interface Props {
  title: string;
}
//#endregion

export default function OrdersManagePage({ title }: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { orders_loaded } = useAppSelector((state) => state.orders);
  //#endregion

  //#region LOGIC
  // Set page specific filters
  useEffect(() => {
    dispatch(
      setOrderParams({
        limit: 10000,
        sort: JSON.stringify({ column: "order_date", order: "desc" }),
        order_code: "",
        promo_code: "",
        order_status: ORDERSTATUS.ordered,
        order_type: ORDERTYPE.backorder,
        shop_id: "",
        printer: "",
        country: "",
        language: "",
        city: "",
        include_sub_orders: 1,
      })
    );
  }, [dispatch]);

  // Load orders
  useEffect(() => {
    const fetchOnload = async () => {
      if (!orders_loaded) {
        await dispatch(fetchOrdersAsync());
        await dispatch(fetchShippableOrdersAsync());
      }
    };
    fetchOnload();
  }, [orders_loaded, dispatch]);
  //#endregion

  //#region RENDER
  return <OrdersContainer title={title} />;
  //#endregion
}
