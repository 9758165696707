import { ProductVersion } from "../../../models/productVersion";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMemo } from "react";
import TableBase from "../../../components/table/TableBase";

interface Props {
  data: ProductVersion[];
}

function ProductVersionsTable({ data }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Column setup
  const colums = useMemo(
    () => [
      {
        Header: t("rituals.code"),
        accessor: "number",
      },
      {
        Header: t("rituals.name"),
        accessor: "name",
      },
      {
        Header: t("rituals.language"),
        accessor: "language",
      },
      {
        Header: t("rituals.producer"),
        accessor: "produced_by",
      },
      {
        Header: t("rituals.project"),
        accessor: "promo_name",
      },
    ],
    [t]
  );
  //#endregion

  //#region LOGIC
  const directToDetailPage = (id: number) => {
    history.push(`/Productversions/${id}`);
  };
  //#endregion

  //#region RENDER
  return (
    <TableBase<ProductVersion>
      columns={colums}
      data={data}
      action={(row) => directToDetailPage(row.original.id)}
    />
  );
  //#endregion
}

export default ProductVersionsTable;
