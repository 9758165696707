import { Icon } from "../../../models/icon";

function SortUnsortIcon({ color = "#fbb033", width = 20 }: Icon) {
  return (
    <svg
      viewBox="0 0 80 80"
      style={{ width: width + "px", marginLeft: width / 2 + "px" }}
    >
      <path
        fill={color}
        d="m40.09,0C17.99,0,0,17.91,0,39.92s17.99,40.08,40.09,40.08,40.08-17.98,40.08-40.08S62.19,0,40.09,0Zm0,72.62c-18.03,0-32.71-14.67-32.71-32.7S22.05,7.38,40.09,7.38s32.7,14.6,32.7,32.53-14.67,32.7-32.7,32.7Z"
      />
      <path
        fill={color}
        d="m52.47,47.81c-.95,0-1.91.36-2.63,1.08l-6,5.92v-9.04s0-.04,0-.06v-20.51l6,5.92c.73.72,1.68,1.08,2.63,1.08s1.94-.37,2.67-1.11c1.45-1.48,1.44-3.85-.04-5.31l-12.36-12.19s0,0,0,0l-.02-.02s-.06-.06-.09-.08c-.06-.05-.11-.1-.17-.15-.06-.05-.12-.09-.18-.13-.04-.03-.07-.05-.1-.08,0,0-.01,0-.02-.01-.07-.04-.13-.08-.2-.12-.03-.02-.06-.04-.09-.06-.01,0-.02-.01-.04-.02-.05-.03-.11-.05-.16-.08-.03-.02-.06-.03-.1-.05,0,0-.02,0-.03-.01-.02,0-.04-.02-.05-.02-.03-.01-.07-.02-.1-.04-.09-.03-.17-.06-.26-.09,0,0-.01,0-.02,0-1.05-.3-2.19-.13-3.12.5,0,0-.01,0-.02.01-.09.06-.18.13-.26.2-.05.04-.09.08-.13.12-.04.04-.08.07-.13.11l-.02.02s0,0,0,0l-12.36,12.19c-1.48,1.45-1.49,3.83-.04,5.3.73.75,1.7,1.12,2.67,1.12s1.91-.36,2.63-1.08l6-5.92v9.04s0,.04,0,.06v20.51l-6-5.92c-.73-.72-1.68-1.08-2.63-1.08s-1.94.37-2.67,1.11c-1.45,1.48-1.44,3.85.04,5.31l12.36,12.19s0,0,0,0l.02.02s.06.06.09.08c.06.05.11.1.17.15.06.05.12.09.18.13.04.03.07.05.1.08,0,0,.01,0,.02.01.07.04.13.08.2.12.03.02.06.04.09.06.01,0,.02.01.04.02.05.03.11.05.16.08.03.02.06.03.1.05,0,0,.02,0,.03.01.02,0,.04.02.05.02.03.01.07.02.1.04.09.03.17.06.26.09,0,0,.01,0,.02,0,1.05.3,2.19.13,3.12-.5,0,0,.01,0,.02-.01.09-.06.18-.13.26-.2.05-.04.09-.08.13-.12.04-.04.08-.07.13-.11l.02-.02s0,0,0,0l12.36-12.19c1.48-1.45,1.49-3.83.04-5.3-.73-.75-1.7-1.12-2.67-1.12Z"
      />
    </svg>
  );
}

export default SortUnsortIcon;
