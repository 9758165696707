//#region IMPORTS
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  TranslationLine,
  TranslationManagerState,
  TranslationManagerParams,
} from "../../models/translations";
import { RootState } from "../../store/configureStore";
import agent from "../../api/agent";
import getAxiosParams from "../../helpers/getAxiosParams";
//#endregion

//#region INIT
// Set initial state
const initialExtraState = {
  loading: false,
  status: "idle",
  translationsLoaded: false,
  translationParams: initParams(),
  metaData: null,
  groups: [],
  languageColums: [],
};

// Set initial query parameters
function initParams(): TranslationManagerParams {
  return {
    page: 1,
    limit: 25,
  };
}
//#endregion

//#region CREATE ADAPTER
const translationManagerAdapter = createEntityAdapter<TranslationLine>();
//#endregion

//#region READ
// Fetch All
export const fetchTranslationLinesAsync = createAsyncThunk<
  TranslationLine[],
  void,
  { state: RootState }
>("translationManager/fetchTranslationLinesAsync", async (_, thunkAPI) => {
  const translationManagerParams =
    thunkAPI.getState().translationManagement.translationParams;
  const params = getAxiosParams(translationManagerParams);
  try {
    const { data, meta } = await agent.Translations.list(params);
    thunkAPI.dispatch(setTranslationManagerMetaData(meta));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});
//#endregion

//#region DELETE
export const deleteTranslationLineAsync = createAsyncThunk<
  number,
  { id: number }
>(
  "translationManager/deleteTranslationLinesAsync",
  async ({ id }, thunkAPI) => {
    try {
      await agent.Translations.delete(id);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);
//#endregion

//#region CREATE REDUCER SLICE
export const translationManagerSlice = createSlice({
  name: "translationManager",
  initialState:
    translationManagerAdapter.getInitialState<TranslationManagerState>(
      initialExtraState
    ),
  reducers: {
    setTranslationManagerParams: (state, action) => {
      state.translationsLoaded = false;
      state.translationParams = {
        ...state.translationParams,
        ...action.payload,
        page: 1,
      };
    },
    resetTranslationManagerParams: (state) => {
      state.translationParams = initParams();
      state.translationsLoaded = false;
    },
    setTranslationManagerMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    setTranslationManagerPageNumber: (state, action) => {
      state.translationsLoaded = false;
      state.translationParams = {
        ...state.translationParams,
        ...action.payload,
      };
    },
    clearTranslationManager: () =>
      translationManagerAdapter.getInitialState<TranslationManagerState>(
        initialExtraState
      ),
  },
  extraReducers: (builder) => {
    // FETCH translation lines
    builder.addCase(fetchTranslationLinesAsync.pending, (state) => {
      state.status = "pendingFetchTranslations";
      state.loading = true;
    });
    builder.addCase(fetchTranslationLinesAsync.fulfilled, (state, action) => {
      // Get unique groups
      const uniqueGroups: string[] = [];
      action.payload.forEach((item) => {
        if (!uniqueGroups.includes(item.group)) {
          uniqueGroups.push(item.group);
        }
      });

      translationManagerAdapter.setAll(state, action.payload);
      state.status = "idle";
      state.translationsLoaded = true;
      state.groups = uniqueGroups;
      state.loading = false;
      state.languageColums =
        action.payload.length > 0
          ? [
              ...Object.keys(
                Object.fromEntries(
                  Object.entries(action.payload[0].text).sort(([a], [b]) =>
                    a === "en" ? -1 : b === "en" ? 1 : a.localeCompare(b)
                  )
                )
              ),
            ]
          : [];
    });
    builder.addCase(fetchTranslationLinesAsync.rejected, (state) => {
      state.status = "idle";
      state.groups = [];
      state.loading = false;
      state.languageColums = [];
    });

    // DELETE translation line
    builder.addCase(deleteTranslationLineAsync.pending, (state) => {
      state.status = "PendingDeleteTranslationLine";
    });
    builder.addCase(deleteTranslationLineAsync.fulfilled, (state, action) => {
      translationManagerAdapter.removeOne(state, action.payload);
      state.status = "idle";
    });
    builder.addCase(deleteTranslationLineAsync.rejected, (state) => {
      state.status = "idle";
    });
  },
});
//#endregion

//#region NORMALISED STATE
export const translationManagerSelectors =
  translationManagerAdapter.getSelectors(
    (state: RootState) => state.translationManagement
  );
//#endregion

//#region EXPORT
export const {
  setTranslationManagerPageNumber,
  setTranslationManagerMetaData,
  resetTranslationManagerParams,
  setTranslationManagerParams,
  clearTranslationManager,
} = translationManagerSlice.actions;
//#endregion
