import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Product } from "../../../models/product";
import { useMemo } from "react";
import TableBase from "../../../components/table/TableBase";

interface Props {
  data: Product[];
}

function ProductsTable({ data }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Column setup
  const colums = useMemo(
    () => [
      {
        Header: t("rituals.code"),
        accessor: "number",
      },
      {
        Header: t("rituals.name"),
        accessor: "name",
      },
      {
        Header: t("rituals.printer"),
        accessor: "produced_by",
      },
      {
        Header: t("rituals.length"),
        accessor: "length",
      },
      {
        Header: t("rituals.width"),
        accessor: "width",
      },
      {
        Header: t("rituals.material"),
        accessor: "specs_material",
      },
      {
        Header: t("rituals.sided"),
        accessor: "specs_sided",
      },
    ],
    [t]
  );
  //#endregion

  //#region LOGIC
  const directToDetailPage = (id: number) => {
    history.push(`/Products/${id}`);
  };
  //#endregion

  //#region RENDER
  return (
    <TableBase<Product>
      columns={colums}
      data={data}
      action={(row) => directToDetailPage(row.original.id)}
    />
  );
  //#endregion
}

export default ProductsTable;
