//#region IMPORTS
import {
  basketReasonSelectors,
  fetchBasketReasonsAsync,
} from "../basketReason/basketReasonSlice";
import { removeBasketItemAsync, addBasketItemAsync } from "./basketSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Item } from "../../models/item";
import Trashcan from "../../components/icons/Trashcan";
//#endregion

//#region INTERFACE
interface Props {
  item: Item;
}
//#endregion

export default function BasketPageItem({ item }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const { status } = useAppSelector((state) => state.basket);
  const basketReasons = useAppSelector(basketReasonSelectors.selectAll);
  const { basketReasonsLoaded } = useAppSelector(
    (state) => state.basketReasons
  );

  // Local state
  const [reasonId, setReasonId] = useState<number>();
  //#endregion

  //#region LOGIC
  // Fetch basket reasons
  useEffect(() => {
    !basketReasonsLoaded && dispatch(fetchBasketReasonsAsync());
  }, [basketReasonsLoaded, dispatch]);

  // Set item reason id
  useEffect(() => {
    const itemReason = basketReasons.find(
      (reason) => reason.reason === item.basket_reason
    );
    itemReason && setReasonId(itemReason.id);
  }, [basketReasons, item]);
  //#endregion

  //#region RENDER
  return (
    <div className="basket__list__item" key={item.product_version_id}>
      <div className="basket__list__item__picturebox">
        <img
          src={item.picture_url}
          className="basket__list__item__picturebox__picture"
          alt="pending"
        />
      </div>

      <div className="basket__list__item__text">
        <p>{item.name}</p>
        {item.basket_reason && (
          <p className="basket__list__item__text__reason">
            {t("rituals.reason")}:{" "}
            <b>{t(`rituals.${item.basket_reason.toLocaleLowerCase()}`)}</b>
          </p>
        )}
      </div>

      <div className="basket__list__item__controls">
        <div className="basket__list__item__controls__alterbuttons">
          <button
            className="basket__list__item__controls__alterbuttons__updatebutton"
            onClick={() =>
              dispatch(
                removeBasketItemAsync({
                  product_version_id: item.product_version_id,
                  quantity: 1,
                  promo_id: item.promo_id,
                  name: "remove",
                })
              )
            }
          >
            -
          </button>
        </div>

        <div>
          <p className="basket__list__item__controls__alterbuttons__ammount">
            {item.quantity}
          </p>
        </div>

        <div className="basket__list__item__controls__alterbuttons">
          {item.quantity < item.max_quantity && (
            <button
              className="basket__list__item__controls__alterbuttons__updatebutton"
              onClick={() =>
                !status.includes("PendingAddItem") &&
                dispatch(
                  addBasketItemAsync({
                    product_version_id: item.product_version_id,
                    promo_id: item.promo_id,
                    max_quantity: item.max_quantity,
                    quantity: 1,
                    basket_reason_id: reasonId,
                  })
                )
              }
            >
              +
            </button>
          )}
        </div>
      </div>

      <button
        className="basket__list__item__delete"
        onClick={() =>
          dispatch(
            removeBasketItemAsync({
              product_version_id: item.product_version_id,
              quantity: item.quantity,
              promo_id: item.promo_id,
              name: "del",
            })
          )
        }
      >
        <Trashcan color="#f25c5c" width={24} />
      </button>
    </div>
  );
  //#endregion
}