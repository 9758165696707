//#region IMPORTS
import { ShoppingBagIcon } from "@heroicons/react/20/solid";
import { setOrderParams } from "../../features/orders/ordersSlice";
import { useAppDispatch } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Basket } from "../../models/basket";
import { Promo } from "../../models/promo";
//#endregion

//#region INTERFACE
interface Props {
  isPicker: boolean;
  isAdmin: boolean;
  HQTeam: boolean;
  project: Promo;
  basket: Basket | null;
  latestItem: boolean;
}
//#endregion

export default function HomeProjectItem({
  project,
  latestItem,
  basket,
  isAdmin,
  isPicker,
  HQTeam,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();

  // Router
  const history = useHistory();
  //#endregion

  //#region LOGIC
  // Fetch data

  const checkoutPromo = () => {
    if (isAdmin || isPicker) {
      history.push(`/promos`);
    } else if (HQTeam) {
      dispatch(
        setOrderParams({
          promo_code: project?.code,
          order_status: "Pending",
        })
      );
      history.push(`/approve/${project.code}`);
    } else {
      project && history.push(`/backorder/promo/${project.id}`);
    }
  };
  //#endregion

  return (
    <div
      onClick={checkoutPromo}
      className={
        latestItem
          ? "group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2 cursor-pointer"
          : "group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full cursor-pointer"
      }
    >
      {/* Image */}
      <img
        src={project?.picture_url}
        alt={project.code}
        className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
      />
      {/* Overlay */}
      <div
        aria-hidden="true"
        className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
      />

      {/* Text */}
      <div className="flex items-end p-6 sm:absolute sm:inset-0">
        <div>
          <h3 className="font-semibold text-white">
            <span className="absolute inset-0" />
            {project.code}
          </h3>

          <p aria-hidden="true" className="mt-1 text-base text-white">
            {isAdmin || isPicker ? (
              `${t("rituals.moreCollections")}...`
            ) : HQTeam ? (
              t("rituals.approveOrders")
            ) : basket && basket.promo_id !== project.id ? null : (
              <span className="flex items-center gap-4">
                <ShoppingBagIcon className="w-6 text-amber-500" />
                <span
                  aria-hidden="true"
                  className="mt-1 text-base text-amber-500 font-bold text-transform: uppercase"
                >
                  {t("rituals.reorderFromThisProject")}
                </span>
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
