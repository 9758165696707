//#region IMPORTS
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { Suspense } from "react";
import { Router } from "react-router-dom";
import { store } from "./store/configureStore";
import LoadingScreen from "./components/loading/LoadingScreen";
import ReactDOM from "react-dom";
import React from "react";
import App from "./App";
import "./index.css";
import "./i18n";
//#endregion

//#region ROUTER
export const history = createBrowserHistory();
//#endregion

//#region RENDER
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingScreen showLogo={false} />}>
      <Router history={history}>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
//#endregion
