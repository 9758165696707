//#region IMPORTS
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { BackOrderState } from "../../models/backorder";
import { RootState } from "../../store/configureStore";
import { Order } from "../../models/order";
import { Item } from "../../models/item";
import agent from "../../api/agent";
//#endregion

//#region CREATE ADAPTER
const backOrderAdapter = createEntityAdapter<Item>();
//#endregion

//#region READ
export const fetchBackorderItemsAsync = createAsyncThunk<
  Order,
  number,
  { state: RootState }
>("backOrder/fetchBackorderItemsAsync", async (promo_id, thunkAPI) => {
  // const instoreProducts = thunkAPI.getState().... ; HIER later instore products inladen
  try {
    const response = await agent.Orders.listPromoIdShop(promo_id, "Original");
    return response.data[0];
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});
//#endregion

//#region CREATE REDUCER SLICE
export const backOrderSlice = createSlice({
  name: "backOrder",
  initialState: backOrderAdapter.getInitialState<BackOrderState>({
    backOrder_loaded: false,
    promo_id: 0,
    status: "idle",
    order_code: "",
  }),
  reducers: {
    clearBackOrder: () =>
      backOrderAdapter.getInitialState<BackOrderState>({
        backOrder_loaded: false,
        promo_id: 0,
        status: "idle",
        order_code: "",
      }),
  },
  extraReducers: (builder) => {
    // FETCH backorderable items
    builder.addCase(fetchBackorderItemsAsync.pending, (state) => {
      state.status = "pendingFetchBackorderItemsAsync";
    });
    builder.addCase(fetchBackorderItemsAsync.fulfilled, (state, action) => {
      if (action.payload) {
        backOrderAdapter.setAll(state, action.payload.order_items);
        state.status = "idle";
        state.backOrder_loaded = true;
        state.order_code = action.payload.order_code;
        state.promo_id = action.payload.promo_id;
      } else {
        state.status = "idle";
        state.backOrder_loaded = false;
        state.order_code = "";
        state.promo_id = 0;
      }
    });
    builder.addCase(fetchBackorderItemsAsync.rejected, (state) => {
      state.status = "idle";
    });
  },
});
//#endregion

//#region NORMALISED STATE
export const backorderItemsSelectors = backOrderAdapter.getSelectors(
  (state: RootState) => state.backOrderItems
);
//#endregion

//#region EXPORTS
export const { clearBackOrder } = backOrderSlice.actions;
//#endregion
