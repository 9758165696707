import { useScrollBlock } from "../../hooks/useScrollBlock";
import { useEffect, useState } from "react";
import "./Modal.css";

interface Props {
  resetHandler?: () => void;
  delay?: number;
  children: any;
  showTimeLeft?: boolean;
}

function Modal({
  resetHandler,
  delay = 1000,
  children,
  showTimeLeft = false,
}: Props) {
  const [timeLeft, setTimeLeft] = useState<number | null>(delay / 1000);

  // Remove automaticaly me after delay
  useEffect(() => {
    if (delay > 1000) {
      const timer = setTimeout(() => {
        resetHandler && resetHandler();
      }, delay);
      return () => clearTimeout(timer);
    }
  }, [resetHandler, showTimeLeft, delay]);

  // Time left
  useEffect(() => {
    if (showTimeLeft) {
      if (timeLeft === 0) {
        setTimeLeft(null);
      }
      if (!timeLeft) return;
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [timeLeft, showTimeLeft]);

  // Scrollblock
  const [blockScroll, allowScroll] = useScrollBlock();
  useEffect(() => {
    blockScroll();
    return () => {
      allowScroll();
    };
  }, [blockScroll, allowScroll]);

  return (
    <div className="modal-container" onClick={resetHandler}>
      <div className="modal-container__modal">
        {showTimeLeft ? <div>closes in {timeLeft} seconds</div> : null}
        {children}
      </div>
    </div>
  );
}

export default Modal;
