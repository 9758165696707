//#region IMPORTS
import { setSelectedSubOrder } from "../../features/orders/ordersSlice";
import { SHOW_SUBORDER_INFO } from "../../models/_consts";
import { useAppDispatch } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { openModal } from "../../features/dashBoard/dashboardSlice";
import { SubOrder } from "../../models/suborder";
import { Order } from "../../models/order";
import OrderStatusBadge from "./OrderStatusBadge";
import OrderParcelCount from "./OrderParcelCount";
//#endregion

//#region INTERFACE
interface Props {
  isPicker?: boolean;
  isHQTeam?: boolean;
  isAdmin?: boolean;
  subOrder: SubOrder;
  order: Order;
}
//#endregion

export default function OrdersTableRowSubOrder({
  subOrder,
  isPicker = false,
  isHQTeam = false,
  isAdmin = false,
  order,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  //#endregion

  //#region LOGIC
  // Redirect to detail page
  const showToSuborderDetailModal = () => {
    dispatch(setSelectedSubOrder({ subOrder, order }));
    dispatch(openModal(SHOW_SUBORDER_INFO));
  };

  //#endregion

  console.log(isHQTeam);
  //#region RENDER
  return (
    <tr>
      {(isAdmin || isPicker || isHQTeam) && <td className="relative px-7" />}
      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0" />
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500" />
      {!isAdmin && !isPicker && !isHQTeam && <td className="relative px-7" />}
      {/* Parcel ammount */}
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-t border-white bg-gray-100">
        <OrderParcelCount order={order} subOrder={subOrder} />
      </td>

      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-t border-white bg-gray-100" />

      {/* Order status */}
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-t border-white bg-gray-100">
        <OrderStatusBadge value={subOrder.order_status} />
      </td>

      {/* Items */}
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-t border-white bg-gray-100">
        <span className="font-bold">
          {
            order.order_items.filter(
              (orderItem) => orderItem.sub_order_id === subOrder.id
            ).length
          }
        </span>
        {isAdmin || isPicker || isHQTeam ? (
          <span>{` by ${subOrder.suppliers.split("(")[0]}`}</span>
        ) : (
          <span>{` item${
            order.order_items.filter(
              (orderItem) => orderItem.sub_order_id === subOrder.id
            ).length > 1
              ? "s"
              : ""
          }`}</span>
        )}
      </td>

      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-t border-white bg-gray-100" />

      {/* Track and trace info */}
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-t border-white bg-gray-100">
        {subOrder.transsmart.track_and_trace_url ? (
          <a
            href={subOrder.transsmart.track_and_trace_url}
            target="_blank"
            rel="noreferrer"
            className="rounded-full bg-emerald-500 px-2 py-1 text-xs font-bold text-white"
          >
            TRACK PARCEL
          </a>
        ) : (
          <span className="py-1 text-sm text-gray-600">
            no tracking info available
          </span>
        )}
      </td>

      {isHQTeam && (
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-t border-white bg-gray-100" />
      )}

      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-t border-white bg-gray-100">
        <button
          onClick={showToSuborderDetailModal}
          className="inline-flex items-center rounded-full bg-sky-200 px-2 py-1 text-xs font-medium text-sky-900"
        >
          {t("rituals.parcelDetail").toUpperCase()}
        </button>
      </td>
    </tr>
  );
  //#endregion
}
