import { Icon } from "../../models/icon";

function ArrowIconDown({ color = "#000000", width = 32 }: Icon) {
  return (
    <svg viewBox="0 0 80 80" fill={color} style={{ width: width + "px" }}>
      <polygon points="70,48.2 62.7,41.2 45,59.9 45,0 35,0 35,59.9 17.3,41.1 10,48.2 40,80 " />
    </svg>
  );
}

export default ArrowIconDown;
