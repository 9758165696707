//#region IMPORTS
import {
  setOrderParams,
  resetOrderParams,
} from "../../features/orders/ordersSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { OrderParams } from "../../models/order";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";
import FilterBarComboBox from "../filterBar/FilterBarComboBox";
import FilterBarSearch from "../filterBar/FilterBarSearch";
import useFilterCount from "../../hooks/useFilterCount";
//#endregion

export default function OrdersCustomFilters() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.orders);

  // Set Filters
  const {
    page,
    sort,
    limit,
    include_sub_orders,
    ...selectedFilters
  }: OrderParams = useAppSelector((state) => state.orders.order_params);
  const filterCount = useFilterCount(selectedFilters);
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* Search */}
      <h2 className="ml-8 mb-4 font-semibold text-amber-600 text-2xl">
        {t("rituals.search")}
      </h2>
      <FilterBarSearch
        setSearchParamHandler={(value) =>
          dispatch(setOrderParams({ order_code: value }))
        }
        title={`${t("rituals.orderCode")}`}
      />

      {/* Filters */}
      <div className="flex justify-between">
        <h2 className="ml-8 mb-4 font-semibold text-amber-600 text-2xl">
          {t("rituals.filters")}
        </h2>
        {filterCount > 0 && (
          <button
            onClick={() => {
              dispatch(resetOrderParams());
              history.push("/orders");
            }}
            className="filterbar__content__section__header__clearbutton"
          >
            {t("rituals.clearAll")}
          </button>
        )}
      </div>

      {/* Promo code */}
      {filters.promo_code.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setOrderParams({ promo_code: value }))
          }
          items={filters.promo_code}
          title={t("rituals.promoCode")}
          selectedValue={selectedFilters.promo_code ?? ""}
        />
      )}

      {/* City */}
      {filters.city.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setOrderParams({ city: value }))
          }
          items={filters.city}
          title={t("rituals.city")}
          selectedValue={selectedFilters.city ?? ""}
        />
      )}

      {/* Country */}
      {filters.country_name.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setOrderParams({ country_name: value }))
          }
          items={filters.country_name}
          title={t("rituals.countryName")}
          selectedValue={selectedFilters.country_name ?? ""}
        />
      )}

      {/* Language */}
      {filters.language.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setOrderParams({ language: value }))
          }
          items={filters.language}
          title={t("rituals.language")}
          selectedValue={selectedFilters.language ?? ""}
        />
      )}

      {/* Order status */}
      {filters.order_status.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setOrderParams({ order_status: value }))
          }
          items={filters.order_status}
          title={t("rituals.orderStatus")}
          selectedValue={selectedFilters.order_status ?? ""}
        />
      )}

      {/* Order type */}
      {filters.order_type.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setOrderParams({ order_type: value }))
          }
          items={filters.order_type}
          title={t("rituals.orderType")}
          selectedValue={selectedFilters.order_type ?? ""}
        />
      )}
    </Fragment>
  );
  //#endregion
}
