//#region IMPORTS
import { promosSelectors } from "../../features/promos/promosSlice";
import { useAppSelector } from "../../store/configureStore";
import { Promo } from "../../models/promo";
import PromosPageItem from "./PromosPageItem";
//#endregion

//#region INTERFACE
interface Props {
  overWriteTransSmartHandler: (promo: Promo) => void;
  refreshTransmartHandler: (promo: Promo) => void;
  setProjectToDelete: (promo: Promo) => void;
  setProjectToEdit: (promo: Promo, uploadData: boolean) => void;
  isPicker: boolean;
  isAdmin: boolean;
  hqTeam: boolean;
}
//#endregion

export default function PromosPageItems({
  overWriteTransSmartHandler,
  refreshTransmartHandler,
  setProjectToDelete,
  setProjectToEdit,
  isPicker,
  isAdmin,
  hqTeam,
}: Props) {
  //#region SETUP
  // Redux
  const { basket } = useAppSelector((state) => state.basket);
  const promos = useAppSelector(promosSelectors.selectAll);
  const currentPromo = useAppSelector(
    (state) => basket && promosSelectors.selectById(state, basket.promo_id)
  );
  //#endregion

  //#region RENDER
  return (
    <ul className="divide-y divide-gray-100">
      {promos.map((promo) => (
        <PromosPageItem
          overWriteTransSmartHandler={() => overWriteTransSmartHandler(promo)}
          refreshTransmartHandler={() => refreshTransmartHandler(promo)}
          setProjectToDelete={() => setProjectToDelete(promo)}
          setProjectToEdit={() => setProjectToEdit(promo, false)}
          promoInBasket={currentPromo}
          uploadHandler={() => setProjectToEdit(promo, true)}
          isPicker={isPicker}
          isAdmin={isAdmin}
          hqTeam={hqTeam}
          promo={promo}
          key={promo.id}
        />
      ))}
    </ul>
  );
  //#endregion
}
