//#region IMPORTS
import { ReactNode } from "react";
import { motion } from "framer-motion";
//#endregion

//#region INTERFACE
interface Props {
  children?: ReactNode;
}
//#endregion

export default function LoadingOverlay({ children }: Props) {
  // FramerMotion variants
  const variants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 0.6,
    },
  };
  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      className="fixed bg-white left-0 h-screen w-screen z-2"
    >
      {children}
    </motion.div>
  );
}
