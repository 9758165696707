import {
  resetProductVersionParams,
  setProductVersion,
} from "../productVersionsSlice";
import { useForm, FieldValues } from "react-hook-form";
import { useEffect, useState } from "react";
import { ProductVersion } from "../../../models/productVersion";
import { useAppDispatch } from "../../../store/configureStore";
import { toast } from "react-toastify";
import SaveButton from "../../../components/genericButtons/saveButton/SaveButton";
import agent from "../../../api/agent";
import "./ProductVersionsForm.css";

interface Props {
  productVersion?: ProductVersion;
  cancelCUD: () => void;
  directTo: () => void;
}

const ProductVersionsCreateForm = ({
  cancelCUD,
  productVersion,
  directTo,
}: Props) => {
  // LOCAL STATE
  const [iAmAware, setIamAware] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });

  useEffect(() => {
    if (productVersion) reset(productVersion);
  }, [productVersion, reset]);

  async function submitForm(data: FieldValues) {
    try {
      let response: ProductVersion;
      if (productVersion) {
        response = await agent.ProductVersions.updateProductVersion(
          productVersion.id,
          data
        );
        dispatch(resetProductVersionParams());
      } else {
        response = await agent.Products.createProduct(data);
      }
      dispatch(setProductVersion(response));
      cancelCUD();
    } catch (error) {
      toast.error(`${error}`);
    }
  }
  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className={`productversions-form${
        isValid ? " productversions-form--valid" : ""
      }`}
    >
      <div className="productversions-form__fields">
        {/* LEFT COLUMN */}
        <fieldset className="productversions-form__inputgroup">
          {/* PRODUCTVERSION ID FIELD */}
          <label
            className={
              errors.id ? "productversions-form__inputgroup__label--error" : ""
            }
          >
            {errors.id ? errors?.id?.message : "Productversion id code"}
          </label>
          <input
            className="productversions-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. 1221001NL"
            {...register("id", {
              required: "Productversion id code is required",
              minLength: { value: 4, message: "at least 4 characters please" },
            })}
          />

          {/* PRODUCT ID FIELD */}
          <label
            className={
              errors.productId
                ? "productversions-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.productId ? errors?.productId?.message : "Product id code"}
          </label>
          <input
            className="productversions-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. 1221001"
            {...register("productId", {
              required: "Product id code is required",
              minLength: { value: 4, message: "at least 4 characters please" },
            })}
          />

          {/* PRODUCTVERSION LANGUAGE FIELD */}
          <label
            className={
              errors.language
                ? "productversions-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.language ? errors?.language?.message : "Language"}
          </label>
          <input
            className="productversions-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. NL"
            {...register("language", {
              required: "Language is required",
              minLength: { value: 1, message: "at least 1 character please" },
            })}
          />
        </fieldset>

        {/* RIGHT COLUMN */}
        <fieldset className="productversions-form__inputgroup">
          {/* PRODUCTVERSION COUNTRY FIELD */}
          <label
            className={
              errors.country
                ? "productversions-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.country ? errors?.country?.message : "Country code"}
          </label>
          <input
            className="productversions-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. BE"
            {...register("country", {
              required: "Length is required",
              minLength: { value: 1, message: "at least 1 characters please" },
            })}
          />

          {/* PRODUCTVERSION ALTERNATION ID FIELD */}
          <label
            className={
              errors.alternationId
                ? "productversions-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.alternationId
              ? errors?.alternationId?.message
              : "AlternationId"}
          </label>
          <input
            className="productversions-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. 1221001INT"
            {...register("alternationId")}
          />

          {/* PRODUCTVERSION ALTERNATION ID FIELD */}
          <label
            className={
              errors.promoId
                ? "productversions-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.promoId ? errors?.promoId?.message : "PromoId"}
          </label>
          <input
            className="productversions-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. 1221XMAS"
            {...register("promoId", {
              required: "PromoId is required",
              minLength: { value: 1, message: "at least 1 characters please" },
            })}
          />
        </fieldset>
      </div>

      <div className="productversions-form__actions">
        <SaveButton
          type="submit"
          value={isSubmitting ? "Saving..." : "Save"}
          // className="save-button__value"
          valid={isValid && iAmAware}
        />
        <input
          className="promo-form__inputgroup__inputfield--checkbox aware-of-trouble--checkbox"
          type="checkbox"
          checked={iAmAware}
          onChange={() => setIamAware((prev) => !prev)}
        />
        <label className="aware-of-trouble">
          I am aware this changes real data
        </label>
      </div>
    </form>
  );
};

export default ProductVersionsCreateForm;
