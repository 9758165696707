//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { toggleDash } from "./dashboardSlice";
import { motion } from "framer-motion";
import DashboardNavToggle from "./DashboardNavToggle";
import DashboardNavList from "./DashboardNavList";
import useBreakpoints from "../../hooks/useBreakPoints";
//#endregion

export default function DashBoardNav() {
  //#region SETUP
  const dispatch = useAppDispatch();
  const { isXs } = useBreakpoints();
  const { dashOpen } = useAppSelector((state) => state.dashboard);
  const asideVariants = {
    open: { width: "200px" },
    closed: { width: "80px" },
  };
  //#endregion

  //#region RENDER
  return isXs ? null : (
    <motion.aside
      className="dashboard__side"
      transition={{ type: "spring", mass: 0.5 }}
      animate={dashOpen ? "open" : "closed"}
      variants={asideVariants}
    >
      <motion.nav
        className="dashboard__side__menu"
        transition={{ type: "spring", mass: 0.5 }}
        animate={dashOpen ? "open" : "closed"}
        variants={asideVariants}
      >
        <div className="dashboard__side__menu__controls">
          <DashboardNavToggle
            isOpen={dashOpen}
            onClick={() => dispatch(toggleDash())}
            strokeWidth="4"
            color="#000000"
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            width="32"
            height="12"
          />
          <DashboardNavList iconwidth={32} dashOpen={dashOpen} />
        </div>

        <div className="dashboard__side__menu__line" />
      </motion.nav>
    </motion.aside>
  );
  //#endregion
}
