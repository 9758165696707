//#region INTERFACE
interface Props {
  saveHandler: () => void;
  addHandler: () => void;
  createMode: boolean;
}
//#endregion

export default function LanguageContainerHeader({
  saveHandler,
  addHandler,
  createMode,
}: Props) {
  //#region RENDER
  return (
    <div className="border-b border-gray-200 bg-white py-5 mb-4">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Languages
          </h3>
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">
          {createMode ? (
            <button
              onClick={saveHandler}
              type="button"
              className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          ) : (
            <button
              onClick={addHandler}
              type="button"
              className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create language
            </button>
          )}
        </div>
      </div>
    </div>
  );
  //#endregion
}
