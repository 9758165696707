//#region IMPORTS
import { useTranslation } from "react-i18next";
import { MetaData } from "../../models/pagination";
import { Language } from "../../models/language";
import { useState } from "react";
import MainTableMotionWrapper from "../_layout/MainTableMotionWrapper";
import LanguageListItem from "./LanguageListItem";
import LoadingOverlay from "../loading/LoadingOverlay";
//#endregion

//#region INTERFACE
interface Props {
  languagesLoaded: boolean;
  languages: Language[];
  metaData: MetaData | null;
}
//#endregion

export default function LanguageList({ languagesLoaded, languages }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Local state
  const [selectedLanguage, setSelectedLanguage] = useState<Language>();
  //#endregion

  //#region FAILSAFE
  if (languagesLoaded && languages.length <= 0)
    return <h2>{t("rituals.notFound")}</h2>;
  //#endregion

  //#region RENDER
  return (
    <div className="relative">
      {!languagesLoaded && <LoadingOverlay />}
      <MainTableMotionWrapper>
        <tbody>
          <tr className="divide-y divide-gray-100">
            {languages.map((language) => (
              <LanguageListItem
                language={language}
                key={language.id}
                isSelected={
                  selectedLanguage && selectedLanguage.id === language.id
                }
                selectHandler={(value: Language) => setSelectedLanguage(value)}
              />
            ))}
          </tr>
        </tbody>
      </MainTableMotionWrapper>
    </div>
  );
  //#endregion
}
