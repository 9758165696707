//#region IMPORTS
import {
  resetDashboard,
  toggleFilterBar,
} from "../../../features/dashBoard/dashboardSlice";
import { useAppDispatch, useAppSelector } from "../../../store/configureStore";
import { useTranslation } from "react-i18next";
import FilterIcon from "../../icons/FilterIcon";
import CancelIcon from "../../icons/CancelIcon";
import "./FilterButton.css";
//#endregion

//#region INTERFACE
interface Props {
  filterCount?: number;
  disabled?: boolean;
}
//#endregion

export default function FilterButton({
  filterCount = 0,
  disabled = false,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const { filterBarOpen } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  //#endregion

  //#region RENDER
  return (
    <button
      disabled={disabled}
      onClick={() =>
        !filterBarOpen
          ? dispatch(toggleFilterBar())
          : dispatch(resetDashboard())
      }
      className={`filterbutton${disabled ? " fiterbutton--disabled" : ""}`}
    >
      {!filterBarOpen ? (
        <div className="filterbutton__open">
          <FilterIcon /> {t("rituals.filters")}
          {filterCount > 0 && (
            <div className="filterbutton__ammount">{filterCount} </div>
          )}
        </div>
      ) : (
        <div className="filterbutton__close">
          <div className="filterbutton__close__icon">
            <CancelIcon />
          </div>
          {t("rituals.close")}
        </div>
      )}
    </button>
  );
  //#endregion
}
