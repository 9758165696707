//#region IMPORTS
import {
  fetchFilters,
  setProductParams,
  productsSelectors,
  fetchProductsAsync,
  resetProductParams,
  setProductPageNumber,
} from "./productsSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useState, useEffect, Fragment } from "react";
import { closeFilterBar } from "../dashBoard/dashboardSlice";
import { useTranslation } from "react-i18next";
import { ProductParams } from "../../models/product";
import { motion } from "framer-motion";
import MainMotionSection from "../../components/_layout/MainMotionSection";
import useAdminManager from "../../hooks/useAdminManager";
import FilterBarSearch from "../../components/filterBar/FilterBarSearch";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import useFilterCount from "../../hooks/useFilterCount";
import ProductsTable from "./productsTable/ProductsTable";
import PaginationBar from "../../components/paginationBar/PaginationBar";
import MainContainer from "../../components/_layout/MainContainer";
import ProductsForm from "./productsForm/ProductsForm";
import CancelIcon from "../../components/icons/CancelIcon";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import FilterBar from "../../components/filterBar/FilterBar";
import "./Products.css";
//#endregion

export default function ProductsPage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux state
  const dispatch = useAppDispatch();
  const products = useAppSelector(productsSelectors.selectAll);
  const { user } = useAppSelector((state) => state.account);
  const { products_loaded, filters_loaded, metaData } = useAppSelector(
    (state) => state.products
  );

  // Set Filters
  const { page, limit, ...selectedFilters }: ProductParams = useAppSelector(
    (state) => state.products.product_params
  );
  const filters = useAppSelector((state) => state.products.filters);
  const filterCount = useFilterCount(selectedFilters);

  // Role state
  const isAdmin = useAdminManager(user);

  // Local state
  const [create, setCreate] = useState<boolean>(false);
  //#endregion

  //#region LOGIC
  // Load filters
  useEffect(() => {
    if (!filters_loaded) {
      dispatch(fetchFilters());
    }
  }, [filters_loaded, dispatch]);

  // Load all products
  useEffect(() => {
    if (!products_loaded) dispatch(fetchProductsAsync());
  }, [products_loaded, dispatch]);

  // Create new product
  const toggleCreate = () => {
    setCreate((prev) => !prev);
  };

  // Close filterbar onleave
  useEffect(() => {
    return () => {
      dispatch(closeFilterBar());
    };
  }, [dispatch]);
  //#endregion

  //#region FAIL SAFE
  if (!products_loaded && !filters_loaded) return <LoadingSpinner />;
  //#endregion

  //#region RENDER
  return (
    <MainContainer>
      <FilterBar
        selectedFilters={selectedFilters}
        resetFilterHandler={() => dispatch(resetProductParams())}
        setParamsHandler={(values: any) => {
          dispatch(setProductParams(values));
        }}
        filters={filters}
        directTo="/products"
      >
        <h2 className="ml-8 font-semibold text-amber-600 text-2xl">
          {t("rituals.search")}
        </h2>
        <FilterBarSearch
          setSearchParamHandler={(values) =>
            dispatch(setProductParams({ name: values }))
          }
          title={t("rituals.name")}
        />
        <FilterBarSearch
          setSearchParamHandler={(values) =>
            dispatch(setProductParams({ code: values }))
          }
          title={t("rituals.code")}
        />
      </FilterBar>

      <MainMotionSection>
        <PageHeader
          createClickHandler={toggleCreate}
          createButtonValue={create ? t("rituals.save") : t("rituals.add")}
          filterCount={filterCount}
          isAdmin={isAdmin}
          create={create}
          filter
          title={t("rituals.products")}
        />

        {/* Create from form*/}
        {isAdmin &&
          (create ? (
            <div className="products-page__content__new-box">
              <motion.button
                whileHover={{ scale: 1.1, rotate: "90deg" }}
                className="products-page__content__new-box__cancel"
                onClick={toggleCreate}
              >
                <CancelIcon />
              </motion.button>
              <ProductsForm cancelCUD={toggleCreate} />
            </div>
          ) : (
            <Fragment>
              {products.length > 0 ? (
                <ProductsTable data={products} />
              ) : filterCount > 0 ? (
                <h2>{t("rituals.noProductsFound")}</h2>
              ) : null}
              <footer>
                {metaData && metaData?.total > 0 && (
                  <PaginationBar
                    metaData={metaData}
                    onPageChange={(page: number) =>
                      dispatch(setProductPageNumber({ page: page }))
                    }
                    pageType={t("rituals.products")}
                    loaded={products_loaded}
                  />
                )}
              </footer>
            </Fragment>
          ))}
      </MainMotionSection>
    </MainContainer>
  );
  //#endregion
}
