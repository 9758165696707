import { Icon } from "../../models/icon";

function PromoIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 24,
}: Icon) {
  return (
    <svg viewBox="0 0 80 80" style={{ width: width + "px" }}>
      <g>
        <g>
          <path
            fill={selected ? outline : "white"}
            d="M40,0c-1.7,0-3.3,0.7-4.4,2l-3.5,4.1l-5-2.2c-0.7-0.3-1.5-0.5-2.4-0.5c-0.8,0-1.7,0.2-2.5,0.5
        c-1.6,0.7-2.7,2.1-3.1,3.8l-1.3,5.1l-5.4,0.1c-1.7,0-3.3,0.8-4.4,2.1c-1.1,1.3-1.5,3.1-1.2,4.8L8,24.9l-4.8,2.4
        c-1.6,0.8-2.7,2.2-3.1,3.8c-0.4,1.7,0,3.5,1.1,4.8L4.5,40l-3.2,4.1c-1.1,1.4-1.5,3.1-1.1,4.8c0.4,1.7,1.5,3.1,3,3.8L8,55.1
        l-1.1,5.1C6.5,61.9,7,63.6,8.1,65c1.1,1.3,2.7,2.1,4.4,2.1l5.5,0.1l1.3,5.1c0.4,1.7,1.6,3,3.1,3.8c0.8,0.4,1.6,0.5,2.5,0.5
        c0.8,0,1.6-0.2,2.4-0.5l5-2.2l3.5,4.1c1.1,1.3,2.7,2,4.4,2c1.7,0,3.3-0.7,4.4-2l3.5-4.1l5,2.2c0.7,0.3,1.5,0.5,2.4,0.5
        c0.9,0,1.7-0.2,2.4-0.5c1.6-0.7,2.7-2.1,3.1-3.8l1.3-5.1l5.4-0.1c1.7,0,3.3-0.8,4.4-2.1c1.1-1.3,1.5-3.1,1.2-4.8L72,55.1l4.8-2.4
        c1.6-0.8,2.7-2.2,3.1-3.8c0.4-1.7,0-3.5-1.1-4.8L75.5,40l3.2-4.1c1.1-1.4,1.5-3.1,1.1-4.8c-0.4-1.7-1.5-3.1-3-3.8L72,24.9l1.1-5.1
        c0.4-1.7-0.1-3.5-1.2-4.8c-1.1-1.3-2.7-2.1-4.4-2.1l-5.5-0.1l-1.3-5.1c-0.4-1.7-1.6-3-3.1-3.8c-0.8-0.4-1.6-0.6-2.5-0.6
        c-0.8,0-1.6,0.2-2.4,0.5l-5,2.2L44.4,2C43.3,0.7,41.7,0,40,0L40,0z"
          />
        </g>
      </g>
      <g>
        <path
          fill={selected ? selectedColor : color}
          d="M36.4,32c0-2.5-2-4.4-4.6-4.4c-1.9,0-3.5,1.1-4.2,2.7c-0.2,0.5-0.4,1.1-0.4,1.7c0,2.5,2,4.4,4.6,4.4
    C34.3,36.4,36.4,34.5,36.4,32z"
        />
        <path
          fill={selected ? selectedColor : color}
          d="M52,45.5c-0.5-0.7-1.2-1.2-1.9-1.6c0,0,0,0-0.1,0c-0.5-0.2-1.1-0.3-1.8-0.3c-2.5,0-4.6,2-4.6,4.4c0,2.5,2,4.4,4.6,4.4
    c2.5,0,4.6-2,4.6-4.4c0-0.6-0.1-1.2-0.4-1.7C52.3,46,52.1,45.7,52,45.5z"
        />
        <path
          fill={selected ? selectedColor : color}
          d="M69.9,40l5.4-6.8c0.2-0.3,0.3-0.7,0.2-1.1c-0.1-0.4-0.3-0.7-0.7-0.9l-7.9-3.9l1.8-8.5c0.1-0.4,0-0.8-0.3-1.1
    c-0.2-0.3-0.6-0.5-1-0.5l-8.8-0.2l-2.1-8.4c-0.1-0.4-0.4-0.7-0.7-0.9c-0.4-0.2-0.8-0.2-1.1,0l-8,3.6L41,4.9c-0.5-0.6-1.5-0.6-2,0
    l-5.6,6.7l-8-3.6c-0.4-0.2-0.8-0.2-1.1,0c-0.4,0.2-0.6,0.5-0.7,0.9l-2.1,8.4l-8.8,0.2c-0.4,0-0.8,0.2-1,0.5
    c-0.2,0.3-0.3,0.7-0.3,1.1l1.8,8.5l-7.9,3.9c-0.4,0.2-0.6,0.5-0.7,0.9c-0.1,0.4,0,0.8,0.2,1.1l5.4,6.8l-5.4,6.8
    c-0.2,0.3-0.3,0.7-0.2,1.1c0.1,0.4,0.3,0.7,0.7,0.9l7.9,3.9l-1.8,8.5c-0.1,0.4,0,0.8,0.3,1.1c0.2,0.3,0.6,0.5,1,0.5l8.8,0.2
    l2.1,8.4c0.1,0.4,0.4,0.7,0.7,0.9c0.4,0.2,0.8,0.2,1.1,0l8-3.6l5.6,6.7c0.3,0.3,0.6,0.5,1,0.5c0.4,0,0.8-0.2,1-0.5l5.6-6.7l8,3.6
    c0.4,0.2,0.8,0.2,1.1,0c0.4-0.2,0.6-0.5,0.7-0.9l2.1-8.4l8.8-0.2c0.4,0,0.8-0.2,1-0.5c0.2-0.3,0.3-0.7,0.3-1.1l-1.8-8.5l7.9-3.9
    c0.4-0.2,0.6-0.5,0.7-0.9c0.1-0.4,0-0.8-0.2-1.1L69.9,40z M24.6,32c0-0.5,0.1-1,0.1-1.4c0.1-0.5,0.2-0.9,0.4-1.3s0.4-0.8,0.7-1.2
    c1.2-1.7,3.1-2.8,5.2-3.1c0.2,0,0.5,0,0.7,0c0.5,0,1,0,1.4,0.1c0.5,0.1,0.9,0.2,1.3,0.4c0.4,0.2,0.8,0.4,1.2,0.6
    c0.8,0.5,1.4,1.1,1.9,1.9c0.3,0.4,0.5,0.8,0.7,1.2c0.2,0.4,0.3,0.9,0.4,1.3C39,31,39,31.5,39,32c0,3.4-2.5,6.2-5.7,6.9
    c-0.5,0.1-1,0.1-1.4,0.1c-0.2,0-0.5,0-0.7,0C27.5,38.6,24.6,35.6,24.6,32z M29.7,51.8c-0.2,0.2-0.6,0.4-0.9,0.4
    c-0.4,0-0.7-0.1-0.9-0.4c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2-0.4-0.2-0.8,0-1.1c0.1-0.1,0.1-0.2,0.2-0.4l22.4-21.9
    c0.2-0.2,0.6-0.4,0.9-0.4c0.4,0,0.7,0.1,0.9,0.4c0.1,0.1,0.2,0.2,0.2,0.4c0.2,0.4,0.2,0.8,0,1.1c-0.1,0.1-0.1,0.2-0.2,0.3
    L29.7,51.8z M55.2,49.4c-0.1,0.5-0.2,0.9-0.4,1.3c-0.2,0.4-0.4,0.8-0.7,1.2c-1.2,1.7-3.1,2.8-5.2,3.1c-0.2,0-0.5,0-0.7,0
    c-0.5,0-1,0-1.4-0.1c-0.5-0.1-0.9-0.2-1.3-0.4c-0.4-0.2-0.8-0.4-1.2-0.6c-0.8-0.5-1.4-1.1-1.9-1.9c-0.3-0.4-0.5-0.8-0.7-1.2
    c-0.2-0.4-0.3-0.9-0.4-1.3C41,48.9,41,48.4,41,48c0-3.4,2.5-6.2,5.7-6.9c0.5-0.1,1-0.1,1.4-0.1c0.2,0,0.5,0,0.7,0
    c3.6,0.4,6.5,3.4,6.5,7C55.4,48.4,55.3,48.9,55.2,49.4z"
        />
      </g>
    </svg>
  );
}

export default PromoIcon;
