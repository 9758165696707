//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clearBasket } from "../basket/basketSlice";
import { clearOrders } from "../orders/ordersSlice";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ConditionalParagraph from "../../components/genericTextLine/ConditionalParagraph";
import CheckoutPageItem from "./CheckoutPageItem";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import EmptyList from "../../components/errors/EmptyList";
import agent from "../../api/agent";
import "./CheckoutPage.css";
//#endregion

export default function CheckoutPage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const { user } = useAppSelector((state) => state.account);
  const { basket } = useAppSelector((state) => state.basket);
  const dispatch = useAppDispatch();

  // Local state
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("no comment");

  // Item count
  const itemCount = basket?.items.reduce((sum, item) => sum + item.quantity, 0);
  //#endregion

  //#region LOGIC
  // Scroll to Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Confirm order
  const handleConfirm = async () => {
    const values = {
      backorder: true,
      comment: comment,
    };

    setLoading(true);
    let createdOrderNumber;
    try {
      await agent.Orders.createOrder(values).then(
        (response) => (createdOrderNumber = response.data.order_code)
      );
      dispatch(clearBasket());
      dispatch(clearOrders()); // refresh order state
      setLoading(false);
      history.push("/checkout/" + createdOrderNumber);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  // add comment value
  const handleChange = (event: any) => {
    setComment(event.target.value);
  };
  //#endregion

  //#region FAILSAFE
  if (!basket) return <EmptyList message={t("rituals.yourBasketIsEmpty")} />;
  if (!user?.store)
    return <EmptyList message={"NO STORE COUPLED TO THE USER!"} />;
  if (loading) return <LoadingSpinner />;
  //#endregion

  //#region RENDER
  return (
    <div className="checkout">
      <PageHeader title={t("rituals.checkout")} />

      <main className="checkout-container">
        <div className="checkout-container__list">
          {basket.items.map((item) => (
            <CheckoutPageItem key={item.product_version_id} item={item} />
          ))}
        </div>

        <section className="checkout-container__shopinfo">
          <div className="checkout-container__shopinfo__box">
            <h2 className="checkout-container__shopinfo__box__title">
              {t("rituals.shop")}
            </h2>
            <p>
              {user?.store.store_name} / {user?.store.external_store_id}
            </p>
            <p>
              {t("rituals.items")}: {basket?.items.length}
            </p>
            <p>
              {t("rituals.totalQuantity")}: {itemCount}
            </p>

            <h2 className="checkout-container__shopinfo__box__title">
              {t("rituals.deliveryAddress")}
            </h2>
            <div className="checkout-container__shopinfo__box__address">
              <ConditionalParagraph
                value={user?.store.delivery_address_address_1}
              />
              <ConditionalParagraph
                value={user?.store.delivery_address_address_2}
              />
              <ConditionalParagraph
                value={user?.store.delivery_address_address_3}
              />
              <ConditionalParagraph
                inline
                value={user?.store.delivery_address_postal_code}
              />
              <ConditionalParagraph
                inline
                margin="0 0 0 0.5rem"
                value={user?.store.delivery_address_city}
              />
              <ConditionalParagraph
                value={user?.store.delivery_address_country_name}
              />
            </div>

            <h2 className="checkout-container__shopinfo__box__title">
              {t("rituals.comment")}
            </h2>
            <textarea
              onChange={handleChange}
              placeholder={t("rituals.optionalCommentHere")}
              className="checkout-container__shopinfo__box__field"
            />
          </div>
          <button
            onClick={handleConfirm}
            className="checkout-container__shopinfo__box__confirm"
          >
            {t("rituals.confirmOrder")}
          </button>
        </section>
      </main>
    </div>
  );
  //#endregion
}
