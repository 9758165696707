//#region IMPORTS
import { TranslationLanguage } from "../../models/translations";
import { motion } from "framer-motion";
//#endregion

//#region INTERFACE
interface Props {
  languages: TranslationLanguage[];
  selectedValue?: string;
  onChange: (language: TranslationLanguage) => void;
}
//#endregion

export default function TranslationDropDownList({
  languages,
  onChange,
  selectedValue,
}: Props) {
  //#region SETUP
  const listVariants = {
    hidden: { opacity: 1, scaleY: 0, originY: 0, originX: 0 },
    visible: {
      opacity: 1,
      scaleY: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.035,
      },
    },
  };

  const listItemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  //#endregion

  //#region RENDER
  return (
    <motion.ul
      variants={listVariants}
      initial="hidden"
      animate="visible"
      className="dropdown__list"
    >
      {languages.map((language) => (
        <motion.li
          variants={listItemVariants}
          onClick={() => onChange(language)}
          className={
            selectedValue === language.code
              ? "dropdown__list__item dropdown__list__item--selected"
              : "dropdown__list__item"
          }
          key={language.id}
        >
          {language.name ? language.name : "notfound"}
        </motion.li>
      ))}
    </motion.ul>
  );
  //#endregion
}