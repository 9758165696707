import { Icon } from "../../models/icon";

function UsersIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 24,
}: Icon) {
  return (
    <svg viewBox="0 0 80 80" style={{ width: width + "px" }}>
      <path
        fill={selected ? outline : "white"}
        d="M57.77,31.05c.99-2.58,1.52-5.34,1.52-8.18C59.29,10.26,49.03,0,36.41,0S13.54,10.26,13.54,22.87c0,2.84,.54,5.6,1.52,8.18C5.7,37.86,0,48.85,0,60.53c0,4.06,1.81,11.55,13.94,16.02,6.05,2.23,14.03,3.46,22.47,3.46s16.42-1.23,22.47-3.46c12.13-4.47,13.94-11.95,13.94-16.02,0-11.68-5.7-22.67-15.06-29.47Z"
      />
      <g>
        <path
          fill={selected ? selectedColor : color}
          d="M51.04,35.57c-3.55,4.08-8.79,6.67-14.63,6.67s-11.08-2.59-14.63-6.67c-8.55,5.03-14.29,14.32-14.29,24.95,0,15.97,57.84,15.97,57.84,0,0-10.64-5.74-19.93-14.29-24.95Z"
        />
        <path
          fill={selected ? selectedColor : color}
          d="M36.41,38.25c4.26,0,8.12-1.73,10.9-4.53,2.77-2.78,4.49-6.62,4.49-10.85,0-8.5-6.89-15.38-15.38-15.38s-15.38,6.88-15.38,15.38c0,4.24,1.71,8.07,4.48,10.85,2.79,2.8,6.64,4.53,10.9,4.53Z"
        />
      </g>
    </svg>
  );
}

export default UsersIcon;
