//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Order, ORDERSTATUS, ORDERTYPE } from "../../models/order";
import { CHOOSE_PICKLIST_SUPPLIER } from "../../models/_consts";
import { useEffect, useState } from "react";
import { fetchOrdersAsync } from "../../features/orders/ordersSlice";
import { openModal } from "../../features/dashBoard/dashboardSlice";
import { SubOrder } from "../../models/suborder";
import { toast } from "react-toastify";
import agent from "../../api/agent";
//#endregion

//#region INTERFACE
interface Props {
  isHQTeam: boolean;
  isPicker: boolean;
  isAdmin: boolean;
}
//#endregion

export default function OrdersTableControlePanelBulkActions({
  isHQTeam,
  isPicker,
  isAdmin,
}: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { order_params, selectedOrders } = useAppSelector(
    (state) => state.orders
  );

  // Local state
  const [approveAbleOrders, setApproveAbleOrders] = useState<Order[]>([]);
  const [shippableOrders, setShippableOrders] = useState<Order[]>([]);
  //#endregion

  //#region LOGIC
  // Select orders by purpose
  useEffect(() => {
    setShippableOrders(
      selectedOrders.filter(
        (order: Order) =>
          order.order_status === ORDERSTATUS.ordered &&
          order.order_type !== ORDERTYPE.original
      )
    );
    setApproveAbleOrders(
      selectedOrders.filter(
        (order: Order) =>
          order.order_status === ORDERSTATUS.pending &&
          order.order_type !== ORDERTYPE.original
      )
    );
  }, [selectedOrders]);

  // Approve in bulk
  const bulkApproveHandler = async () => {
    const updateOrderValues = approveAbleOrders.map((apo) => {
      return {
        id: apo.id,
        approved: apo.approved,
      };
    });

    try {
      const ammountApproved: number = updateOrderValues.filter(
        (o) => o.approved
      ).length;
      const ammountDeclined: number = updateOrderValues.filter(
        (o) => !o.approved
      ).length;

      // Change status of suborders
      const response = await agent.Orders.approveOrder(updateOrderValues);

      // Approve bulk suborders of the orders
      await updateBulkSuborders(
        approveAbleOrders
          .filter((order) => order.order_status === ORDERSTATUS.ordered)
          .map((order) => order.sub_orders)
          .flat(),
        ORDERSTATUS.ordered
      );

      // Decline bulk suborders of the orders
      await updateBulkSuborders(
        approveAbleOrders
          .filter((order) => order.order_status === ORDERSTATUS.cancelled)
          .map((order) => order.sub_orders)
          .flat(),
        ORDERSTATUS.cancelled
      );

      //#region SHOW TOAST RESPONSE
      Object.values(response.result).length - ammountDeclined > 0 &&
        toast.success(
          `${Object.values(response.result).length - ammountDeclined} order${
            Object.values(response.result).length - ammountDeclined > 1
              ? "s"
              : ""
          } approved`
        );
      Object.values(response.result).length - ammountApproved > 0 &&
        toast.error(
          `${Object.values(response.result).length - ammountApproved} order${
            Object.values(response.result).length - ammountApproved > 1
              ? "s"
              : ""
          } declined`
        );
      //#endregion
    } catch (error) {
      toast.error(`${error}`);
    }
    dispatch(fetchOrdersAsync());
  };

  // Ship in bulk
  const bulkShipHandler = async () => {
    try {
      // Update statusses of the orders
      const updateOrderValues = {
        orders: shippableOrders.map((order) => order.id.toString()),
        order_status: ORDERSTATUS.shipped,
      };
      const response = await agent.Orders.updateMultiOrderStatus(
        updateOrderValues
      );

      // Update statusses of suborders of the orders
      await updateBulkSuborders(
        shippableOrders.map((order) => order.sub_orders).flat(),
        ORDERSTATUS.shipped
      );

      // Show message
      toast.success(
        `${response.data.length} order${
          response.data.length > 1 ? "s" : ""
        } succesfully changed to shipped `
      );
    } catch (error: any) {
      toast.error(error.message);
    }
    dispatch(fetchOrdersAsync());
  };

  // Update suborder helper
  const updateBulkSuborders = async (
    subOrders: SubOrder[],
    order_status: string
  ) => {
    const updateSuborderValues = {
      sub_orders: subOrders.map((subOrder) => subOrder.id.toString()),
      order_status,
    };

    // Check if suborders can be changed
    if (
      subOrders.some(
        (subOrder) => subOrder.order_status === ORDERSTATUS.shipped
      )
    ) {
      toast.warning("Suborders that are shipped can not be changed anymore");
    } else {
      await agent.Orders.updateMultiSubOrderStatus(updateSuborderValues);
    }
  };
  //#endregion

  //#region RENDER
  return (
    <div className="flex gap-4 items-center">
      {/* Bulk download lists */}
      {
      //  ? 
      (
        isAdmin || (order_params.order_status !== "" && isPicker) ? (
          <button
            onClick={() => dispatch(openModal(CHOOSE_PICKLIST_SUPPLIER))}
            disabled={selectedOrders.length <= 0}
            type="button"
            className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
          >
            {selectedOrders.length > 0
              ? `Request ${selectedOrders.length} list${
                  selectedOrders.length > 1 ? "s" : ""
                }`
              : "Request"}
          </button>
        ) : null
      ) 
      // : null
      }

      {/* Bulk ship order */}
      {(isPicker || isAdmin) && (
        <button
          onClick={bulkShipHandler}
          disabled={shippableOrders.length <= 0}
          type="button"
          className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
        >
          {shippableOrders.length > 0
            ? `Ship ${shippableOrders.length} order${
                shippableOrders.length > 1 ? "s" : ""
              }`
            : "Ship multiple"}
        </button>
      )}

      {/* Bulk approve or decline orders */}
      {(isHQTeam || isAdmin) && (
        <button
          onClick={bulkApproveHandler}
          disabled={approveAbleOrders.length <= 0}
          type="button"
          className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
        >
          {approveAbleOrders.length > 0
            ? `${
                approveAbleOrders.some((order) => !order.approved)
                  ? "Handle"
                  : "Approve"
              } ${approveAbleOrders.length} order${
                approveAbleOrders.length > 1 ? "s" : ""
              }`
            : "Handle multiple"}
        </button>
      )}
    </div>
  );
  //#endregion
}
