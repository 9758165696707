import { Fragment, ReactChild } from "react";
import { useAppSelector } from "../../store/configureStore";
import { motion } from "framer-motion";
import useBreakpoints from "../../hooks/useBreakPoints";

interface Props {
  children: ReactChild;
}

function DashboardSlideBox({ children }: Props) {
  const { dashOpen } = useAppSelector((state) => state.dashboard);

  const slideVariants = {
    open: { left: "-48px" },
    closed: { left: "0px" },
  };

  const { isXs } = useBreakpoints();

  return (
    <Fragment>
      {isXs ? (
        <div className="dashboard__content__slidebox">{children}</div>
      ) : (
        <motion.div
          animate={dashOpen ? "open" : "closed"}
          variants={slideVariants}
          className="dashboard__content__slidebox"
        >
          {children}
        </motion.div>
      )}
    </Fragment>
  );
}

export default DashboardSlideBox;
