import RobinLogo from "../logos/RobinLogo";
import "./Loading.css";

interface Props {
  color?: string;
}

function LoadingSpinner({ color }: Props) {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner">
        <RobinLogo repeat={true} color={color ?? "#fbb033"} />
      </div>
    </div>
  );
}

export default LoadingSpinner;
