//#region IMPORTS
import { SubOrder } from "../../models/suborder";
import { Order } from "../../models/order";
import OrderParcelCount from "../orders/OrderParcelCount";
//#endregion

//#region INTERFACE
interface Props {
  order: Order;
  subOrder: SubOrder;
}
//#endregion

export default function SubOrderDetailHeader({ order, subOrder }: Props) {
  //#region RENDER
  return (
    <header className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
      <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-4">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {order.store.store_name}
          </h3>
          <p className="mt-1 text-sm text-gray-500">{order.order_code}</p>
        </div>
        <div className="ml-4 mt-4 flex-shrink-0">
          <OrderParcelCount subOrder={subOrder} order={order} />
        </div>
      </div>
    </header>
  );
  //#endregion
}
