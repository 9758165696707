import ArrowIconRight from "../icons/ArrowIconRight";
import ArrowIconLeft from "../icons/ArrowIconLeft";

interface Props {
  direction: "left" | "right";
  clickHandler: () => void;
  disabled: boolean;
}

function PaginationBarArrow({ direction, clickHandler, disabled }: Props) {
  return (
    <button disabled={disabled} onClick={clickHandler}>
      {direction === "left" ? (
        <ArrowIconLeft color={disabled ? "#acacac" : "#254559"} width={20} />
      ) : (
        <ArrowIconRight color={disabled ? "#acacac" : "#254559"} width={20} />
      )}
    </button>
  );
}

export default PaginationBarArrow;
