import SortAscIcon from "../../icons/tableSortIcons.tsx/SortAscIcon";
import SortDescIcon from "../../icons/tableSortIcons.tsx/SortDescIcon";
import SortUnsortIcon from "../../icons/tableSortIcons.tsx/SortUnsortIcon";

interface Props {
  sorted: boolean;
  descending?: boolean;
  clickHandler?: () => void;
  selected: boolean;
}

const SortButton = ({ sorted, descending, clickHandler, selected }: Props) => {
  return (
    <span onClick={clickHandler}>
      {sorted && selected ? (
        descending ? (
          <SortDescIcon width={16} color={selected ? "hotpink" : "green"} />
        ) : (
          <SortAscIcon width={16} color={selected ? "hotpink" : "green"} />
        )
      ) : (
        <SortUnsortIcon width={16} color="#becbd3" />
      )}
    </span>
  );
};

export default SortButton;
