//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { setOrdersWithSuborders } from "../../features/orders/ordersSlice";
import ToggleButton from "../genericButtons/toggleButton/ToggleButton";
import { useTranslation } from "react-i18next";
//#endregion

export default function OrdersTableControlePanelViewActions() {
  //#region SETUP
  // Translations
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const { showOrdersWithSuborders } = useAppSelector((state) => state.orders);
  //#endregion

  //#region RENDER
  return (
    <div className="flex gap-4 items-center mr-4">
      <ToggleButton
        startValue={showOrdersWithSuborders}
        label={
          showOrdersWithSuborders
            ? t("rituals.hideAllParcelInfo")
            : t("rituals.showAllParcelInfo")
        }
        toggleHandler={() => {
          dispatch(setOrdersWithSuborders(!showOrdersWithSuborders));
        }}
      />
    </div>
  );
  //#endregion
}
