//#region IMPORTS
import { FieldValues, useForm } from "react-hook-form";
import { useState } from "react";
import { toast } from "react-toastify";
import { User } from "../../models/user";
import InputFieldErrorMark from "../errors/InputFieldErrorMark";
import SaveButton from "../genericButtons/saveButton/SaveButton";
import agent from "../../api/agent";
//#endregion

//#region INTERFACE
interface Props {
  cancelCUD: () => void;
  admin: boolean;
  user: User;
}
//#endregion

export default function UserPasswordResetForm({
  cancelCUD,
  admin = false,
  user,
}: Props) {
  //#region SETUP
  // Local State
  const [iAmAware, setIamAware] = useState<boolean>(false);

  // UseForm configuration
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });
  //#endregion

  //#region LOGIC

  // OnSubmit
  async function submitForm(data: FieldValues) {
    try {
      // Change password enpoint call here
      await agent.Users.updatePassword(user?.id, data).then(() => {
        toast.success(
          `The password of ${user?.username} was changed succesful`
        );
      });
      cancelCUD();
    } catch (error) {
      toast.error(`${error}`);
    }
  }
  //#endregion

  if (!admin) {
    cancelCUD();
  }

  //#region RENDER
  return !user ? (
    <p>Please select a user first</p>
  ) : (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="space-y-12">
        <fieldset className="sm:col-span-3">
          {/* Password */}
          <label
            htmlFor="password"
            className={
              errors.password
                ? "block text-sm font-medium leading-6 text-red-900"
                : "block text-sm font-medium leading-6 text-gray-900"
            }
          >
            {errors.password ? errors?.password?.message : "New password"}
          </label>
          <div className="relative mt-2">
            <input
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6 ml-0"
              type="password"
              {...register("password", {
                required: "password is required",
                minLength: {
                  value: 3,
                  message: "at least 3 characters please",
                },
              })}
            />
            <InputFieldErrorMark show={errors.password} />
          </div>
        </fieldset>

        {/* Password confirm */}
        <fieldset className="sm:col-span-3">
          <label
            htmlFor="confirm_password"
            className={
              errors.confirm_password
                ? "block text-sm font-medium leading-6 text-red-900"
                : "block text-sm font-medium leading-6 text-gray-900"
            }
          >
            {errors.confirm_password
              ? errors?.confirm_password?.message
              : "Confirm new password"}
          </label>
          <div className="relative mt-2">
            <input
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
              type="password"
              placeholder="e.g. ls541741sdf144LK!"
              {...register("confirm_password", {
                required: "confirm_password is required",
                minLength: {
                  value: 3,
                  message: "at least 3 characters please",
                },
              })}
            />
          </div>
        </fieldset>
      </div>

      <div className="mt-6 flex items-center justify-between gap-x-6">
        <div className="flex items-center gap-x-6">
          <SaveButton
            type="submit"
            value={isSubmitting ? "Saving..." : "Save"}
            valid={isValid && iAmAware}
          />
          <input
            className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
            type="checkbox"
            checked={iAmAware}
            onChange={() => setIamAware((prev) => !prev)}
          />
          <label className="font-medium text-gray-900">
            I am aware this changes the password of {user.username}
          </label>
        </div>
      </div>
    </form>
  );
  //#endregion
}
