//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useState } from "react";
import { resetDashboard } from "../../features/dashBoard/dashboardSlice";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import FilterBarSelect from "./FilterBarSelect";
import useFilterCount from "../../hooks/useFilterCount";
import "./FilterBar.css";
//#endregion

//#region INTERFACE
interface Props {
  resetFilterHandler?: () => void;
  setParamsHandler?: (value: any) => void;
  selectedFilters?: any;
  directTo?: string;
  children?: any;
  filters?: any;
}
//#endregion

export default function FilterBar({
  resetFilterHandler,
  setParamsHandler,
  selectedFilters,
  directTo,
  children,
  filters,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Global State
  const dispatch = useAppDispatch();
  const { filterBarOpen, dashOpen } = useAppSelector(
    (state) => state.dashboard
  );

  // Local State
  const [openFilter, setOpenFilter] = useState<string>("");

  // Custom Hooks
  const filterCount = useFilterCount(selectedFilters);

  // FramerMotion variant
  const filterbarVariants = (dashOpen: boolean) => ({
    open: {
      translateX: dashOpen ? "200px" : "80px",
      opacity: 1,
      transition: {
        type: "spring",
        mass: 0.5,
        delayChildren: 0.1,
        staggerChildren: 0.05,
      },
    },
    closed: {
      translateX: dashOpen ? "-200px" : "-280px",
      opacity: 0,
    },
  });
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      <div
        className={
          filterBarOpen
            ? "filterbar-overlay"
            : "filterbar-overlay filterbar-overlay--hidden"
        }
        onClick={() => dispatch(resetDashboard())}
      />
      <motion.aside
        className="filterbar"
        initial="closed"
        animate={filterBarOpen ? "open" : "closed"}
        variants={filterbarVariants(dashOpen)}
      >
        <div className="filterbar__content">
          {/* Set filters */}
          {children}

          {/* Dynamic filters */}
          {filters && (
            <section className="filterbar__content__section">
              <header className="filterbar__content__section__header">
                {filters && (
                  <Fragment>
                    <h2 className="font-semibold text-amber-600 text-2xl mt-4">
                      {t("rituals.filter")}
                    </h2>
                    {filterCount > 0 && (
                      <button
                        onClick={resetFilterHandler}
                        className="filterbar__content__section__header__clearbutton"
                      >
                        {t("rituals.clearAll")}
                      </button>
                    )}
                  </Fragment>
                )}
              </header>
              <div>
                {filters &&
                  Object.entries(filters).map((filter) => {
                    const key: string = filter[0];
                    const values: any = filter[1];
                    return (
                      filter.length > 0 &&
                      values.length > 0 && (
                        <FilterBarSelect
                          filterTitle={t(
                            `rituals.${key.replace(/_([a-z])/g, (_, letter) =>
                              letter.toUpperCase()
                            )}`
                          )}
                          directTo={directTo}
                          values={values}
                          key={key}
                          selectedValue={selectedFilters[
                            key as keyof typeof selectedFilters
                          ]?.toString()}
                          open={openFilter === key}
                          openFilterHandler={() => setOpenFilter(key)}
                          closeFilterHandler={() => setOpenFilter("")}
                          changeParams={(value: string) =>
                            setParamsHandler &&
                            setParamsHandler({ [key]: value })
                          }
                        />
                      )
                    );
                  })}
              </div>
            </section>
          )}

          <div
            className="filterbar__content__excess"
            onClick={() => dispatch(resetDashboard())}
          ></div>
        </div>

        <div className="filterbar__line" />
      </motion.aside>
    </Fragment>
  );
  //#endregion
}
