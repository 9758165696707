//#region IMPORTS
import {
  resetDashboard,
  toggleFilterBar,
} from "../../../features/dashBoard/dashboardSlice";
import { useAppDispatch, useAppSelector } from "../../../store/configureStore";
import { useTranslation } from "react-i18next";
import CancelIcon from "../../icons/CancelIcon";
import SearchIcon from "../../icons/SearchIcon";
import "./SearchButton.css";
//#endregion

//#region INTERFACE
interface Props {
  title?: string;
  disabled?: boolean;
}
//#endregion

export default function SearchButton({ title, disabled = false }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const { filterBarOpen } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  //#endregion

  //#region RENDER
  return (
    <button
      disabled={disabled}
      onClick={() =>
        !filterBarOpen
          ? dispatch(toggleFilterBar())
          : dispatch(resetDashboard())
      }
      className={`searchbutton${disabled ? " searchbutton--disabled" : ""}`}
    >
      {!filterBarOpen ? (
        <div className="filterbutton__open">
          <SearchIcon /> {title ?? t("rituals.search")}
        </div>
      ) : (
        <div className="filterbutton__close">
          <div className="filterbutton__close__icon">
            <CancelIcon />
          </div>
          {t("rituals.close")}
        </div>
      )}
    </button>
  );
  //#endregion
}
