const dateHelperWeirdFromBackend = (date: string, errorMessage: string = "") => {
  let dateFormatted: string;
  if (date) {
    const orderDateSplit = date.split(" ")[0].split("-");
    dateFormatted =
      orderDateSplit[2] + "-" + orderDateSplit[1] + "-" + orderDateSplit[0];
  } else {
    dateFormatted = errorMessage;
  }
  return `${dateFormatted}`;
};

export default dateHelperWeirdFromBackend;
