//#region IMPORTS
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { BasketReason } from "../../models/basketReason";
import { RootState } from "../../store/configureStore";
import { toast } from "react-toastify";
import agent from "../../api/agent";
//#endregion

//#region INIT
const initialExtraState = {
  basketReasonsLoaded: false,
  status: "iddle",
};
//#endregion

//#region CREATE ADAPTER
const basketReasonAdapter = createEntityAdapter<BasketReason>();
//#endregion

//#region READ
export const fetchBasketReasonsAsync = createAsyncThunk<BasketReason[]>(
  "basketReasons/fetchBasketReasonsAsync",
  async () => {
    try {
      return await agent.Basket.listReasons();
    } catch (error) {
      toast.error(`${error}`)
    }
  }
);
//#endregion

//#region CREATE REDUCER SLICE
export const basketReasonSlice = createSlice({
  name: "basketReasons",
  initialState: basketReasonAdapter.getInitialState(initialExtraState),
  reducers: {
    clearReasons: () => basketReasonAdapter.getInitialState(initialExtraState),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBasketReasonsAsync.pending, (state) => {
      state.status = "pendingFetchBasketReasons";
    });
    builder.addCase(fetchBasketReasonsAsync.fulfilled, (state, action) => {
      basketReasonAdapter.setAll(state, action.payload);
      state.status = "iddle";
      state.basketReasonsLoaded = true;
    });
    builder.addCase(fetchBasketReasonsAsync.rejected, (state) => {
      state.status = "iddle";
    });
  },
});
//#endregion

//#region NORMALISED STATE
export const basketReasonSelectors = basketReasonAdapter.getSelectors(
  (state: RootState) => state.basketReasons
);
//#endregion

//#region EXPORT
export const { clearReasons } = basketReasonSlice.actions;
//#endregion
