//#region IMPORTS
import { Fragment } from "react";
import OrdersTableControlePanelBulkActions from "./OrdersTableControlePanelBulkActions";
import OrdersTableControlePanelViewActions from "./OrdersTableControlePanelViewActions";
import OrdersTableControlePanelSortActions from "./OrdersTableControlePanelSortActions";
//#endregion

//#region INTERFACE
interface Props {
  isPicker: boolean;
  isHQTeam: boolean;
  isAdmin: boolean;
}
//#endregion

export default function OrdersTableControlePanel({
  isPicker,
  isAdmin,
  isHQTeam,
}: Props) {
  //#region RENDER
  return (
    <Fragment>
      <div className="flex h-12 items-center justify-between space-x-3 sm:left-12">
        {/* Bulk actions */}
        <OrdersTableControlePanelBulkActions
          isPicker={isPicker}
          isHQTeam={isHQTeam}
          isAdmin={isAdmin}
        />

        {/* View actions */}
        <div className="flex items-center">
          <OrdersTableControlePanelViewActions />

          <OrdersTableControlePanelSortActions />
        </div>
      </div>
    </Fragment>
  );
  //#endregion
}
