//#region IMPORTS
import {
  setPromoParams,
  fetchPromosAsync,
  deletePromoAsync,
  refreshPromoTransmartDataAsync,
} from "./promosSlice";
import {
  DELETE_PROJECT_SURE,
  UPLOAD_DATA_FOR_PROMO,
  CREATE_OR_UPDATE_PROMO,
  UPLOAD_DATA_OVERWRITE_TRANSMART,
} from "../../models/_consts";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { openModal } from "../dashBoard/dashboardSlice";
import { Promo } from "../../models/promo";
import { toast } from "react-toastify";
import PromosDataUploadOverWriteTransSmart from "../../components/promos/PromosDataUploadOverWriteTransSmart";
import MainMotionSection from "../../components/_layout/MainMotionSection";
import PromosDataUpload from "../../components/promos/PromosDataUpload";
import usePickerManager from "../../hooks/usePickerManager";
import PromosPageItems from "../../components/promos/PromosPageItems";
import useAdminManager from "../../hooks/useAdminManager";
import MainContainer from "../../components/_layout/MainContainer";
import useHQmanager from "../../hooks/useHQmanager";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import AreYouSure from "../../components/modalTypes/AreYouSure";
import PromoForm from "../../components/promos/PromoForm";
import MainModal from "../../components/_layout/MainModal";
//#endregion

export default function PromosPage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const { promosLoaded } = useAppSelector((state) => state.promos);
  const { user } = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();

  // Roles
  const isPicker = usePickerManager(user);
  const isAdmin = useAdminManager(user);
  const hqTeam = useHQmanager(user);

  // Local state
  const [savingDataUpload, setSavingDataUpload] = useState<boolean>(false);
  const [overWriteTransSmart, setOverWriteTransSmart] =
    useState<boolean>(false);
  const [projectToManage, setProjectToManage] = useState<Promo>();
  //#endregion

  //#region LOGIC
  // Create new project
  const createNewPromo = () => {
    setProjectToManage(undefined);
    dispatch(openModal(CREATE_OR_UPDATE_PROMO));
  };

  // Update a project
  const updatePromo = (promo: Promo, upload: boolean) => {
    setProjectToManage(promo);
    dispatch(
      openModal(!upload ? CREATE_OR_UPDATE_PROMO : UPLOAD_DATA_FOR_PROMO)
    );
  };

  // Set Delete promo warning
  const deletePromoWarningHandler = (promo: Promo) => {
    setProjectToManage(promo);
    dispatch(openModal(DELETE_PROJECT_SURE));
  };

  // Delete promo
  const deletePromoHandler = async () => {
    if (projectToManage) {
      await dispatch(deletePromoAsync({ id: projectToManage.id }));
      dispatch(openModal(false));
    }
  };

  // Cancel create or update promo
  const cancelCRUDPromo = () => {
    if (!savingDataUpload) {
      dispatch(openModal(false));
      setProjectToManage(undefined);
    } else {
      toast.error("Some background tasks are stil running. Please wait...");
    }
  };

  // Set promo pagination
  useEffect(() => {
    dispatch(
      setPromoParams({
        limit: 50,
        page: 1,
      })
    );
  }, [dispatch]);

  // Load promos
  useEffect(() => {
    const fetchOnload = async () => {
      !promosLoaded && (await dispatch(fetchPromosAsync()));
    };
    fetchOnload();
  }, [promosLoaded, dispatch]);

  const toggleOverWriteTransSmart = (promo?: Promo) => {
    if (overWriteTransSmart) {
      setProjectToManage(undefined)
      dispatch(openModal(false));
      setOverWriteTransSmart(false);
    } else {
      setProjectToManage(promo);
      dispatch(dispatch(openModal(UPLOAD_DATA_OVERWRITE_TRANSMART)));
      setOverWriteTransSmart(true);
    }
  };

  //#endregion

  const refreshPromoTransmartData = async (promo: Promo) => {
    await dispatch(refreshPromoTransmartDataAsync());
  };

  //#region RENDER
  return (
    <Fragment>
      {/* MODALS */}
      <MainModal type={DELETE_PROJECT_SURE} closeHandler={cancelCRUDPromo}>
        <AreYouSure
          confirmHandler={deletePromoHandler}
          closeHandler={cancelCRUDPromo}
          message={`Are you sure you want to delete project ${projectToManage?.code}`}
          title="Delete project?"
        />
      </MainModal>

      <MainModal type={UPLOAD_DATA_FOR_PROMO} closeHandler={cancelCRUDPromo}>
        <PromosDataUpload
          setSavingHandler={(value: boolean) => setSavingDataUpload(value)}
          cancelCUD={cancelCRUDPromo}
          saving={savingDataUpload}
          promo={projectToManage}
        />
      </MainModal>

      <MainModal
        type={UPLOAD_DATA_OVERWRITE_TRANSMART}
        closeHandler={cancelCRUDPromo}
      >
        <PromosDataUploadOverWriteTransSmart
          setSavingHandler={(value: boolean) => setSavingDataUpload(value)}
          cancelCUD={toggleOverWriteTransSmart}
          saving={savingDataUpload}
          promo={projectToManage}
        />
      </MainModal>

      <MainModal type={CREATE_OR_UPDATE_PROMO} closeHandler={cancelCRUDPromo}>
        <PromoForm
          cancelCUD={cancelCRUDPromo}
          isAdmin={isAdmin}
          promo={projectToManage}
        />
      </MainModal>

      {/* BODY */}
      <MainContainer>
        <MainMotionSection>
          <PageHeader
            createButtonValue={t("rituals.newProject")}
            createClickHandler={createNewPromo}
            isAdmin={isAdmin}
            upload={true}
            title={t("rituals.projects")}
          />

          <PromosPageItems
            setProjectToDelete={(promo: Promo) =>
              deletePromoWarningHandler(promo)
            }
            setProjectToEdit={(promo: Promo, upload: boolean) =>
              updatePromo(promo, upload)
            }
            isPicker={isPicker}
            isAdmin={isAdmin}
            hqTeam={hqTeam}
            refreshTransmartHandler={(promo: Promo) =>
              refreshPromoTransmartData(promo)
            }
            overWriteTransSmartHandler={(promo: Promo) =>
              toggleOverWriteTransSmart(promo)
            }
          />
        </MainMotionSection>
      </MainContainer>
    </Fragment>
  );
  //#endregion
}
