//#region IMPORTS
import { useAppSelector } from "../../store/configureStore";
import { ReactNode } from "react";
import { motion } from "framer-motion";
//#endregion

//#region INTERFACE
interface Props {
  children: ReactNode;
}
//#endregion

export default function MainMotionSection({ children }: Props) {
  //#region SETUP
  // Redux
  const { filterBarOpen, dashOpen } = useAppSelector(
    (state) => state.dashboard
  );

  // FramerMotion variants
  const filterVariants = {
    open: { translateX: dashOpen ? "260px" : "208px" },
    closed: { translateX: "0px" },
  };
  //#endregion

  //#region RENDER
  return (
    <motion.section
      className="px-4 sm:px-6 lg:px-8 pb-16 relative"
      initial="closed"
      transition={{ type: "spring", mass: 0.5 }}
      animate={filterBarOpen ? "open" : "closed"}
      variants={filterVariants}
    >
      {children}
    </motion.section>
  );
  //#endregion
}
