import { MetaData } from "./pagination";
import { SubOrder } from "./suborder";
import { Store } from "./store";
import { Item } from "./item";

export interface Order {
  id: number;
  order_code: string;
  promo_id: number;
  promo_name: string;
  buyer_id: string;
  store: Store;
  comment: string;
  order_status: string;
  order_type: string;
  order_date: Date;
  order_date_h: string;
  shipping_date: Date;
  order_items: Item[];
  imported_from_excel: boolean;
  sub_orders: SubOrder[];
  selected: boolean;
  approved: boolean;
}

export interface OrderParams {
  [key: string]: any;
  page?: number;
  limit?: number;
  sort?: any;
  order_code?: string;
  promo_code?: string;
  order_status?: string;
  order_type?: string;
  shop_id?: string;
  printer?: string;
  city?: string;
  language?: string;
  country_name?: string;
  include_sub_orders?: number | null;
}

export interface OrderFilters {
  promo_code: string[];
  order_status: string[];
  stock_portal: string[];
  order_type: string[];
  country_name: string[];
  city: string[];
  language: string[];
}

export interface OrderPending {
  promo_id: string;
  ammount_pending: number;
}

export interface OrderOpen {
  promo_id: string;
  ammount_open: number;
}

export interface OrderUpdate {
  buyer_id: string;
  comment: string;
  order_code: string;
  promo_id: number;
  order_status: string;
  order_type: string;
}

export interface OrderApprove extends Order {
  approved: boolean;
  selected: boolean;
}

export interface OrdersState {
  orders_loaded: boolean;
  orders_pending: number;
  orders_ordered: number;
  filters_loaded: boolean;
  detail_group: string[];
  status: string;
  filters: OrderFilters;
  order_params: OrderParams;
  metaData: MetaData | null;
  selectedAll: boolean;
  selectedOrders: Order[];
  showOrdersWithSuborders: boolean;
  selectedSubOrder: SubOrder | null;
  selectedOrder: Order | null;
}

export interface OrderApproveState {
  status: string;
  selectedAll: boolean;
  approvablesLoaded: boolean;
  approvableParams: OrderParams;
  metaData: MetaData | null;
}

export const ORDERSTATUS = {
  cancelled: "Cancelled",
  ordered: "Ordered",
  inProgress: "In progress",
  pending: "Pending",
  shipped: "Shipped",
  created: "Created",
  unknownStatus: "UnknownStatus",
};

export const ORDERTYPE = {
  original: "Original",
  backorder: "Back order",
};

export const ORDERPAGETYPE = {
  shipped: true,
  pending: true,
  manage: true,
  history: true,
};
