import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { Order } from "../../../models/order";
import agent from "../../../api/agent";
import ArrowIconDown from "../../icons/ArrowIconDown";
import "./DownLoadButton.css";

interface Props {
  order: Order;
}

function DownLoadButton({ order }: Props) {
  //#region LOGIC
  const batchGenerateAndEmailHandler = async () => {
    try {
      const response = await agent.Reports.emailMeListsForSelectedOrders({
        orders: [order.id],
      });
      toast.success(response.message, { autoClose: 2000 });
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  //#endregion

  return (
    <motion.button
      className="downloadpdf-button"
      whileHover={{ scale: 1.1 }}
      onClick={batchGenerateAndEmailHandler}
    >
      <div>Lists</div>
      <ArrowIconDown />
    </motion.button>
  );
}

export default DownLoadButton;
