import { Icon } from "../../models/icon";

function CompletedIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 24,
}: Icon) {
  return (
    <svg viewBox="0 0 78 76.21" style={{ width: width + "px" }}>
      <path
        fill={selected ? outline : "white"}
        d="m72.87,22.08l3.09-4.04c1.61-2.1,2.3-4.7,1.96-7.31-.34-2.62-1.69-4.96-3.79-6.56-1.73-1.33-3.8-2.03-5.99-2.03-1.7,0-3.37.44-4.85,1.27l-.13.06-.2.12c-1.08.66-1.99,1.48-2.7,2.42l-.54.71-1.18-.75c-6.12-3.9-13.19-5.96-20.45-5.96C17.09,0,0,17.1,0,38.1c0,2.74.3,5.49.89,8.16.18.82.4,1.67.65,2.56.92,3.13,2.22,6.12,3.88,8.87,6.85,11.42,19.37,18.51,32.66,18.52h0c13.5,0,25.72-6.93,32.67-18.52,1.66-2.77,2.97-5.75,3.88-8.86.24-.82.46-1.68.65-2.57.59-2.67.89-5.41.89-8.16,0-4.19-.69-8.33-2.05-12.3l-1.27-3.71Z"
      />
      <g>
        <path
          fill={selected ? selectedColor : color}
          d="m5.38,47.69c.82,2.81,1.99,5.47,3.47,7.93,5.96,9.93,16.82,16.57,29.24,16.58,12.43,0,23.29-6.65,29.25-16.58,1.48-2.46,2.65-5.12,3.47-7.93.22-.75.42-1.52.59-2.3.52-2.35.79-4.79.8-7.3,0-3.86-.65-7.55-1.83-11.01l-5.95,7.77c.13,1.06.2,2.13.2,3.24,0,2.54-.38,4.98-1.05,7.3-.22.78-.48,1.54-.77,2.3-1.14,2.93-2.79,5.61-4.84,7.93-.36.41-.72.83-1.11,1.23-4.8,4.8-11.41,7.76-18.75,7.76s-13.94-2.96-18.74-7.76c-.39-.4-.74-.81-1.11-1.23-2.05-2.33-3.7-5-4.84-7.93-.3-.75-.55-1.52-.78-2.3-.67-2.32-1.04-4.76-1.04-7.3,0-7.33,2.96-13.94,7.77-18.74,4.8-4.81,11.41-7.77,18.74-7.77,5.01,0,9.69,1.39,13.68,3.79l4.62-6.04c-5.28-3.36-11.55-5.34-18.3-5.34-18.82,0-34.08,15.27-34.08,34.1,0,2.51.28,4.95.79,7.3.17.77.37,1.54.59,2.3Z"
        />
        <path
          fill={selected ? selectedColor : color}
          d="m37.91,41.77l-8.69-10.7c-2.05-2.52-5.77-2.9-8.28-.85-2.53,2.05-2.92,5.76-.87,8.28l5.59,6.9,1.86,2.3,5.93,7.31c.19.24.42.43.63.63,1.08.98,2.46,1.56,3.95,1.56h.09c1.45-.02,2.8-.6,3.85-1.56.26-.23.52-.48.74-.76l5.49-7.18,1.76-2.3,12.87-16.81,5.2-6.78,4.64-6.07.04-.05.06-.08c1.98-2.59,1.5-6.28-1.09-8.26-1.96-1.5-4.55-1.57-6.55-.39-.03.01-.06.03-.09.04-.6.37-1.16.84-1.62,1.44l-3,3.92-4.62,6.02-17.9,23.39Z"
        />
      </g>
    </svg>
  );
}

export default CompletedIcon;
