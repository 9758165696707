//#region IMPORTS
import {
  setPageNumber,
  setSelectedOrders,
} from "../../features/orders/ordersSlice";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Order, ORDERSTATUS, ORDERTYPE } from "../../models/order";
import { useTranslation } from "react-i18next";
import { MetaData } from "../../models/pagination";
import OrdersTableControlePanel from "./OrdersTableControlePanel";
import MainTableMotionWrapper from "../_layout/MainTableMotionWrapper";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import LoadingOverlay from "../loading/LoadingOverlay";
import OrdersTableRow from "./OrdersTableRow";
import PaginationBar from "../paginationBar/PaginationBar";
import useHQmanager from "../../hooks/useHQmanager";
//#endregion

//#region INTERFACE
interface Props {
  changeOrderStatusHandler: (order: Order, status: string) => void;
  filtersLoaded?: boolean;
  ordersLoaded?: boolean;
  metaData: MetaData | null;
  orders: Order[];
}
//#endregion

export default function OrdersTable({
  changeOrderStatusHandler,
  filtersLoaded = false,
  ordersLoaded = false,
  metaData,
  orders = [],
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Ref
  const checkbox = useRef<any>();

  // Redux
  const dispatch = useAppDispatch();
  const { selectedOrders } = useAppSelector((state) => state.orders);

  // Roles
  const { user } = useAppSelector((state) => state.account);
  const isPicker = usePickerManager(user);
  const isHQTeam = useHQmanager(user);
  const isAdmin = useAdminManager(user);

  // Local state
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  //#endregion

  //#region LOGIC
  useEffect(() => {
    if (!ordersLoaded) {
      dispatch(setSelectedOrders([]));
    }
  }, [ordersLoaded, dispatch]);

  // Change table on the fly
  useLayoutEffect(() => {
    const isIndeterminate =
      selectedOrders.length > 0 && selectedOrders.length < orders.length;
    setChecked(selectedOrders.length === orders.length);
    setIndeterminate(isIndeterminate);
    if (isAdmin || isPicker || isHQTeam) {
      if (checkbox.current) {
        checkbox.current.indeterminate = isIndeterminate;
      }
    }
  }, [selectedOrders, orders, checkbox, isPicker, isAdmin, isHQTeam]);

  // Toggle all backorders
  const toggleAll = () => {
    dispatch(
      setSelectedOrders(
        checked || indeterminate
          ? []
          : isAdmin || isPicker
          ? orders.filter((order) => order.promo_id)
          : orders.filter(
              (order) =>
                // select pending orders to approve
                (order.order_type !== ORDERTYPE.original &&
                  order.order_status === ORDERSTATUS.pending &&
                  order.order_status !== ORDERSTATUS.cancelled) ||
                // select orders to ship
                (order.order_type !== ORDERTYPE.original &&
                  order.order_status === ORDERSTATUS.ordered &&
                  order.order_status !== ORDERSTATUS.cancelled)
            )
      )
    );
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };
  //#endregion

  //#region FAILSAFE
  if (ordersLoaded && orders.length <= 0)
    return <h2>{t("rituals.noOrdersFoundWithThisFilterSelection")}</h2>;
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* Controle Panel */}
      <OrdersTableControlePanel
        isPicker={isPicker}
        isHQTeam={isHQTeam}
        isAdmin={isAdmin}
      />

      {/* Table */}
      <div className="relative">
        {(!ordersLoaded || !filtersLoaded) && <LoadingOverlay />}
        <MainTableMotionWrapper>
          <thead>
            <tr>
              {/* Checkbox */}
              <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                <input
                  style={{ opacity: isAdmin || isPicker || isHQTeam ? 1 : 0 }}
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                  ref={checkbox}
                  checked={checked}
                  onChange={toggleAll}
                />
              </th>

              {/* Store */}
              {isHQTeam && (
                <th
                  scope="col"
                  className="whitespace-nowrap pr-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {t("rituals.store")}
                </th>
              )}

              {/* Project */}
              <th
                scope="col"
                className="whitespace-nowrap pr-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {t("rituals.project")}
              </th>

              {/* Order date */}
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {t("rituals.orderDate")}
              </th>

              {/* Ordercode */}
              {!isHQTeam && (
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {t("rituals.orderCode")}
                </th>
              )}

              {/* Ordertype */}
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {t("rituals.orderType")}
              </th>

              {/* Orderstatus */}
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {t("rituals.orderStatus")}
              </th>

              {/* Items */}
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left w-64 text-sm font-semibold text-gray-900"
              >
                {t("rituals.items")}
              </th>

              {/* Comment */}
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left w-24 text-sm font-semibold text-gray-900"
              />

              {/* Parcel info */}
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left w-72 text-sm font-semibold text-gray-900"
              >
                {t("rituals.parcelInfo")}
              </th>

              {/* Reasons */}
              {isHQTeam && (
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {t("rituals.reason")}
                </th>
              )}
              {/* Actions */}
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              />
            </tr>
          </thead>
          <tbody className="bg-white">
            {orders.map((order) => (
              <OrdersTableRow
                clearSelectedOrdersHandler={() =>
                  dispatch(setSelectedOrders([]))
                }
                changeOrderStatusHandler={(order: Order, status: string) =>
                  changeOrderStatusHandler(order, status)
                }
                selectOrderHandler={(e: any, order: Order) =>
                  dispatch(
                    setSelectedOrders(
                      e.target.checked
                        ? [...selectedOrders, order]
                        : selectedOrders.filter((o) => o !== order)
                    )
                  )
                }
                selectedOrders={selectedOrders}
                isPicker={isPicker}
                isHQTeam={isHQTeam}
                isAdmin={isAdmin}
                order={order}
                key={order.id}
              />
            ))}
          </tbody>
        </MainTableMotionWrapper>
        <footer>
          {metaData && metaData?.total > 0 && (
            <PaginationBar
              metaData={metaData}
              onPageChange={(page: number) => dispatch(setPageNumber({ page }))}
              pageType={t("rituals.orders")}
            />
          )}
        </footer>
      </div>
    </Fragment>
  );
  //#endregion
}
