//#region IMPORTS
import {
  backorderItemsSelectors,
  fetchBackorderItemsAsync,
} from "./backOrderSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Promo } from "../../models/promo";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import ItemContainer from "../../components/itemsGrid/ItemContainer";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import agent from "../../api/agent";
import "./BackOrderPage.css";
//#endregion

export default function BackOrderPage() {
  //#region SETUP
  // Params
  const { id } = useParams<{ id: string }>();

  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const { basket } = useAppSelector((state) => state.basket);
  const { backOrder_loaded, promo_id, order_code, status } = useAppSelector(
    (state) => state.backOrderItems
  );
  const backOrderItems = useAppSelector(backorderItemsSelectors.selectAll);

  // Local state
  const [promo, setPromo] = useState<Promo>();
  //#endregion

  //#region LOGIC
  // Fetch backorderables
  useEffect(() => {
    if (!backOrder_loaded || promo_id !== parseInt(id)) {
      dispatch(fetchBackorderItemsAsync(parseInt(id)));
    }
  }, [backOrder_loaded, basket, dispatch, id, promo_id]);

  // Fetch promo detail
  useEffect(() => {
    const fetchPromoAsync = async () => {
      const { data } = await agent.Promos.detail(Number(id));
      setPromo(data);
    };
    !promo && fetchPromoAsync();
  }, [id, promo]);
  //#endregion

  //#region RENDER
  if (basket && basket.promo_id !== parseInt(id))
    return (
      <Link to="/">
        <div className="backorderpage__unorderable">
          <h1 className="backorderpage__unorderable__modal">
            {t("rituals.itIsNotPossibleToBackOrderCrossPromo")}
            <br />
            {t("rituals.pleaseFinishOrClearYourCurrentBasket")}
          </h1>
        </div>
      </Link>
    );

  return (
    <main className="backorderpage">
      {status !== "idle" ? (
        <div className="backorderpage__loadingcontainer">
          <LoadingSpinner />
        </div>
      ) : order_code ? (
        <Fragment>
          <PageHeader
            title={
              t("rituals.availableItems") +
              (promo?.code ? ` ${promo.code}` : "")
            }
          />

          <div className="backorderpage__header__descr">
            <p>{t("rituals.myOriginalOrder")}</p>
            <p>
              <b>{order_code}</b>
            </p>
          </div>

          <ItemContainer items={backOrderItems} newOrder={false} />
        </Fragment>
      ) : (
        <h1>{t("rituals.noOriginalOrderFoundForThisPromo")}...</h1>
      )}
    </main>
  );
  //#endregion
}
