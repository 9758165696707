import { Fragment, useEffect, useState } from "react";
import { ImageUpload } from "../../models/image";
import { toast } from "react-toastify";
import ImageUploaderItemV3 from "./ImageUploaderItemV3";
import SquareButton from "../genericButtons/squareButton/SquareButton";
import agent from "../../api/agent";
import "./ImageUploader.css";

function ImageUploaderV3() {
  //#region SETUP
  // Local state
  const [productFileGroups, setProductFileGroups] = useState<ImageUpload[]>([]);
  const [saveGroupName, setSaveGroupName] = useState<string | null>(null);
  const [productNames, setProductNames] = useState<string[]>([]);
  const [savingBatch, setSavingBatch] = useState<boolean>(false);
  const [loadedFiles, setLoadedFiles] = useState<File[]>([]);
  const [errorList, setErrorList] = useState<any>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  //#endregion

  //#region LOGIC
  //#region PREPARE DATA
  // Upload handler
  const getFiles = async (event: any) => {
    const files = await event.target.files;
    setLoadedFiles([...files]);
  };

  // Make product groups
  useEffect(() => {
    const products = [];
    const map = new Map();
    for (const item of loadedFiles) {
      if (!map.has(item.name.split("_")[0])) {
        map.set(item.name.split("_")[0], true);
        products.push(item.name.split("_")[0]);
      }
    }
    setProductNames(products);
  }, [loadedFiles]);

  // Group versions by product
  useEffect(() => {
    const productVersionFiles: ImageUpload[] = [];
    productNames.forEach((productName) => {
      productVersionFiles.push({
        product: productName,
        versions: loadedFiles.filter(
          (fileDeep) => fileDeep.name.split("_")[0] === productName
        ),
        saved: false,
      });
      setProductFileGroups(productVersionFiles);
    });
  }, [productNames, loadedFiles]);

  // Ready watcher
  useEffect(() => {
    loadedFiles.length > 0 && setReady(true);
  }, [loadedFiles]);
  //#endregion

  // Clear
  const clearUploadHandler = () => {
    setSaveGroupName(null);
    setProductFileGroups([]);
    setProductNames([]);
    setSavingBatch(false);
    setLoadedFiles([]);
    setErrorList([]);
    setSaving(false);
    setReady(false);
  };

  // Save one file
  const saveOne = async (file: File) => {
    const formDataFile = new FormData();
    formDataFile.append("file", file);
    try {
      await agent.ProductVersions.image(formDataFile);
    } catch (error) {
      setErrorList((prev: any) => [...prev, file.name]);
    }
  };

  // Save one productFileGroup
  const saveProductFileGroup = async (productFileGroup: ImageUpload) => {
    setSaveGroupName(productFileGroup.product);
    setSaving(true);

    for (const versionFile of productFileGroup.versions) {
      await saveOne(versionFile);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    productFileGroup.saved = true;
    setSaveGroupName(productFileGroup.product);
    setSaving(false);
    toast.success(productFileGroup.product + " saved");
  };

  // Save all productFileGroups
  const saveProductImagesHandler = async () => {
    setSavingBatch(true);
    try {
      for (const productFileGroup of productFileGroups) {
        if (!productFileGroup.saved) {
          await saveProductFileGroup(productFileGroup);
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
      clearUploadHandler();
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region RENDER
  return !ready ? (
    <section className="image-uploader">
      {errorList.length > 0 ? (
        <ul onClick={() => setErrorList([])}>
          {errorList.map((error: any, index: number) => (
            <li key={index}>{error.response.data.error}</li>
          ))}
        </ul>
      ) : (
        <div>
          <input
            className="image-uploader__button"
            onChange={getFiles}
            multiple
            type="file"
            name="file"
          />
        </div>
      )}
    </section>
  ) : (
    <Fragment>
      <section className="image-uploader__saving-modal">
        {!savingBatch && (
          <SquareButton
            clickHandler={saveProductImagesHandler}
            value={"Save All"}
          />
        )}

        <ul className="image-uploader__saving-modal__list">
          {productFileGroups.map(
            (productFilesGroup) =>
              !productFilesGroup.saved && (
                <ImageUploaderItemV3
                  key={productFilesGroup.product}
                  imageGroup={productFilesGroup}
                  saving={saving}
                  savingDetail={
                    saving && saveGroupName === productFilesGroup.product
                  }
                />
              )
          )}
        </ul>
        {!saving && productFileGroups.length === 0 && (
          <SquareButton clickHandler={() => setReady(false)} value={"Done"} />
        )}
        {ready && saving && (
          <SquareButton clickHandler={clearUploadHandler} value={"Cancel"} />
        )}
      </section>
    </Fragment>
  );
  //#endregion
}

export default ImageUploaderV3;
