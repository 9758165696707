//#region IMPORTS
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { ReactNode } from "react";
//#endregion

//#region INTERFACE
interface Props {
  hidePlusIcon?: boolean;
  clickHandler?: () => void;
  value?: string;
  children?: ReactNode;
}
//#endregion

export default function ActionButton({
  hidePlusIcon = false,
  clickHandler,
  value,
  children,
}: Props) {
  //#region RENDER
  return (
    <button
      onClick={clickHandler}
      type="button"
      className="inline-flex items-center gap-x-2 rounded-md bg-emerald-600 px-3.5 py-2 text-1xl font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
    >
      {!hidePlusIcon && !children && (
        <PlusCircleIcon className="-ml-0.5 h-8 w-8" aria-hidden="true" />
      )}
      {children && children}
      {value}
    </button>
  );
  //#endregion
}
