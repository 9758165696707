import { useExpanded, useGroupBy, useSortBy, useTable } from "react-table";
import { useEffect } from "react";
import { Item } from "../../models/item";
import SortUnsortIcon from "../icons/tableSortIcons.tsx/SortUnsortIcon";
import SortDescIcon from "../icons/tableSortIcons.tsx/SortDescIcon";
import SortAscIcon from "../icons/tableSortIcons.tsx/SortAscIcon";
import "./OrderdetailPageTable.css";

interface Props {
  data: Item[];
  columns: any;
  reference?: any;
  group: string[];
  status: boolean;
}

function OrderdetailPageTable({
  columns,
  data,
  reference,
  group,
  status,
}: Props) {
  //#region REACT-TABLEV7 SETUP
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    // @ts-ignore
    setGroupBy,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      autoResetExpanded: false,
    },
    useGroupBy,
    useSortBy,
    useExpanded
  );
  //#endregion

  //#region LOGIC HELPER
  useEffect(() => {
    if (status) {
      setGroupBy(group);
    }
  }, [group, status, setGroupBy]);
  //#endregion

  //#region RENDER
  return (
    <table {...getTableProps()} className="order-detail-table" ref={reference}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="order-detail-table__row"
          >
            {headerGroup.headers.map((column: any) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={`order-detail-table__cell--${column.id}`}
              >
                {column.render("Header")}
                <span style={{ verticalAlign: "middle" }}>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <SortDescIcon />
                    ) : (
                      <SortAscIcon />
                    )
                  ) : (
                    <SortUnsortIcon color="#254559" />
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="order-detail-table__body">
        {rows.map((row: any, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="order-detail-table__row">
              {row.cells.map((cell: any) => {
                return (
                  <td
                    className="order-detail-table__row__cell"
                    {...cell.getCellProps()}
                    style={{
                      background:
                        (cell.isAggregated && "#254559") ||
                        (cell.isGrouped && "#254559"),
                      color:
                        (cell.isAggregated && "white") ||
                        (cell.isGrouped && "white"),
                    }}
                  >
                    {cell.isGrouped ? (
                      <>
                        <span
                          className={`order-detail-table__row__cell__groupbutton${
                            row.isExpanded
                              ? " order-detail-table__row__cell__groupbutton--open"
                              : ""
                          }`}
                          {...row.getToggleRowExpandedProps()}
                        >
                          {row.isExpanded ? "X" : "+"}
                        </span>{" "}
                        {cell.render("Cell")}
                      </>
                    ) : cell.isAggregated ? (
                      cell.render("Aggregated")
                    ) : cell.isPlaceholder ? null : (
                      cell.render("Cell")
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
  //#endregion
}

export default OrderdetailPageTable;
