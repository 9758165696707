import { AppUser } from "../models/user";

function useAdminManager(user: AppUser | null): boolean {
  if (!user) {
    return false;
  } else if (user.roles.includes("Admin")) {
    return true;
  } else {
    return false;
  }
}

export default useAdminManager;
