//#region IMPORTS
import { Fragment } from "react";
import { Store } from "../../models/store";
import StoresTableRowActions from "./StoresTableRowActions";
import dateHelperWeirdFromBackend from "../../helpers/dateHelperWeirdFromBackend";
//#endregion

//#region INTERFACE
interface Props {
  commentHandler: (store: Store) => void;
  isHQTeam: boolean;
  isAdmin: boolean;
  store: Store;
}
//#endregion

export default function StoresTableRow({
  commentHandler,
  isHQTeam = false,
  isAdmin = false,
  store,
}: Props) {
  //#region RENDER
  return (
    <Fragment>
      <tr className="border-t border-slate-300 pt-12">
        {/* Store number */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-500 sm:pl-0">
          {store.external_store_id}
        </td>

        {/* Store name */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-500 sm:pl-0">
          {store.store_name}
        </td>

        {/* Street name */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-500">
          {store.address_1}
        </td>

        {/* Postal code */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
          {store.postal_code}
        </td>

        {/* City */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
          {store.city}
        </td>

        {/* Country */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
          {store.country_code}
        </td>

        {/* Language */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
          {store.language}
        </td>

        {/* Openings date */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
          {dateHelperWeirdFromBackend(store.opening_date)}
        </td>

        {/* Updated at */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
          {store.updated_at}
        </td>

        {/* Storefromat */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
          {store.store_format ? (
            <div className="inline-flex items-center rounded-full bg-gray-200 px-2 py-1 text-xs font-medium text-gray-900">
              {store.store_format.name}
            </div>
          ) : null}
        </td>

        {/* Actions cell */}
        <StoresTableRowActions
          commentHandler={(store: Store) => commentHandler(store)}
          isHQTeam={isHQTeam}
          isAdmin={isAdmin}
          store={store}
        />
      </tr>
    </Fragment>
  );
  //#endregion
}
