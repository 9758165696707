//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { basketReasonSelectors } from "../../features/basketReason/basketReasonSlice";
import { useState, useEffect } from "react";
import { addBasketItemAsync } from "../../features/basket/basketSlice";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { Item } from "../../models/item";
import LoadingSpinner from "../loading/LoadingSpinner";
import ItemMinus from "./ItemMinus";
import ItemPlus from "./ItemPlus";
//#endregion

//#region INTERFACE
interface Props {
  newOrder: boolean;
  list?: boolean;
  item: Item;
}
//#endregion

export default function ItemCard({ item, newOrder, list }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const basketReasons = useAppSelector(basketReasonSelectors.selectAll);
  const { status } = useAppSelector((state) => state.basket);
  const { basket } = useAppSelector((state) => state.basket);
  const { basketReasonsLoaded, status: reasonStatus } = useAppSelector(
    (state) => state.basketReasons
  );
  const dispatch = useAppDispatch();

  // Local state
  const [ammount, setAmmount] = useState(1);
  const [reasonModal, setReasonModal] = useState(false);
  const [ammountInBasket, setAmmountInBasket] = useState(0);
  const [orderAble, setOrderable] = useState(false);
  //#endregion

  //#region LOGIC
  // Check if item in basket
  useEffect(() => {
    const itemIndex = basket?.items.findIndex(
      (i) => i.product_version_id === item.product_version_id
    );
    if (itemIndex === -1 || itemIndex === undefined) return;
    setAmmountInBasket(basket!.items[itemIndex].quantity!);
  }, [item, basket]);

  useEffect(() => {
    if (!basket || basket.promo_id === item.promo_id) {
      setOrderable(true);
    }
  }, [basket, item]);

  // Check max is reached
  const disableAddItem = () => {
    const itemIndex = basket?.items.findIndex(
      (i) => i.product_version_id === item.product_version_id
    );
    if (itemIndex === -1 || itemIndex === undefined || !item.quantity) return;
    if (basket!.items[itemIndex].quantity >= item.quantity) return true;
  };

  // Ammount handling
  const handleAmmountChange = (value: any) => {
    let approvedParsed = 0;
    const parsed = parseInt(value);
    if (isNaN(parsed)) {
      approvedParsed = 1;
    } else {
      approvedParsed = parsed;
    }

    let ammountChange;
    if (approvedParsed <= 0) ammountChange = 1;
    else if (approvedParsed > item.quantity - ammountInBasket)
      ammountChange = item.quantity - ammountInBasket;
    else ammountChange = approvedParsed;
    setAmmount(ammountChange);
  };

  const increaseAmmount = () => {
    if (ammount < item.quantity - ammountInBasket) {
      setAmmount((prev) => prev + 1);
    }
  };
  const decreaseAmmount = () => {
    if (ammount > 1) {
      setAmmount((prev) => prev - 1);
    }
  };

  // Add item to cart
  const handleAddItem = () => {
    if (!newOrder && ammountInBasket === 0) {
      setReasonModal(true);
    } else {
      addItemToCart();
    }
  };

  const addItemToCart = async (reason_id?: number) => {
    // speed click safety
    !status.includes("PendingAddItem") &&
      orderAble &&
      (await dispatch(
        addBasketItemAsync({
          product_version_id: item.product_version_id,
          promo_id: item.promo_id,
          quantity: ammount,
          max_quantity: item.quantity ? item.quantity : 100,
          basket_reason_id: reason_id ? reason_id : 1,
        })
      ));
    setAmmount(1);
    setReasonModal(false);
    toast.success(t("rituals.itemAdded"));
  };

  // Show bigger image
  const showBigImage = () => {
    item.id && history.push("/image/" + item.product_version_id);
  };
  //#endregion

  //#region FAIL SAFE
  if (reasonStatus.includes("pending")) return <LoadingSpinner />;
  //#endregion

  //#region RENDER
  return (
    <div className={`itemcard${list ? " itemcard--list" : ""}`}>
      {reasonModal && (
        <div
          className={`itemcard__modal${list ? " itemcard__modal--list" : ""}`}
          onClick={() => setReasonModal(false)}
        >
          <div className="itemcard__modal__box">
            <h2>{t("rituals.hiYouAreReorderingThisProduct")}...</h2>
            <p>{t("rituals.whatIsTheReasonForThisAction")}</p>
            {basketReasonsLoaded &&
              basketReasons.slice(1).map((reason) => (
                <button
                  className="itemcard__modal__box__reasonbutn"
                  value={reason.id}
                  key={reason.id}
                  onClick={(e: any) => addItemToCart(e.target.value)}
                >
                  {t(
                    `rituals.${
                      reason.reason === "Dammaged"
                        ? "damaged"
                        : reason.reason.toLowerCase()
                    }`
                  )}
                </button>
              ))}
          </div>
        </div>
      )}

      <div
        className={`itemcard__subbox${list ? " itemcard__subbox--list" : ""}`}
      >
        <div
          className={`itemcard__imagewrapper${
            list ? " itemcard__imagewrapper--list" : ""
          }`}
          style={{
            backgroundImage: item.picture_url
              ? "none"
              : `url(${
                  process.env.PUBLIC_URL +
                  "/images/placeholder/placeholder_product__image.jpg"
                })`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          onClick={showBigImage}
        >
          {item.picture_url ? (
            <img src={item.picture_url} alt={item.name} />
          ) : (
            <p style={{ fontSize: "2rem" }}>
              <b>{t("rituals.noImageAvailable")}</b>
            </p>
          )}
        </div>

        <p className={`itemcard__title${list ? " itemcard__title--list" : ""}`}>
          {item.name.replaceAll("_", " ")}
        </p>
        {item.quantity ? (
          <p className="itemcard__quantity">
            {t("rituals.quantityReceived")}: {item.quantity}
          </p>
        ) : null}

        <p
          className={`itemcard__ammountinbasket${
            list ? " itemcard__ammountinbasket--list" : ""
          }`}
        >
          {ammountInBasket
            ? item.quantity === ammountInBasket
              ? "maximum in basket"
              : `in basket: ${ammountInBasket}`
            : null}
        </p>
      </div>
      {!disableAddItem() && orderAble && (
        <>
          <div
            className={`itemcard__controls${
              list ? " itemcard__controls--list" : ""
            }`}
          >
            <motion.button
              whileTap={{ scale: 0.95 }}
              onClick={handleAddItem}
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-full bg-slate-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 px-8"
            >
              <PlusCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              {t("rituals.add").toUpperCase()}
            </motion.button>

            <div className="itemcard__controls__ammountcontrols">
              <ItemMinus clickHandler={decreaseAmmount} />
              <input
                className="block w-20 mx-6 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                type="number"
                value={ammount}
                onChange={(e) => handleAmmountChange(e.target.value)}
              />

              <ItemPlus
                clickHandler={increaseAmmount}
                visible={ammount < item.quantity - ammountInBasket}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
  //#endregion
}
