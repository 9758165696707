//#region IMPORTS
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { Promo } from "../../models/promo";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
//#endregion

//#region INTERFACE
interface Props {
  overWriteTransSmartHandler?: () => void;
  refreshTransmartHandler?: () => void;
  exportUPSuniqueHandler?: () => void;
  deletehandler?: () => void;
  mainHandler?: () => void;
  subHandler?: () => void;
  isPicker: boolean;
  isAdmin: boolean;
  hqTeam: boolean;
  promo: Promo;
}
//#endregion

export default function PromosPageItemButtons({
  overWriteTransSmartHandler,
  refreshTransmartHandler,
  exportUPSuniqueHandler,
  deletehandler,
  mainHandler,
  subHandler,
  isPicker,
  isAdmin,
  hqTeam,
  promo,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();
  //#endregion

  //#region RENDER
  return (
    <Menu as="div" className="relative flex-none">
      <Menu.Button
        disabled={!promo.back_orderable && !isPicker && !isAdmin}
        className={classNames(
          !promo.back_orderable && !isPicker && !isAdmin
            ? "text-gray-200 hover:text-gray-200"
            : "text-gray-400 hover:text-gray-900",
          "-m-2.5 block p-2.5 "
        )}
      >
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <Fragment>
            {/* Main handler button */}
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={mainHandler}
                  className={classNames(
                    active ? "bg-gray-50" : "",
                    "block w-full px-3 py-1 text-sm font-bold leading-6 text-sky-900"
                  )}
                >
                  {isAdmin
                    ? t("rituals.edit")
                    : hqTeam
                    ? t("rituals.approve")
                    : isPicker
                    ? t("rituals.manage")
                    : t("rituals.reOrder")}
                </button>
              )}
            </Menu.Item>

            {/* Subhandler button */}
            {(isAdmin || isPicker) && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={subHandler}
                    className={classNames(
                      active ? "bg-gray-50" : "",
                      "block w-full px-3 py-1 text-sm leading-6 text-amber-600"
                    )}
                  >
                    {isAdmin ? t("rituals.import") : t("rituals.detail")}
                  </button>
                )}
              </Menu.Item>
            )}
            {/* Import shippingData button */}
            {(isAdmin || isPicker) && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={refreshTransmartHandler}
                    className={classNames(
                      active ? "bg-gray-50" : "",
                      "block w-full px-3 py-1 text-sm leading-6 text-amber-600"
                    )}
                  >
                    <b>refresh</b>
                  </button>
                )}
              </Menu.Item>
            )}

            {/* Overwrite ups unique button */}
            {(isAdmin || isPicker) && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={overWriteTransSmartHandler}
                    className={classNames(
                      active ? "bg-gray-50" : "",
                      "block w-full px-3 py-1 text-sm leading-6 text-amber-600"
                    )}
                  >
                    <b>overwrite T&T</b>
                  </button>
                )}
              </Menu.Item>
            )}
            {/* Export ups unique button */}
            {(isAdmin || isPicker) && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={exportUPSuniqueHandler}
                    className={classNames(
                      active ? "bg-gray-50" : "",
                      "block w-full px-3 py-1 text-sm leading-6 text-red-600"
                    )}
                  >
                    <b>export T&T</b>
                  </button>
                )}
              </Menu.Item>
            )}
          </Fragment>

          {/* Delete button only when project is closed and admin */}
          {isAdmin && !promo.back_orderable && (
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={deletehandler}
                  className={classNames(
                    active ? "bg-gray-50" : "",
                    "block w-full px-3 py-1 text-sm leading-6 text-red-600"
                  )}
                >
                  {t("rituals.delete")}
                </button>
              )}
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
  //#endregion
}
