import { MetaData } from "../../models/pagination";
import PaginationBarItem from "./PaginationBarItem";
import "./PaginationBar.css";
import { useTranslation } from "react-i18next";

interface Props {
  metaData: MetaData;
  pageType: string;
  onPageChange: (page: number) => void;
  loaded?: boolean;
}

function PaginationBar({
  metaData,
  pageType,
  onPageChange,
  loaded = true,
}: Props) {
  // Destructure metadata
  const { current_page, from, to, total, last_page, links } = metaData;

  // Translation
  const { t } = useTranslation();

  return (
    <div className="pagination-bar">
      <div className="pagination-bar__group pagination-bar__group--left">
        {last_page > 1 && (
          <div className="pagination-bar__group__pagecounter">
            <p
              className={`pagination-bar__group__pagecounter__current${
                !loaded
                  ? " pagination-bar__group__pagecounter__current--loading"
                  : ""
              }`}
            >
              {current_page}
            </p>
            <div className="pagination-bar__group__pagecounter__divider" />
            <p className="pagination-bar__group__pagecounter__total">
              {last_page}
            </p>
          </div>
        )}
      </div>

      {
        <div className="pagination-bar__group pagination-bar__group--pagination">
          {links.map((link, index) => (
            <PaginationBarItem
              currentPage={current_page}
              lastPage={last_page}
              link={link}
              key={index}
              onPageChange={(pageNr) => onPageChange(pageNr)}
            />
          ))}
        </div>
      }

      <p className="pagination-bar__group pagination-bar__group--right">
        <b>
          {from} - {to}
        </b>{" "}
        {t("rituals.of")}{" "}
        <b>
          {total} {pageType}
        </b>
      </p>
    </div>
  );
}

export default PaginationBar;
