import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router";
import { useAppSelector } from "../../store/configureStore";
import { ComponentType } from "react";

interface Props extends RouteProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

function PrivateRoute({ component: Component, ...rest }: Props) {
  const { user } = useAppSelector((state) => state.account);
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
