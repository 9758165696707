import { Icon } from "../../models/icon";

function BasketIconVtwo({ color = "#000000", className }: Icon) {
  return (
    <svg viewBox="0 0 59.63 80" className={className}>
      <path
        fill={color}
        d="m16.89,12.93C16.89,5.79,22.68,0,29.82,0h0c7.09,0,12.86,5.73,12.92,12.81h0c0,1.18-.95,2.14-2.12,2.16h0c-1.17,0-2.14-.95-2.14-2.12h0c-.03-4.72-3.94-8.59-8.66-8.59h0c-4.77,0-8.66,3.9-8.67,8.67h0c0,1.17-.95,2.13-2.13,2.13h0c-1.18,0-2.13-.96-2.13-2.13h0Z"
      />
      <path
        fill={color}
        d="m54.45,80H5.17c-2.85,0-5.17-2.32-5.17-5.17v-.18S5.03,21.51,5.03,21.51c.05-2.81,2.35-5.07,5.17-5.07h39.43c2.82,0,5.12,2.27,5.17,5.08l4.82,53.31c0,2.85-2.32,5.17-5.18,5.17Zm-50.59-5.1c.04.68.62,1.24,1.31,1.24h49.28c.69,0,1.28-.56,1.31-1.24l-4.82-53.28c0-.71-.6-1.31-1.31-1.31H10.2c-.71,0-1.31.6-1.31,1.31v.18s-5.03,53.1-5.03,53.1Z"
      />
    </svg>
  );
}

export default BasketIconVtwo;
