import { Icon } from "../../models/icon";

function HowToIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 24,
}: Icon) {
  return (
    <svg viewBox="0 0 80 80" style={{ width: width + "px" }}>
      <path
        fill={selected ? outline : "white"}
        d="m40,80C17.94,80,0,62.06,0,40S17.94,0,40,0s40,17.94,40,40-17.94,40-40,40Z"
      />
      <path
        fill={selected ? selectedColor : color}
        d="m40,75.95c-19.83,0-35.95-16.13-35.95-35.95S20.17,4.05,40,4.05s35.95,16.13,35.95,35.95-16.13,35.95-35.95,35.95Zm0-65.33c-16.2,0-29.38,13.18-29.38,29.38s13.18,29.38,29.38,29.38,29.38-13.18,29.38-29.38-13.18-29.38-29.38-29.38Z"
      />
      <path
        fill={selected ? selectedColor : color}
        d="m39.92,20.63c6.63-.43,11.45,3.25,11.87,9.62.33,5.15-2.4,8.94-6.46,10.49-1.51.52-2.42,1.28-2.44,2.66-.04,1.87-1.53,3.46-3.6,3.59s-3.68-.88-3.88-3.91c-.17-2.6.77-6.28,5.11-8.32l1.7-.86c1.8-.97,2.21-2.06,2.13-3.23-.1-1.59-1.74-3.03-3.97-2.89-1.59.1-2.92.94-3.44,2.78-.47,1.74-3.12,2.76-5.03,2.09-1.91-.68-2.54-3.03-1.96-4.78,1.32-4.24,4.93-6.93,9.97-7.25Zm-.43,28.75c2.49-.16,4.74,1.55,4.9,4.1s-1.83,4.6-4.33,4.76-4.68-1.56-4.85-4.16,1.78-4.54,4.28-4.7Z"
      />
    </svg>
  );
}

export default HowToIcon;
