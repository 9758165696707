//#region IMPORTS
import { useCallback, useEffect } from "react";
import { clearBackOrder } from "../backorder/backOrderSlice";
import { useAppDispatch } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
//#endregion

export default function CheckoutConfirm() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useAppDispatch();
  //#endregion

  //#region LOGIC
  const resetBackOrderItems = useCallback(async () => {
    try {
      dispatch(clearBackOrder());
      history.push("/");
    } catch (error) {
      toast.warning(`${error}`);
    }
  }, [dispatch, history]);

  useEffect(() => {
    const timer = setTimeout(() => {
      resetBackOrderItems();
    }, 5000);
    return () => clearTimeout(timer);
  }, [resetBackOrderItems]);
  //#endregion

  //#region RENDER
  return (
    <div className="checkoutmodal-container" onClick={resetBackOrderItems}>
      <div className="checkoutmodal-container__modal">
        <h1>Rituals...</h1>
        <p>
          {t("rituals.thanksForYourOrder.")}
          <br />
          {t("rituals.theOrderIsNowPendingApprovalAtHeadquarters.")}
        </p>
      </div>
    </div>
  );
  //#endregion
}