//#region IMPORTS
import {
  fetchTranslationLinesAsync,
  translationManagerSelectors,
  setTranslationManagerParams,
  setTranslationManagerPageNumber,
} from "./translatioManagerSlice";
import {
  TranslationLanguage,
  TranslationManagerParams,
} from "../../models/translations";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import TranslationManagerExcelLoader from "./TranslationManagerExcelLoader/TranslationManagerExcelLoader";
import TranslationManagerList from "./translationManagerList/TranslationManagerList";
import MainMotionSection from "../../components/_layout/MainMotionSection";
import FilterBarSearch from "../../components/filterBar/FilterBarSearch";
import useAdminManager from "../../hooks/useAdminManager";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import PaginationBar from "../../components/paginationBar/PaginationBar";
import MainContainer from "../../components/_layout/MainContainer";
import CancelIcon from "../../components/icons/CancelIcon";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import FilterBar from "../../components/filterBar/FilterBar";
import agent from "../../api/agent";
import "./TranslationManagerPage.css";
//#endregion

export default function TranslationManagerPage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);
  const { translationsLoaded, metaData, loading, languageColums } =
    useAppSelector((state) => state.translationManagement);
  const { page, limit, sort, ...selectedFilters }: TranslationManagerParams =
    useAppSelector((state) => state.translationManagement.translationParams);
  const translationManagerLines = useAppSelector(
    translationManagerSelectors.selectAll
  );

  // Local State
  const [uploadMode, setUploadMode] = useState<boolean>(false);
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const [languages, setLanguages] = useState<TranslationLanguage[]>([]);
  const [languagesLoaded, setLanguagesLoaded] = useState<boolean>(false);
  const [create, setCreate] = useState<boolean>(false);
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region LOGIC
  // Load translations
  useEffect(() => {
    if (!translationsLoaded) dispatch(fetchTranslationLinesAsync());
  }, [translationsLoaded, dispatch]);

  // Fetch Languages localy
  useEffect(() => {
    const fetchAllTranslationManagerLanguageCodes = async () => {
      try {
        const { languages } = await agent.Translations.getAvailableLanguages();
        setLanguages(languages);
        setLanguagesLoaded(true);
      } catch (error) {
        setLanguagesLoaded(false);
        toast.error(`${error}`);
      }
    };
    fetchAllTranslationManagerLanguageCodes();
  }, []);

  // Create translation line
  const toggleCreateTranslationLine = () => {
    if (uploadMode) {
      setUploadMode(false);
    }
    setCreate((prev) => !prev);
  };

  // Upload translation lines
  const toggleUploadTranslationLines = () => {
    if (create) {
      setCreate(false);
    }
    setUploadMode((prev) => !prev);
  };
  //#endregion

  //#region FAIL SAFE
  if (!translationsLoaded && !languagesLoaded && loading)
    return <LoadingSpinner />;
  //#endregion

  //#region RENDER
  return (
    <MainContainer>
      <FilterBar directTo="/translations" selectedFilters={selectedFilters}>
        <h2 className="ml-8 font-semibold text-amber-600 text-2xl">
          {t("rituals.search")}
        </h2>
        <FilterBarSearch
          setSearchParamHandler={(value) =>
            dispatch(setTranslationManagerParams({ key: value }))
          }
          title={t("rituals.name")}
          lastValue={selectedFilters.key}
        />
      </FilterBar>

      <MainMotionSection>
        <PageHeader
          title={t("rituals.translations")}
          createButtonValue={create ? t("rituals.save") : t("rituals.add")}
          createClickHandler={toggleCreateTranslationLine}
          uploadButtonValue={
            uploadMode ? t("rituals.cancel") : t("rituals.uploadExcel")
          }
          uploadClickHandler={toggleUploadTranslationLines}
          create={create}
          isAdmin={isAdmin}
          search={true}
          upload={uploadMode}
          removeAllbuttonsWhenCRUD={create || uploadMode}
        >
          {/* CancelButtons */}
          {create ? (
            <motion.button
              whileHover={{ scale: 1.1, rotate: "90deg" }}
              className="translation-manager-list__header__cancel-box"
              onClick={toggleCreateTranslationLine}
            >
              <CancelIcon />
            </motion.button>
          ) : uploadMode ? (
            <motion.button
              whileHover={{ scale: 1.1, rotate: "90deg" }}
              className="translation-manager-list__header__cancel-box"
              onClick={toggleUploadTranslationLines}
            >
              <CancelIcon />
            </motion.button>
          ) : null}
        </PageHeader>

        {/* Excelloader */}
        <AnimatePresence>
          {uploadMode && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "50px" }}
              exit={{ opacity: 0, height: 0 }}
            >
              <TranslationManagerExcelLoader
                setDownloaded={(value: boolean) => setDownloaded(value)}
                downloaded={downloaded}
                closeModal={toggleUploadTranslationLines}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <div>
          {metaData &&
          translationManagerLines.length > 0 &&
          languages.length > 0 ? (
            <TranslationManagerList
              toggleCreateHandler={toggleCreateTranslationLine}
              languages={languages}
              colums={languageColums}
              create={create}
              data={translationManagerLines}
            />
          ) : null}
        </div>
        <footer>
          {metaData && metaData?.total > 0 && (
            <PaginationBar
              metaData={metaData}
              onPageChange={(page: number) =>
                dispatch(setTranslationManagerPageNumber({ page: page }))
              }
              pageType={t("rituals.translations")}
            />
          )}
        </footer>
      </MainMotionSection>
    </MainContainer>
  );
  //#endregion
}
