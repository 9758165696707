import { Icon } from "../../models/icon";

function PendingIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 24,
}: Icon) {
  return (
    <svg viewBox="0 0 80 80" style={{ width: width + "px" }}>
      <path
        fill={selected ? outline : "white"}
        d="M40,80C17.9,80,0,62.1,0,40S17.9,0,40,0s40,17.9,40,40S62.1,80,40,80z"
      />
      <path
        fill={selected ? selectedColor : color}
        d="M40,75.9c-19.8,0-36-16.1-36-36S20.2,4.1,40,4.1s35.9,16.1,35.9,36S59.8,75.9,40,75.9z M40,10.6
	c-16.2,0-29.4,13.2-29.4,29.4S23.8,69.4,40,69.4S69.4,56.2,69.4,40S56.2,10.6,40,10.6z"
      />
      <path
        fill={selected ? selectedColor : color}
        d="M53.2,26.8c0-6.6,0-7.1,0-7.1c0-1-0.8-1.8-1.8-1.8H28.6c-1,0-1.8,0.8-1.8,1.8c0,0,0,0.5,0,7.1
	c0,6.6,8,9.8,8,13.2s-8,6.6-8,13.2c0,6.6,0,7,0,7c0,1,0.8,1.8,1.8,1.8h1.6h0.2h19.3H50h1.4c1,0,1.8-0.8,1.8-1.8c0,0,0-0.5,0-7
	c0-6.5-8-9.4-8-13.2C45.2,36.2,53.2,33.4,53.2,26.8z M38.9,50.9c-0.5,0.2-1,0.4-1.4,0.8l-6.1,5.8h-1v-4.4c0-2.3,2-4.3,3.9-6.2
	c2-2,4.1-4,4.1-6.9c0,0,0-0.1,0-0.1c0.2,0.1,0.3,0.1,0.5,0.2v1.6V50.9z M45.9,47.3c2.3,2.2,3.7,3.8,3.7,5.9v4.4h-1l-6.1-5.8
	c-0.4-0.4-0.9-0.7-1.4-0.8v-9.2v-1.6c0.2-0.1,0.4-0.1,0.6-0.2c0,0,0,0.1,0,0.1C41.7,43.2,43.9,45.4,45.9,47.3z M32,30.7
	c-1-1.3-1.6-2.4-1.6-3.8v-4.4h19.3v4.4c0,1.4-0.7,2.6-1.7,3.9H32z"
      />
    </svg>
  );
}

export default PendingIcon;
