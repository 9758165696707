import { Icon } from "../../../models/icon";

function SortDescIcon({ color = "#fbb033", width = 20 }: Icon) {
  return (
    <svg
      viewBox="0 0 80 80"
      style={{ width: width + "px", marginLeft: width / 2 + "px" }}
    >
      <path
        fill={color}
        d="m40.09,0C17.99,0,0,17.91,0,39.92s17.99,40.08,40.09,40.08,40.08-17.98,40.08-40.08S62.19,0,40.09,0Zm0,72.62c-18.03,0-32.71-14.67-32.71-32.7S22.05,7.38,40.09,7.38s32.7,14.6,32.7,32.53-14.67,32.7-32.7,32.7Z"
      />
      <path
        fill={color}
        d="m37.21,58.81s0,0,0,0l.02.02s.07.06.1.09c.06.06.12.11.18.16.06.05.13.1.19.14.04.03.07.06.11.08,0,0,.01,0,.02.01.07.05.14.09.22.13.03.02.07.04.1.06.01,0,.03.01.04.02.06.03.12.06.18.08.03.02.07.03.1.05.01,0,.02.01.03.01.02,0,.04.02.06.02.03.01.07.02.11.04.09.03.18.07.28.1,0,0,.02,0,.02,0,1.13.32,2.37.14,3.38-.54,0,0,.01-.01.02-.02.1-.07.19-.14.28-.21.05-.04.09-.08.14-.13.04-.04.09-.08.14-.12l.02-.02s0,0,0,0l13.37-13.19c1.6-1.57,1.61-4.14.04-5.74-.79-.81-1.84-1.21-2.89-1.21s-2.06.39-2.85,1.17l-6.49,6.4v-22.19c0-2.24-1.81-4.06-4.06-4.06s-4.06,1.82-4.06,4.06v22.19l-6.49-6.4c-.79-.78-1.82-1.17-2.85-1.17s-2.09.4-2.89,1.21c-1.57,1.6-1.56,4.17.04,5.74l13.37,13.19Z"
      />
    </svg>
  );
}

export default SortDescIcon;
