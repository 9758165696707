interface Props{
  className?: string
}

function RitualsLogo({className}:Props) {
  return (
    <div className={className ? className : "navbar__logo"}>
      <svg viewBox="0 0 168.83 17.2">
        <g>
          <path
            id="Path_117"
            d="M15.02,16.21h-3.63l-3.13-4.72H4.97v4.72H2.08v-4.72H0.29V8.74h1.79V1.1h7.2
		c3.26,0,5.45,2.09,5.45,5.19c-0.03,2.11-1.44,3.96-3.47,4.54L15.02,16.21z M4.97,8.74h4.09c2.05,0,2.78-1.26,2.78-2.44
		s-0.73-2.44-2.78-2.44H4.97V8.74z"
          />
          <path id="Path_119" d="M25.02,16.21h-2.89V1.1h2.89V16.21z" />
          <path
            id="Path_120"
            d="M40.38,16.21h-2.89V3.85H33.1V1.1h11.67v2.75h-4.39V16.21z"
          />
          <path
            id="Path_121"
            d="M58.66,16.21c-3.56,0.14-6.57-2.63-6.71-6.19c0-0.1-0.01-0.19-0.01-0.29V0.79h2.89v8.34
		c0,2.55,1.57,4.33,3.83,4.33c2.25,0,3.83-1.78,3.83-4.33V0.79h2.89v8.94c0.02,3.57-2.86,6.47-6.43,6.49
		C58.86,16.22,58.76,16.22,58.66,16.21"
          />
          <path
            id="Path_123"
            d="M87.38,16.21h-3.11l-0.02-0.04l-1.13-2.78h-6.26l-1.14,2.82h-3.1l0.04-0.09l5.42-12.41h-3.4
		v-2.6h6.3L81,1.15L87.38,16.21z M77.91,10.79h4.18l-2.08-5.14L77.91,10.79z"
          />
          <path
            id="Path_124"
            d="M107.49,16.21H95.94V1.1h2.89v12.36h5.77V9.88h2.89C107.49,9.88,107.49,16.21,107.49,16.21z"
          />
          <path
            id="Path_125"
            d="M119.96,16.21c-2.44-0.01-4.78-1.01-6.46-2.78l-0.04-0.05l2.19-1.97l0.04,0.04
		c1.13,1.24,2.72,1.97,4.4,2.01c2.17-0.02,3.52-0.86,3.52-2.18c0-1.37-1.72-1.6-3.7-1.86c-2.66-0.35-5.96-0.8-5.96-4.36
		c0-2.61,2.69-4.57,6.26-4.57c2.24-0.1,4.41,0.74,6.01,2.31l0.05,0.05l-2.14,1.92l-0.04-0.04c-1.02-1.14-2.48-1.78-4.01-1.77
		c-2.13,0-3.09,0.99-3.09,1.97c0,1.37,1.71,1.59,3.68,1.84c2.66,0.33,5.98,0.75,5.98,4.39C126.64,14.43,123.2,16.21,119.96,16.21"
          />
          <path
            id="Path_126"
            d="M138.62,16.21c-1.35,0-2.44-1.09-2.44-2.44s1.09-2.44,2.44-2.44s2.44,1.1,2.44,2.44
		C141.06,15.12,139.96,16.21,138.62,16.21"
          />
          <path
            id="Path_127"
            d="M151.78,16.21c-1.35,0-2.44-1.09-2.44-2.44s1.09-2.44,2.44-2.44s2.44,1.09,2.44,2.44
		C154.23,15.12,153.13,16.21,151.78,16.21"
          />
          <path
            id="Path_128"
            d="M164.95,16.21c-1.35,0-2.44-1.09-2.44-2.44s1.09-2.44,2.44-2.44s2.44,1.09,2.44,2.44
		S166.3,16.21,164.95,16.21"
          />
        </g>
      </svg>
    </div>
  );
}

export default RitualsLogo;
