//#region IMPORTS
import { Item } from "../../models/item";
//#endregion

//#region INTERFACE
interface Props {
  item: Item;
}
//#endregion

export default function CheckoutPageItem({ item }: Props) {
  //#region RENDER
  return (
    <div
      className="checkout-container__list__item"
      key={item.product_version_id}
    >
      <p className="checkout-container__list__item__qty">{item.quantity}</p>
      <div className="checkout-container__list__item__picturebox">
        <img className="checkout-container__list__item__picturebox__picture" src={item.picture_url} alt={item.picture_url} />
      </div>
      <p className="checkout-contianer__list__item__cell3">
        {item.name}
      </p>
    </div>
  );
  //#endregion
}

