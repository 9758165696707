import { Icon } from "../../models/icon";

function TranslationIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 24,
}: Icon) {
  return (
    <svg viewBox="0 0 80 80" style={{ width: width + "px" }}>
      <path
        fill={selected ? outline : "white"}
        d="m39.96,79.91c-6.51,0-12.93-1.61-18.69-4.67l-13.04,4.35c-.62.21-1.29.32-1.98.32-1.67,0-3.24-.65-4.42-1.83-1.68-1.68-2.26-4.13-1.51-6.39l4.35-13.05C1.61,52.88,0,46.46,0,39.95,0,32.42,2.14,25.06,6.2,18.66c.1-.21.23-.46.42-.72l.33-.46.03-.03C14.46,6.51,26.74,0,39.96,0s25.66,6.6,33.13,17.67c.05.06.1.13.15.19l.07.1c.17.23.29.46.38.65,4.07,6.41,6.22,13.79,6.22,21.34s-2.14,14.89-6.19,21.3c-.1.22-.23.46-.42.72l-.34.48-.03.02c-7.48,10.93-19.76,17.43-32.97,17.43Z"
      />
      <path
        fill={selected ? selectedColor : color}
        d="m70.16,59.39c3.62-5.61,5.75-12.27,5.75-19.43s-2.14-13.85-5.77-19.47c-.04-.07-.05-.15-.1-.22-.02-.03-.06-.05-.08-.08-6.44-9.74-17.48-16.19-30-16.19s-23.55,6.44-29.99,16.17c-.03.04-.07.05-.1.09-.06.08-.07.18-.12.26-3.62,5.61-5.75,12.27-5.75,19.43,0,6.43,1.73,12.72,5.02,18.27l-4.91,14.72c-.27.81-.06,1.7.54,2.3.43.43,1,.66,1.59.66.24,0,.48-.04.71-.12l14.72-4.91c5.55,3.29,11.84,5.02,18.27,5.02,12.52,0,23.55-6.44,29.99-16.17.03-.04.07-.05.1-.09.06-.08.07-.18.12-.26Zm-2.71-4.18c-2.91-1.71-6.42-3.11-10.36-4.15.55-3.49.85-7.21.85-11.11s-.3-7.62-.85-11.11c3.94-1.04,7.45-2.45,10.36-4.15,2.52,4.52,3.97,9.72,3.97,15.26s-1.45,10.73-3.97,15.26Zm-27.49,16.2c-4.52,0-9.4-6.52-11.86-16.92,3.73-.68,7.75-1.05,11.86-1.05s8.16.36,11.87,1.03c-2.46,10.41-7.34,16.94-11.87,16.94Zm0-22.47c-4.44,0-8.73.4-12.73,1.13-.48-3.13-.76-6.51-.76-10.12s.28-6.99.76-10.12c4,.74,8.31,1.13,12.73,1.13s8.72-.39,12.73-1.13c.48,3.13.76,6.51.76,10.12s-.28,6.99-.76,10.12c-4-.74-8.31-1.13-12.73-1.13Zm0-40.45c4.53,0,9.41,6.53,11.87,16.94-3.71.67-7.72,1.03-11.87,1.03s-8.16-.36-11.87-1.03c2.46-10.41,7.34-16.94,11.87-16.94Zm25.01,12.46c-2.46,1.42-5.43,2.59-8.76,3.49-1.35-5.76-3.42-10.65-6.02-14.2,5.93,2.05,11.04,5.82,14.77,10.71ZM29.72,10.24c-2.6,3.54-4.67,8.44-6.02,14.2-3.33-.9-6.3-2.07-8.76-3.48,3.73-4.9,8.85-8.66,14.78-10.71Zm-21.22,29.72c0-5.53,1.45-10.73,3.97-15.26,2.91,1.71,6.42,3.11,10.36,4.15-.55,3.49-.85,7.21-.85,11.11s.3,7.61.85,11.09c-3.93,1.04-7.45,2.43-10.36,4.14-2.6-4.67-3.97-9.89-3.97-15.22Zm13.47,26.22c-.24,0-.48.04-.71.12l-11.45,3.82,3.33-9.99c2.71-1.92,6.35-3.5,10.57-4.63,1.35,5.76,3.42,10.65,6.01,14.19-2.28-.79-4.48-1.83-6.55-3.15-.36-.23-.78-.35-1.2-.35Zm28.23,3.49c2.6-3.54,4.67-8.44,6.02-14.2,3.33.9,6.3,2.07,8.76,3.49-3.73,4.9-8.85,8.66-14.77,10.71Z"
      />
    </svg>
  );
}

export default TranslationIcon;
