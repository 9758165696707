//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useState } from "react";
import { clearBackOrder } from "../backorder/backOrderSlice";
import { useTranslation } from "react-i18next";
import { clearBasket } from "./basketSlice";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import BasketPageItem from "./BasketPageItem";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import ArrowIconRight from "../../components/icons/ArrowIconRight";
import GoBackButton from "../../components/genericButtons/goBackButton/GoBackButton";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import EmptyList from "../../components/errors/EmptyList";
import NotFound from "../../components/errors/NotFound";
import agent from "../../api/agent";
import "./BasketPage.css";
//#endregion

export default function BasketPage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();
  const { basket } = useAppSelector((state) => state.basket);

  // Local state
  const [loading, setLoading] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(false);
  //#endregion

  //#region LOGIC
  const itemCount = basket?.items.reduce((sum, item) => sum + item.quantity, 0);

  const handleCheckout = async () => {
    setLoading(true);
    try {
      history.push("/checkout");
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleDeleteBasket = async () => {
    if (!basket) return NotFound();
    setLoading(true);
    try {
      await agent.Basket.delete(basket!.id);
      dispatch(clearBasket());
      dispatch(clearBackOrder());
      setLoading(false);
      setDeleteWarning(false);
      toast.success(t("rituals.yourBasketIsEmpty"));
      history.push("/");
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleReturnToPromo = () => {
    if (basket?.promo_id !== null) {
      history.push(`/backorder/promo/${basket?.promo_id}`);
    }
  };
  //#endregion

  //#region FAIL SAFE
  if (!basket) return <EmptyList message={t("rituals.yourBasketIsEmpty")} />;
  if (loading) return <LoadingSpinner />;
  //#endregion

  //#region RENDER
  return (
    <main className="basket">
      <PageHeader
        title={t("rituals.myBasket")}
        extra={
          <Fragment>
            <div className="backorderpage__header__descr">
              <div>
                <p>
                  <b>{t("rituals.summary")}</b>
                </p>
                <p>
                  {t("rituals.totalQuantity")}: {itemCount}
                </p>
              </div>
            </div>
          </Fragment>
        }
      >
        <GoBackButton />
      </PageHeader>

      <section className="basket__list">
        {basket.items.map((item) => (
          <BasketPageItem key={item.product_version_id} item={item} />
        ))}
      </section>

      <footer className="basket__footer">
        <div className="basket__footer__btn-group">
          {!deleteWarning ? (
            <Fragment>
              <button onClick={handleReturnToPromo} className="basket__cta">
                {t("rituals.addMore")}
              </button>
              <button
                onClick={() => setDeleteWarning(true)}
                className="basket__cta basket__cta--delete"
              >
                {t("rituals.deleteBasket")}
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <div className="basket__cta basket__cta--warning">
                <h3>{t("rituals.areYouSure")}</h3>
                <div>
                  <button
                    onClick={handleDeleteBasket}
                    className="basket__cta basket__cta--warning__yes"
                  >
                    {t("rituals.yes")}
                  </button>
                  <button
                    onClick={() => setDeleteWarning(false)}
                    className="basket__cta basket__cta--warning__no"
                  >
                    {t("rituals.cancel")}
                  </button>
                </div>
              </div>
            </Fragment>
          )}
        </div>

        <button
          onClick={handleCheckout}
          className="basket__cta basket__cta--confirm"
        >
          <span>{t("rituals.nextStep")}</span>
          <ArrowIconRight color="white" />
        </button>
      </footer>
    </main>
  );
  //#endregion
}
