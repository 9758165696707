//#region IMPORTS
import { TranslationLanguage } from "../../models/translations";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TranslationDropDownList from "./TranslationDropdownList";
import CrossIcon from "../icons/CrossIcon";
import "./TranslationDropdown.css";
//#endregion

//#region INTERFACE
interface Props {
  availableLanguages: TranslationLanguage[];
}
//#endregion

export default function TranslationDropdown({ availableLanguages }: Props) {
  //#region SETUP
  // Translation
  const { i18n, t } = useTranslation();

  // Local State
  const [open, setOpen] = useState<boolean>(false);
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>(
    i18n.language
  );
  const [selectedLanguageName, setSelectedLanguageName] = useState<string>(
    i18n.language
  );
  //#endregion

  //#region LOGIC
  useEffect(() => {
    const foundLanguage = availableLanguages.find(
      (lang) => lang.code === i18n.language
    );
    foundLanguage && setSelectedLanguageName(foundLanguage.name);
  }, [availableLanguages, i18n]);

  // Select handler
  const handleSelectLanguage = async (language: TranslationLanguage) => {
    setSelectedLanguageCode(language.code);
    setSelectedLanguageName(language.name);
    localStorage.setItem("robinLanguage", language.code);
    await i18n.changeLanguage(language.code);
    closeHandler();
  };

  // Open handler
  const openHandler = () => {
    !open && setOpen(true);
  };

  // Close handler
  const closeHandler = () => {
    open && setOpen(false);
  };
  //#endregion

  //#region RENDER
  return (
    <div className="dropdown">
      <p className="dropdown__name">{t("rituals.portalLanguage")}</p>
      <div className="dropdown__cta">
        <h3 className="dropdown__cta__title serif">{selectedLanguageName}</h3>
        <button
          onClick={open ? closeHandler : openHandler}
          className={
            "dropdown__cta__button" +
            (open ? " dropdown__cta__button--open" : "")
          }
        >
          <CrossIcon width={16} />
        </button>
      </div>

      {/* Drop down menu */}
      {open && (
        <TranslationDropDownList
          selectedValue={selectedLanguageCode}
          languages={availableLanguages}
          onChange={(language) => handleSelectLanguage(language)}
        />
      )}
    </div>
  );
  //#endregion
}
