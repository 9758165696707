import { ReactElement, JSXElementConstructor } from "react";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn,
} from "react-hook-form";

export default function StoreFormDatePicker({
  isClearable = false,
  onClear = () => {},
  ...rest
}) {
  return (
    <div className="store-form__inputgroup__inputfield">
      <Controller
        render={function ({
          field,
          fieldState,
          formState,
        }: {
          field: ControllerRenderProps<FieldValues, string>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<FieldValues>;
        }): ReactElement<any, string | JSXElementConstructor<any>> {
          throw new Error("Function not implemented.");
        }}
        name={""}
        {...rest}
      />
      {isClearable && (
        <button
          type="button"
          className="store-form__inputgroup__inputfield__clearbutton"
          onClick={onClear}
        >
          x
        </button>
      )}
    </div>
  );
}
