import { Item } from "../../models/item";
import ItemCard from "./ItemCard";
import "./Items.css";

interface Props {
  items: Item[];
  newOrder: boolean;
}

function ItemContainer({ items, newOrder }: Props) {
  return (
    <section className="items-container">
      {items.map((item) => (
        <ItemCard
          key={item.product_version_id}
          item={item}
          newOrder={newOrder}
          list={false}
        />
      ))}
    </section>
  );
}

export default ItemContainer;
