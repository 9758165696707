//#region IMPORT
import { useTranslation } from "react-i18next";
import { Order } from "../../models/order";
import { Item } from "../../models/item";
//#endregion

//#region INTERFACE
interface Props {
   subOrderId: number;
  order: Order;
}
//#endregion

export default function SubOrderDetailList({ order, subOrderId }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();
  //#endregion

  return (
    <ul className="w-auto divide-y divide-gray-100">
      {order.order_items
        .filter((orderItem: Item) => orderItem.sub_order_id === subOrderId)
        .map((orderItem) => (
          <li key={orderItem.id} className="flex justify-between gap-x-6 py-5">
            <div className="flex min-w-0 gap-x-4">
              <img
                className="order-detail-table__row__thumb"
                src={orderItem.picture_url}
                alt={orderItem.id.toString()}
              />
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {orderItem.name}
                </p>
                <p className="mt-1 text-sm text-gray-600">
                  {t("rituals.quantity") + ": " + orderItem.quantity}
                </p>
              </div>
            </div>
          </li>
        ))}
    </ul>
  );
}