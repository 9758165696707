import { Icon } from "../../models/icon";

function ManageIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 24,
}: Icon) {
  return (
    <svg viewBox="0 0 80 80" style={{ width: width + "px" }}>
      <path
        fill={selected ? outline : "white"}
        d="M39.63,80c-1.59-.01-3.19-.13-4.78-.33-3.34-.44-5.97-3.06-6.4-6.39l-.34-2.65-1.48-.62-2.12,1.62c-1.28,.98-2.88,1.52-4.49,1.52s-3.25-.55-4.55-1.56c-1.26-.98-2.47-2.04-3.6-3.16-1.27-1.26-2.47-2.62-3.56-4.03-2.05-2.66-2.04-6.37,0-9.03l1.63-2.12-.61-1.49-2.65-.35c-3.33-.45-5.95-3.08-6.36-6.41C.09,43.23-.02,41.42,0,39.63c.02-1.59,.13-3.2,.33-4.78,.43-3.33,3.06-5.96,6.39-6.4l2.65-.34,.62-1.49-1.62-2.12c-2.04-2.67-2.02-6.39,.04-9.04,.97-1.25,2.04-2.46,3.16-3.6,1.26-1.27,2.61-2.47,4.03-3.56,1.29-.99,2.89-1.54,4.51-1.54s3.23,.55,4.52,1.54l2.12,1.63,1.49-.61,.35-2.65c.45-3.33,3.08-5.95,6.41-6.36,1.64-.21,3.32-.31,4.98-.31h.39c1.59,.02,3.2,.13,4.78,.33,3.34,.43,5.97,3.06,6.4,6.39l.34,2.65,1.48,.62,2.12-1.62c1.28-.98,2.88-1.52,4.49-1.52s3.26,.55,4.55,1.56c1.25,.97,2.46,2.03,3.6,3.16,1.27,1.26,2.47,2.61,3.56,4.03,2.05,2.66,2.04,6.38,0,9.04l-1.63,2.12,.61,1.49,2.65,.35c3.33,.45,5.94,3.08,6.37,6.41,.22,1.76,.33,3.57,.31,5.37-.01,1.59-.13,3.19-.33,4.78-.44,3.34-3.07,5.97-6.4,6.4l-2.65,.34-.62,1.48,1.62,2.12c2.04,2.67,2.02,6.39-.04,9.04-.98,1.26-2.04,2.47-3.16,3.6-1.26,1.27-2.61,2.47-4.03,3.56-1.28,.99-2.89,1.54-4.51,1.54h0c-1.63,0-3.23-.55-4.52-1.54l-2.12-1.63-1.49,.61-.36,2.65c-.44,3.33-3.08,5.94-6.41,6.36-1.64,.21-3.32,.31-4.99,.31h-.38Z"
      />
      <path
        fill={selected ? selectedColor : color}
        d="M66.62,31.52l-1.75-4.25c-.33-.8-.21-1.7,.32-2.39l2.54-3.3c.67-.86,.67-2.07,0-2.93-.95-1.24-1.99-2.41-3.11-3.52-1-.99-2.05-1.91-3.15-2.76-.86-.67-2.07-.68-2.94-.02l-3.31,2.53c-.68,.52-1.59,.64-2.39,.31l-4.25-1.77c-.79-.33-1.35-1.06-1.46-1.91l-.54-4.13c-.14-1.09-1-1.94-2.08-2.08-1.38-.18-2.78-.28-4.18-.29-1.58-.01-3.14,.08-4.69,.27-1.08,.14-1.93,.99-2.08,2.07l-.55,4.13c-.11,.85-.67,1.58-1.47,1.91l-4.25,1.75c-.8,.33-1.7,.21-2.39-.32l-3.3-2.54c-.86-.67-2.07-.67-2.93,0-1.24,.95-2.41,1.99-3.52,3.11-.99,1-1.91,2.05-2.76,3.15-.67,.86-.68,2.07-.02,2.94l2.53,3.31c.52,.68,.64,1.59,.31,2.39l-1.77,4.25c-.33,.79-1.06,1.35-1.91,1.46l-4.13,.54c-1.09,.14-1.94,1-2.08,2.08-.18,1.38-.28,2.78-.29,4.18-.01,1.58,.08,3.14,.27,4.69,.14,1.08,.99,1.93,2.07,2.08l4.13,.55c.85,.11,1.58,.67,1.91,1.47l1.75,4.25c.33,.8,.21,1.7-.32,2.39l-2.54,3.3c-.67,.86-.67,2.07,0,2.93,.95,1.24,1.99,2.41,3.11,3.52,1,.99,2.05,1.91,3.15,2.76,.86,.67,2.07,.68,2.94,.02l3.31-2.53c.68-.52,1.59-.64,2.39-.31l4.25,1.77c.79,.33,1.35,1.06,1.46,1.91l.54,4.13c.14,1.09,1,1.94,2.08,2.08,1.38,.18,2.78,.28,4.18,.29,1.58,.01,3.14-.08,4.69-.27,1.08-.14,1.93-.99,2.08-2.07l.55-4.13c.11-.85,.67-1.58,1.47-1.91l4.25-1.75c.8-.33,1.7-.21,2.39,.32l3.3,2.54c.86,.67,2.07,.67,2.93,0,1.24-.95,2.41-1.99,3.52-3.11,.99-1,1.91-2.05,2.76-3.15,.67-.86,.68-2.07,.02-2.94l-2.53-3.31c-.52-.68-.64-1.59-.31-2.39l1.77-4.25c.33-.79,1.06-1.35,1.91-1.46l4.13-.54c1.09-.14,1.94-1,2.08-2.08,.18-1.38,.28-2.78,.29-4.18,.01-1.58-.08-3.14-.27-4.69-.14-1.08-.99-1.93-2.07-2.08l-4.13-.55c-.85-.11-1.58-.67-1.91-1.47Zm-20.58,23.15c-8.09,3.33-17.39-.54-20.72-8.63-3.33-8.09,.54-17.39,8.63-20.72,8.09-3.33,17.39,.54,20.72,8.63,3.33,8.09-.54,17.39-8.63,20.72Z"
      />
    </svg>
  );
}

export default ManageIcon;
