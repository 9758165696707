//#region IMPORT
import { ORDERTYPE, Order } from "../../models/order";
import { Fragment } from "react";
//#endregion

//#region INTERFACE
interface Props {
  showByPrinter: boolean;
  order: Order;
}
//#endregion

export default function OrdersTableRowItems({ order, showByPrinter }: Props) {
  //#region RENDER
  return (
    <Fragment>
      {showByPrinter &&
      order.sub_orders &&
      order.order_type !== ORDERTYPE.original ? (
        order.sub_orders.map((subOrder) => (
          <div
            key={subOrder.id}
            className="flex items-center gap-1 text-xs font-medium text-grey-900"
          >
            <span className="font-bold">
              {
                order.order_items.filter(
                  (orderItem) => orderItem.sub_order_id === subOrder.id
                ).length
              }
            </span>
            <span>{`by ${subOrder.suppliers.split("(")[0]}`}</span>
          </div>
        ))
      ) : (
        <div> {order.order_items.length}</div>
      )}
    </Fragment>
  );
  //#endregion
}
