import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router";
import { useAppSelector } from "../../store/configureStore";
import { ComponentType } from "react";
import useAdminManager from "../../hooks/useAdminManager";

interface Props extends RouteProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

function AdminRoute({ component: Component, ...rest }: Props) {
  const { user } = useAppSelector((state) => state.account);
  const isAdmin = useAdminManager(user);

  return (
    <Route
      {...rest}
      render={(props) =>
        user && isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

export default AdminRoute;
