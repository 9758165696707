//#region IMPORTS
import { createLanguageAsync, fetchLanguagesAsync, languageSelectors } from "../../features/languages/languageSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import LanguageContainerHeader from "./LanguageContainerHeader";
import LanguageList from "./LanguageList";
//#endregion

export default function LanguageContainer() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { languages_loaded, metaData } = useAppSelector(
    (state) => state.languagesManagement
  );
  const languages = useAppSelector(languageSelectors.selectAll);

  // Local state
  const [create, setCreate] = useState<boolean>(false);
  const [newLanguageCode, setNewLanguageCode] = useState<string>("");

  //#endregion

  //#region LOGIC
  // Load languages
  useEffect(() => {
    !languages_loaded && dispatch(fetchLanguagesAsync());
  }, [languages_loaded, dispatch]);

  // Save new storeFormat
  const saveHandler = async () => {
    await dispatch(
      createLanguageAsync({
        values: {
          code: newLanguageCode,
          short_code: newLanguageCode,
        },
      })
    );
    setCreate(false);
  };
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      <LanguageContainerHeader
        createMode={create}
        addHandler={() => setCreate(true)}
        saveHandler={saveHandler}
      />
      {create && (
        <input
          placeholder="e.g. BENL"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6 mb-4"
          onChange={(e: any) => setNewLanguageCode(e.target.value)}
          type="text"
        />
      )}

      <LanguageList
        languages={languages}
        languagesLoaded={languages_loaded}
        metaData={metaData}
      />
    </Fragment>
  );
  //#endregion
}
