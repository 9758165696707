//#region IMPORTS
import { useTranslation } from "react-i18next";
import { AppUser } from "../../models/user";
//#endregion

//#region INTERFACE
interface Props {
  user: AppUser | null;
}
//#endregion

export default function HomeHeader({ user }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();
  //#endregion

  //#region RENDER
  return (
    <header className="home__header">
      <h1 className="home__header__title serif">
        {t("rituals.hello")} <span>{user?.name.split(".")[0]}</span>,
      </h1>
    </header>
  );
  //#endregion
}
