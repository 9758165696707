//#region IMPORTS
import { resetOrderParams } from "../../features/orders/ordersSlice";
import { useAppDispatch } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ExcelDownloadWidget from "../excelDownloadWidget/ExcelDownloadWidget";
import useWeekDay from "../../hooks/useWeekDay";
//#endregion

//#region INTERFACE
interface Props {
  isPicker: boolean;
  isAdmin: boolean;
}
//#endregion

export default function HomeInfoItems({ isAdmin, isPicker }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();

  // Dates
  const current = new Date();
  const weekday = useWeekDay(current);
  //#endregion

  //#region RENDER
  return (
    <div className="home-datagrid">
      {isAdmin ? (
        <div className="rounded-lg home-datagrid__generalinfo">
          <ExcelDownloadWidget />
        </div>
      ) : (
        <div className="rounded-lg home-datagrid__generalinfo">
          <p>{t(`rituals.${weekday.toString()}`)}</p>
          <p>{`${current.getDate()}/${
            current.getMonth() + 1
          }/${current.getFullYear()}`}</p>
        </div>
      )}

      <div
        className="rounded-lg home-datagrid__extras"
        onClick={() => {
          dispatch(resetOrderParams());
          history.push("/orders");
        }}
      >
        {t("rituals.orderHistory")}
      </div>
    </div>
  );
  //#endregion
}
