import { Icon } from "../../models/icon";

function FilterIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  width = 16,
}: Icon) {
  return (
    <svg viewBox="0 0 80 80" style={{ width: width + "px" }}>
      <path
        fill={selected ? selectedColor : color}
        d="M44.43,13.35C42.25,5.65,35.16,0,26.77,0S11.29,5.65,9.1,13.35H0v10.01H9.1c2.18,7.7,9.28,13.35,17.67,13.35s15.48-5.65,17.67-13.35h35.57V13.35H44.43Zm-17.67,13.35c-4.6,0-8.35-3.74-8.35-8.35s3.74-8.35,8.35-8.35,8.34,3.74,8.34,8.35-3.74,8.35-8.34,8.35Z"
      />
      <path
        fill={selected ? selectedColor : color}
        d="M71.17,56.76c-2.14-7.76-9.27-13.48-17.7-13.48s-15.56,5.72-17.7,13.48H0v10.01H35.84c2.23,7.63,9.29,13.22,17.63,13.22s15.4-5.59,17.63-13.22h8.9v-10.01h-8.83Zm-17.7,13.22c-4.6,0-8.35-3.74-8.35-8.35s3.74-8.35,8.35-8.35,8.34,3.74,8.34,8.35-3.74,8.35-8.34,8.35Z"
      />
    </svg>
  );
}

export default FilterIcon;
