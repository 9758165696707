import { Icon } from "../../models/icon";

function ArrowIconRight({ color = "#000000", width = 32 }: Icon) {
  return (
    <svg viewBox="0 0 80 60" fill={color} style={{ width: width + "px"}}>
      <polygon points="48.19 0 41.16 7.28 59.91 24.96 0 24.96 0 35.03 59.89 35.03 41.14 52.72 48.19 60 80 30 48.19 0" />
    </svg>
  );
}

export default ArrowIconRight;
