//#region IMPORTS
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProductVersion } from "../../models/productVersion";
import agent from "../../api/agent";
//#endregion

function ItemBigImage() {
  //#region SETUP
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [productVersion, setProductVersion] = useState<ProductVersion>();
  //#endregion

  //#region LOGIC
  const closeModal = () => history.goBack();

  useEffect(() => {
    const getProductVersionImage = async () => {
      console.log(id);
      const response = await agent.ProductVersions.detail(Number(id));
      console.log(response);
      setProductVersion(response.data);
    };
    id && getProductVersionImage();
  }, [id]);

  //#endregion

  //#region RENDER
  return (
    <div className="big-image-modal-container" onClick={closeModal}>
      <div className="big-image-modal-container__modal">
        {productVersion?.picture_url && (
          <img
            style={{ height: "80vh" }}
            src={productVersion?.picture_url}
            alt={
              productVersion?.picture_url ? productVersion?.name : "placeholder"
            }
          />
        )}
      </div>
    </div>
  );
  //#endregion
}

export default ItemBigImage;
