import { MetaDataLink } from "../../models/pagination";
import PaginationBarArrow from "./PaginationBarArrow";

interface Props {
  link: MetaDataLink;
  currentPage: number;
  lastPage: number;
  onPageChange: (page: number) => void;
}

function PaginationBarItem({
  link,
  onPageChange,
  currentPage,
  lastPage,
}: Props) {
  const { url, label, active } = link;

  const clickhandler = (value: string) => {
    onPageChange(parseInt(value));
  };

  if (label.includes("&laquo;")) {
    return currentPage === 1 ? null : (
      <PaginationBarArrow
        direction="left"
        clickHandler={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
    );
  } else if (label.includes("&raquo;")) {
    return currentPage === lastPage ? null : (
      <PaginationBarArrow
        direction="right"
        clickHandler={() => onPageChange(currentPage + 1)}
        disabled={currentPage === lastPage}
      />
    );
  } else if (url === null) {
    return (
      <div className="pagination-bar__group--pagination__item">{label}</div>
    );
  } else {
    return (
      <button
        className={`pagination-bar__group--pagination__item${
          active ? " pagination-bar__group--pagination__item--active" : ""
        }`}
        onClick={() => clickhandler(label)}
      >
        {label}
      </button>
    );
  }
}

export default PaginationBarItem;

// ||
