//#region IMPORTS
import {
  StoreDetail,
  StoreRequest,
  StoreDeliveryAddress,
} from "../../models/store";
import {
  storeFormatSelectors,
  fetchStoreFormatsAsync,
} from "../../features/storeFormats/storeFormatSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { resetStoreParams } from "../../features/stores/storesSlice";
import { Language } from "../../models/language";
import { Country } from "../../models/country";
import { toast } from "react-toastify";
import StoreFormDatePicker from "./StoreFormDatePicker";
import SaveButton from "../genericButtons/saveButton/SaveButton";
import agent from "../../api/agent";
import "./StoreForm.css";
//#endregion

//#region INTERFACE
interface Props {
  updateStore?: (updatedStore: StoreDetail) => void;
  countryName?: string;
  cancelCUD: () => void;
  create?: boolean;
  active?: boolean;
  store?: StoreDetail;
  id?: number;
}
//#endregion

export default function StoreForm({
  updateStore,
  countryName,
  cancelCUD,
  create = false,
  active = true,
  store,
  id,
}: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { storeFormats_loaded } = useAppSelector((state) => state.storeFormats);
  const storeFormats = useAppSelector(storeFormatSelectors.selectAll);

  // Local State
  const [selectedDeliveryAddressId, setSelectedDeliveryAddressId] = useState<
    number[]
  >([]);
  const [extravalidation, setExtraValidation] = useState<boolean>(true);
  const [sameDelivery, setSameDelivery] = useState<boolean>(false);
  const [shopRequest, setShopRequest] = useState<StoreRequest>();
  const [languages, setLanguages] = useState<Language[]>();
  const [countries, setCountries] = useState<Country[]>();

  // Create shopRequest when shop is given
  useEffect(() => {
    if (store) {
      const newRequest: StoreRequest = {
        customer_id: store.customer_id,
        store_type: store.store_type,
        store_name: store.store_name,
        external_store_id: store.external_store_id,
        address_1: store.address_1,
        address_2: store.address_2,
        address_3: store.address_3,
        city: store.city,
        country_code: store.country_code,
        postal_code: store.postal_code,
        language: store.language,
        billing_id: store.billing_id,
        comment: store.comment,
        active: store.active,
        opening_date: store.opening_date,
        contact_first_name: store.contact_first_name,
        contact_last_name: store.contact_last_name,
        contact_email: store.contact_email,
        contact_phone: store.contact_phone,
        same_as_address: store.same_as_address,
        delivery_addresses: [...store.delivery_addresses.reverse()],
        store_format_id: store.store_format?.id,
        store_format_name: store.store_format?.name,
      };
      setShopRequest(newRequest);
    }
  }, [store]);
  //#endregion

  //#region LOGIC
  // Fetch Languages
  useEffect(() => {
    const fetchAllStoreLanguageCodes = async () => {
      try {
        const response = await agent.LanguagesManagement.list();
        setLanguages(response.data);
      } catch (error) {
        toast.error(`${error}`);
      }
    };
    fetchAllStoreLanguageCodes();
  }, []);

  // Fetch storeFormats
  useEffect(() => {
    !storeFormats_loaded && dispatch(fetchStoreFormatsAsync());
  }, [storeFormats_loaded, dispatch]);

  // Fetch Countries
  useEffect(() => {
    const fetchAllStoreCountryCodes = async () => {
      try {
        const response = await agent.CountryManagement.list();

        // Sort countries on name before setting state
        const sortedResponse = response.data.sort((a: Country, b: Country) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );

        setCountries(sortedResponse);
      } catch (error) {
        toast.error(`${error}`);
      }
    };
    fetchAllStoreCountryCodes();
  }, []);

  // Check if is sameDelivery
  useEffect(() => {
    if (store) {
      if (store.same_as_address) {
        setSameDelivery(true);
      }
    }
  }, [store]);

  // Set same as delivery
  const handleSameAsDelivery = (e: any) => {
    setSameDelivery(e.target.checked);
  };

  // Form setup
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors, isValid },
    control,
  } = useForm<StoreRequest>({
    mode: "all",
    defaultValues: {
      customer_id: "1",
      store_name: "",
      store_type: "",
      external_store_id: "",
      address_1: "",
      address_2: "",
      address_3: "",
      city: "",
      country_code: "",
      postal_code: "",
      language: "",
      billing_id: "",
      comment: "",
      active: false,
      contact_first_name: "",
      contact_last_name: "",
      contact_email: "",
      contact_phone: "",
      same_as_address: sameDelivery,
      store_format_id: 0,
      store_format_name: "",
      delivery_addresses: [
        {
          id: 0,
          address_1: "",
          address_2: "",
          address_3: "",
          city: "",
          country_code: "",
          postal_code: "",
          activation_date: Date.now().toString(),
          store_email: "",
          store_phone: "",
          current_address: false,
        },
      ],
    },
  });

  // Create a dynamic form field array
  const { fields, append, remove } = useFieldArray({
    name: "delivery_addresses",
    control,
  });

  // Set current selected delivery addres id if shop exists
  useEffect(() => {
    if (store) {
      const existing = selectedDeliveryAddressId.includes(
        store.current_delivery_address_id
      );
      !existing &&
        setSelectedDeliveryAddressId((prev) => [
          ...prev,
          store.current_delivery_address_id,
        ]);
    }
  }, [store]);

  // Set extravalidation
  useEffect(() => {
    if (
      (!sameDelivery && selectedDeliveryAddressId.length > 1) ||
      (!sameDelivery && selectedDeliveryAddressId.length <= 0)
    ) {
      setExtraValidation(false);
    } else {
      setExtraValidation(true);
    }
  }, [selectedDeliveryAddressId, sameDelivery]);

  // Fill in fields if store exists
  useEffect(() => {
    if (shopRequest) {
      reset(shopRequest);
    }
  }, [shopRequest, reset]);

  // Reset fields if !active
  useEffect(() => {
    if (!active) reset(shopRequest);
  }, [active, reset, shopRequest]);

  // Submit form
  async function submitForm(data: StoreRequest) {
    // Manipulate data to match with server requests
    // Remove the delivery addresses if sameDelivery === true
    data.same_as_address = sameDelivery;
    if (sameDelivery) {
      delete data.delivery_addresses;
    }

    // Remove id from delivery address it is zero
    if (data.delivery_addresses && data.delivery_addresses?.length > 0) {
      const cleanedDeliveryAddresses = data.delivery_addresses.map(
        (item: StoreDeliveryAddress) => {
          if (item.id === 0) {
            // @ts-ignore
            delete item.id;
          }
          return item;
        }
      );
      data.delivery_addresses = { ...cleanedDeliveryAddresses };
    }

    try {
      if (id && !create) {
        const response = await agent.StoreManagement.update(id, data);
        dispatch(resetStoreParams());
        updateStore && updateStore(response.data);
        toast.success(`${data.store_name} has been updated.`);
      } else {
        await agent.StoreManagement.create(data).then(() => {
          dispatch(resetStoreParams());
          toast.success(`${data.store_name} is now added.`);
        });
      }
      cancelCUD();
    } catch (error: any) {
      toast.error(`${error.response.data.errors.message}`);
    }
  }

  const handleSelectedAddress = (e: any, id: number) => {
    const existing = selectedDeliveryAddressId.includes(id);
    if (e.target.checked && !existing) {
      setSelectedDeliveryAddressId((prev) => [...prev, id]);
    } else if (!e.target.checked) {
      setSelectedDeliveryAddressId((prev) =>
        prev.filter((exid) => exid !== id)
      );
    }
  };
  //#endregion

  //#region RENDER
  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className={`store-form${
        active
          ? isValid && extravalidation
            ? " store-form--valid"
            : ""
          : " store-form--inactive"
      }`}
    >
      {/* LEFT CONTAINER */}
      <div className="store-form__item-container--left">
        {/* name */}
        <fieldset className="store-form__inputgroup">
          <div className="store-form__inputgroup__labelgroup">
            <label>Store name *</label>
            <p>
              The store name is unique and identifies a specific retail store.
            </p>
            <p className="store-form__inputgroup__labelgroup--error">
              {errors.store_name && errors.store_name.message}
            </p>
          </div>
          <input
            className={
              !active
                ? "store-form__inputgroup__inputfield"
                : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--active"
            }
            type="text"
            placeholder="e.g. Rituals Gent"
            disabled={!active}
            {...register("store_name", {
              required: "Store name is required",
              minLength: { value: 6, message: "At least 6 characters please" },
            })}
          />
        </fieldset>
        {/* number */}
        <fieldset className="store-form__inputgroup">
          <div className="store-form__inputgroup__labelgroup">
            <label>Store Number *</label>
            <p>The store number is a identifier assigned by the customer.</p>
            <p className="store-form__inputgroup__labelgroup--error">
              {errors.external_store_id && errors?.external_store_id?.message}
            </p>
          </div>
          <input
            className={
              !active
                ? "store-form__inputgroup__inputfield"
                : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--active"
            }
            type="text"
            placeholder="e.g. 6666"
            disabled={!active}
            {...register("external_store_id", {
              // required: "Store number is required",
              minLength: {
                value: 4,
                message: "At least 4 characters please",
              },
              maxLength: {
                value: 20,
                message: "Maximum 20 characters please",
              },
            })}
          />
        </fieldset>

        {/* store format */}
        <fieldset className="store-form__inputgroup">
          <div className="store-form__inputgroup__labelgroup">
            <label>Store format</label>
            <p>The format used this store.</p>
          </div>
          {active ? (
            <select
              disabled={!active}
              className={
                !active
                  ? "store-form__inputgroup__inputfield"
                  : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--active"
              }
              {...register("store_format_id")}
            >
              <option disabled={true}>--choose format--</option>
              {storeFormats &&
                storeFormats.map((format) => (
                  <option key={format.id} value={format.id}>
                    {!active
                      ? shopRequest?.store_format_id
                      : format.name === ""
                      ? "-"
                      : format.name}
                  </option>
                ))}
            </select>
          ) : (
            <p className="store-form__inputgroup__inputfield">
              {shopRequest?.store_format_name}
            </p>
          )}
        </fieldset>

        {/* opening date */}
        <fieldset className="store-form__inputgroup">
          <div className="store-form__inputgroup__labelgroup">
            <label>Opening date *</label>
            <p>This is the date, the shop was opened.</p>
            <p className="store-form__inputgroup__labelgroup--error">
              {errors.opening_date && errors?.opening_date?.message}
            </p>
          </div>
          <input
            className={
              !active
                ? "store-form__inputgroup__inputfield"
                : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--active"
            }
            type="date"
            placeholder="04/05/2024"
            disabled={!active}
            {...register("opening_date", {
              required: "opening date is required",
            })}
          />
        </fieldset>

        {/* language */}
        <fieldset className="store-form__inputgroup">
          <div className="store-form__inputgroup__labelgroup">
            <label>Language *</label>
            {errors.language ? (
              <p className="store-form__inputgroup__labelgroup--error">
                {errors.language.message}
              </p>
            ) : (
              <p>The primary language used for communication in the store.</p>
            )}
          </div>
          {active ? (
            <select
              disabled={!active}
              className={
                !active
                  ? "store-form__inputgroup__inputfield"
                  : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--active"
              }
              {...register("language", {
                required: "Language is required",
                minLength: {
                  value: 1,
                  message: "At least 1 characters please",
                },
                maxLength: {
                  value: 20,
                  message: "Maximum 20 characters please",
                },
              })}
            >
              <option disabled={true}>--choose language--</option>
              {languages &&
                languages.map((language) => (
                  <option key={language.id} value={language.code}>
                    {!active
                      ? shopRequest?.language
                      : language.code === ""
                      ? "-"
                      : language.code}
                  </option>
                ))}
            </select>
          ) : (
            <p className="store-form__inputgroup__inputfield">
              {shopRequest?.language}
            </p>
          )}
        </fieldset>
        {/* billing_id */}
        <fieldset className="store-form__inputgroup">
          <div className="store-form__inputgroup__labelgroup">
            <label>Billing Id</label>
            {errors.billing_id ? (
              <p className="store-form__inputgroup__labelgroup--error">
                {errors.billing_id.message}
              </p>
            ) : (
              <p>
                The billing ID is a unique identifier used to track and manage
                invoices associated with a specific store.
              </p>
            )}
          </div>
          <input
            className={
              !active
                ? "store-form__inputgroup__inputfield"
                : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--active"
            }
            type="text"
            placeholder="e.g. 666-666"
            disabled={!active}
            {...register("billing_id", {
              minLength: {
                value: 1,
                message: "At least 1 characters please",
              },
              maxLength: {
                value: 50,
                message: "Maximum 50 characters please",
              },
            })}
          />
        </fieldset>
        {/* comment */}
        <fieldset className="store-form__inputgroup">
          <div className="store-form__inputgroup__labelgroup">
            <label>Comment</label>
            <p>
              For users to provide feedback, or additional information about the
              shop.
            </p>
            <p className="store-form__inputgroup__labelgroup--error">
              {errors.comment && errors?.comment?.message}
            </p>
          </div>
          <input
            className={
              !active
                ? "store-form__inputgroup__inputfield"
                : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--active"
            }
            type="text"
            placeholder="e.g. a verry nice store"
            disabled={!active}
            {...register("comment")}
          />
        </fieldset>
        {/* contact */}
        <fieldset className="store-form__inputgroup">
          <div className="store-form__inputgroup__labelgroup">
            <h2>Contact person</h2>
            <p>
              The store contact refers to the person who can be contacted
              regarding any inquiries or issues related to the store.
            </p>
            <ul className="store-form__inputgroup__labelgroup--error">
              {errors.contact_first_name && (
                <li>{errors.contact_first_name.message}</li>
              )}
              {errors.contact_last_name && (
                <li>{errors.contact_last_name.message}</li>
              )}
              {errors.contact_phone && <li>{errors.contact_phone.message}</li>}
              {errors.contact_email && <li>{errors.contact_email.message}</li>}
            </ul>
          </div>

          <div className="store-form__inputgroup__multiple">
            {/* contact firstname */}
            <div className="store-form__inputgroup__multiple__double">
              <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
                <label>First name</label>
              </div>
              <input
                className={
                  !active
                    ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                    : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
                }
                type="text"
                placeholder="e.g. Saskia"
                disabled={!active}
                {...register("contact_first_name")}
              />
            </div>

            {/* contact lastname */}
            <div className="store-form__inputgroup__multiple__double">
              <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
                <label>Last name</label>
              </div>
              <input
                className={
                  !active
                    ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                    : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
                }
                type="text"
                placeholder="e.g. Handgraaf"
                disabled={!active}
                {...register("contact_last_name")}
              />
            </div>

            {/* contact email */}
            <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
              <label>Email *</label>
            </div>
            <input
              disabled={!active}
              className={
                !active
                  ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                  : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
              }
              type="email"
              placeholder="e.g. annelies@test.com"
              {...register("contact_email", {
                required: "contact email is required",
                maxLength: {
                  value: 255,
                  message: "Maximum 255 characters please",
                },
              })}
            />

            {/* contact phone */}
            <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
              <label>Phone</label>
            </div>
            <input
              disabled={!active}
              className={
                !active
                  ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                  : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
              }
              type="phone"
              placeholder="e.g. 09 586 544"
              {...register("contact_phone")}
            />
          </div>
        </fieldset>
        {/* active */}
        <fieldset className="store-form__inputgroup">
          <div className="store-form__inputgroup__labelgroup">
            <input
              type="checkbox"
              className="h-4 w-4 mr-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
              disabled={!active}
              {...register("active")}
            />
            <label>store is active</label>
          </div>
        </fieldset>
      </div>

      {/* RIGHT CONTAINER */}
      <div className="store-form__item-container--right">
        {/* address */}
        <fieldset className="store-form__inputgroup">
          <div className="store-form__inputgroup__labelgroup">
            <h2>Address</h2>
            <p>
              This is the main address of the store. This can be different from
              the current delivery address for logistical reasons.
            </p>

            {/* delivery same as address */}
            <div
              className="form__inputgroup__labelgroup__extrainfo"
              style={{ marginTop: "1rem" }}
            >
              <div className="flex">
                <input
                  disabled={!active ? true : false}
                  className="mt-1 h-4 w-4 mr-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                  type="checkbox"
                  defaultChecked={store ? store.same_as_address : false}
                  onChange={(e: any) => handleSameAsDelivery(e)}
                />
                <p className="form__inputgroup__labelgroup__extrainfo__infolabel">
                  Delivery address same as address?
                </p>
              </div>
            </div>
            <ul className="store-form__inputgroup__labelgroup--error">
              {errors.address_1 && <li>{errors.address_1.message}</li>}
              {errors.address_2 && <li>{errors.address_2.message}</li>}
              {errors.address_3 && <li>{errors.address_3.message}</li>}
              {errors.postal_code && <li>{errors.postal_code.message}</li>}
              {errors.city && <li>{errors.city.message}</li>}
              {errors.country_code && <li>{errors.country_code.message}</li>}
            </ul>
          </div>

          <div className="store-form__inputgroup__multiple">
            {/* main street line */}
            <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
              <label>Street *</label>
            </div>
            <input
              disabled={!active}
              className={
                !active
                  ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                  : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
              }
              type="text"
              placeholder="e.g. Koekoekstraat 70"
              {...register("address_1", {
                required: "Street is required",
                minLength: {
                  value: 4,
                  message: "at least 4 characters please",
                },
                maxLength: {
                  value: 300,
                  message: "Maximum 300 characters please",
                },
              })}
            />

            {/* street line 2*/}
            {!active &&
            (store?.address_2 === "" ||
              store?.address_2 === "-" ||
              store?.address_2 === null) ? null : (
              <Fragment>
                <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
                  <label>Street line 2</label>
                </div>
                <input
                  disabled={!active}
                  className={
                    !active
                      ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                      : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
                  }
                  type="text"
                  placeholder="e.g. bus 44"
                  {...register("address_2", {
                    maxLength: {
                      value: 300,
                      message: "Maximum 300 characters please",
                    },
                  })}
                />
              </Fragment>
            )}

            {/* street line 3 */}
            {!active &&
            (store?.address_3 === "" ||
              store?.address_3 === "-" ||
              store?.address_2 === null) ? null : (
              <Fragment>
                <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
                  <label>Street line 3</label>
                </div>
                <input
                  disabled={!active}
                  className={
                    !active
                      ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                      : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
                  }
                  type="text"
                  placeholder="e.g. 3e deur rechts"
                  {...register("address_3")}
                />
              </Fragment>
            )}

            {/* postal code */}
            <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
              <label>Postal code</label>
            </div>
            <input
              disabled={!active}
              className={
                !active
                  ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                  : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
              }
              type="text"
              placeholder="e.g. 9000"
              {...register("postal_code")}
            />

            {/* city */}
            <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
              <label>City *</label>
            </div>
            <input
              disabled={!active}
              className={
                !active
                  ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                  : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
              }
              type="text"
              placeholder="e.g. Gent"
              {...register("city", {
                required: "City is required",
              })}
            />

            {/* country */}
            <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
              <label>Country *</label>
            </div>

            {active ? (
              <select
                disabled={!active}
                defaultValue={countryName}
                className={
                  !active
                    ? "store-form__inputgroup__inputfield"
                    : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--active"
                }
                {...register("country_code", {
                  required: "Country is required",
                })}
              >
                {countries &&
                  countries.map((country) => (
                    <option value={country.code} key={country.id}>
                      {country.name}
                    </option>
                  ))}
              </select>
            ) : (
              <p>{countryName}</p>
            )}
          </div>
        </fieldset>

        {/* DELIVERY ADDRESSES */}
        {/* Only show when creating or editing store */}
        {((create && !sameDelivery) || !sameDelivery) && (
          <div>
            {fields.map((field, index) => {
              setValue(`delivery_addresses.${index}.id`, field.id);
              return (
                <fieldset
                  key={index}
                  className="store-form__inputgroup store-form__inputgroup--delivery-addresses"
                >
                  <div className="store-form__inputgroup__labelgroup">
                    <h2>Delivery address</h2>
                    <p>
                      This is a <b>delivery address</b> of the store. This
                      address can be different from the main address for
                      logistical reasons.
                    </p>
                    {/* Is current delivery address */}
                    <div
                      className="form__inputgroup__labelgroup__extrainfo"
                      style={{ marginTop: "1rem" }}
                    >
                      <p className="form__inputgroup__labelgroup__extrainfo__infolabel">
                        Current delivery address?
                      </p>
                      <input
                        disabled={!active ? true : false}
                        className="store-form__inputgroup__inputfield store-form__inputgroup__inputfield--checkbox"
                        type="checkbox"
                        {...register(
                          `delivery_addresses.${index}.current_address`
                        )}
                        onChange={(e: any) =>
                          handleSelectedAddress(e, parseInt(field.id))
                        }
                      />
                    </div>

                    {errors.delivery_addresses !== undefined &&
                      errors.delivery_addresses[index] !== undefined && (
                        <ul className="store-form__inputgroup__labelgroup--error">
                          {errors.delivery_addresses[index].address_1
                            ?.message && (
                            <li>
                              {
                                errors.delivery_addresses[index].address_1
                                  ?.message
                              }
                            </li>
                          )}
                          {errors.delivery_addresses[index].address_2
                            ?.message && (
                            <li>
                              {
                                errors.delivery_addresses[index].address_2
                                  ?.message
                              }
                            </li>
                          )}
                          {errors.delivery_addresses[index].address_3
                            ?.message && (
                            <li>
                              {
                                errors.delivery_addresses[index].address_3
                                  ?.message
                              }
                            </li>
                          )}
                          {errors.delivery_addresses[index].postal_code
                            ?.message && (
                            <li>
                              {
                                errors.delivery_addresses[index].postal_code
                                  ?.message
                              }
                            </li>
                          )}
                          {errors.delivery_addresses[index].city?.message && (
                            <li>
                              {errors.delivery_addresses[index].city?.message}
                            </li>
                          )}
                          {errors.delivery_addresses[index].country_code
                            ?.message && (
                            <li>
                              {
                                errors.delivery_addresses[index].country_code
                                  ?.message
                              }
                            </li>
                          )}
                          {errors.delivery_addresses[index].activation_date
                            ?.message && (
                            <li>
                              {
                                errors.delivery_addresses[index].activation_date
                                  ?.message
                              }
                            </li>
                          )}
                        </ul>
                      )}
                    <ul className="store-form__inputgroup__labelgroup--error">
                      {selectedDeliveryAddressId.length > 1 && (
                        <li>Select only one current delivery address.</li>
                      )}
                      {selectedDeliveryAddressId.length <= 0 && (
                        <li>Add at a current delivery address.</li>
                      )}
                    </ul>
                    {active && create && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          remove(index);
                        }}
                        className="store-form__actions__remove-address"
                      >
                        <p className="store-form__actions__remove-address__value">
                          Cancel
                        </p>
                      </button>
                    )}
                    {active && !create && field.id === 0 && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          remove(index);
                        }}
                        className="store-form__actions__remove-address"
                      >
                        <p className="store-form__actions__remove-address__value">
                          Remove
                        </p>
                      </button>
                    )}
                  </div>

                  <div className="store-form__inputgroup__multiple">
                    {/* main delivery street line */}
                    <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
                      <label>Street *</label>
                    </div>
                    <input
                      disabled={!active}
                      className={
                        !active
                          ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                          : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
                      }
                      type="text"
                      placeholder="e.g. Koekoekstraat 70"
                      {...register(`delivery_addresses.${index}.address_1`, {
                        required: sameDelivery ? false : "Street is required",
                      })}
                    />

                    {/* delivery street line 2*/}
                    {!active &&
                    (field.address_2 === "" ||
                      field?.address_2 === "-" ||
                      field.address_2 === null) ? null : (
                      <Fragment>
                        <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
                          <label>Street line 2</label>
                        </div>
                        <input
                          disabled={!active}
                          className={
                            !active
                              ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                              : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
                          }
                          type="text"
                          placeholder="e.g. bus 44"
                          {...register(`delivery_addresses.${index}.address_2`)}
                        />
                      </Fragment>
                    )}
                    {/* delivery street line 3 */}
                    {!active &&
                    (field.address_3 === "" ||
                      field?.address_3 === "-" ||
                      field.address_3 === null) ? null : (
                      <Fragment>
                        <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
                          <label>Street line 3</label>
                        </div>
                        <input
                          disabled={!active}
                          className={
                            !active
                              ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                              : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
                          }
                          type="text"
                          placeholder="e.g. 3e deur rechts"
                          {...register(`delivery_addresses.${index}.address_3`)}
                        />
                      </Fragment>
                    )}
                    {/* delivery postal code */}
                    <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
                      <label>Postal code</label>
                    </div>
                    <input
                      disabled={!active}
                      className={
                        !active
                          ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                          : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
                      }
                      type="text"
                      placeholder="e.g. 9000"
                      {...register(`delivery_addresses.${index}.postal_code`)}
                    />
                    {/* delivery city */}
                    <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
                      <label>City *</label>
                    </div>
                    <input
                      disabled={!active}
                      className={
                        !active
                          ? "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple"
                          : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--multiple store-form__inputgroup__inputfield--active"
                      }
                      type="text"
                      placeholder="e.g. Gent"
                      {...register(`delivery_addresses.${index}.city`, {
                        required: sameDelivery ? false : "City is required",
                      })}
                    />
                    {/* country */}
                    <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
                      <label>Country *</label>
                    </div>
                    {active ? (
                      <select
                        disabled={!active}
                        defaultValue={countryName}
                        className={
                          !active
                            ? "store-form__inputgroup__inputfield"
                            : "store-form__inputgroup__inputfield store-form__inputgroup__inputfield--active"
                        }
                        {...register(
                          `delivery_addresses.${index}.country_code`,
                          {
                            required: sameDelivery
                              ? false
                              : "Country is required",
                          }
                        )}
                      >
                        {countries &&
                          countries.map((country) => (
                            <option value={country.code} key={country.id}>
                              {country.name}
                            </option>
                          ))}
                      </select>
                    ) : (
                      <p>{countryName}</p>
                    )}
                    {/* Activiation date*/}
                    {field.activation_date && (
                      <Fragment>
                        <div className="store-form__inputgroup__labelgroup store-form__inputgroup__labelgroup--multiple">
                          <label>Activation date</label>
                        </div>
                        <StoreFormDatePicker
                          name={`delivery_addresses.${index}.activation_date`}
                          control={control}
                          rules={{
                            required: {
                              value: sameDelivery ? false : true,
                              message: "Activation date is required",
                            },
                          }}
                          render={({ field: { ref, ...rest } }: any) => {
                            return <input type="date" {...rest} />;
                          }}
                          isClearable={active}
                          onClear={() =>
                            setValue(
                              `delivery_addresses.${index}.activation_date`,
                              "",
                              {
                                shouldValidate: true,
                                shouldDirty: true,
                              }
                            )
                          }
                        />
                      </Fragment>
                    )}
                  </div>
                </fieldset>
              );
            })}
            {active && !fields.some((item) => item.id === 0) && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  append({
                    id: 0,
                    address_1: "",
                    address_2: "",
                    address_3: "",
                    city: "",
                    country_code: "",
                    postal_code: "",
                    activation_date: Date.now().toString(),
                    store_email: "",
                    store_phone: "",
                    current_address: false,
                  });
                }}
                className="store-form__actions__add-address"
              >
                <p className="store-form__actions__add-address__value">
                  Add delivery address
                </p>
              </button>
            )}
          </div>
        )}
      </div>

      {/* CTA buttons */}
      {active && (
        <Fragment>
          <div className="store-form__actions">
            <div className="store-form__actions__save-container">
              <SaveButton
                type="submit"
                value={isSubmitting ? "Saving..." : "Save"}
                valid={isValid && extravalidation}
              />
              {isValid && extravalidation ? null : (
                <p className="text-slate-500 ml-2 mt-2">
                  Items marked with * are required to succefully save the data.
                </p>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </form>
  );
  //#endregion
}
