import { Icon } from "../../models/icon";

function ShopIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 24,
}: Icon) {
  return (
    <svg viewBox="0 0 80 80" style={{ width: width + "px" }}>
      <path
        fill={selected ? outline : "white"}
        d="M49.8,77.9l-13.3-20h-4.7v20H7.6v-20H0V34.3h7.6V1.9h36.5c17.1,0,29.1,11.5,29.1,28
	c-0.1,8.8-4.7,16.9-11.7,21.6L80,77.9H49.8z M43.2,34.3c5.2,0,5.8-2.5,5.8-4.3s-0.6-4.3-5.8-4.3H31.9v8.7H43.2z"
      />
      <path
        fill={selected ? selectedColor : color}
        d="M68.4,71.9H53.1l-13.3-20H25.9v20H13.6v-20H6V40.3h7.6V7.9h30.5c13.8,0,23.1,8.9,23.1,22
	c-0.1,8.9-6.1,16.8-14.7,19.2L68.4,71.9z M25.9,40.3h17.3c8.7,0,11.8-5.3,11.8-10.3s-3.1-10.3-11.8-10.3H25.9V40.3z"
      />
    </svg>
  );
}

export default ShopIcon;
