function FeaturePageNL() {
  return (
    <div className="manual">
      <article className="manual__toc">
        <div className="manual__toc__container">
          <ol>
            <li>
              <a href="#lognav">Inloggen</a>
            </li>
            <li>
              <a href="#dasnav">Dashboardnavigatie</a>
            </li>
            <li>
              <a href="#accnav">Accountnavigatie</a>{" "}
            </li>
            <li>
              <a href="#profnav">Profielgebied</a>
            </li>
            <li>
              <a href="#homnav">Startscherm</a>
            </li>
            <li>
              <a href="#pronav">Projecten</a>
            </li>
            <li>
              <a href="#avanav">Herordening items</a>
            </li>
            <li>
              <a href="#basnav">Winkelwagen</a>
            </li>
            <li>
              <a href="#chenav">Afrekenen</a>
            </li>
            <li>
              <a href="#hisnav">Bestellingen</a>
            </li>
            <li>
              <a href="#pennav">In behandeling</a>
            </li>
            <li>
              <a href="#shinav">Verzonden bestellingen</a>
            </li>
            <li>
              <a href="#orsnav">Bestelstatussen</a>
            </li>
            <li>
              <a href="#ortnav">Besteltypen</a>
            </li>
          </ol>
        </div>
      </article>

      <main className="manual__text">
        <h2 id="dasnav">1. Inloggen</h2>
        <ul>
          <li>
            Het portaal is toegankelijk via het bekende Sharepoint-platform.
          </li>
          <li>
            Een winkel e-mailadres en wachtwoord worden gebruikt om in te
            loggen.
          </li>
          <li>
            De gebruiker wordt ingelogd via Single Sign On, wat betekent dat de
            gebruiker geen extra gebruikersnaam en inloggegevens heeft voor deze
            applicatie.
          </li>
          <li>
            Deze informatie wordt verstrekt door de IT-afdeling van Rituals.
          </li>
        </ul>

        <div className="manual__text__warning">
          <div className="manual__text__warning__eyecather">BELANGRIJK!</div>
          <p>
            Alle verdere functies zijn alleen toegankelijk na succesvol
            inloggen.
          </p>
        </div>

        <h2 id="accnav">2. Dashboardnavigatie</h2>
        <p>
          De gebruiker heeft toegang tot een navigatiegebied in dashboardstijl.
          Dit bevindt zich aan de linkerkant van de applicatie.
        </p>
        <p>
          Het gebied is altijd zichtbaar met standaard tekst. Dit kan worden
          ingeklapt voor een beter gebruik van het scherm met de "X"-knop
          linksboven. Het gebied kan weer worden uitgevouwen door op de "="-knop
          te klikken die de "X"-knop vervangt.
        </p>

        <h3>Er zijn 6 knoppen in dit gebied:</h3>
        <ul>
          <li>
            <span>"home": </span>leidt naar de startpagina. Pictogram: Oog.
          </li>
          <li>
            <span>"projects": </span>leidt naar de lijst met alle huidige
            beschikbare projecten. Pictogram: Promotiebord.
          </li>
          <li>
            <span>"bestellingen": </span>leidt naar de bestelgeschiedenis van de
            gebruiker gekoppelde winkel. Pictogram: Klok.
          </li>
          <li>
            <span>"pending": </span>leidt naar een gefilterde lijst van de
            geschiedenis, dwz de nieuwe bestellingen die nog in behandeling
            zijn. Zie Bestelstatus en Besteltypes voor meer informatie over de
            betekenis van "in behandeling". Pictogram: Zandloper.
          </li>
          <li>
            <span>"shipped": </span>leidt naar een gefilterde lijst van de
            geschiedenis, dwz de nieuwe bestellingen die naar de winkel zijn
            verzonden. Zie Bestelstatus en Besteltypes. Pictogram: Gemarkeerde
            cirkel.
          </li>
          <li>
            <span>"how-to": </span>leidt naar deze handleiding. Pictogram
            Vraagteken.
          </li>
        </ul>

        <h2 id="profnav">3. Accountnavigatie</h2>
        <p>
          Het <strong>"Accountnavigatie"</strong>-gebied bevindt zich
          rechtsboven in het dashboard van de applicatie.
        </p>
        <p>De gebruiker heeft toegang tot:</p>
        <ul>
          <li>
            Een <strong>"Winkelwagen"</strong> pictogram, leeg of met een rood
            cirkeltje met een getal dat het aantal unieke bestelde items in de
            winkelwagen vertegenwoordigt.
          </li>
          <li>
            Een <strong>"Gebruikersnaam"</strong>.
          </li>
          <li>
            Een <strong>"Avatar"</strong> pictogram. Dit pictogram is een
            weergegeven pixelachtige afbeelding, geïnterpoleerd uit de
            gebruikersnaam en is niet aanpasbaar. Als je op de avatar tikt,
            wordt het <strong>"Profielgebied"</strong> geactiveerd aan de
            rechterkant van het scherm. Voor meer informatie over het{" "}
            <strong>"Profielgebied"</strong>, zie{" "}
            <strong>"Profielgebied"</strong>.
          </li>
        </ul>

        <h2 id="pronav">4. Profielgebied</h2>
        <p>
          Het <strong>"Profielgebied"</strong> is een heel eenvoudige maar
          effectieve plek om de <strong>"Gebruikersinformatie"</strong> en de{" "}
          <strong>"Winkel informatie"</strong> te bekijken. De knop "Uitloggen"
          logt de gebruiker uit van de applicatie en keert terug naar de
          inlogpagina.
        </p>
        <p>
          Zie <strong>"Inloggen"</strong>. De <strong>"Winkelwagen"</strong> van
          de gebruiker wordt niet geleegd bij het uitloggen en wordt bewaard
          voor latere bestellingen!
        </p>
        <p>
          In het geval dat de gebruiker niet wil uitloggen en alleen gebruikers-
          of winkelgegevens wil bekijken, kan het gebied worden ingeklapt door
          te klikken op de nu groter weergegeven Avatar bovenaan of door links
          op de witte ruimte te tikken.
        </p>
        <h2 id="homnav">5. Home</h2>
        <p>
          Een overzichtelijke en georganiseerde weergave, met toegangslinks tot
          verschillende functies:
        </p>
        <ol>
          <li>
            <span>"Mijn geschiedenis": </span>Deze knop leidt de gebruiker
            direct naar de "geschiedenis" van de winkel.
          </li>
          <li>
            <span>"DYNAMISCHE QUICKLINK": </span>Ongeacht of de{" "}
            <strong>"Winkelwagen"</strong> van de gebruiker leeg is of niet, zal
            deze link van doel veranderen:
            <ul>
              <li>
                <span>"Opnieuw bestellen vanuit dit project": </span>Wanneer de{" "}
                <strong>"Winkelwagen"</strong> van de gebruiker leeg is, wordt
                de gebruiker direct naar de lijst met opnieuw te bestellen items
                van dit specifieke project geleid. Dit heeft alleen betrekking
                op het laatste project.
              </li>
              <li>
                <span>"Project in winkelwagen": </span>Wanneer de{" "}
                <strong>"Winkelwagen"</strong> van de gebruiker 1 of meer items
                bevat, wordt de gebruiker naar de lijst met opnieuw te bestellen
                items van het project met items in de winkelwagen geleid.
              </li>
            </ul>
          </li>
          <div className="manual__text__warning">
            <div className="manual__text__warning__eyecather">BELANGRIJK!</div>
            <p>
              Het is niet mogelijk om te bestellen uit verschillende projecten
              in deze versie van de applicatie.
            </p>
          </div>
          <li>
            <span>"Beschikbare projecten": </span>Deze knop leidt de gebruiker
            naar alle huidige beschikbare projecten.
          </li>
          <li>
            <span>"Doorgaan met winkelwagen": </span>Deze knop leidt de
            gebruiker naar de <strong>"Winkelwagen"</strong>. Deze knop is
            alleen zichtbaar wanneer de <strong>"Winkelwagen"</strong> van de
            gebruiker niet leeg is.
          </li>
        </ol>

        <h2 id="avanav">6. Projecten</h2>
        <p>
          Op deze pagina wordt een overzicht weergegeven van recente beschikbare
          promotionele projecten van Rituals. De gebruiker kan vanaf deze pagina
          doorverwezen worden naar de pagina met opnieuw te bestellen items van
          dit specifieke project. Als de <strong>"Winkelwagen"</strong> van de
          gebruiker niet leeg is, is alleen het project met items in de
          winkelwagen toegankelijk. Om items uit een ander project te bestellen,
          moet het huidige winkelmandje worden voltooid of volledig worden
          leeggemaakt. Zie <strong>"Winkelwagen"</strong>. De momenteel
          geselecteerde projectcode is ook zichtbaar onder aan de lijst, ter
          referentie.
        </p>

        <h2 id="reonav">
          7. Beschikbare opnieuw te bestellen items van recent project
        </h2>
        <p>
          Wanneer een project is geselecteerd, wordt de gebruiker doorverwezen
          naar de bestelbare items van dit project. Dit is de kernpagina van de
          applicatie. Het is een standaard webshopstijl-overzicht met alle
          beschikbare bestelbare items van een specifiek project.
        </p>
        <ul>
          <li>
            Aan de rechterkant, net onder het <strong>"Accountgebied"</strong>,
            wordt de "Originele Bestelling" (zie Bestellingstypen) geplaatst,
            ter referentie van de gebruiker.
          </li>
          <li>
            Afhankelijk van de grootte van het project zijn er veel bestelbare
            items georganiseerd in een raster. Elk bestelbaar item heeft zijn
            eigen "Kaart".
          </li>
        </ul>

        <h4 id="basnav">7a. Herbestelbare kaart</h4>
        <ul>
          <li>Afbeelding van het product</li>
          <li>Productnaam</li>
          <li>
            Hoeveelheid ontvangen bij de start van het oorspronkelijke project.{" "}
          </li>
          <div className="manual__text__warning">
            <div className="manual__text__warning__eyecather">BELANGRIJK!</div>
            <p>
              De maximale "herbestelbare" hoeveelheid is gelijk aan de ontvangen
              hoeveelheid in het oorspronkelijke project.
            </p>
          </div>
          <li>
            <span>"TOEVOEGEN"-knop: </span>Voeg dit product toe aan de
            winkelwagen met de in de eigenschap "hoeveelheid" gespecificeerde
            hoeveelheid ernaast.
          </li>
          <li>
            <span>"Hoeveelheid": </span>verhoog, verlaag of typ de gewenste
            hoeveelheid in.
          </li>
        </ul>

        <p>
          Wanneer de gebruiker voor de eerste keer op de "TOEVOEGEN"-knop van
          een item klikt, wordt de gebruiker gevraagd om de "Reden" op te geven
          waarom de gebruiker dit specifieke item opnieuw wil bestellen. Zie
          "7b. Lijst met redenen".
        </p>

        <h4>7b. Lijst met redenen</h4>
        <ul>
          <li>
            Dit is een lijst van veelvoorkomende redenen waarom een item opnieuw
            moet worden besteld en zal in de loop van de tijd
            veranderen/groeien.
          </li>
        </ul>

        <h2 id="chenav">8. Winkelwagen</h2>
        <p>
          Dit vertegenwoordigt de lijst met toegevoegde items in de{" "}
          <strong>"Winkelwagen"</strong> van de gebruiker.
        </p>
        <h3>Een rij van een item bevat:</h3>
        <ul>
          <li>Een miniatuurafbeelding;</li>
          <li>De productnaam;</li>
          <li>De reden voor het opnieuw bestellen van het item;</li>
          <li>Knoppen om de hoeveelheid te verhogen of te verlagen;</li>
          <li>
            Een knop om het item uit de <strong>"Winkelwagen"</strong> van de
            gebruiker te verwijderen. Pictogram: Prullenbak
          </li>
        </ul>
        <h3>Er zijn 3 oproep tot actieknoppen onder de lijst:</h3>
        <ul>
          <li>
            <span>"MEER TOEVOEGEN"-knop: </span>leidt de gebruiker terug naar de
            pagina met opnieuw te bestellen items.
          </li>
          <li>
            <span>"WINKELWAGEN VERWIJDEREN"-knop: </span>LET OP! deze actie
            verwijdert elk item uit de winkelwagen samen met de winkelwagen
            zelf, zodat er een ander project kan worden gekozen om te bestellen.
          </li>
          <li>
            <span>"VOLGENDE STAP"-knop: </span>leidt de gebruiker naar de pagina
            voor het afrekenen om de bestelling af te ronden.
          </li>
        </ul>

        <h2 id="hisnav">9. Afrekenen</h2>
        <ul>
          <li>
            Een snel overzicht van alle opnieuw te bestellen items, klaar om te
            bestellen;
          </li>
          <li>
            Een kort overzicht van de <b>"Winkelgegevens"</b>;
          </li>
          <li>
            Een kort overzicht van het <b>"Verzendadres"</b>;
          </li>
          <li>
            Een tekstveld om eventuele opmerkingen in te voeren, indien nodig;
          </li>
          <li>
            Een <b>"BESTELLING BEVESTIGEN"</b>-knop.
          </li>
        </ul>
        <br />
        <p>
          Wanneer de gebruiker een bestelling bevestigt, wordt de bestelling
          naar het hoofdkantoor van Rituals gestuurd ter goedkeuring.
        </p>
        <p>
          Er verschijnt een pop-up met de bevestiging dat de bestelling naar het
          hoofdkantoor van Rituals wordt verzonden.
        </p>

        <h2 id="pennav">10. Geschiedenis</h2>
        <p>
          Op de pagina "geschiedenis" staat een overzicht van alle bestellingen
          die de winkel in de loop van de tijd heeft geplaatst. Deze lijst kan
          oplopend of aflopend gesorteerd worden door te klikken op de knop met
          pijlen in de koptekst van de kolom.
        </p>
        <p>
          Aan de linkerkant is er een filterknop om het filtermenu te openen.
        </p>
        <p>De bestelgeschiedenis kan worden gefilterd op:</p>
        <ul>
          <li>Promocode</li>
          <li>Bestelstatus</li>
          <li>Besteltype</li>
        </ul>
        <p>Het is ook mogelijk om te gaan zoeken op bestel code</p>
        <br />
        <p>
          Wanneer een winkel meer dan 20 bestellingen heeft geplaatst, wordt de
          geschiedenispagina in de loop van de tijd gepagineerd. Onder aan de
          tabel verschijnt een balk met paginagegevens. Klik op de pijl of het
          paginanummer om door alle bestellingen te navigeren.
        </p>

        <h2 id="shinav">11. In behandeling</h2>
        <p>
          De pagina met "in behandeling zijnde" bestellingen is een vooraf
          gedefinieerde filter op de geschiedenispagina. Deze pagina is gemaakt
          voor het gemak van de gebruiker.
        </p>
        <p>
          De pagina toont een bestelgeschiedenis, maar gefilterd op de
          bestelstatus "In behandeling" en het besteltype "Nalevering", dus met
          uitzondering van alle "Originele" bestellingen.
        </p>

        <h2 id="orsnav">12. Verzonden</h2>
        <p>
          De pagina met "verzonden" bestellingen is ook een vooraf gedefinieerde
          filter op de geschiedenispagina. Deze pagina is gemaakt voor het gemak
          van de gebruiker. Het toont een bestelgeschiedenis, maar gefilterd op
          de bestelstatus "Verzonden" en het besteltype "Nalevering", dus met
          uitzondering van alle "Originele" bestellingen.
        </p>
        <div className="manual__text__warning">
          <div className="manual__text__warning__eyecather">BELANGRIJK!</div>
          <p>
            In deze versie van de applicatie is er geen optie voor
            traceerbaarheid.
          </p>
        </div>

        <h2 id="ortnav">13. Bestelstatussen</h2>
        <p>
          Ter referentie: een bestelling kan een van deze bestelstatuslabels
          hebben:
        </p>
        <ol>
          <li>
            <span>"In behandeling": </span>de bestelling is naar het
            hoofdkantoor van Rituals gestuurd ter goedkeuring door een
            HQ-gebruiker;
          </li>
          <li>
            <span>"Besteld": </span>de bestelling is goedgekeurd door het
            hoofdkantoor en naar de drukleverancier gestuurd;
          </li>
          <li>
            <span>"Verzonden": </span>de bestelling is verzonden door de
            drukleverancier;
          </li>
          <li>
            <span>"Geannuleerd": </span>de bestelling is geannuleerd door het
            hoofdkantoor van Rituals.
          </li>
        </ol>
        <div className="manual__text__warning">
          <div className="manual__text__warning__eyecather">BELANGRIJK!</div>
          <p>
            Deze bovengenoemde statussen zijn de 4 meest gebruikte statussen.
          </p>
        </div>

        <p>Ter referentie zijn er ook deze statussen:</p>
        <ul>
          <li>"Aangemaakt";</li>
          <li>"Bezig".</li>
        </ul>

        <h2>14. Besteltypes</h2>
        <p>
          Ter referentie: een bestelling kan een van deze besteltypelabels
          hebben:
        </p>
        <ol>
          <li>
            <span>"Origineel": </span>de oorspronkelijke bestelling voor uw
            winkel, gemaakt door het hoofdkantoor van Rituals in de
            projectcontext;
          </li>
          <li>
            <span>"Nalevering": </span>de bestelling die een winkel heeft
            geplaatst in de naleveringsweek na het project;
          </li>
        </ol>
      </main>
    </div>
  );
}

export default FeaturePageNL;
