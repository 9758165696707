import { useState } from "react";
import { AppUser } from "../../models/user";
import Identicon from "react-identicons";

interface Props {
  clickhandler: () => void;
  user: AppUser;
}

function ProfileAvatar({ clickhandler, user }: Props) {
  const [color, setColor] = useState<String>("red");

  return (
    <div
      className="profile__container__header__bigicon"
      onClick={clickhandler}
      style={{
        borderColor: `#${color}`,
        backgroundColor: `#${color}25`,
      }}
    >
      <Identicon
        string={user.name}
        size="64"
        getColor={(value: string) => setColor(value)}
      />
    </div>
  );
}

export default ProfileAvatar;
