//#region IMPORTS
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { Store } from "../../models/store";
import ProfileStoreHideAndClick from "./ProfileStoreHideAndClick";
import ProfileParagraph from "./ProfileParagraph";
//#endregion

//#region INTERFACE
interface Props {
  userStore: Store;
}
//#endregion

export default function ProfileStore({ userStore }: Props) {
  //#region SETUP
  const { t } = useTranslation();
  //#endregion

  //#region RENDER
  return (
    <div>
      <ProfileStoreHideAndClick title={t("rituals.storeInfo")}>
        <ProfileParagraph
          marginTop="0.25rem"
          value={userStore.external_store_id}
          title={t("rituals.storeNumber")}
        />
        <ProfileParagraph
          marginTop="0.25rem"
          value={userStore.language}
          title={t("rituals.storeLanguage")}
        />
        <ProfileParagraph
          marginTop="0.25rem"
          value={userStore.contact_email}
          title={t("rituals.contactEmail")}
        />
        <ProfileParagraph
          marginTop="0.25rem"
          value={userStore.contact_phone}
          title={t("rituals.contactPhone")}
        />
      </ProfileStoreHideAndClick>

      <ProfileStoreHideAndClick title={t("rituals.address")}>
        <Fragment>
          <ProfileParagraph value={userStore.store_name} />
          <ProfileParagraph value={userStore.address_1} />
          <ProfileParagraph value={userStore.address_2} />
          <ProfileParagraph value={userStore.address_3} />
          <ProfileParagraph inline value={userStore.postal_code} />
          <ProfileParagraph inline value={userStore.city} />
          <ProfileParagraph value={userStore.country_code} />
        </Fragment>
      </ProfileStoreHideAndClick>

      <ProfileStoreHideAndClick title={t("rituals.deliveryAddress")}>
        <Fragment>
          <ProfileParagraph value={userStore.delivery_address_address_1} />
          <ProfileParagraph value={userStore.delivery_address_address_2} />
          <ProfileParagraph value={userStore.delivery_address_address_3} />
          <ProfileParagraph
            inline
            value={userStore.delivery_address_postal_code}
          />
          <ProfileParagraph inline value={userStore.delivery_address_city} />
          <ProfileParagraph value={userStore.delivery_address_country_name} />
        </Fragment>
      </ProfileStoreHideAndClick>
    </div>
  );
  //#endregion
}
