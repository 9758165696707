//#region IMPORTS
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Basket, BasketState } from "../../models/basket";
import { toast } from "react-toastify";
import agent from "../../api/agent";
//#endregion

//#region INITIAL STATE
const initialState: BasketState = {
  basket: null,
  status: "idle",
};
//#endregion

//#region ADD ITEM 
export const addBasketItemAsync = createAsyncThunk<
  Basket,
  {
    product_version_id: number;
    promo_id: number;
    quantity?: number;
    max_quantity?: number;
    basket_reason_id?: number;
  }
>(
  "basket/addBasketItemAsync",
  async ({
    product_version_id,
    promo_id,
    quantity = 1,
    max_quantity = 100,
    basket_reason_id = 1,
  }) => {
    try {
      const response = await agent.Basket.addItem({
        product_version_id,
        promo_id,
        quantity,
        max_quantity,
        basket_reason_id,
      });
      return response.basket;
    } catch (error) {
      toast.error(`${error}`);
    }
  }
);
//#endregion

//#region REMOVE ITEM
export const removeBasketItemAsync = createAsyncThunk<
  void,
  {
    product_version_id: number;
    promo_id: number;
    quantity: number;
    name?: string;
  }
>(
  "basket/removeBasketItemAsync",
  async ({ product_version_id, promo_id, quantity }) => {
    try {
      await agent.Basket.removeItem({ product_version_id, promo_id, quantity });
    } catch (error) {
      toast.error(`${error}`);
    }
  }
);
//#endregion

//#region CREATE REDUCER SLICE
export const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    setBasket: (state, action) => {
      state.basket = action.payload;
    },
    clearBasket: (state) => {
      state.basket = null;
    },
  },
  extraReducers: (builder) => {
    // ADD item
    builder.addCase(addBasketItemAsync.pending, (state, action) => {
      state.status = "PendingAddItem" + action.meta.arg.product_version_id;
    });
    builder.addCase(addBasketItemAsync.fulfilled, (state, action) => {
      state.basket = action.payload;
      state.status = "idle";
    });
    builder.addCase(addBasketItemAsync.rejected, (state) => {
      state.status = "idle";
    });

    // REMOVE item
    builder.addCase(removeBasketItemAsync.pending, (state, action) => {
      state.status =
        "PendingRemoveItem" +
        action.meta.arg.product_version_id +
        action.meta.arg.name;
    });
    builder.addCase(removeBasketItemAsync.fulfilled, (state, action) => {
      const { product_version_id, quantity } = action.meta.arg;
      const itemIndex = state.basket?.items.findIndex(
        (item) => item.product_version_id === product_version_id
      );
      if (itemIndex === -1 || itemIndex === undefined) return;
      state.basket!.items[itemIndex].quantity -= quantity;
      if (state.basket?.items[itemIndex].quantity === 0)
        state.basket.items.splice(itemIndex, 1);
      if (state.basket?.items.length === 0) {
        state.basket = null;
      }
      state.status = "idle";
    });
    builder.addCase(removeBasketItemAsync.rejected, (state) => {
      state.status = "idle";
    });
  },
});
//#endregion

//#region EXPORTS
export const { setBasket, clearBasket } = basketSlice.actions;
//#endregion
