import { Icon } from "../../models/icon";

function ArrowIconLeft({ color = "#000000", width = 32 }: Icon) {
  return (
    <svg viewBox="0 0 80 60" fill={color} style={{ width: width + "px"}}>
      <polygon points="31.81 60 38.84 52.72 20.09 35.04 80 35.04 80 24.97 20.11 24.97 38.86 7.28 31.81 0 0 30 31.81 60"/>
    </svg>
  );
}

export default ArrowIconLeft;