import { Icon } from "../../models/icon";

function CrossIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 24,
  className,
}: Icon) {
  return (
    <svg
      viewBox="0 0 80 80"
      style={{ width: width + "px" }}
      className={className}
    >
      <path
        fill={selected ? outline : "white"}
        d="M51.64,0H28.36V28.36H0V51.64H28.36V80H51.64V51.64H80V28.36H51.64V0Z"
      />
      <path
        fill={selected ? selectedColor : color}
        d="M32.81,4.44V32.81H4.44V47.19H32.81V75.56H47.19V47.19H75.56V32.81H47.19V4.44Z"
      />
    </svg>
  );
}

export default CrossIcon;
