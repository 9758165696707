import {
  resetProductVersionParams,
  setProductVersion,
} from "../productVersionsSlice";
import { useForm, FieldValues } from "react-hook-form";
import { useEffect, useState } from "react";
import { ProductVersion } from "../../../models/productVersion";
import { useAppDispatch } from "../../../store/configureStore";
import { toast } from "react-toastify";
import SaveButton from "../../../components/genericButtons/saveButton/SaveButton";
import agent from "../../../api/agent";
import "./ProductVersionsForm.css";

interface Props {
  productVersion?: ProductVersion;
  cancelCUD: () => void;
  active: boolean;
}

const ProductVersionsUpdateForm = ({
  productVersion,
  cancelCUD,
  active,
}: Props) => {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });

  // Local state
  const [iAmAware, setIamAware] = useState<boolean>(false);
  //#endregion

  //#region LOGIC
  // Reset form
  useEffect(() => {
    if (productVersion) reset(productVersion);
  }, [productVersion, reset]);

  // Fill in fields if productversion
  useEffect(() => {
    if (productVersion) reset(productVersion);
  }, [productVersion, reset]);

  // Reset fields if !active
  useEffect(() => {
    if (!active) reset(productVersion);
  }, [active, reset, productVersion]);

  // Submit handler
  async function submitForm(data: FieldValues) {
    try {
      let response: ProductVersion;
      if (productVersion) {
        response = await agent.ProductVersions.updateProductVersion(
          productVersion.id,
          data
        );
        dispatch(resetProductVersionParams());
      } else {
        response = await agent.Products.createProduct(data);
      }
      dispatch(setProductVersion(response));
      cancelCUD();
    } catch (error) {
      toast.error(`${error}`);
    }
  }
  //#endregion

  //#region RENDER
  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className={`productversions-form${
        active
          ? isValid
            ? " productversions-form--valid"
            : ""
          : " productversions-form--inactive"
      }`}
    >
      <div className="productversions-form__fields">
        {/* LEFT COLUM */}
        {productVersion && (
          <div className="productversions-form__fields__reference-image">
            <img src={productVersion.picture_url} alt={productVersion.name} />
          </div>
        )}

        {/* MIDDLE column */}
        <fieldset className="productversions-form__inputgroup">
          {/* Language */}
          <label
            className={
              errors.language
                ? "productversions-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.language ? errors?.language?.message : "Language"}
          </label>
          <input
            disabled={!active}
            className="productversions-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. NL"
            {...register("language", {
              required: "Language is required",
              minLength: { value: 1, message: "at least 1 character please" },
            })}
          />
          {/* Country */}
          <label
            className={
              errors.country
                ? "productversions-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.country ? errors?.country?.message : "Country code"}
          </label>
          <input
            disabled={!active}
            className="productversions-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. BE"
            {...register("country", {
              required: "Length is required",
              minLength: { value: 1, message: "at least 1 characters please" },
            })}
          />
        </fieldset>

        {/* RIGHT COLUMN */}
        <fieldset className="productversions-form__inputgroup">
          {/* alternation ID */}
          <label
            className={
              errors.alternationId
                ? "productversions-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.alternationId
              ? errors?.alternationId?.message
              : "Alternation Id"}
          </label>
          <input
            disabled={!active}
            className="productversions-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. 1221001INT"
            {...register("alternationId")}
          />

          {/* Promo ID */}
          <label
            className={
              errors.promoId
                ? "productversions-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.promoId ? errors?.promoId?.message : "PromoId"}
          </label>
          <input
            disabled={!active}
            className="productversions-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. 1221XMAS"
            {...register("promoId", {
              required: "PromoId is required",
              minLength: { value: 1, message: "at least 1 characters please" },
            })}
          />
        </fieldset>
      </div>
      {active && (
        <div className="productversions-form__actions">
          <SaveButton
            type="submit"
            value={isSubmitting ? "Saving..." : "Save"}
            valid={isValid && iAmAware}
          />
          <input
            disabled={!active}
            className="promo-form__inputgroup__inputfield--checkbox aware-of-trouble--checkbox"
            type="checkbox"
            checked={iAmAware}
            onChange={() => setIamAware((prev) => !prev)}
          />
          <label className="aware-of-trouble">
            I am aware this changes real data
          </label>
        </div>
      )}
    </form>
  );
  //#endregion
};

export default ProductVersionsUpdateForm;
