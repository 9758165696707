import { Icon } from "../../models/icon";

function ApproveIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 24,
}: Icon) {
  return (
    <svg viewBox="0 0 80 80" style={{ width: width + "px" }}>
      <circle fill={selected ? outline : "white"} cx="40" cy="40" r="40" />
      <path
        fill={selected ? selectedColor : color}
        d="M73.5,28.83l-20.71,15.05,7.96,24.45-20.75-15.1-20.8,15.1,7.96-24.45L6.5,28.83h25.54l7.96-24.32,7.92,24.32h25.58Z"
      />
    </svg>
  );
}

export default ApproveIcon;
