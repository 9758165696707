//#region IMPORTS
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  StoreFormat,
  StoreFormatState,
  StoreFormatRequest,
} from "../../models/storeFormat";
import { RootState } from "../../store/configureStore";
import { toast } from "react-toastify";
import agent from "../../api/agent";
//#endregion

//#region INIT
// Set initial state
const initialExtraState: StoreFormatState = {
  storeFormats_loaded: false,
  metaData: null,
  loading: false,
  status: "idle",
};
//#endregion

//#region CREATE ADPATER
const storeFormatsAdapter = createEntityAdapter<StoreFormat>();
//#endregion

//#region READ
// Fetch All
export const fetchStoreFormatsAsync = createAsyncThunk<
  StoreFormat[],
  void,
  { state: RootState }
>("storeFormats/fetchStoreFormatsAsync", async (_, thunkAPI) => {
  try {
    const response = await agent.StoreFormats.list();
    thunkAPI.dispatch(setStoreFormatsMetaData(response.meta));
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});
//#endregion

//#region CREATE
export const createStoreFormatAsync = createAsyncThunk<
  StoreFormat,
  { values: StoreFormatRequest }
>("storeFormats/createStoreFormatAsync", async ({ values }, thunkAPI) => {
  try {
    const { data } = await agent.StoreFormats.create({ ...values });
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});
//#endregion

//#region UPDATE
export const updateStoreFormatAsync = createAsyncThunk<
  StoreFormat,
  { storeId: number; values: StoreFormatRequest }
>(
  "storeFormats/updateStoreFormatAsync",
  async ({ storeId, values }, thunkAPI) => {
    try {
      const { data } = await agent.StoreFormats.update(storeId, { ...values });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);
//#endregion

//#region DELETE
export const deleteStoreFormatAsync = createAsyncThunk<number, { id: number }>(
  "storeFormats/deleteStoreFormatAsync",
  async ({ id }, thunkAPI) => {
    try {
      await agent.StoreFormats.delete(id);
      return id;
    } catch (error: any) {
      toast.error(error.data);
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);
//#endregion

//#region CREATE REDUCER SLICE
export const storeFormatSlice = createSlice({
  name: "storeFormats",
  initialState:
    storeFormatsAdapter.getInitialState<StoreFormatState>(initialExtraState),
  reducers: {
    setStoreFormatsPageNumber: (state, action) => {
      state.storeFormats_loaded = false;
      state.loading = true;
    },
    setStoreFormatsMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    clearStoreFormats: () =>
      storeFormatsAdapter.getInitialState<StoreFormatState>(initialExtraState),
    setStoreFormat: (state, action) =>
      storeFormatsAdapter.upsertOne(state, action.payload),
    removeStoreFormat: (state, action) =>
      storeFormatsAdapter.removeOne(state, action.payload),
  },
  extraReducers: (builder) => {
    // FETCH storeFormats
    builder.addCase(fetchStoreFormatsAsync.pending, (state) => {
      state.status = "pendingFetchStoreFormats";
      state.loading = true;
    });
    builder.addCase(fetchStoreFormatsAsync.fulfilled, (state, action) => {
      storeFormatsAdapter.setAll(state, action.payload);
      state.status = "idle";
      state.storeFormats_loaded = true;
      state.loading = false;
    });
    builder.addCase(fetchStoreFormatsAsync.rejected, (state) => {
      state.status = "idle";
      state.loading = false;
    });

    // CREATE storeFormat
    builder.addCase(createStoreFormatAsync.pending, (state) => {
      state.status = "PendingCreateStoreFormat";
    });
    builder.addCase(createStoreFormatAsync.fulfilled, (state, action) => {
      storeFormatsAdapter.upsertOne(state, action.payload);
      state.status = "idle";
    });
    builder.addCase(createStoreFormatAsync.rejected, (state) => {
      state.status = "idle";
      toast.error("Something went realy wrong");
    });

    // UPDATE storeFormat
    builder.addCase(updateStoreFormatAsync.pending, (state, action) => {
      state.status = "PendingUpdateStoreFormat" + action.meta.arg.storeId;
    });
    builder.addCase(updateStoreFormatAsync.fulfilled, (state, action) => {
      storeFormatsAdapter.upsertOne(state, action.payload);
      state.status = "idle";
    });
    builder.addCase(updateStoreFormatAsync.rejected, (state) => {
      state.status = "idle";
      toast.error("Something went realy wrong");
    });

    // DELETE user
    builder.addCase(deleteStoreFormatAsync.pending, (state) => {
      state.status = "PendingDeleteStoreFormat";
    });
    builder.addCase(deleteStoreFormatAsync.fulfilled, (state, action) => {
      storeFormatsAdapter.removeOne(state, action.payload);
      toast.success(`Store format with id ${action.payload} deleted`);
      state.status = "idle";
    });
    builder.addCase(deleteStoreFormatAsync.rejected, (state) => {
      state.status = "idle";
      toast.error("Something went wrong");
    });
  },
});
//#endregion

//#region NORMALISED STATE
export const storeFormatSelectors = storeFormatsAdapter.getSelectors(
  (state: RootState) => state.storeFormats
);
//#endregion

//#region EXPORT
export const {
  setStoreFormatsPageNumber,
  setStoreFormatsMetaData,
  removeStoreFormat,
  clearStoreFormats,
  setStoreFormat,
} = storeFormatSlice.actions;
//#endregion
