interface Props {
  value: string;
  inline?: boolean;
  margin?: string;
}

export default function ConditionalParagraph({
  value,
  inline = false,
  margin,
}: Props) {
  return value ? (
    <p style={{ display: inline ? "inline" : "block", margin }}>{value}</p>
  ) : null;
}
