//#region IMPORTS
import { Basket } from "../../models/basket";
import { Promo } from "../../models/promo";
import HomeProjectItem from "./HomeProjectItem";
//#endregion

//#region INTERFACE
interface Props {
  projects: Promo[];
  isPicker: boolean;
  isAdmin: boolean;
  HQTeam: boolean;
  basket: Basket | null;
}
//#endregion

export default function HomeProjectItems({
  projects,
  isPicker,
  isAdmin,
  HQTeam,
  basket,
}: Props) {
  //#region RENDER
  return (
    <div className="home-project-items grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-4 lg:gap-4">
      {projects.map((project: Promo, index) => (
        <HomeProjectItem
          latestItem={index === 0}
          isPicker={isPicker}
          isAdmin={isAdmin}
          project={project}
          HQTeam={HQTeam}
          basket={basket}
          key={project.id}
        />
      ))}
    </div>
  );
  //#endregion
}
