import { useAppSelector } from "../../store/configureStore";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { AppUser } from "../../models/user";
import { motion } from "framer-motion";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import BasketIconVtwo from "../icons/BasketIconVtwo";
import useHQmanager from "../../hooks/useHQmanager";
import Avatar from "../avatar/Avatar";

interface Props {
  user: AppUser | null;
  open: boolean;
  avatarClickHandler: () => void;
}

function NavBarAccount({ user, avatarClickHandler, open }: Props) {
  const { basket } = useAppSelector((state) => state.basket);

  const isPicker = usePickerManager(user);
  const isAdmin = useAdminManager(user);
  const hqTeam = useHQmanager(user);

  // Count unique items in basket
  const itemCount = basket?.items.length;

  return (
    <div className="navbar__account">
      {user ? (
        <div className="navbar__account__loggedinbox">
          {hqTeam || isAdmin || isPicker ? null : (
            <NavLink to="/basket">
              <div className="navbar__account__basket">
                <BasketIconVtwo className="navbar__account__basket__icon"/>
                <motion.span
                  className="navbar__account__itemcount"
                  animate={{ scale: 1.3 }}
                  transition={{ type: "spring", stiffness: 80 }}
                  key={itemCount}
                  style={{ display: basket ? "inline-flex" : "none" }}
                >
                  {itemCount}
                </motion.span>
              </div>
            </NavLink>
          )}
          <div className="navbar__account__loggedinbox__info">
            {!open && (
              <Fragment>
                <p className="navbar__account__loggedinbox__info__name serif">
                  {user.name}
                </p>
                <Avatar name={user.name} clickHandler={avatarClickHandler} />
              </Fragment>
            )}
          </div>
        </div>
      ) : (
        <>
          <NavLink to="/login" className="navbar__account__button">
            login
          </NavLink>
        </>
      )}
    </div>
  );
}

export default NavBarAccount;
