function ItemPlus({ clickHandler, visible }: any) {
  return (
    <svg
      viewBox="0 0 32 32"
      className="item-cross"
      onClick={clickHandler}
      style={{ opacity: visible ? 1 : 0 }}
    >
      <polygon
        fill="#91a1ab"
        points="32 12 20 12 20 0 12 0 12 12 0 12 0 20 12 20 12 32 20 32 20 20 32 20 32 12"
      />
    </svg>
  );
}

export default ItemPlus;
