import { createSlice } from "@reduxjs/toolkit";

export interface DashboardState {
  dashOpen: boolean;
  filterBarOpen: boolean;
  profileOpen: boolean;
  modalOpen: boolean;
  modalType: string | null;
}

const initialState: DashboardState = {
  dashOpen: true,
  filterBarOpen: false,
  profileOpen: false,
  modalOpen: false,
  modalType: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    closeDash: (state) => {
      state.profileOpen = false;
      state.dashOpen = false;
    },
    toggleDash: (state) => {
      state.profileOpen = false;
      state.dashOpen = !state.dashOpen;
    },
    toggleFilterBar: (state) => {
      state.filterBarOpen = !state.filterBarOpen;
      state.profileOpen = false;
      state.dashOpen = false;
    },
    closeFilterBar: (state) => {
      state.filterBarOpen = false;
      state.profileOpen = false;
    },
    toggleProfile: (state) => {
      state.filterBarOpen = false;
      state.profileOpen = !state.profileOpen;
      state.dashOpen = false;
    },
    resetDashboard: (state) => {
      state.filterBarOpen = false;
      state.profileOpen = false;
      state.modalOpen = false;
      state.modalType = null;
      state.dashOpen = true;
    },
    closeModal: (state) => {
      state.modalOpen = false;
      state.modalType = null;
    },
    openModal: (state, { payload }) => {
      state.modalOpen = true;
      state.modalType = payload;
    },
  },
});

export const {
  toggleFilterBar,
  resetDashboard,
  closeFilterBar,
  toggleProfile,
  toggleDash,
  closeModal,
  openModal,
  closeDash,
} = dashboardSlice.actions;
