//#region IMPORTS
import { Redirect, Route, RouteProps } from "react-router";
import { useAppSelector } from "../../store/configureStore";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import React from "react";
//#endregion

//#region INTERFACE
interface Props extends RouteProps {
  children: React.ReactNode;
}
//#endregion

export default function PickerRoute({ children, ...rest }: Props) {
  //#region SETUP
  const { user } = useAppSelector((state) => state.account);
  const isPicker = usePickerManager(user);
  const isAdmin = useAdminManager(user);
  const child = React.Children.only(children) as React.ReactElement;
  //#endregion

  //#region RENDER
  return (
    <Route
      {...rest}
      render={(props) =>
        (user && isPicker) || (user && isAdmin) ? (
          React.cloneElement(child, { ...props })
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
  //#endregion
}
