import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import ArrowIconRight from "../../icons/ArrowIconRight";
import "./ContinueButton.css";

interface Props {
  to?: string;
  color?: string;
  value?: string;
}

function ContinueButton({ to, color, value }: Props) {
  const history = useHistory();

  const clickHandler = () => {
    to && history.push(`${to}`);
  };

  return (
    <motion.button
      onClick={clickHandler}
      className="continue-button"
      whileHover={{
        scale: 1.03,
      }}
      whileTap={{ scale: 0.9 }}
    >
      <ArrowIconRight color={color} width={24} />
      <p className="continue-button__value" style={{ color: color }}>
        continue {value}
      </p>
    </motion.button>
  );
}

export default ContinueButton;
