//#region IMPORTS
import {
  fetchFilters,
  setProductVersionParams,
  productVersionsSelectors,
  resetProductVersionParams,
  fetchProductVersionsAsync,
  setProductVersionPageNumber,
} from "./productVersionsSlice";
import { useState, useEffect, Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { ProductVersionParams } from "../../models/productVersion";
import { closeFilterBar } from "../dashBoard/dashboardSlice";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import ProductVersionsCreateForm from "./productVersionsForm/ProductVersionsCreateForm";
import ProductVersionsTable from "./productVersionsTable/ProductVersionsTable";
import MainMotionSection from "../../components/_layout/MainMotionSection";
import FilterBarSearch from "../../components/filterBar/FilterBarSearch";
import ImageUploaderV3 from "../../components/imageUpload/ImageUploaderV3";
import useAdminManager from "../../hooks/useAdminManager";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import useFilterCount from "../../hooks/useFilterCount";
import PaginationBar from "../../components/paginationBar/PaginationBar";
import MainContainer from "../../components/_layout/MainContainer";
import CancelIcon from "../../components/icons/CancelIcon";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import FilterBar from "../../components/filterBar/FilterBar";
import "./ProductVersions.css";
//#endregion

export default function ProductVersionsPage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const productVersions = useAppSelector(productVersionsSelectors.selectAll);
  const { user } = useAppSelector((state) => state.account);
  const { product_versions_loaded, filters_loaded, metaData } = useAppSelector(
    (state) => state.productVersions
  );

  // Set filters
  const { page, limit, ...selectedFilters }: ProductVersionParams =
    useAppSelector((state) => state.productVersions.product_version_params);
  const filters = useAppSelector((state) => state.productVersions.filters);
  const filterCount = useFilterCount(selectedFilters);

  // Role State
  const isAdmin = useAdminManager(user);

  // Local State
  const [create, setCreate] = useState<boolean>(false);
  //#endregion

  //#region LOGIC
  // Load filters
  useEffect(() => {
    if (!filters_loaded) dispatch(fetchFilters());
  }, [filters_loaded, dispatch]);

  // Load product versions
  useEffect(() => {
    if (!product_versions_loaded) dispatch(fetchProductVersionsAsync());
  }, [product_versions_loaded, dispatch]);

  // Create new productversion
  const toggleCreate = () => {
    setCreate((prev) => !prev);
  };

  // Close filterbar onleave
  useEffect(() => {
    return () => {
      dispatch(closeFilterBar());
    };
  }, [dispatch]);

  //#endregion

  //#region FAIL SAFE
  if (!product_versions_loaded && !filters_loaded) return <LoadingSpinner />;
  //#endregion

  //#region RENDER
  return (
    <MainContainer>
      <FilterBar
        selectedFilters={selectedFilters}
        resetFilterHandler={() => dispatch(resetProductVersionParams())}
        setParamsHandler={(values: any) => {
          dispatch(setProductVersionParams(values));
        }}
        filters={filters}
        directTo="/productversions"
      >
        <h2 className="ml-8 font-semibold text-amber-600 text-2xl">
          {t("rituals.search")}
        </h2>
        <FilterBarSearch
          setSearchParamHandler={(values) =>
            dispatch(setProductVersionParams({ number: values }))
          }
          title={t("rituals.code")}
        />
        <FilterBarSearch
          setSearchParamHandler={(values) =>
            dispatch(setProductVersionParams({ product_name: values }))
          }
          title={t("rituals.productName")}
        />
      </FilterBar>

      <MainMotionSection>
        <PageHeader
          createClickHandler={toggleCreate}
          createButtonValue={create ? t("rituals.save") : t("rituals.add")}
          filterCount={filterCount}
          isAdmin={isAdmin}
          create={create}
          filter
          title={t("rituals.versions")}
        />

        {/* Upload images */}
        <ImageUploaderV3 />

        {/* Create from form */}
        {isAdmin &&
          (create ? (
            <div className="productversions-page__content__new-box">
              <motion.button
                whileHover={{ scale: 1.1, rotate: "90deg" }}
                className="productversions-page__content__new-box__cancel"
                onClick={toggleCreate}
              >
                <CancelIcon />
              </motion.button>
              <ProductVersionsCreateForm
                cancelCUD={toggleCreate}
                directTo={() => void {}}
              />
            </div>
          ) : (
            <Fragment>
              {productVersions.length > 0 ? (
                <ProductVersionsTable data={productVersions} />
              ) : filterCount > 0 ? (
                <h2>No versions found with this filter selection</h2>
              ) : null}
              <footer>
                {metaData && metaData?.total > 0 && (
                  <PaginationBar
                    metaData={metaData}
                    onPageChange={(page: number) =>
                      dispatch(setProductVersionPageNumber({ page: page }))
                    }
                    pageType={t("rituals.versions")}
                    loaded={product_versions_loaded}
                  />
                )}
              </footer>
            </Fragment>
          ))}
      </MainMotionSection>
    </MainContainer>
  );
  //#endregion
}
