//#region IMPORTS
import { ORDERSTATUS, ORDERTYPE, Order } from "../../models/order";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { useAppSelector } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import OrdersTableRowCheckbox from "./OrdersTableRowCheckbox";
import OrdersTableRowSubOrder from "./OrdersTableRowSubOrder";
import OrdersTableRowActions from "./OrdersTableRowActions";
import OrderStatusSelectBox from "./OrderStatusSelectBox";
import OrdersTableRowItems from "./OrdersTableRowItems";
import OrderStatusBadge from "./OrderStatusBadge";
//#endregion

//#region INTERFACE
interface Props {
  clearSelectedOrdersHandler: () => void;
  changeOrderStatusHandler: (order: Order, status: string) => void;
  selectOrderHandler: (e: any, value: Order) => void;
  selectedOrders: Order[];
  isHQTeam?: boolean;
  isPicker?: boolean;
  isAdmin?: boolean;
  order: Order;
}
//#endregion

export default function OrdersTableRow({
  clearSelectedOrdersHandler,
  changeOrderStatusHandler,
  selectOrderHandler,
  selectedOrders = [],
  isHQTeam = false,
  isPicker = false,
  isAdmin = false,
  order,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const { showOrdersWithSuborders, filters } = useAppSelector(
    (state) => state.orders
  );

  // Local state
  const [showThisOrderParcelInfo, setShowThisOrderParcelInfo] =
    useState<boolean>(false);
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      <tr className="border-t border-slate-300 pt-12">
        {/* Checkbox */}
        <td className="relative px-7 sm:w-12 sm:px-6">
          {(isAdmin || isPicker || isHQTeam) && order.promo_id && (
            <OrdersTableRowCheckbox
              selectOrderHandler={(e: any) => selectOrderHandler(e, order)}
              selectedOrders={selectedOrders}
              order={order}
            />
          )}
        </td>

        {/* Store */}
        {isHQTeam && (
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-500 sm:pl-0">
            {order.store ? order.store.store_name : "No store found"}
          </td>
        )}

        {/* Project */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-500 sm:pl-0">
          {order.promo_name}
        </td>

        {/* Order date */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-500">
          {order.order_date}
        </td>

        {/* Order code */}
        {!isHQTeam && (
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
            {order.order_code}
          </td>
        )}

        {/* Order type */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
          <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
            <svg
              className={
                order.order_type === "Back order"
                  ? "h-1.5 w-1.5 fill-amber-600"
                  : "h-1.5 w-1.5 fill-emerald-500"
              }
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
            {order.order_type}
          </span>
        </td>

        {/* Order status */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-500">
          {isAdmin &&
          order.order_type !== ORDERTYPE.original &&
          order.order_status !== ORDERSTATUS.shipped ? (
            <OrderStatusSelectBox
              currentStatus={order.order_status}
              orderStatusses={filters.order_status}
              changeOrderStatusHandler={(status: string) =>
                changeOrderStatusHandler(order, status)
              }
            />
          ) : (
            <OrderStatusBadge value={order.order_status} />
          )}
        </td>

        {/* Items */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-500">
          {!showThisOrderParcelInfo && (
            <OrdersTableRowItems
              order={order}
              showByPrinter={isHQTeam || isAdmin}
            />
          )}
        </td>

        {/* Comment */}
        <td className="whitespace-nowrap max-w-24 px-2 py-3.5 text-left text-sm text-gray-500">
          {order.comment !== null &&
            order.order_type === ORDERTYPE.backorder &&
            order.comment !== "no comment" && (
              <Fragment>
                <ChatBubbleLeftIcon
                  className="w-6"
                  data-tooltip-id={order.id.toString()}
                  data-tooltip-content={order.comment}
                />
                <Tooltip id={order.id.toString()} />
              </Fragment>
            )}
        </td>

        {/* Parcel info */}
        <td className="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-500">
          {!showOrdersWithSuborders && (
            <Fragment>
              {order.sub_orders && order.sub_orders.length > 0 ? (
                <button
                  className="flex items-center gap-2"
                  onClick={() => setShowThisOrderParcelInfo((prev) => !prev)}
                >
                  {showThisOrderParcelInfo ? (
                    <EyeSlashIcon height={16} className="text-red-600" />
                  ) : (
                    <EyeIcon height={16} />
                  )}
                  {order.sub_orders.length}{" "}
                  {order.sub_orders.length > 1
                    ? t("rituals.parcels")
                    : t("rituals.parcel")}
                </button>
              ) : (
                // fallback when no suborders
                "No parcel info available"
              )}
            </Fragment>
          )}
        </td>

        {/* Reasons */}
        {isHQTeam && (
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-500">
            <div className="flex flex-wrap gap-1">
              {order.order_type === ORDERTYPE.backorder &&
                [
                  ...new Set(
                    order.order_items.map(
                      (orderItem) => orderItem.basket_reason
                    )
                  ),
                ]
                  .sort()
                  .map((value: string) => (
                    <span
                      key={value}
                      className="inline-flex items-center rounded-md bg-slate-100 px-2 py-0.5 text-xs font-medium text-slate-800 ring-1 ring-inset ring-slate-800/10"
                    >
                      {t(`rituals.${value.toLowerCase()}`)}
                    </span>
                  ))}
            </div>
          </td>
        )}

        {/* Actions cell */}
        <OrdersTableRowActions
          clearSelectedOrdersHandler={clearSelectedOrdersHandler}
          approveOrderHandler={() =>
            changeOrderStatusHandler(order, ORDERSTATUS.ordered)
          }
          cancelOrderHandler={() =>
            changeOrderStatusHandler(order, ORDERSTATUS.cancelled)
          }
          shipOrderHandler={() =>
            changeOrderStatusHandler(order, ORDERSTATUS.shipped)
          }
          isPicker={isPicker}
          isHQTeam={isHQTeam}
          isAdmin={isAdmin}
          order={order}
        />
      </tr>

      {/* Sub orders extra rows */}
      {order.sub_orders &&
        order.sub_orders.map(
          (subOrder) =>
            (showOrdersWithSuborders || showThisOrderParcelInfo) && (
              <OrdersTableRowSubOrder
                isPicker={isPicker}
                isHQTeam={isHQTeam}
                subOrder={subOrder}
                isAdmin={isAdmin}
                key={subOrder.id}
                order={order}
              />
            )
        )}
    </Fragment>
  );
  //#endregion
}
