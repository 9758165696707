//#region IMPORTS
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  Language,
  LanguageState,
  LanguageRequest,
} from "../../models/language";
import { RootState } from "../../store/configureStore";
import { toast } from "react-toastify";
import agent from "../../api/agent";
//#endregion

//#region INIT
// Set initial state
const initialExtraState: LanguageState = {
  languages_loaded: false,
  metaData: null,
  loading: false,
  status: "idle",
};
//#endregion

//#region CREATE ADPATER
const languagesAdapter = createEntityAdapter<Language>();
//#endregion

//#region READ
// Fetch All
export const fetchLanguagesAsync = createAsyncThunk<
  Language[],
  void,
  { state: RootState }
>("languages/fetchLanguagesAsync", async (_, thunkAPI) => {
  try {
    const response = await agent.LanguagesManagement.list();
    thunkAPI.dispatch(setLanguagesMetaData(response.meta));
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});
//#endregion

//#region CREATE
export const createLanguageAsync = createAsyncThunk<
  Language,
  { values: LanguageRequest }
>("languages/createLanguageAsync", async ({ values }, thunkAPI) => {
  try {
    const { data } = await agent.LanguagesManagement.create({ ...values });
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});
//#endregion

//#region UPDATE
export const updateLanguageAsync = createAsyncThunk<
  Language,
  { languageId: number; values: LanguageRequest }
>("languages/updateLanguageAsync", async ({ languageId, values }, thunkAPI) => {
  try {
    const { data } = await agent.LanguagesManagement.update(languageId, { ...values });
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});
//#endregion

//#region DELETE
export const deleteLanguageAsync = createAsyncThunk<number, { id: number }>(
  "languages/deleteLanguageAsync",
  async ({ id }, thunkAPI) => {
    try {
      await agent.LanguagesManagement.delete(id);
      return id;
    } catch (error: any) {
      toast.error(error.data);
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);
//#endregion

//#region CREATE REDUCER SLICE
export const languageSlice = createSlice({
  name: "languages",
  initialState:
    languagesAdapter.getInitialState<LanguageState>(initialExtraState),
  reducers: {
    setLanguagesPageNumber: (state, action) => {
      state.languages_loaded = false;
      state.loading = true;
    },
    setLanguagesMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    clearLanguages: () =>
      languagesAdapter.getInitialState<LanguageState>(initialExtraState),
    setLanguage: (state, action) =>
      languagesAdapter.upsertOne(state, action.payload),
    removeLanguage: (state, action) =>
      languagesAdapter.removeOne(state, action.payload),
  },
  extraReducers: (builder) => {
    // FETCH languages
    builder.addCase(fetchLanguagesAsync.pending, (state) => {
      state.status = "pendingFetchLanguages";
      state.loading = true;
    });
    builder.addCase(fetchLanguagesAsync.fulfilled, (state, action) => {
      languagesAdapter.setAll(state, action.payload);
      state.status = "idle";
      state.languages_loaded = true;
      state.loading = false;
    });
    builder.addCase(fetchLanguagesAsync.rejected, (state) => {
      state.status = "idle";
      state.loading = false;
    });

    // CREATE language
    builder.addCase(createLanguageAsync.pending, (state) => {
      state.status = "PendingCreateLanguage";
    });
    builder.addCase(createLanguageAsync.fulfilled, (state, action) => {
      languagesAdapter.upsertOne(state, action.payload);
      state.status = "idle";
    });
    builder.addCase(createLanguageAsync.rejected, (state) => {
      state.status = "idle";
      toast.error("Something went realy wrong");
    });

    // UPDATE language
    builder.addCase(updateLanguageAsync.pending, (state, action) => {
      state.status = "PendingUpdateLanguage" + action.meta.arg.languageId;
    });
    builder.addCase(updateLanguageAsync.fulfilled, (state, action) => {
      languagesAdapter.upsertOne(state, action.payload);
      state.status = "idle";
    });
    builder.addCase(updateLanguageAsync.rejected, (state) => {
      state.status = "idle";
      toast.error("Something went realy wrong");
    });

    // DELETE language
    builder.addCase(deleteLanguageAsync.pending, (state) => {
      state.status = "PendingDeleteLanguage";
    });
    builder.addCase(deleteLanguageAsync.fulfilled, (state, action) => {
      languagesAdapter.removeOne(state, action.payload);
      toast.success(`Language id ${action.payload} deleted`);
      state.status = "idle";
    });
    builder.addCase(deleteLanguageAsync.rejected, (state) => {
      state.status = "idle";
      toast.error("Something went wrong");
    });
  },
});
//#endregion

//#region NORMALISED STATE
export const languageSelectors = languagesAdapter.getSelectors(
  (state: RootState) => state.languagesManagement
);
//#endregion

//#region EXPORT
export const {
  setLanguagesPageNumber,
  setLanguagesMetaData,
  removeLanguage,
  clearLanguages,
  setLanguage,
} = languageSlice.actions;
//#endregion
