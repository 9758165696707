//#region IMPORTS
import { Language, LanguageRequest } from "../../models/language";
import { updateLanguageAsync } from "../../features/languages/languageSlice";
import { useAppDispatch } from "../../store/configureStore";
import { PencilIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
//#endregion

//#region INTERFACE
interface Props {
  language: Language;
  isSelected?: boolean;
  selectHandler: (value: Language) => void;
}
//#endregion

export default function LanguageListItem({
  language,
  isSelected,
  selectHandler,
}: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();

  // Local state
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editedLanguageCode, setEditedLanguageCode] = useState<string>(
    language.code
  );
  //#endregion

  //#region LOGIC
  const editModeHandler = () => {
    selectHandler(language);
    setEditMode(true);
  };

  const saveHandler = async () => {
    const languageToSave: LanguageRequest = {
      code: editedLanguageCode,
      short_code: editedLanguageCode,
    };
    await dispatch(
      updateLanguageAsync({ languageId: language.id, values: languageToSave })
    );
    setEditMode(false);
  };
  //#endregion

  //#region RENDER
  return editMode && isSelected ? (
    <div className="flex items-center justify-between py-1 mb-2">
      <input
        value={editedLanguageCode}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
        onChange={(e: any) => setEditedLanguageCode(e.target.value)}
        type="text"
      />
      <button
        onClick={() => saveHandler()}
        type="button"
        className="ml-2 rounded bg-yellow-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
      >
        save
      </button>
    </div>
  ) : (
    <div className="flex items-center justify-between py-1">
      <p className="text-sm leading-6 text-gray-800">{language.code}</p>
      <button onClick={editModeHandler}>
        <PencilIcon className="w-4 h-4 text-yellow-800" />
      </button>
    </div>
  );
  //#endregion
}
