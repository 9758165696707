//#region IMPORTS
import { FieldValues, useForm } from "react-hook-form";
import { promosSelectors } from "../../features/promos/promosSlice";
import { useAppSelector } from "../../store/configureStore";
import { Promo } from "../../models/promo";
import { toast } from "react-toastify";
import agent from "../../api/agent";
import "./ExcelDownloadWidget.css";
//#endregion

export default function ExcelDownloadWidget() {
  //#region SETUP
  // Redux
  const promos: Promo[] = useAppSelector(promosSelectors.selectAll);

  // Form setup
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });

  //#endregion

  //#region LOGIC
  async function submitForm(data: FieldValues) {
    if (data.promo_code !== "false") {
      try {
        const params = new URLSearchParams();
        params.append("promo_code", data.promo_code);
        params.append("order_type", "backorder");
        await agent.Orders.downloadspreadsheat(params).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${data.promo_code}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      } catch (error) {
        toast.error(`${error}`);
      }
    }
  }
  //#endregion

  //#region RENDER
  return (
    <div className="exceldownload">
      <h4 className="exceldownload__title">EXCEL EXPORT</h4>
      <form onSubmit={handleSubmit(submitForm)} className="exceldownload__form">
        <label
          htmlFor="promocode"
          className={
            errors.promo_code
              ? "block text-sm font-medium leading-6 text-gray-900 exceldownload__form__label--error"
              : "block text-sm font-medium leading-6 text-gray-900"
          }
        >
          {errors.promo_code ? errors?.promo_code?.message : "Select a project"}
        </label>
        <select
          className="mt-2 block w-full rounded-md border-0 py-1.5 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-slate-600 sm:text-sm sm:leading-6"
          {...register("promo_code", {
            required: "promocode is required",
          })}
          id="promocode"
        >
          {promos.map((promo) => (
            <option key={promo.id} value={promo.code}>
              {promo.code}
            </option>
          ))}
        </select>

        <button
          disabled={!isValid}
          type="submit"
          className="rounded-md border-0 py-1.5 bg-slate-800 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-800 mt-4"
        >
          {isSubmitting ? "downloading..." : "download"}
        </button>
      </form>
    </div>
  );
  //#endregion
}
