import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import ArrowIconLeft from "../../icons/ArrowIconLeft";
import "./GoBackButton.css";

interface Props {
  color?: string;
}

function GoBackButton({ color }: Props) {
  const history = useHistory();

  const clickHandler = () => {
    history.goBack();
  };

  return (
    <motion.button
      onClick={clickHandler}
      className="goback-button"
      whileHover={{
        scale: 1.2,
      }}
      whileTap={{ scale: 0.9 }}
    >
      <ArrowIconLeft color={color} width={24} />
    </motion.button>
  );
}

export default GoBackButton;
