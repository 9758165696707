//#region IMPORTS
import {
  storeFormatSelectors,
  fetchStoreFormatsAsync,
} from "../../features/storeFormats/storeFormatSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect } from "react";
import { setStoreParams } from "../../features/stores/storesSlice";
import { useTranslation } from "react-i18next";
import { StoreParams } from "../../models/store";
import FilterBarDatePicker from "../filterBar/FilterBarDatePicker";
import FilterBarComboBox from "../filterBar/FilterBarComboBox";
import FilterBarToggle from "../filterBar/FilterBarToggle";
import FilterBarSearch from "../filterBar/FilterBarSearch";
//#endregion

export default function StoresCustomFilters() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const { stores_loaded, filters, store_params } = useAppSelector(
    (state) => state.stores
  );
  const { storeFormats_loaded } = useAppSelector((state) => state.storeFormats);
  const storeFormats = useAppSelector(storeFormatSelectors.selectAll);

  // Set Filters
  const { page, limit, sort, ...selectedFilters }: StoreParams = useAppSelector(
    (state) => state.stores.store_params
  );
  //#endregion

  //#region LOGIC
  // Fetch storeFormats
  useEffect(() => {
    !storeFormats_loaded && dispatch(fetchStoreFormatsAsync());
  }, [storeFormats_loaded, dispatch]);

  // Show only active stores
  const toggleActiveStoresHandler = () => {
    store_params.active === "Yes"
      ? dispatch(setStoreParams({ active: "" }))
      : dispatch(setStoreParams({ active: "Yes" }));
  };

  // Show only closed stores
  const toggleClosedStoresHandler = () => {
    store_params.active === "No"
      ? dispatch(setStoreParams({ active: "" }))
      : dispatch(setStoreParams({ active: "No" }));
  };
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* Search */}
      <h2 className="ml-8 font-semibold text-amber-600 text-2xl">
        {t("rituals.search")}
      </h2>
      <FilterBarSearch
        setSearchParamHandler={(values) =>
          dispatch(setStoreParams({ store_name: values }))
        }
        title={t("rituals.storeName")}
        lastValue={selectedFilters.store_name}
      />
      <FilterBarSearch
        setSearchParamHandler={(values) =>
          dispatch(setStoreParams({ external_store_id: values }))
        }
        title={t("rituals.storeId")}
        lastValue={selectedFilters.external_store_id}
      />
      <FilterBarSearch
        setSearchParamHandler={(values) =>
          dispatch(setStoreParams({ postal_code: values }))
        }
        title={t("rituals.postalCode")}
        lastValue={selectedFilters.postal_code}
      />
      <FilterBarSearch
        setSearchParamHandler={(values) =>
          dispatch(setStoreParams({ address_1: values }))
        }
        title={t("rituals.streetName")}
        lastValue={selectedFilters.address_1}
      />
      <FilterBarSearch
        setSearchParamHandler={(values) =>
          dispatch(setStoreParams({ comment: values }))
        }
        title={t("rituals.comment")}
        lastValue={selectedFilters.comment}
      />

      <FilterBarDatePicker
        setSearchParamHandler={(values) =>
          dispatch(setStoreParams({ opening_date: values }))
        }
        title={t("rituals.openingsDate")}
        lastValue={selectedFilters.opening_date}
      />

      {/* Filters */}
      <div className="flex justify-between">
        <h2 className="ml-8 mb-4 mt-8 font-semibold text-amber-600 text-2xl">
          {t("rituals.filters")}
        </h2>
      </div>

      {/* Country */}
      {filters.country_name.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setStoreParams({ country_name: value }))
          }
          items={filters.country_name}
          title={t("rituals.countryName")}
          selectedValue={selectedFilters.country_name ?? ""}
        />
      )}

      {/* Language */}
      {filters.language.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setStoreParams({ language: value }))
          }
          items={filters.language}
          title={t("rituals.language")}
          selectedValue={selectedFilters.language ?? ""}
        />
      )}

      {/* Store format */}
      {storeFormats.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setStoreParams({ store_format_id: value.id }))
          }
          items={storeFormats.sort((a, b) => {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();
            if (x > y) {
              return 1;
            }
            if (x < y) {
              return -1;
            }
            return 0;
          })}
          title={t("rituals.storeFormats")}
          selectedValue={
            storeFormats.find((f) => f.id === selectedFilters.store_format_id)
              ?.name ?? ""
          }
        />
      )}

      {/* City */}
      {filters.city.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setStoreParams({ city: value }))
          }
          items={filters.city}
          title={t("rituals.city")}
          selectedValue={selectedFilters.city ?? ""}
        />
      )}

      {/* Active stores */}
      <FilterBarToggle
        value={store_params.active === "Yes" ? true : false}
        refresh={stores_loaded}
        toggleHandler={toggleActiveStoresHandler}
        title={"show only active stores"}
      />

      {/* Closed store */}
      <FilterBarToggle
        value={store_params.active === "No" ? true : false}
        refresh={stores_loaded}
        toggleHandler={toggleClosedStoresHandler}
        title={"show only closed stores"}
      />
    </Fragment>
  );
  //#endregion
}
