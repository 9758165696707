//#region IMPORTS
import { FieldValues, useForm } from "react-hook-form";
import { setStoreParams } from "../../features/stores/storesSlice";
import { useAppDispatch } from "../../store/configureStore";
import { toast } from "react-toastify";
import SubmitButton from "../genericButtons/submitButton/SubmitButton";
import LoadingData from "../loading/LoadingData";
import agent from "../../api/agent";
//#endregion

//#region INTERFACE
interface Props {
  setSavingHandler: (value: boolean) => void;
  customer_id: string;
  cancelCUD: () => void;
  saving?: boolean;
}
//#endregion

export default function StoresDataUpload({
  setSavingHandler,
  customer_id,
  cancelCUD,
  saving = false,
}: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();

  // UseForm
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });

  //#endregion

  //#region LOGIC
  // Submit form with excel filte
  async function submitForm(data: FieldValues) {
    let formData = new FormData();
    formData.append("file", data.excellFile[0]);

    if (customer_id) {
      try {
        setSavingHandler(true);
        await agent.StoreManagement.createFromDataSource(formData, customer_id);
        toast.success("Data imported");
        dispatch(
          setStoreParams({
            sort: JSON.stringify({ column: "updated_at", order: "desc" }),
          })
        );
        setSavingHandler(false);
        cancelCUD();
      } catch (error) {
        cancelCUD();
        setSavingHandler(false);
        toast.error(`${error}`);
      }
    } else toast.warning("no customer selected");
  }

  // Download template
  const downloadTemplateHandler = async () => {
    try {
      await agent.StoreManagement.downloadTemplate().then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Store-template.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  //#endregion

  //#region RENDER
  return (
    <div className="px-4 py-5 sm:p-6">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {saving ? "The data is uploading..." : "Upload store data"}
      </h3>
      <div className="mt-2 max-w-xl text-sm text-gray-500">
        <p>
          {saving
            ? "This can take a few moments..."
            : "This wil upload new stores or overwrite existing data."}
        </p>
      </div>

      {!saving ? (
        <form
          className="mt-5 flex justify-between sm:items-center"
          onSubmit={handleSubmit(submitForm)}
        >
          <div className="w-full sm:max-w-xs">
            <label htmlFor="excellFile" className="sr-only">
              {errors.excellFile
                ? errors?.excellFile?.message
                : "choose excell file"}
            </label>
            <input
              className="block w-full rounded-md pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none"
              type="file"
              autoFocus
              placeholder="choose main promo excell"
              {...register("excellFile", {
                required: "excell file is required",
              })}
            />
          </div>
          <div>
            <button
              onClick={cancelCUD}
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900 mr-4"
            >
              Cancel
            </button>

            <SubmitButton
              conditionals={[isValid]}
              isSubmitting={isSubmitting}
              isSubmittingValue="Importing"
              defaultValue="Import"
            />

            <button
              onClick={downloadTemplateHandler}
              type="button"
              className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-amber-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600 sm:ml-3 sm:mt-0 sm:w-auto"
            >
              Download template
            </button>
          </div>
        </form>
      ) : (
        <div className="flex items-center justify-center mb-8">
          <LoadingData />
        </div>
      )}
    </div>
  );
  //#endregion
}
