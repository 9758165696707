function FeaturePageEN() {
   return (
<div className="manual">
        <article className="manual__toc">
          <div className="manual__toc__container">
            <ol>
              <li>
                <a href="#lognav">Login</a>
              </li>
              <li>
                <a href="#dasnav">Dashboard navigation</a>
              </li>
              <li>
                <a href="#accnav">Account navigation</a>{" "}
              </li>
              <li>
                <a href="#profnav">Profile area</a>
              </li>
              <li>
                <a href="#homnav">Home screen</a>
              </li>
              <li>
                <a href="#pronav">Projects</a>
              </li>
              <li>
                <a href="#avanav">Re-orderable items</a>
              </li>
              <li>
                <a href="#basnav">Basket</a>
              </li>
              <li>
                <a href="#chenav">Checkout</a>
              </li>
              <li>
                <a href="#hisnav">Orders</a>
              </li>
              <li>
                <a href="#pennav">Pending orders</a>
              </li>
              <li>
                <a href="#shinav">Shipped orders</a>
              </li>
              <li>
                <a href="#orsnav">Order statuses</a>
              </li>
              <li>
                <a href="#ortnav">Order types</a>
              </li>
            </ol>
          </div>
        </article>

        <main className="manual__text">
          <h2 id="dasnav">1. Login</h2>
          <ul>
            <li>The portal is accessible via the known Sharepoint platform.</li>
            <li>A store email address and password will be used to login.</li>
            <li>
              The user will be logged in via Single Sign On, which means the
              user will not have an extra username and login for this
              application.
            </li>
            <li>
              This information is provided by the IT department of Rituals.{" "}
            </li>
          </ul>

          <div className="manual__text__warning">
            <div className="manual__text__warning__eyecather">IMPORTANT!</div>
            <p>
              All further features are only accessible after successful login.
            </p>
          </div>

          <h2 id="accnav">2. Dashboard navigation</h2>
          <p>
            The user has access to a dashboard style navigation area. This is on
            the left side of the application.
          </p>
          <p>
            The area is always visible with text by default. This can be
            collapsed, for better overall screen usage with the “X” button in
            the top left corner. The area can be expanded again by clicking the
            “=” button which replaced the “X” button.
          </p>

          <h3>There are 6 buttons in this area:</h3>
          <ul>
            <li>
              <span>“home”: </span>redirects to the homepage. Icon: Eye.
            </li>
            <li>
              <span>“projects”: </span> redirects to list with all current
              available projects. Icon: Promotional Sign.
            </li>
            <li>
              <span>“orders”: </span>
              redirects to the order history of the user linked shop. Icon:
              Clock.
            </li>
            <li>
              <span>“pending”: </span>
              redirects to a filtered list of history ea. the new orders that
              are still in pending status. See Order Status and Order types for
              more information on the meaning of “pending status”. Icon:
              Hourglass.
            </li>
            <li>
              <span>“shipped”: </span>
              redirects to a filtered list of history ea. the new orders that
              are shipped to the shop. See Order Status and Order types. Icon:
              Marked circle.
            </li>
            <li>
              <span>“how-to”: </span>redirects to this manual. Icon Question
              mark.
            </li>
          </ul>

          <h2 id="profnav">3. Account navigation</h2>
          <p>
            The <strong>“Account navigation”</strong> area is in the top right
            corner of the application dashboard.
          </p>
          <p>The user has access to:</p>
          <ul>
            <li>
              A <strong>“Basket”</strong> icon, either empty or with a red
              circle containing a number representing the amount of unique
              ordered items currently in the basket.
            </li>
            <li>
              A <strong>“Username”</strong>.
            </li>
            <li>
              An <strong>“Avatar”</strong> icon. This icon is a rendered
              pixel-style image, interpolated from the username and is not
              customizable. Tapping the avatar triggers the{" "}
              <strong>“Profile area”</strong> sliding into the right side of the
              screen, for more information on the{" "}
              <strong>“Profile Area”</strong>, See{" "}
              <strong>“Profile Area”</strong>.
            </li>
          </ul>

          <h2 id="homnav">4. Profile area</h2>
          <p>
            The <strong>“Profile Area”</strong> is a very simple yet effective
            place to view the <strong>“User information”</strong> and the{" "}
            <strong>“Shop information”</strong>. The logout button logs the user
            out from the application and returns to the login page.
          </p>
          <p>
            See <strong>“Login”</strong>. The user's <strong>Basket</strong>{" "}
            will not be emptied when logging out and will be saved for later
            ordering!
          </p>
          <p>
            In case the user does not want to log out and only view user- or
            shop data, the area can be collapsed by clicking the now Larger
            represented Avatar at the top or tap on the left side in the white
            area.
          </p>

          <h2 id="pronav">5. Home</h2>
          <p>
            Clean and organised overview, with access links to several features:
          </p>
          <ol>
            <li>
              <span>“My history”: </span>
              This button redirects the user instantly to the shop's “history”
              page.
            </li>
            <li>
              <span>"DYNAMIC QUICKLINK": </span>Whether the user's current{" "}
              <strong>“Basket”</strong> is empty or not, this link will change
              its purpose:
              <ul>
                <li>
                  <span>“Re-order from this project”: </span>When the user's{" "}
                  <strong>“Basket”</strong> is empty, it will redirect the user
                  instantly to the list of re-orderable items from this specific
                  project. This addresses only the latest project.
                </li>
                <li>
                  <span>“Project in basket”: </span>
                  When the users “Basket” has 1 or more items, it redirects the
                  user to the list of re-orderable items from the project with
                  items in the basket.
                </li>
              </ul>
            </li>
            <div className="manual__text__warning">
              <div className="manual__text__warning__eyecather">IMPORTANT!</div>
              <p>
                It is not possible to order from different projects in this
                version of the application.
              </p>
            </div>
            <li>
              <span>“Available projects”: </span>
              This button redirects the user to all current available projects.
            </li>
            <li>
              <span>“Continue basket”: </span>
              This button redirects the user to the <strong>“Basket”</strong>.
              This button will only be visible when the user's{" "}
              <strong>“Basket”</strong> is not empty.
            </li>
          </ol>

          <h2 id="avanav">6. Projects</h2>
          <p>
            This page lists an overview of recent available promotional projects
            by Rituals. The user can redirect from this page to the page with
            reorderable items from this specific project. If the user's{" "}
            <strong>“Basket”</strong> is not empty, only the project with items
            in the basket will be accessible. To order items from another
            project, either finish the current basket or completely empty the
            basket. See <strong>“Basket”</strong>. The current selected project
            code is also visible at the bottom of the list, for reference.
          </p>

          <h2 id="reonav">
            7. Available re-orderable items from recent project
          </h2>
          <p>
            When a project is selected, the user will be redirected to the
            orderable items of this project, this is the core page of the
            application. It is a standard web shop style overview with all
            available orderable items of a specific project.
          </p>
          <ul>
            <li>
              In the top right, just below the <strong>“Account Area”</strong>{" "}
              the “Original Order” (see Ordertypes) is placed, for user's
              reference.
            </li>
            <li>
              Depending how large the project was, there are a lot of orderable
              items organised in a grid. Each orderable item has its own “Card”.
            </li>
          </ul>

          <h4 id="basnav">7a. Reorderable card</h4>
          <ul>
            <li>Product image</li>
            <li>Product name</li>
            <li>Quantity received when original project kicked off. </li>
            <div className="manual__text__warning">
              <div className="manual__text__warning__eyecather">IMPORTANT!</div>
              <p>
                The maximal “re-orderable” quantity is equal to the amount
                received in the original project.
              </p>
            </div>

            <li>
              <span>"ADD” button: </span>
              Add this product to the basket with amount specified in
              amount-property next to it.
            </li>
            <li>
              <span>“Amount”: </span>increase, decrease or type-in the desired
              amount.
            </li>
          </ul>

          <p>
            When clicking the “ADD” button of an item, for the first time, user
            will be prompted to specify the “Reason” the user wants to re-order
            this specific item. See “7b Reason list”.
          </p>

          <h4>7b. Reason list</h4>
          <ul>
            <li>
              This is a list of common reasons why an item needs to be reordered
              and will change/grow over time.
            </li>
          </ul>

          <h2 id="chenav">8. Basket</h2>
          <p>
            This represents the list of added items into the user's “Basket”.
          </p>
          <h3>An item row contains:</h3>
          <ul>
            <li>A thumbnail image;</li>
            <li>The product name;</li>
            <li>The reason for re-ordering the item;</li>
            <li>Buttons to increase or decrease the quantity;</li>
            <li>
              A button to be able to delete the item from the user “Basket”.
              Icon: Trashcan
            </li>
          </ul>

          <h3>Below the list there are 3 call-to-action buttons:</h3>
          <ul>
            <li>
              <span>“ADD MORE” button: </span>
              redirects the user back to re-orderable items page.
            </li>
            <li>
              <span>“DELETE BASKET” button: </span>
              WATCH OUT! this action deletes every item from the basket along
              with the basket itself so another project can be chosen to order
              from.
            </li>
            <li>
              <span>“NEXT STEP” button: </span>
              redirects user to the checkout page to finalise the order.
            </li>
          </ul>

          <h2 id="hisnav">9. Checkout</h2>
          <ul>
            <li>A quick overview of all re-orderable items, ready to order;</li>
            <li>
              A short overview of the <b>“Shop Info”</b>;
            </li>
            <li>
              A short overview of the <b>“Shipping address”</b>;
            </li>
            <li>A text area to input comment if necessary;</li>
            <li>
              A <b>“CONFIRM ORDER”</b> button.
            </li>
          </ul>
          <br />
          <p>
            When confirming an order, the order is sent to Rituals Headquarters,
            for approval.
          </p>
          <p>
            A popup will appear with the confirmation that the order will be
            sent to Rituals Headquarters.
          </p>

          <h2 id="pennav">10. Orders</h2>
          <p>
            On the “orders” page there is an overview of all orders the shop
            has ordered over time. This list can be sorted descending or
            ascending by clicking on the little button with arrows in header of
            the column.
          </p>

          <p>
            On the left-hand side there is a filter button to open the filter
            menu.
          </p>
          <p>Order history can be filtered by:</p>
          <ul>
            <li>Promo code</li>
            <li>Order status</li>
            <li>Order type</li>
          </ul>
          <p>It is also possible to search by order code</p>
          <br />
          <p>
            When a shop has ordered more than 20 orders, over time, the order
            history page will become paginated. On the bottom side, under the
            table there will appear a bar with page information. Click on the
            arrow or the pagenumber to navigate through all orders.
          </p>

          <h2 id="shinav">11. Pending</h2>
          <p>
            The pending orders page is a pre-defined filter on the history page.
            This page is made for user convenience.
          </p>
          <p>
            It will show an order history but filtered by “Pending” order status
            and “Backorder” order type, so excluding all “Original” orders.
          </p>

          <h2 id="orsnav">12. Shipped</h2>
          <p>
            The shipped orders page is also a pre-defined filter on the history
            page. This page is made for user convenience. It will show an order
            history but filtered by “Shipped” order status and “Backorder” order
            type, so excluding all “Original” orders.
          </p>
          <div className="manual__text__warning">
            <div className="manual__text__warning__eyecather">IMPORTANT!</div>
            <p>
              There is no track & trace option in this version of the
              application.
            </p>
          </div>

          <h2 id="ortnav">13. Order statuses</h2>
          <p>
            For reference: an order can have one of these order status tags:
          </p>
          <ol>
            <li>
              <span>“Pending”: </span>
              the order has been sent to Rituals Headquarters for approval by a
              HQ-user;
            </li>
            <li>
              <span>“Ordered”: </span>
              the order is approved by Headquarters and send to the print
              supplier;
            </li>
            <li>
              <span>“Shipped”: </span>
              the order has been shipped by the print supplier;
            </li>
            <li>
              <span>“Cancelled”: </span>
              the order is cancelled by Rituals Headquarters.
            </li>
          </ol>
          <div className="manual__text__warning">
            <div className="manual__text__warning__eyecather">IMPORTANT!</div>
            <p>These statuses above are the 4 most used statuses.</p>
          </div>

          <p>For reference, you have also these statuses:</p>
          <ul>
            <li>“Created”;</li>
            <li>“In Progress”.</li>
          </ul>

          <h2>14. Order types</h2>
          <p>For reference: an order can have one of these order type tags:</p>
          <ol>
            <li>
              <span>“Original”: </span>
              the original order for your shop, created by Rituals Headquarters
              in the project context;
            </li>
            <li>
              <span>“Back order”: </span>
              the order a shop has placed in the back-order week after the
              project;
            </li>
          </ol>
        </main>
      </div>
   );
}

export default FeaturePageEN;