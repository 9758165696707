//#region IMPORTS
import RobinLogo from "../logos/RobinLogo";
import "./Loading.css";
//#endregion

//#region INTERFACE
interface Props {
  showLogo?: boolean;
}
//#endregion

export default function LoadingScreen({ showLogo = true }: Props) {
  //#region RENDER
  return <div className="loadingscreen">{showLogo && <RobinLogo />}</div>;
  //#endregion
}
