//#region IMPORTS
import { TruckIcon } from "@heroicons/react/20/solid";
import { SubOrder } from "../../models/suborder";
import { Order } from "../../models/order";
//#endregion

//#region INTERFACE
interface Props {
  subOrder: SubOrder;
  order: Order;
}
//#endregion

export default function OrderParcelCount({ order, subOrder }: Props) {
  //#region RENDER
  return (
    <div className="flex gap-2 items-center">
      <TruckIcon height={16} />
      {`parcel ${
        order.sub_orders.findIndex((so: SubOrder) => so.id === subOrder.id) + 1
      }/${order.sub_orders.length}`}
    </div>
  );
  //#endregion
}
