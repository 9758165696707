//#region IMPORTS
import {
  setOrderParams,
  resetOrderParams,
} from "../../features/orders/ordersSlice";
import { useAppDispatch } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Promo } from "../../models/promo";
import dateHelperWeirdFromBackend from "../../helpers/dateHelperWeirdFromBackend";
import PromosPageItemButtons from "./PromosPageItemButtons";
import agent from "../../api/agent";
import { toast } from "react-toastify";
//#endregion

//#region INTERFACE
interface Props {
  overWriteTransSmartHandler: () => void;
  refreshTransmartHandler: () => void;
  setProjectToDelete: () => void;
  setProjectToEdit: (uploadData?: boolean) => void;
  uploadHandler: () => void;
  promoInBasket: Promo | null | undefined;
  isPicker: boolean;
  isAdmin: boolean;
  hqTeam: boolean;
  promo: Promo;
}
//#endregion

export default function PromosPageItem({
  overWriteTransSmartHandler,
  refreshTransmartHandler,
  setProjectToDelete,
  setProjectToEdit,
  uploadHandler,
  promoInBasket,
  isPicker,
  isAdmin,
  hqTeam,
  promo,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();

  // Local State
  const history = useHistory();

  // Check if promo_id of item is in basket
  const isCurrent: boolean = promoInBasket
    ? promoInBasket?.id === promo.id
    : false;
  //#endregion

  //#region LOGIC
  // Item main action by role
  const mainHandler = async (id: number, promo_code: string) => {
    if (hqTeam) {
      dispatch(resetOrderParams());
      dispatch(setOrderParams({ promo_code, order_status: "Pending" }));
      history.push(`/approve`);
    } else if (isAdmin) {
      setProjectToEdit();
    } else if (isPicker) {
      dispatch(resetOrderParams());
      dispatch(setOrderParams({ promo_code }));
      history.push("/manage");
    } else {
      history.push(`/backorder/promo/${id}`);
    }
  };

  const exportUPSuniqueHandler = async () => {
    try {
      await agent.Transmart.exportTrackAndTrace(promo.id).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `trackandtrace_${promo.code}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  // Item sub action by role
  const subHandler = async () => {
    if (isAdmin) {
      uploadHandler();
    } else {
      setProjectToEdit();
    }
  };
  //#endregion

  //#region RENDER
  return (
    <li className="relative flex items-center justify-between gap-x-6 py-5 text-base">
      <div className="flex min-w-0 gap-x-4">
        <img
          className="h-32 w-32 flex-none rounded-full bg-gray-50"
          src={
            promo.picture_url ??
            process.env.PUBLIC_URL +
              "/images/placeholder/placeholder_project__image.jpg"
          }
          alt={promo.code}
        />
        <div className="min-w-0 flex flex-col justify-center">
          <p className="text-xl font-semibold leading-6 text-gray-900">
            {promo.code}
          </p>
          {isCurrent && (
            <p className="mt-1 text-base flex leading-5 text-gray-500">
              {t("rituals.currentProject")}
            </p>
          )}
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-x-4">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          <p className="leading-6 text-gray-900">
            {t("rituals.projectCode")}: {promo.mp_code}
          </p>
          {promo.back_orderable ? (
            <div className="mt-1 flex items-center gap-x-1.5">
              <div className="rounded-full bg-emerald-500/20 p-1">
                <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
              </div>
              <p className="text-gray-500">
                {t("rituals.thisProjectIsAvailableFrom$VAR$until$VAR2$")
                  .replace(
                    "$VAR$",
                    dateHelperWeirdFromBackend(promo.active_from_date)
                  )
                  .replace(
                    "$VAR2$",
                    dateHelperWeirdFromBackend(promo.active_to_date)
                  )}
              </p>
            </div>
          ) : (
            <p className="leading-5 text-gray-500">
              {t("rituals.thisProjectIsClosed")}
            </p>
          )}
        </div>
        {!isPicker && !isAdmin && !hqTeam ? (
          <button
            onClick={() => mainHandler(promo.id, promo.code)}
            type="button"
            className="ml-4 rounded-md bg-pink-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-700"
          >
            {t("rituals.reOrder").toUpperCase()}
          </button>
        ) : (
          <PromosPageItemButtons
            exportUPSuniqueHandler={exportUPSuniqueHandler}
            overWriteTransSmartHandler={overWriteTransSmartHandler}
            refreshTransmartHandler={refreshTransmartHandler}
            deletehandler={setProjectToDelete}
            mainHandler={() => mainHandler(promo.id, promo.code)}
            subHandler={subHandler}
            isPicker={isPicker}
            isAdmin={isAdmin}
            hqTeam={hqTeam}
            promo={promo}
          />
        )}
      </div>
    </li>
  );
  //#endregion
}
