import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import Stargazer from "../icons/Stargazer";
import "./Errors.css";

function NotFound() {
  const history = useHistory();

  const handleClick = () => history.push("/");

  return (
    <main className="error-container">
      <Stargazer width={260} />
      <div className="error-container__box">
        <h1 className="error-container__box__bigtext">woops 404</h1>
        <h2>
          we looked everywhere, but couldn't find the page you we're looking
          for.
        </h2>
      </div>
      <motion.button
        onClick={handleClick}
        className="error-container__backbtn"
        whileHover={{
          scale: 1.03,
        }}
        whileTap={{ scale: 0.9 }}
      >
        back
      </motion.button>
    </main>
  );
}

export default NotFound;
