//#region IMPORTS
import { AnimatePresence, motion } from "framer-motion";
//#endregion

//#region INTERFACE
interface Props {
  selected?: boolean;
  linkName: string;
  dashOpen?: boolean;
}
//#endregion

export default function DashBoardNavListItemText({
  selected,
  linkName,
  dashOpen,
}: Props) {
  //#region SETUP
  const transition = {
    type: "spring",
    mass: 0.5,
  };
  //#endregion

  //#region RENDER
  return (
    <AnimatePresence>
      {dashOpen && (
        <motion.span
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0 }}
          transition={transition}
          className={`dashboard__side__menu__controls__list__item__box__linkname${
            selected
              ? " dashboard__side__menu__controls__list__item__box__linkname--selected"
              : ""
          }`}
        >
          {linkName}
        </motion.span>
      )}
    </AnimatePresence>
  );
  //#endregion
}
