//#region IMPORTS
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { Promo, PromoParams, PromoState } from "../../models/promo";
import { FieldValues } from "react-hook-form";
import { RootState } from "../../store/configureStore";
import { toast } from "react-toastify";
import getAxiosParams from "../../helpers/getAxiosParams";
import agent from "../../api/agent";
//#endregion

//#region INIT
const initialExtraState: PromoState = {
  refreshingTransmart: false,
  promosLoaded: false,
  status: "iddle",
  metaData: null,
  promo_params: initParams(),
};

// Set initial query parameters
function initParams(): PromoParams {
  return {
    page: 1,
    limit: 3,
  };
}
//#endregion

//#region CREATE ADAPTER
const promosAdapter = createEntityAdapter<Promo>();
//#endregion

//#region READ
// Fetch All
export const fetchPromosAsync = createAsyncThunk<
  Promo[],
  void,
  { state: RootState }
>("promos/fetchPromosAsync", async (_, thunkAPI) => {
  const promoParams = getAxiosParams(thunkAPI.getState().promos.promo_params);
  try {
    const { data, meta } = await agent.Promos.list(promoParams);
    thunkAPI.dispatch(setPromoMetaData(meta));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});

// Fetch by id
export const fetchPromoAsync = createAsyncThunk<
  Promo,
  number,
  { state: RootState }
>("promos/fetchAvailablePromoAsync", async (promo_id, thunkAPI) => {
  try {
    return await agent.Promos.detail(promo_id);
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});
//#endregion

//#region CREATE
export const createPromoAsync = createAsyncThunk<
  Promo,
  { values: FieldValues; file: FormData | null }
>("promos/createPromoAsync", async ({ values, file }, thunkAPI) => {
  try {
    const response = await agent.Promos.create({ ...values });
    if (file !== null) {
      const urlResponse = await agent.Promos.image(response.data.id, file);
      response.data.picture_url = urlResponse.url;
    }
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});
//#endregion

//#region REFRESH
export const refreshPromoTransmartDataAsync = createAsyncThunk<void>(
  "promos/refreshPromoTransmartDataAsync",
  async (_, thunkAPI) => {
    try {
      const response = await agent.Transmart.refreshAll();
      console.log(response);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);
//#endregion

//#region UPDATE
export const updatePromoAsync = createAsyncThunk<
  Promo,
  { id: number; values: FieldValues; file: FormData | null }
>("promos/updatePromoAsync", async ({ id, values, file }, thunkAPI) => {
  try {
    const response = await agent.Promos.update(id, { ...values });
    if (file !== null) {
      const urlResponse = await agent.Promos.image(id, file);
      response.data.picture_url = urlResponse.url;
    }
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});
//#endregion

//#region DELETE
export const deletePromoAsync = createAsyncThunk<number, { id: number }>(
  "promos/deletePromoAsync",
  async ({ id }, thunkAPI) => {
    try {
      await agent.Promos.delete(id);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);
//#endregion

//#region CREATE REDUCER SLICE
export const promoSlice = createSlice({
  name: "promos",
  initialState: promosAdapter.getInitialState<PromoState>(initialExtraState),
  reducers: {
    setPromoParams: (state, action) => {
      state.promosLoaded = false;
      state.promo_params = {
        ...state.promo_params,
        ...action.payload,
        page: 1,
      };
    },
    setPromoPageNumber: (state, action) => {
      state.promosLoaded = false;
      state.promo_params = { ...state.promo_params, ...action.payload };
    },
    setPromoMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    resetPromoParams: (state) => {
      state.promosLoaded = false;
      state.promo_params = initParams();
    },
    clearPromos: () =>
      promosAdapter.getInitialState<PromoState>(initialExtraState),
  },
  extraReducers: (builder) => {
    // FETCH promos
    builder.addCase(fetchPromosAsync.pending, (state) => {
      state.status = "pendingFetchPromos";
    });
    builder.addCase(fetchPromosAsync.fulfilled, (state, action) => {
      promosAdapter.setAll(state, action.payload);
      state.status = "idle";
      state.promosLoaded = true;
    });
    builder.addCase(fetchPromosAsync.rejected, (state) => {
      state.status = "idle";
    });

    // FETCH promo by id
    builder.addCase(fetchPromoAsync.pending, (state) => {
      state.status = "pendingFetchPromo";
    });
    builder.addCase(fetchPromoAsync.fulfilled, (state, action) => {
      promosAdapter.upsertOne(state, action.payload);
      state.status = "idle";
    });
    builder.addCase(fetchPromoAsync.rejected, (state) => {
      state.status = "idle";
    });

    // REFRESH transmart data
    builder.addCase(refreshPromoTransmartDataAsync.pending, (state) => {
      state.refreshingTransmart = true;
    });
    builder.addCase(
      refreshPromoTransmartDataAsync.fulfilled,
      (state, action) => {
        state.refreshingTransmart = false;
      }
    );
    builder.addCase(refreshPromoTransmartDataAsync.rejected, (state) => {
      state.refreshingTransmart = false;
    });

    // CREATE promo
    builder.addCase(createPromoAsync.pending, (state) => {
      state.status = "PendingCreatePromo";
    });
    builder.addCase(createPromoAsync.fulfilled, (state, action) => {
      promosAdapter.addOne(state, action.payload);
      toast.success("Promo added");
      state.status = "idle";
      state.promosLoaded = false;
    });
    builder.addCase(createPromoAsync.rejected, (state) => {
      state.status = "idle";
      toast.error("Something went wrong");
    });

    // UPDATE promo
    builder.addCase(updatePromoAsync.pending, (state, action) => {
      state.status = "PendingUpdatePromo" + action.meta.arg.id;
    });
    builder.addCase(updatePromoAsync.fulfilled, (state, action) => {
      promosAdapter.upsertOne(state, action.payload);
      toast.success(`Promo ${action.payload.code} updated`);
      state.status = "idle";
      state.promosLoaded = false;
    });
    builder.addCase(updatePromoAsync.rejected, (state) => {
      state.status = "idle";
      toast.error("Something went wrong");
    });

    // DELETE promo
    builder.addCase(deletePromoAsync.pending, (state) => {
      state.status = "PendingDeletePromo";
    });
    builder.addCase(deletePromoAsync.fulfilled, (state, action) => {
      promosAdapter.removeOne(state, action.payload);
      toast.success("Promo deleted");
      state.status = "idle";
    });
    builder.addCase(deletePromoAsync.rejected, (state) => {
      state.status = "idle";
      toast.error("Something went wrong");
    });
  },
});
//#endregion

//#region NORMALISED STATE
export const promosSelectors = promosAdapter.getSelectors(
  (state: RootState) => state.promos
);
//#endregion

//#region EXPORT
export const {
  setPromoPageNumber,
  setPromoMetaData,
  resetPromoParams,
  setPromoParams,
  clearPromos,
} = promoSlice.actions;
//#endregion
