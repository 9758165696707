import { AppUser } from "../models/user";

function usePickerManager(user: AppUser | null): boolean {
  if (!user) {
    return false;
  } else if (user.roles.includes("Picker")) {
    return true;
  } else {
    return false;
  }
}

export default usePickerManager;
