export interface MetaData {
  current_page: number;
  from: number;
  to: number;
  per_page: number;
  total: number;
  last_page: number;
  links: MetaDataLink[];
}

export interface MetaDataLink {
  url: string;
  label: string;
  active: boolean;
}

export class PaginatedResponse<T> {
  items: T;
  metaData: MetaData;

  constructor(items: T, metaData: MetaData) {
    this.items = items;
    this.metaData = metaData;
  }
}
