//#region IMPORTS
import { resetOrderParams, setOrderParams } from "../orders/ordersSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { ORDERSTATUS, ORDERTYPE } from "../../models/order";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import DashboardNavListItem from "./DashboardNavListItem";
import ManageVersionIcon from "../../components/icons/ManageVersionIcon";
import usePickerManager from "../../hooks/usePickerManager";
import TranslationIcon from "../../components/icons/TranslationIcon";
import CompletedIcon from "../../components/icons/CompletedIcon";
import OverViewIcon from "../../components/icons/OverViewIcon";
import useHQmanager from "../../hooks/useHQmanager";
import ApproveIcon from "../../components/icons/ApproveIcon";
import HistoryIcon from "../../components/icons/HistoryIcon";
import PendingIcon from "../../components/icons/PendingIcon";
import ManageIcon from "../../components/icons/ManageIcon";
import PromoIcon from "../../components/icons/PromoIcon";
import UsersIcon from "../../components/icons/UsersIcon";
import HowToIcon from "../../components/icons/HowToIcon";
import ShopIcon from "../../components/icons/ShopIcon";
//#endregion

//#region INTERFACE
interface Props {
  iconwidth: number;
  dashOpen: boolean;
}
//#endregion

export default function DashboardNavList({ iconwidth, dashOpen }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");

  // Redux
  const dispatch = useAppDispatch();
  const { orders_pending, orders_ordered } = useAppSelector(
    (state) => state.orders
  );

  // Roles
  const { user } = useAppSelector((state) => state.account);
  const isPicker = usePickerManager(user);
  const isHq = useHQmanager(user);
  //#endregion

  //#region RENDER
  return (
    <ul className="dashboard__side__menu__controls__list">
      {/* HOME */}
      <DashboardNavListItem
        to="/"
        linkName={t("rituals.home")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === ""}
      >
        <OverViewIcon width={iconwidth} selected={splitLocation[1] === ""} />
      </DashboardNavListItem>

      {/* PROMOS */}
      <DashboardNavListItem
        to="/promos"
        linkName={t("rituals.projects")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === "promos"}
      >
        <PromoIcon width={iconwidth} selected={splitLocation[1] === "promos"} />
      </DashboardNavListItem>

      {/* STOREMANAGER */}
      <DashboardNavListItem
        to="/stores"
        linkName={t("rituals.stores")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === "stores"}
        shop={false}
        picker={true}
        admin={true}
        headquarters={true}
      >
        <ShopIcon width={iconwidth} selected={splitLocation[1] === "stores"} />
      </DashboardNavListItem>

      {/* PRODUCTS */}
      <DashboardNavListItem
        to="/products"
        linkName={t("rituals.products")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === "products"}
        shop={false}
        admin={true}
        picker={false}
        headquarters={false}
      >
        <ManageIcon
          width={iconwidth}
          selected={splitLocation[1] === "products"}
        />
      </DashboardNavListItem>

      {/* PRODUCTVERSIONS */}
      <DashboardNavListItem
        to="/productversions"
        linkName={t("rituals.versions")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === "productversions"}
        shop={false}
        admin={true}
        headquarters={false}
        picker={false}
      >
        <ManageVersionIcon
          width={iconwidth}
          selected={splitLocation[1] === "productversions"}
        />
      </DashboardNavListItem>

      {/* APPROVABLE ORDERS */}
      <DashboardNavListItem
        to="/approve"
        linkName={t("rituals.approve")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === "approve"}
        countItem={isHq ? orders_pending : 0}
        shop={false}
        admin={false}
        picker={false}
        clickhandler={() =>
          dispatch(
            setOrderParams({
              page: 1,
              limit: 10000,
              sort: JSON.stringify({ column: "order_date", order: "desc" }),
              order_code: "",
              promo_code: "",
              order_status: ORDERSTATUS.pending,
              order_type: ORDERTYPE.backorder,
              shop_id: "",
              printer: "",
              country: "",
              language: "",
              city: "",
              include_sub_orders: 1,
            })
          )
        }
      >
        <ApproveIcon
          width={iconwidth}
          selected={splitLocation[1] === "approve"}
        />
      </DashboardNavListItem>

      {/* SHIPPABLE ORDERS */}
      <DashboardNavListItem
        to="/manage"
        linkName={t("rituals.manage")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === "manage"}
        countItem={isPicker ? orders_ordered : 0}
        shop={false}
        headquarters={false}
        admin={false}
        picker={true}
        clickhandler={() =>
          dispatch(
            setOrderParams({
              page: 1,
              limit: 10000,
              sort: JSON.stringify({ column: "order_date", order: "desc" }),
              order_code: "",
              promo_code: "",
              order_status: ORDERSTATUS.ordered,
              order_type: ORDERTYPE.backorder,
              shop_id: "",
              printer: "",
              country: "",
              language: "",
              city: "",
              include_sub_orders: 1,
            })
          )
        }
      >
        <ManageIcon
          width={iconwidth}
          selected={splitLocation[1] === "manage"}
        />
      </DashboardNavListItem>

      {/* ALL ORDERS */}
      <DashboardNavListItem
        to="/orders"
        linkName={t("rituals.orders")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === "orders"}
        clickhandler={() => dispatch(resetOrderParams())}
      >
        <HistoryIcon
          width={iconwidth}
          selected={splitLocation[1] === "orders"}
        />
      </DashboardNavListItem>

      {/* PENDING BACKORDERS */}
      <DashboardNavListItem
        to="/pending"
        linkName={t("rituals.pending")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === "pending"}
        admin={false}
        picker={false}
        headquarters={false}
        clickhandler={() =>
          dispatch(
            setOrderParams({
              page: 1,
              limit: 50,
              sort: JSON.stringify({ column: "order_date", order: "desc" }),
              order_code: "",
              promo_code: "",
              order_status: ORDERSTATUS.pending,
              order_type: ORDERTYPE.backorder,
              shop_id: "",
              printer: "",
              country: "",
              language: "",
              city: "",
              include_sub_orders: 1,
            })
          )
        }
      >
        <PendingIcon
          width={iconwidth}
          selected={splitLocation[1] === "pending"}
        />
      </DashboardNavListItem>

      {/* SHIPPED BACKORDERS */}
      <DashboardNavListItem
        to="/shipped"
        linkName={t("rituals.shipped")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === "shipped"}
        admin={false}
        picker={false}
        clickhandler={() =>
          dispatch(
            setOrderParams({
              page: 1,
              limit: 50,
              sort: JSON.stringify({ column: "order_date", order: "desc" }),
              order_code: "",
              promo_code: "",
              order_status: ORDERSTATUS.shipped,
              order_type: ORDERTYPE.backorder,
              shop_id: "",
              printer: "",
              country: "",
              language: "",
              city: "",
              include_sub_orders: 1,
            })
          )
        }
      >
        <CompletedIcon
          width={iconwidth}
          selected={splitLocation[1] === "shipped"}
        />
      </DashboardNavListItem>

      {/* USER MANAGER*/}
      <DashboardNavListItem
        to="/users"
        linkName={t("rituals.users")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === "users"}
        shop={false}
        admin={true}
        headquarters={false}
        picker={false}
      >
        <UsersIcon width={iconwidth} selected={splitLocation[1] === "users"} />
      </DashboardNavListItem>

      {/* FEATURELIST */}
      <DashboardNavListItem
        to="/featurelist"
        linkName={t("rituals.howTo")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === "featurelist"}
        admin={false}
        picker={false}
      >
        <HowToIcon
          width={iconwidth}
          selected={splitLocation[1] === "featurelist"}
        />
      </DashboardNavListItem>

      {/* TRANSLATION */}
      <DashboardNavListItem
        to="/translations"
        linkName={t("rituals.translations")}
        dashOpen={dashOpen}
        selected={splitLocation[1] === "translations"}
        headquarters={false}
        picker={false}
        admin={true}
        shop={false}
      >
        <TranslationIcon
          width={iconwidth}
          selected={splitLocation[1] === "translations"}
        />
      </DashboardNavListItem>
    </ul>
  );
  //#endregion
}
