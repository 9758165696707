import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { toggleProfile } from "../../features/dashBoard/dashboardSlice";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import useBreakpoints from "../../hooks/useBreakPoints";
import NavBarAccount from "./NavBarAccount";
import RitualsLogo from "../logos/RitualsLogo";
import Profile from "../profile/Profile";
import "./NavBar.css";

function NavBar() {
  // Redux
  const dispatch = useAppDispatch();
  const { profileOpen } = useAppSelector((state) => state.dashboard);
  const { dashOpen } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.account);

  const slideVariants = {
    open: { left: "200px" },
    closed: { left: "128px" },
  };

  const { isXs, isSm } = useBreakpoints();

  return (
    <Fragment>
      {isXs || isSm ? (
        <nav className="navbar">
          <NavLink to="/">
            <RitualsLogo />
          </NavLink>
          <NavBarAccount
            user={user}
            avatarClickHandler={() => dispatch(toggleProfile())}
            open={profileOpen}
          />
        </nav>
      ) : (
        <motion.nav
          className={`navbar${dashOpen ? " navbar--open" : ""}`}
          animate={dashOpen ? "open" : "closed"}
          variants={slideVariants}
        >
          <NavLink to="/">
            <RitualsLogo />
          </NavLink>
          <NavBarAccount
            user={user}
            avatarClickHandler={() => dispatch(toggleProfile())}
            open={profileOpen}
          />
        </motion.nav>
      )}

      {user && (
        <Profile
          open={profileOpen}
          user={user}
        />
      )}
    </Fragment>
  );
}

export default NavBar;
