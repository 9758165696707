//#region IMPORTS
import {
  CheckIcon,
  XCircleIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { useState } from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
//#endregion

//#region INTERFACE
interface Props {
  setSearchParamHandler: (values: any) => void;
  selectedValue: string;
  title: string;
  items: any[];
}
//#endregion

export default function FilterBarComboBox({
  setSearchParamHandler,
  selectedValue,
  title,
  items,
}: Props) {
  //#region SETUP
  const [query, setQuery] = useState("");
  //#endregion

  //#region LOGIC
  const filteredItems =
    query === ""
      ? items
      : items.filter((item) => {
          return item.toLowerCase().includes(query.toLowerCase());
        });

  const setItem = (item: any) => {
    setSearchParamHandler(item);
  };
  //#endregion

  //#region RENDER
  return (
    <Combobox
      as="div"
      value={selectedValue}
      onChange={setItem}
      className="mb-3 pl-8"
    >
      <div className="flex items-center justify-between">
        <Combobox.Label className="font-semibold text-gray-900 text-sm">
          {title}
        </Combobox.Label>
        {selectedValue && (
          <XCircleIcon
            className="h-4 w-4 text-red-600"
            onClick={() => setSearchParamHandler("")}
          />
        )}
      </div>

      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: string) => item}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredItems.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredItems.map((item) => (
              <Combobox.Option
                key={item}
                value={item}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-1 pl-3 pr-9",
                    active ? "bg-amber-300 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {item.name ? item.name : item}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-amber-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
  //#endregion
}
