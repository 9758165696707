//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { orderSortingOptions } from "../../models/_consts";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { setOrderParams } from "../../features/orders/ordersSlice";
import { SortOption } from "../../models/sorting";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
//#endregion

export default function OrdersTableControlePanelSortActions() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { order_params } = useAppSelector((state) => state.orders);

  // Local state
  const [sortOptions, setSortOptions] =
    useState<SortOption[]>(orderSortingOptions);
  const [filterText, setFilterText] = useState<string>("Sort by ...");
  //#endregion

  //#region LOGIC
  // Check if sorting is applied
  useEffect(() => {
    if (order_params.sort === "") setFilterText("Sort by ...");
  }, [order_params]);

  // Set order sorting
  const sortChangedHandler = ({
    id,
    current,
    value,
    label,
    direction,
  }: SortOption) => {
    setSortOptions((currentOptions) => {
      return currentOptions.map((option) => ({
        ...option,
        current: option.id === id ? current : false,
      }));
    });
    if (value !== null) {
      dispatch(
        setOrderParams({
          sort: JSON.stringify({ column: value, order: direction }),
        })
      );
      setFilterText(`Sorted by ${label}`);
    } else {
      dispatch(setOrderParams({ sort: null }));
      setFilterText("Sort by ...");
    }
  };
  //#endregion

  //#region RENDER
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
          {filterText}
          <ChevronDownIcon
            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {sortOptions.map((option) => (
              <Menu.Item key={option.id}>
                {({ active }) => (
                  <div
                    onClick={() => sortChangedHandler(option)}
                    className={classNames(
                      option.current
                        ? "font-medium text-gray-900"
                        : "text-gray-500",
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    {option.label}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
  //#endregion
}
