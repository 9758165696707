import { motion } from "framer-motion";

interface Props {
  values: string[];
  selectedValue?: string;
  onChange: (value: string) => void;
}

function FilterBarSelectDropDown({ values, onChange, selectedValue }: Props) {
  return (
    <motion.ul className="filterbar__select__list">
      {values
        .filter((value) => value !== "Unknown type" && value !== "Unknown status")
        .map((value) => (
          <li
            onClick={() => onChange(value)}
            className={
              selectedValue === value
                ? "filterbar__select__list__item filterbar__select__list__item--selected"
                : "filterbar__select__list__item"
            }
            key={value ? value : "notfound"}
          >
            {value ? value : "notfound"}
          </li>
        ))}
    </motion.ul>
  );
}

export default FilterBarSelectDropDown;
