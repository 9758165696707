//#region IMPORTS
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  Product,
  ProductFilters,
  ProductParams,
  ProductsState,
} from "../../models/product";
import { RootState } from "../../store/configureStore";
import { toast } from "react-toastify";
import agent from "../../api/agent";
import getAxiosParams from "../../helpers/getAxiosParams";
//#endregion

//#region INIT
const initialExtraState: ProductsState = {
  products_loaded: false,
  filters_loaded: false,
  filters: initFilters(),
  product_params: initParams(),
  status: "idle",
  metaData: null,
};

// Set initial query parameters
function initParams(): ProductParams {
  return {
    page: 1,
    limit: 25,
  };
}

// Set all filter options
function initFilters(): ProductFilters {
  return {
    producer: [],
  };
}
//#endregion

//#region CREATE ADAPTER
const productsAdapter = createEntityAdapter<Product>();
//#endregion

//#region READ
// Fetch All
export const fetchProductsAsync = createAsyncThunk<
  Product[],
  void,
  { state: RootState }
>("products/fetchProductsAsync", async (_, thunkAPI) => {
  const productParams = getAxiosParams(
    thunkAPI.getState().products.product_params
  );
  try {
    const { data, meta } = await agent.Products.list(productParams);
    thunkAPI.dispatch(setProductMetaData(meta));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});

// Fetch detail
export const fetchProductAsync = createAsyncThunk<
  Product,
  number,
  { state: RootState }
>("products/fetchProductAsync", async (id) => {
  try {
    return await agent.Products.detail(id);
  } catch (error) {
    toast.error(`${error}`);
  }
});

// Fetch all filters
export const fetchFilters = createAsyncThunk(
  "products/fetchFilters",
  async (_, thunkAPI) => {
    try {
      return agent.Products.fetchFilters();
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);
//#endregion

//#region CREATE REDUCER SLICE
export const productsSlice = createSlice({
  name: "products",
  initialState:
    productsAdapter.getInitialState<ProductsState>(initialExtraState),
  reducers: {
    setProductParams: (state, action) => {
      state.products_loaded = false;
      state.product_params = {
        ...state.product_params,
        ...action.payload,
        page: 1,
      };
    },
    setProductPageNumber: (state, action) => {
      state.products_loaded = false;
      state.product_params = { ...state.product_params, ...action.payload };
    },
    setProductMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    resetProductParams: (state) => {
      state.products_loaded = false;
      state.product_params = initParams();
    },
    clearProducts: () =>
      productsAdapter.getInitialState<ProductsState>(initialExtraState),
    setProduct: (state, action) =>
      productsAdapter.upsertOne(state, action.payload),
    removeProduct: (state, action) =>
      productsAdapter.removeOne(state, action.payload),
  },
  extraReducers: (builder) => {
    // FETCH products
    builder.addCase(fetchProductsAsync.pending, (state) => {
      state.status = "pendingFetchProducts";
    });
    builder.addCase(fetchProductsAsync.fulfilled, (state, action) => {
      productsAdapter.setAll(state, action.payload);
      state.status = "idle";
      state.products_loaded = true;
    });
    builder.addCase(fetchProductsAsync.rejected, (state) => {
      state.status = "idle";
    });

    // FETCH product by id
    builder.addCase(fetchProductAsync.pending, (state) => {
      state.status = "pendingFetchProduct";
    });
    builder.addCase(fetchProductAsync.fulfilled, (state, action) => {
      productsAdapter.upsertOne(state, action.payload);
      state.status = "idle";
    });
    builder.addCase(fetchProductAsync.rejected, (state) => {
      state.status = "idle";
    });

    // FETCH filters
    builder.addCase(fetchFilters.pending, (state) => {
      state.status = "pendingFetchFilters";
    });
    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      state.filters.producer = action.payload.producers;
      state.filters_loaded = true;
      state.status = "idle";
    });
    builder.addCase(fetchFilters.rejected, (state) => {
      state.status = "idle";
    });
  },
});
//#endregion

//#region NORMALISED STATE
export const productsSelectors = productsAdapter.getSelectors(
  (state: RootState) => state.products
);
//#endregion

//#region EXPORT
export const {
  clearProducts,
  setProduct,
  setProductParams,
  setProductMetaData,
  setProductPageNumber,
  resetProductParams,
  removeProduct,
} = productsSlice.actions;
//#endregion
