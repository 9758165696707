const ItemMinus = ({clickHandler}: any) => {
    return (
      <svg viewBox="0 0 32 8" className="item-minus" onClick={clickHandler}>
        <rect
          fill="#91a1ab"
          x="12"
          width="8"
          height="32"
          transform="translate(0 20) rotate(-90)"
        />
      </svg>
    );
  };
  
  export default ItemMinus;
  