//#region IMPORTS
import {
  fetchFilters,
  setUserParams,
  usersSelectors,
  deleteUserAsync,
  resetUserParams,
  fetchUsersAsync,
  setUserPageNumber,
  fetchUserRolesAsync,
} from "./usersSlice";
import { CREATE_OR_UPDATE_USER, DELETE_USER_SURE } from "../../models/_consts";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { closeFilterBar, openModal } from "../dashBoard/dashboardSlice";
import { User, UserParams } from "../../models/user";
import { useTranslation } from "react-i18next";
import MainMotionSection from "../../components/_layout/MainMotionSection";
import useAdminManager from "../../hooks/useAdminManager";
import FilterBarSearch from "../../components/filterBar/FilterBarSearch";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import useFilterCount from "../../hooks/useFilterCount";
import PaginationBar from "../../components/paginationBar/PaginationBar";
import MainContainer from "../../components/_layout/MainContainer";
import AreYouSure from "../../components/modalTypes/AreYouSure";
import UsersTable from "./usersTable/UsersTable";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import FilterBar from "../../components/filterBar/FilterBar";
import MainModal from "../../components/_layout/MainModal";
import UserForm from "../../components/users/UserForm";
import agent from "../../api/agent";
import "./Users.css";
//#endregion

export default function UsersPage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const users = useAppSelector(usersSelectors.selectAll);
  const { user } = useAppSelector((state) => state.account);
  const { users_loaded, userRoles_loaded, filters_loaded, filters, metaData } =
    useAppSelector((state) => state.users);

  // Local State
  const [userToManage, setUserToManage] = useState<User>();

  // Roles
  const isAdmin = useAdminManager(user);

  // Set Filters
  const { page, limit, ...selectedFilters }: UserParams = useAppSelector(
    (state) => state.users.user_params
  );
  const filterCount = useFilterCount(selectedFilters);
  //#endregion

  //#region LOGIC
  // Load filters
  useEffect(() => {
    if (!filters_loaded) {
      dispatch(fetchFilters());
    }
  }, [filters_loaded, dispatch]);

  // Load users
  useEffect(() => {
    !users_loaded && dispatch(fetchUsersAsync());
  }, [users_loaded, dispatch]);

  // Load user roles
  useEffect(() => {
    if (!userRoles_loaded) {
      dispatch(fetchUserRolesAsync());
    }
  }, [userRoles_loaded, dispatch]);

  // Close filterbar onleave
  useEffect(() => {
    return () => {
      dispatch(closeFilterBar());
    };
  }, [dispatch]);

  // Create new user
  const createNewUser = () => {
    setUserToManage(undefined);
    dispatch(openModal(CREATE_OR_UPDATE_USER));
  };

  // Update a user
  const manageUser = async (id: number) => {
    const { data: user } = await agent.Users.detail(id);
    setUserToManage(user);
    dispatch(openModal(CREATE_OR_UPDATE_USER));
  };

  // Set Delete user warning
  const deleteUserWarningHandler = async (id: number) => {
    const { data: user } = await agent.Users.detail(id);
    setUserToManage(user);
    dispatch(openModal(DELETE_USER_SURE));
  };

  // Delete promo
  const deleteUserHandler = async () => {
    userToManage && (await dispatch(deleteUserAsync({ id: userToManage.id })));
    cancelCUD();
  };

  // Cancel create or update promo
  const cancelCUD = () => {
    dispatch(openModal(false));
    setUserToManage(undefined);
  };

  //#endregion

  //#region FAIL SAFE
  if (!users_loaded && !filters_loaded) return <LoadingSpinner />;
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* MODALS */}
      <MainModal type={DELETE_USER_SURE} closeHandler={cancelCUD}>
        <AreYouSure
          confirmHandler={deleteUserHandler}
          closeHandler={cancelCUD}
          message={`Are you sure you want to delete user ${userToManage?.username}`}
          title="Delete User?"
        />
      </MainModal>

      <MainModal type={CREATE_OR_UPDATE_USER} closeHandler={cancelCUD}>
        <UserForm cancelCUD={cancelCUD} isAdmin={isAdmin} user={userToManage} />
      </MainModal>

      {/* BODY */}
      <MainContainer>
        <FilterBar
          selectedFilters={selectedFilters}
          resetFilterHandler={() => dispatch(resetUserParams())}
          setParamsHandler={(values: any) => {
            dispatch(setUserParams(values));
          }}
          filters={filters}
          directTo="/users"
        >
          <h2 className="ml-8 font-semibold text-amber-600 text-2xl">
            {t("rituals.search")}
          </h2>
          <FilterBarSearch
            setSearchParamHandler={(values) =>
              dispatch(setUserParams({ name: values }))
            }
            title={t("rituals.name")}
          />
          <FilterBarSearch
            setSearchParamHandler={(values) =>
              dispatch(setUserParams({ external_store_id: values }))
            }
            title={t("rituals.storeNumber")}
          />
        </FilterBar>

        <MainMotionSection>
          <PageHeader
            createButtonValue={t("rituals.newUser")}
            createClickHandler={createNewUser}
            filterCount={filterCount}
            isAdmin={isAdmin}
            create={false}
            filter
            title={t("rituals.users")}
          />

          <Fragment>
            {users.length > 0 ? (
              <UsersTable
                data={users}
                manage={(id: number) => manageUser(id)}
                deleteAction={(id: number) => deleteUserWarningHandler(id)}
              />
            ) : filterCount > 0 ? (
              <h2>{t("rituals.noUsersFound")}</h2>
            ) : null}
            <footer>
              {metaData && metaData?.total > 0 && (
                <PaginationBar
                  metaData={metaData}
                  onPageChange={(page: number) =>
                    dispatch(setUserPageNumber({ page: page }))
                  }
                  pageType={t("rituals.users")}
                  loaded={users_loaded}
                />
              )}
            </footer>
          </Fragment>
        </MainMotionSection>
      </MainContainer>
    </Fragment>
  );
  //#endregion
}
