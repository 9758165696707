//#region IMPORTS
import { fetchFilters, resetProductParams, setProduct } from "../productsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { useForm, FieldValues } from "react-hook-form";
import { toast } from "react-toastify";
import { Product } from "../../../models/product";
import SubmitButton from "../../../components/genericButtons/submitButton/SubmitButton";
import agent from "../../../api/agent";
import "./ProductsForm.css";
//#endregion

//#region INTERFACE
interface Props {
  cancelCUD: () => void;
  product?: Product;
  active?: boolean;
}
//#endregion

export default function ProductsForm({ cancelCUD, product, active }: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { filters, filters_loaded } = useAppSelector((state) => state.products);

  // Local State
  const [iAmAware, setIamAware] = useState<boolean>(false);

  // Use form
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });
  //#endregion

  //#region LOGIC
  // Fill in fields if product
  useEffect(() => {
    if (product) reset(product);
  }, [product, reset]);

  // Reset fields if !active
  useEffect(() => {
    if (!active) reset(product);
  }, [active, reset, product]);

  // Fetch filters
  useEffect(() => {
    !filters_loaded && dispatch(fetchFilters());
  }, [dispatch, filters_loaded]);

  // Submit
  async function submitForm(data: FieldValues) {
    try {
      if (product) {
        await agent.Products.updateProduct(product.id, data).then(
          (response) => {
            dispatch(resetProductParams());
            dispatch(setProduct(response.data));
            toast.success(`Shop ${data.name} updated.`);
          }
        );
      } else {
        await agent.Products.createProduct(data).then((response) => {
          dispatch(setProduct(response.data));
          toast.success(`Product ${data.id} added.`);
        });
      }
      cancelCUD();
    } catch (error) {
      toast.error(`${error}`);
    }
  }
  //#endregion

  //#region RENDER
  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className={`products-form${
        active
          ? isValid
            ? " products-form--valid"
            : ""
          : " products-form--inactive"
      }`}
    >
      <div className="products-form__fields">
        {/* LEFT column */}
        <fieldset className="products-form__inputgroup">
          {/* name */}
          <label
            className={
              errors.name ? "products-form__inputgroup__label--error" : ""
            }
          >
            {errors.name ? errors?.name?.message : "Product name"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. flyer"
            {...register("name", {
              required: "Name is required",
              minLength: { value: 3, message: "at least 3 characters please" },
            })}
          />

          {/* number */}
          {!product && (
            <Fragment>
              <label
                className={
                  errors.number ? "products-form__inputgroup__label--error" : ""
                }
              >
                {errors.number ? errors?.number?.message : "Product number"}
              </label>
              <input
                disabled={!active}
                className="products-form__inputgroup__inputfield"
                type="text"
                placeholder="e.g. 1234_test"
                {...register("number", {
                  required: "Number is required",
                  minLength: {
                    value: 3,
                    message: "at least 3 characters please",
                  },
                })}
              />
            </Fragment>
          )}

          {/* produced_by */}
          <label
            className={
              errors.produced_by
                ? "products-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.produced_by ? errors?.produced_by?.message : "Produced by"}
          </label>
          <select
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            {...register("produced_by", {
              required: "Producer is required",
            })}
          >
            {filters.producer.map((producer) => (
              <option key={producer} value={producer}>
                {producer}
              </option>
            ))}
          </select>

          {/* length */}
          <label
            className={
              errors.length ? "products-form__inputgroup__label--error" : ""
            }
          >
            {errors.length ? errors?.length?.message : "Length"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="number"
            placeholder="e.g. 125"
            {...register("length", {
              required: "Length is required",
              minLength: { value: 1, message: "at least 1 characters please" },
            })}
          />

          {/* width */}
          <label
            className={
              errors.width ? "products-form__inputgroup__label--error" : ""
            }
          >
            {errors.width ? errors?.width?.message : "Width"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="number"
            placeholder="e.g. 156"
            {...register("width", {
              required: "Width is required",
              minLength: { value: 1, message: "at least 1 characters please" },
            })}
          />
        </fieldset>

        {/* MIDDLE left column */}
        <fieldset className="products-form__inputgroup">
          {/* store_type */}
          <label
            className={
              errors.store_type ? "products-form__inputgroup__label--error" : ""
            }
          >
            {errors.store_type ? errors?.store_type?.message : "Store type"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. Retail"
            {...register("store_type", {
              required: "Store type is required",
              minLength: { value: 3, message: "at least 3 characters please" },
            })}
          />

          {/* sort */}
          <label
            className={
              errors.sort ? "products-form__inputgroup__label--error" : ""
            }
          >
            {errors.sort ? errors?.sort?.message : "Sorting order"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="number"
            placeholder="e.g. 1"
            {...register("sort", {
              required: "Sort is required",
              minLength: { value: 1, message: "at least 1 characters please" },
            })}
          />

          {/* machine */}
          <label
            className={
              errors.machine ? "products-form__inputgroup__label--error" : ""
            }
          >
            {errors.machine ? errors?.machine?.message : "Machine"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. iridesse"
            {...register("machine")}
          />

          {/* finish */}
          <label
            className={
              errors.finish ? "products-form__inputgroup__label--error" : ""
            }
          >
            {errors.finish ? errors?.finish?.message : "Finish"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. nasnijden"
            {...register("finish")}
          />

          {/* quantity_in_set */}
          <label
            className={
              errors.quantity_in_set
                ? "products-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.quantity_in_set
              ? errors?.quantity_in_set?.message
              : "Quantity in set"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="number"
            placeholder="e.g. 1"
            {...register("quantity_in_set")}
          />

          {/* quantity_in_bundle */}
          <label
            className={
              errors.quantity_in_bundle
                ? "products-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.quantity_in_bundle
              ? errors?.quantity_in_bundle?.message
              : "Quantity in bundle"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="number"
            placeholder="e.g. 1"
            {...register("quantity_in_bundle")}
          />
        </fieldset>

        {/* MIDDLE right column */}
        <fieldset className="products-form__inputgroup">
          {/* quantity_in_sheet_set */}
          <label
            className={
              errors.quantity_in_sheet_set
                ? "products-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.quantity_in_sheet_set
              ? errors?.quantity_in_sheet_set?.message
              : "Quantity in sheet set"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="number"
            placeholder="e.g. 1"
            {...register("quantity_in_sheet_set")}
          />

          {/* quantity_set_on_sheet */}
          <label
            className={
              errors.quantity_set_on_sheet
                ? "products-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.quantity_set_on_sheet
              ? errors?.quantity_set_on_sheet?.message
              : "Quantity set on sheet"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="number"
            placeholder="e.g. 1"
            {...register("quantity_set_on_sheet")}
          />

          {/* colors_r */}
          <label
            className={
              errors.colors_r ? "products-form__inputgroup__label--error" : ""
            }
          >
            {errors.colors_r ? errors?.colors_r?.message : "Colors recto"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. Q"
            {...register("colors_r")}
          />

          {/* colors_v */}
          <label
            className={
              errors.colors_v ? "products-form__inputgroup__label--error" : ""
            }
          >
            {errors.colors_v ? errors?.colors_v?.message : "Colors verso"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. Z+G"
            {...register("colors_v")}
          />

          {/* slipsheet */}
          <label
            className={
              errors.slipsheet ? "products-form__inputgroup__label--error" : ""
            }
          >
            {errors.slipsheet ? errors?.slipsheet?.message : "Slipsheet"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. Ok"
            {...register("slipsheet")}
          />

          {/* extra_info */}
          <label
            className={
              errors.extra_info ? "products-form__inputgroup__label--error" : ""
            }
          >
            {errors.extra_info ? errors?.extra_info?.message : "Extra info"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. Lorem ispum..."
            {...register("extra_info")}
          />
        </fieldset>

        {/* RIGHT column */}
        <fieldset className="products-form__inputgroup">
          {/* procent_extra */}
          <label
            className={
              errors.procent_extra
                ? "products-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.procent_extra
              ? errors?.procent_extra?.message
              : "Procent extra"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="number"
            placeholder="e.g. 2"
            {...register("procent_extra")}
          />

          {/* specs_material */}
          <label
            className={
              errors.specs_material
                ? "products-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.specs_material
              ? errors?.specs_material?.message
              : "Specs material"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. Lorem ispum..."
            {...register("specs_material")}
          />

          {/* specs_sided */}
          <label
            className={
              errors.specs_sided
                ? "products-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.specs_sided ? errors?.specs_sided?.message : "Specs sided"}
          </label>
          <input
            disabled={!active}
            className="products-form__inputgroup__inputfield"
            type="text"
            placeholder="e.g. Lorem ispum..."
            {...register("specs_sided")}
          />

          {/* pick_in_house */}
          <label
            className={
              errors.pick_in_house
                ? "products-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.pick_in_house
              ? errors?.pick_in_house?.message
              : "Inhouse picking"}
          </label>
          <input
            disabled={!active}
            className="promo-form__inputgroup__inputfield--checkbox aware-of-trouble--checkbox pickinhouse--checkbox"
            type="checkbox"
            {...register("pick_in_house")}
          />
        </fieldset>
      </div>
      {active && (
        <div className="products-form__actions">
          <SubmitButton conditionals={[isValid, iAmAware]} isSubmitting={isSubmitting}/>
          <input
            disabled={!active}
            className="promo-form__inputgroup__inputfield--checkbox aware-of-trouble--checkbox"
            type="checkbox"
            checked={iAmAware}
            onChange={() => setIamAware((prev) => !prev)}
          />
          <label className="aware-of-trouble">
            I am aware this changes real data
          </label>
        </div>
      )}
    </form>
  );
  //#endregion
}
