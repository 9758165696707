//#region IMPORTS
import {
  removeProduct,
  productsSelectors,
  fetchProductAsync,
} from "./productsSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import DeleteButton from "../../components/genericButtons/deleteButton/DeleteButton";
import GoBackButton from "../../components/genericButtons/goBackButton/GoBackButton";
import ProductsForm from "./productsForm/ProductsForm";
import CancelIcon from "../../components/icons/CancelIcon";
import PencilIcon from "../../components/icons/PencilIcon";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import NotFound from "../../components/errors/NotFound";
import agent from "../../api/agent";
//#endregion

export default function ProductsDetailPage() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { status } = useAppSelector((state) => state.products);
  const product = useAppSelector((state) =>
    productsSelectors.selectById(state, id)
  );

  // Local state
  const [updateMode, setUpdateMode] = useState(false);
  //#endregion

  //#region LOGIC
  useEffect(() => {
    if (!product) dispatch(fetchProductAsync(parseInt(id)));
  }, [id, product, dispatch]);

  const deleteHandler = async () => {
    try {
      if (product) {
        await agent.Products.deleteProduct(product.id).then((response) => {
          history.push("/products");
          toast.success(`${product.id} ${response.message}`);
          dispatch(removeProduct(product.id));
        });
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  //#endregion

  //#region FAIL SAFE
  if (status.includes("pending")) return <LoadingSpinner />;
  if (!product) return <NotFound />;
  //#endregion

  //#region RENDER
  return (
    product && (
      <article className="products-page">
        <PageHeader
          reset={() => history.push("/products")}
          title={updateMode ? "Edit product" : "Product info"}
          extra={
            !updateMode && (
              <DeleteButton color="#f25c5c" clickHandler={deleteHandler} />
            )
          }
        >
          <GoBackButton />
        </PageHeader>

        {product.product_versions.length > 0 && (
          <article className="products-page__versions">
            <h2 className="products-page__versions__title">
              This product is available in {product.product_versions.length}{" "}
              language{product.product_versions.length > 1 ? "s" : ""}:
            </h2>
            {product.product_versions.map((version) => (
              <div
                className="products-page__versions__list__item"
                key={version.id}
                onClick={() => history.push(`/Productversions/${version.id}`)}
              >
                {version.language}
              </div>
            ))}
          </article>
        )}

        <section className="products-page__content">
          {updateMode ? (
            <motion.button
              whileHover={{ scale: 1.1 }}
              className="products-page__content__new-box__cancel"
              onClick={() => setUpdateMode(false)}
            >
              <CancelIcon />
            </motion.button>
          ) : (
            <motion.button
              whileHover={{ scale: 1.1 }}
              className="products-page__content__new-box__cancel"
              onClick={() => setUpdateMode(true)}
            >
              <PencilIcon width={32} />
            </motion.button>
          )}

          <ProductsForm
            product={product}
            active={updateMode}
            cancelCUD={() => setUpdateMode(false)}
          />
        </section>
      </article>
    )
  );
  //#endregion
}
