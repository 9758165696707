import "./DeleteButton.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import CheckoutButton from "../checkoutButton/CheckoutButton";

interface Props {
  clickHandler?: () => void;
  color?: string;
  shake?: boolean;
}

function DeleteButton({
  clickHandler,
  color = "#f25c5c",
  shake = false,
}: Props) {
  const [warning, setWarning] = useState<boolean>(false);
    // Translation
    const { t } = useTranslation();

  return (
    <div className="delete-button-container">
      <CheckoutButton
        value={warning ? t("rituals.cancel") : t("rituals.delete")}
        color={color}
        clickHandler={() => setWarning(!warning)}
      />
      {warning && (
        <div className="delete-button-modal">
          <p>{t("rituals.areYouSure")}</p>
          <button
            onClick={clickHandler}
            className={`delete-button${shake ? " delete-button--shake" : ""}`}
          >
            <p className="delete-button__value">{t("rituals.yesContinue")}</p>
          </button>
          <button
            onClick={() => setWarning(!warning)}
            className={`delete-button${shake ? " delete-button--shake" : ""}`}
          >
            <p className="delete-button__value" style={{color: "#becbd3"}}>{t("rituals.noCancel")}</p>
          </button>
        </div>
      )}
    </div>
  );
}

export default DeleteButton;
