import { ImageUpload } from "../../models/image";

interface Props {
  savingDetail: boolean;
  imageGroup: ImageUpload;
  saving: boolean;
}

function ImageUploaderItemV3({ savingDetail, imageGroup, saving }: Props) {
  return !imageGroup.saved ? (
    <li
      key={imageGroup.product}
      className="image-uploader__saving-modal__list__item "
    >
      <p>
        {savingDetail && saving
          ? `saving ${imageGroup.product}...`
          : imageGroup.product}
      </p>
      <p>{imageGroup.versions.length} files</p>
    </li>
  ) : (
    <li>SAVED!</li>
  );
}

export default ImageUploaderItemV3;
