//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StoreDetail } from "../../models/store";
import { removeStore } from "./storesSlice";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import useAdminManager from "../../hooks/useAdminManager";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import DeleteButton from "../../components/genericButtons/deleteButton/DeleteButton";
import GoBackButton from "../../components/genericButtons/goBackButton/GoBackButton";
import CancelIcon from "../../components/icons/CancelIcon";
import PencilIcon from "../../components/icons/PencilIcon";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import StoreForm from "../../components/stores/StoreForm";
import NotFound from "../../components/errors/NotFound";
import agent from "../../api/agent";
//#endregion

export default function StoreDetailPage() {
  //#region SETUP
  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { user } = useAppSelector((state) => state.account);

  // Shop uses local state as detail differs from list
  const [updateMode, setUpdateMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState<StoreDetail>();

  // Role State
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region LOGIC
  // Fetch ShopDetail
  useEffect(() => {
    const fetchStoreDetail = async () => {
      try {
        const response = await agent.StoreManagement.detail(parseInt(id));
        setStore(response.data);
        setLoading(false);
      } catch (error) {
        toast.error(`${error}`);
        setLoading(false);
      }
    };
    fetchStoreDetail();
  }, [id, dispatch]);

  // Delete the shop by ID
  const deleteHandler = async () => {
    try {
      if (store) {
        await agent.StoreManagement.delete(store.id).then(() => {
          history.push("/stores");
          dispatch(removeStore(store.id));
          toast.success(`${store.external_store_id} has been deleted`);
        });
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  //#endregion

  //#region FAIL SAFE
  if (loading) return <LoadingSpinner />;
  if (!store) return <NotFound />;
  //#endregion

  //#region RENDER
  return (
    store && (
      <article className="store-page">
        <PageHeader
          title={updateMode ? "Edit store" : "Store info"}
          extra={
            !updateMode &&
            isAdmin && (
              <DeleteButton color="#f25c5c" clickHandler={deleteHandler} />
            )
          }
          reset={() => history.push("/stores")}
        >
          <GoBackButton />
        </PageHeader>

        <section className="store-page__content">
          {isAdmin && (
            <Fragment>
              {updateMode ? (
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  className="store-page__content__new-box__cancel"
                  onClick={() => setUpdateMode(false)}
                >
                  <CancelIcon />
                </motion.button>
              ) : (
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  className="store-page__content__new-box__cancel"
                  onClick={() => setUpdateMode(true)}
                >
                  <PencilIcon width={32} />
                </motion.button>
              )}
            </Fragment>
          )}

          <StoreForm
            store={store}
            countryName={store.country_name}
            id={store.id}
            active={updateMode}
            cancelCUD={() => setUpdateMode(false)}
            updateStore={(updatedStore) => setStore(updatedStore)}
          />
        </section>
      </article>
    )
  );
  //#endregion
}
