//#region IMPORTS
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  Order,
  ORDERSTATUS,
  OrderParams,
  OrdersState,
  OrderUpdate,
  OrderFilters,
  ORDERTYPE,
} from "../../models/order";
import { RootState } from "../../store/configureStore";
import { toast } from "react-toastify";
import getAxiosParams from "../../helpers/getAxiosParams";
import agent from "../../api/agent";
//#endregion

//#region INIT
// Set initial state
const initialExtraState: OrdersState = {
  orders_pending: 0,
  orders_ordered: 0,
  orders_loaded: false,
  filters_loaded: false,
  status: "idle",
  filters: initFilters(),
  order_params: initParams(),
  metaData: null,
  detail_group: [],
  selectedAll: false,
  selectedOrders: [],
  showOrdersWithSuborders: false,
  selectedSubOrder: null,
  selectedOrder: null,
};

// Set initial query parameters
function initParams(): OrderParams {
  return {
    page: 1,
    limit: 50,
    sort: JSON.stringify({ column: "order_date", order: "desc" }),
    order_code: "",
    promo_code: "",
    order_status: "",
    order_type: "",
    shop_id: "",
    printer: "",
    country: "",
    language: "",
    city: "",
    include_sub_orders: 1,
  };
}

// Set all filter options
function initFilters(): OrderFilters {
  return {
    promo_code: [],
    order_status: [],
    stock_portal: [],
    order_type: [],
    country_name: [],
    city: [],
    language: [],
  };
}
//#endregion

//#region CREATE ADAPTER
const ordersAdapter = createEntityAdapter<Order>();
//#endregion

//#region READ
// Fetch All
export const fetchOrdersAsync = createAsyncThunk<
  Order[],
  void,
  { state: RootState }
>("orders/fetchOrdersAsync", async (_, thunkAPI) => {
  const orderParams = getAxiosParams(thunkAPI.getState().orders.order_params);
  try {
    const { data, meta } = await agent.Orders.list(orderParams);
    thunkAPI.dispatch(setMetaData(meta));

    return data.map((item: Order) => ({
      ...item,
      approved: true,
      selected: false,
    }));
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});

// Fetch Approvable Ammount
export const fetchApprovableOrdersAsync = createAsyncThunk<
  Order[],
  void,
  { state: RootState }
>("orders/fetchApprovableOrdersAsync", async (_, thunkAPI) => {
  const approveParams = new URLSearchParams();
  approveParams.append("order_status", ORDERSTATUS.pending);
  approveParams.append("order_type", ORDERTYPE.backorder);
  try {
    const { data, meta } = await agent.Orders.list(approveParams);
    thunkAPI.dispatch(setAmmountPending(meta.total));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});

// Fetch Shippable Ammount
export const fetchShippableOrdersAsync = createAsyncThunk<
  Order[],
  void,
  { state: RootState }
>("orders/fetchShippableOrdersAsync", async (_, thunkAPI) => {
  const orderedParams = new URLSearchParams();
  orderedParams.append("order_status", ORDERSTATUS.ordered);
  orderedParams.append("order_type", ORDERTYPE.backorder);
  try {
    const { data, meta } = await agent.Orders.list(orderedParams);
    thunkAPI.dispatch(setAmmountOrdered(meta.total));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});

// Fetch all filters
export const fetchFilters = createAsyncThunk(
  "orders/fetchFilters",
  async (_, thunkAPI) => {
    try {
      return agent.Orders.fetchFilters();
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);
//#endregion

//#region UPDATE
// Update order
export const updateOrderAsync = createAsyncThunk<
  Order,
  { orderId: number; values: OrderUpdate }
>("orders/updateOrderAsync", async ({ orderId, values }, thunkAPI) => {
  try {
    const { data } = await agent.Orders.updateOrder(orderId, { ...values });
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});
//#endregion

//#region CREATE REDUCER SLICE
export const ordersSlice = createSlice({
  name: "orders",
  initialState: ordersAdapter.getInitialState<OrdersState>(initialExtraState),
  reducers: {
    updateApproveStateOfOrder: (state, action) => {
      const updatedOrder = action.payload;
      ordersAdapter.upsertOne(state, updatedOrder);
    },
    updateSelectedStateOfOrder: (state, action) => {
      const updatedOrder = action.payload;
      ordersAdapter.upsertOne(state, updatedOrder);
    },
    setOrderParams: (state, action) => {
      state.orders_loaded = false;
      state.order_params = {
        ...state.order_params,
        ...action.payload,
        page: 1,
      };
    },
    setPageNumber: (state, action) => {
      state.orders_loaded = false;
      state.order_params = { ...state.order_params, ...action.payload };
    },
    setMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    setAmmountPending: (state, action) => {
      state.orders_pending = action.payload;
    },
    setAmmountOrdered: (state, action) => {
      state.orders_ordered = action.payload;
    },
    resetOrderParams: (state) => {
      state.order_params = initParams();
      state.orders_loaded = false;
    },
    setDetailGroup: (state, action) => {
      state.detail_group = action.payload;
    },
    resetDetailGroup: (state) => {
      state.detail_group = [];
    },
    setSelectedAll: (state) => {
      state.selectedAll = true;
    },
    clearSelectedAll: (state) => {
      state.selectedAll = false;
    },
    clearOrders: () =>
      ordersAdapter.getInitialState<OrdersState>(initialExtraState),
    setOrdersWithSuborders: (state, action) => {
      state.showOrdersWithSuborders = action.payload;
    },
    setSelectedOrders: (state, action) => {
      state.selectedOrders = action.payload;
    },
    setSelectedSubOrder: (state, action) => {
      state.selectedSubOrder = action.payload.subOrder;
      state.selectedOrder = action.payload.order;
    },
  },
  extraReducers: (builder) => {
    // FETCH orders
    builder.addCase(fetchOrdersAsync.pending, (state) => {
      state.status = "pendingFetchOrders";
      state.orders_loaded = false;
    });
    builder.addCase(fetchOrdersAsync.fulfilled, (state, action) => {
      ordersAdapter.setAll(state, action.payload);
      state.status = "idle";
      state.orders_loaded = true;
    });
    builder.addCase(fetchOrdersAsync.rejected, (state) => {
      state.status = "idle";
    });

    // FETCH approvable orders ammount
    builder.addCase(fetchApprovableOrdersAsync.pending, (state) => {
      state.status = "pendingFetchApprovableOrders";
    });
    builder.addCase(fetchApprovableOrdersAsync.fulfilled, (state) => {
      state.status = "idle";
    });
    builder.addCase(fetchApprovableOrdersAsync.rejected, (state) => {
      state.status = "idle";
    });

    // FETCH shippable orders ammount
    builder.addCase(fetchShippableOrdersAsync.pending, (state) => {
      state.status = "pendingFetchShippableOrders";
    });
    builder.addCase(fetchShippableOrdersAsync.fulfilled, (state) => {
      state.status = "idle";
    });
    builder.addCase(fetchShippableOrdersAsync.rejected, (state) => {
      state.status = "idle";
    });

    // FETCH filters
    builder.addCase(fetchFilters.pending, (state) => {
      state.status = "pendingFetchFilters";
    });
    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      state.filters.order_type = action.payload.order_types;
      state.filters.order_status = action.payload.order_statuses;
      state.filters.stock_portal = action.payload.stock_portal;
      // OLD 29-05-2024
      // ? action.payload.printers.filter((p: string) => p !== "Y")
      // : [];
      state.filters.city = action.payload.cities ?? [];
      state.filters.language = action.payload.languages ?? [];
      // Sort promocode descending by year
      state.filters.promo_code = action.payload.promos
        .map((promo: { code: any }) => promo.code)
        .reverse((x: string, y: string) => {
          var xp = x.slice(2, 3);
          var yp = y.slice(2, 3);
          return xp === yp ? 0 : xp < yp ? -1 : 1;
        });
      state.filters.country_name =
        (action.payload.countries &&
          action.payload.countries.map(
            (country: { name: string }) => country.name
          )) ??
        [];
      state.filters_loaded = true;
      state.status = "idle";
    });
    builder.addCase(fetchFilters.rejected, (state) => {
      state.status = "idle";
    });

    // UPDATE order
    builder.addCase(updateOrderAsync.pending, (state, action) => {
      state.status = "PendingUpdateItem" + action.meta.arg.orderId;
    });
    builder.addCase(updateOrderAsync.fulfilled, (state, action) => {
      ordersAdapter.upsertOne(state, action.payload);
      state.orders_loaded = false;
      state.status = "idle";
    });
    builder.addCase(updateOrderAsync.rejected, (state) => {
      state.status = "idle";
      state.orders_loaded = false;
      toast.error("Something went realy wrong");
    });
  },
});
//#endregion

//#region NORMALISED STATE
export const ordersSelectors = ordersAdapter.getSelectors(
  (state: RootState) => state.orders
);
//#endregion

//#region EXPORT
export const {
  updateSelectedStateOfOrder,
  updateApproveStateOfOrder,
  setOrdersWithSuborders,
  setSelectedSubOrder,
  setAmmountOrdered,
  setAmmountPending,
  setSelectedOrders,
  resetOrderParams,
  resetDetailGroup,
  clearSelectedAll,
  setOrderParams,
  setDetailGroup,
  setSelectedAll,
  setPageNumber,
  setMetaData,
  clearOrders,
} = ordersSlice.actions;
//#endregion
