//#region IMPORTS
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { motion } from "framer-motion";
import "./SaveButton.css";
//#endregion

//#region INTERFACE
interface Props {
  saveHandler?: () => void;
  value?: string;
  valid?: boolean;
  extraTopMargin?: string;
  type?: "button" | "submit" | "reset" | undefined;
}
//#endregion

export default function SaveButton({
  saveHandler,
  value,
  valid,
  extraTopMargin,
  type = "button",
}: Props) {
  //#region RENDER
  return (
    <motion.button
      type={type}
      disabled={valid ? false : true}
      onClick={saveHandler}
      className={`flex items-center gap-x-1.5 rounded-md bg-${
        valid ? "emerald" : "red"
      }-600 px-3 py-2 text-sm font-semibold text-white hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-grey-600 mt-2`}
      style={{ marginTop: extraTopMargin }}
    >
      {valid && (
        <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
      )}
      {valid ? value : "Please check the values"}
    </motion.button>
  );
  //#endregion
}
