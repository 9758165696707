import { AppUser } from "../models/user";

function useHQmanager(user: AppUser | null): boolean {
  if (!user) {
    return false;
  } else if (user.roles.includes("HQteam")) {
    return true;
  } else {
    return false;
  }
}

export default useHQmanager;
