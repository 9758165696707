//#region IMPORTS
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { AccountState } from "../../models/account";
import { FieldValues } from "react-hook-form";
import { setBasket } from "../basket/basketSlice";
import { AppUser } from "../../models/user";
import { history } from "../..";
import { toast } from "react-toastify";
import getCookie from "../../helpers/getCookieHelper";
import agent from "../../api/agent";
import axios from "axios";
//#endregion

//#region INITIALSTATE
const initialState: AccountState = {
  user: null,
};
//#endregion

//#region LOGIN
export const signInUser = createAsyncThunk<AppUser, FieldValues>(
  "account/signInUser",
  async (data, thunkAPI) => {
    try {
      const userDto = await agent.Account.login(data);
      const { basket, ...user } = userDto;
      if (basket) {
        thunkAPI.dispatch(setBasket(basket));
      }
      // localStorage.setItem("userBO", JSON.stringify(user));
      document.cookie = `personal_access_token=${user.token}`;
      axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
      return user;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);
//#endregion

//#region READ
export const fetchCurrentUser = createAsyncThunk<AppUser>(
  "account/fetchCurrentUser",
  async (_, thunkAPI) => {
    // thunkAPI.dispatch(setUser(JSON.parse(localStorage.getItem("userBO")!)));
    try {
      const userDto = await agent.Account.currentUser();
      const { basket, ...user } = userDto;
      if (basket) {
        thunkAPI.dispatch(setBasket(basket));
      }
      // localStorage.setItem("userBO", JSON.stringify(user));
      // document.cookie = `personal_access_token=${user.token}`;
      return user;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  },
  {
    condition: () => {
      if (!getCookie("personal_access_token")) return false; // api call if token
    },
  }
);
//#endregion

//#region REDUCER SLICE
export const accountSlice = createSlice({
  name: "account",
  initialState: initialState,
  reducers: {
    signOut: (state) => {
      state.user = null;
      document.cookie =
        "personal_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      axios.defaults.headers.common["Authorization"] = "";
      history.push("/");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.rejected, (state) => {
      state.user = null;
      document.cookie =
        "personal_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      toast.error("Session expired, please login again");
    });
    builder.addMatcher(
      isAnyOf(signInUser.fulfilled, fetchCurrentUser.fulfilled),
      (state, action) => {
        state.user = {
          ...action.payload,
        };
      }
    );
    builder.addMatcher(isAnyOf(signInUser.rejected), (state) => {
      state.user = null;
      document.cookie =
        "personal_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      toast.error("Please check credentials");
    });
  },
});
//#endregion

//#region EXPORT
export const { signOut } = accountSlice.actions;
//#endregion
