import "./CheckoutButton.css";
import CrossIcon from "../../icons/CrossIcon";

interface Props {
  clickHandler?: () => void;
  color?: string;
  value?: string;
  shake?: boolean;
  showCrossIcon?: boolean;
}

function CheckoutButton({
  clickHandler,
  color,
  value,
  shake = false,
  showCrossIcon = true,
}: Props) {
  return (
    <button
      onClick={clickHandler}
      className={`check-out-button${shake ? " check-out-button--shake" : ""}`}
    >
      {showCrossIcon && (
        <CrossIcon color={color} className="check-out-button__value--icon" />
      )}
      <p className="check-out-button__value" style={{ color: color }}>
        {value}
      </p>
    </button>
  );
}

export default CheckoutButton;
