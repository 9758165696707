function useWeekDay(date: Date | null): string {
  const weekday = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  if (!date) {
    return "";
  } else {
    return weekday[date.getDay()];
  }
}

export default useWeekDay;
