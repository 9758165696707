import { useEffect, useState } from "react";

function useFilterCount(objectToCount: Object) {
  const [ammountFilters, setAmmountFilters] = useState<number>(0);

  useEffect(() => {
    const filtersSingleCount = Object.values(objectToCount).filter((item) => {
      if (item !== "" || item !== null) return item;
      else return null;
    });
    setAmmountFilters(filtersSingleCount.length);
  }, [objectToCount]);

  return ammountFilters;
}

export default useFilterCount;
