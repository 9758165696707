import { Icon } from "../../models/icon";

function SearchIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  width = 16,
}: Icon) {
  return (
    <svg viewBox="0 0 80 80" style={{ width: width + "px" }}>
      <path
        fill={selected ? selectedColor : color}
        d="m77.72,66.68l-16.51-16.5c7.74-12.71,6.15-29.53-4.83-40.51C50.14,3.44,41.84,0,33.02,0S15.9,3.44,9.66,9.67c-12.88,12.88-12.88,33.83,0,46.71,6.24,6.23,14.54,9.67,23.36,9.67,6.16,0,12.04-1.71,17.16-4.84l16.5,16.49c1.52,1.53,3.52,2.29,5.52,2.29s4-.76,5.52-2.29c3.04-3.05,3.04-7.98,0-11.03Zm-27.96-16.92c-4.48,4.47-10.42,6.92-16.74,6.93-6.32,0-12.27-2.46-16.74-6.93-9.23-9.23-9.23-24.25,0-33.48,4.47-4.47,10.41-6.93,16.73-6.93s12.27,2.46,16.74,6.93c9.22,9.23,9.22,24.25,0,33.48Z"
      />
    </svg>
  );
}

export default SearchIcon;
