interface Props {
  message?: string;
}

function EmptyList({ message= "This list is empty" }: Props) {
  return (
    <div>
      <h1 className="main-big-app-title">{message}</h1>
    </div>
  );
}

export default EmptyList;
