//#region IMPORTS
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { User } from "../../../models/user";
import TableBase from "../../../components/table/TableBase";
//#endregion

//#region INTERFACE
interface Props {
  data: User[];
  deleteAction?: (id: number) => void;
  manage?: (id: number) => void;
}
//#endregion

export default function UsersTable({ data, manage, deleteAction }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Column setup
  const colums = useMemo(
    () => [
      {
        Header: t("rituals.name"),
        accessor: "username",
      },
      {
        Header: t("rituals.email"),
        accessor: "email",
      },
      {
        Header: t("rituals.phone"),
        accessor: "phone_number",
      },
      {
        Header: t("rituals.function"),
        accessor: "function",
      },
      {
        id: "store",
        Header: t("rituals.storeId"),
        accessor: (user: User) =>
          user.store ? user?.store.external_store_id : "none",
      },
    ],
    [t]
  );
  //#endregion

  //#region RENDER
  return (
    <TableBase<User>
      columns={colums}
      data={data}
      action={({ original }) => manage && manage(original.id)}
      deleteAction={({ original }) => deleteAction && deleteAction(original.id)}
    />
  );
  //#endregion
}
