import { resetTranslationManagerParams } from "../translatioManagerSlice";
import { Fragment, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { TranslationLine } from "../../../models/translations";
import { useAppDispatch } from "../../../store/configureStore";
import { toast } from "react-toastify";
import Modal from "../../../components/genericModal/Modal";
import agent from "../../../api/agent";

interface Props {
  setCurrentEditingId: (id: number | null) => void;
  currentEditingId: number | null;
  setCurrentDirty: (id: boolean) => void;
  columsAmmount: number;
  disabled: boolean;
  item: TranslationLine;
}

function TranslationManagerListItem({
  setCurrentEditingId,
  currentEditingId,
  setCurrentDirty,
  columsAmmount,
  disabled,
  item,
}: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();

  // Local state
  const [showModal, setShowModal] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  // UseForm configuration
  const {
    reset,
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ mode: "all" });
  //#endregion

  //#region LOGIC
  useEffect(() => {
    if (isDirty) {
      setCurrentDirty(isDirty);
    }
  }, [isDirty, setCurrentDirty]);

  // Clickhandler
  const handleItemClick = () => {
    if ((currentEditingId && currentEditingId === item.id) || disabled) {
      return;
    } else if (currentEditingId && currentEditingId !== item.id && disabled) {
      setShowModal(true);
    } else if (
      !currentEditingId ||
      (currentEditingId && currentEditingId !== item.id && !disabled)
    ) {
      reset({
        id: item.id,
        group: item.group,
        key: item.key,
        ...item.text,
      });
      setCurrentEditingId(item.id);
    }
  };

  // Reset line select
  const handleReset = () => {
    setCurrentEditingId(null);
    setShowModal(false);
    setCurrentDirty(false);
  };

  // OnSubmit
  async function submitForm(data: FieldValues) {
    setSaving(true);
    const translationRequest: any = {
      id: data.id,
      key: data.key,
      group: data.group,
      text: {
        de: data.de,
        en: data.en,
        es: data.es,
        fr: data.fr,
        it: data.it,
        nl: data.nl,
        pl: data.pl,
        pt: data.pt,
      },
    };
    try {
      const { message } = await agent.Translations.createOrUpdate(
        translationRequest
      );
      toast.success(`${data.key} ${message}`);
      setSaving(false);
      handleReset();
      dispatch(resetTranslationManagerParams());
    } catch (error) {
      toast.error(`${error}`);
      handleReset();
    }
  }
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {showModal && (
        <Modal>
          <p>You have not saved your changes! </p>
          <button onClick={() => setShowModal(false)}>return</button>
          <button onClick={handleReset}>discard changes</button>
        </Modal>
      )}
      <form
        onSubmit={handleSubmit(submitForm)}
        key={item.id}
        className="translation-manager-list__list__item"
        onClick={handleItemClick}
      >
        {saving && (
          <div className="translation-manager-list__list__item__saving">
            saving...
          </div>
        )}
        <div
          className="translation-manager-list__list__item__cell"
          style={{ width: `${100 / columsAmmount}%` }}
        >
          <p>{item.key}</p>
        </div>

        {Object.keys(
          Object.fromEntries(
            Object.entries({
              ...item.text,
            }).sort(([a], [b]) =>
              a === "en" ? -1 : b === "en" ? 1 : a.localeCompare(b)
            )
          )
        )
          .map((key) => ({
            key,
            value: {
              ...item.text,
            }[key],
          }))
          .map(({ key, value }, index) => (
            <div
              key={index}
              className="translation-manager-list__list__item__cell"
              style={{ width: `${100 / columsAmmount}%` }}
            >
              {currentEditingId && currentEditingId === item.id ? (
                <input
                  className="translation-manager-list__list__item__cell__inputfield"
                  key={index}
                  type="text"
                  {...register(key, {})}
                />
              ) : (
                <p>{value}</p>
              )}
            </div>
          ))}
        <div className="translation-manager-list__list__item__cell--save">
          {currentEditingId && currentEditingId === item.id && isDirty && (
            <button type="submit">Save</button>
          )}
        </div>
      </form>
    </Fragment>
  );
  //#endregion
}

export default TranslationManagerListItem;
