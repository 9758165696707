//#region IMPORTS
import { FieldValues, useForm } from "react-hook-form";
import { useEffect } from "react";
//#endregion

//#region INTERFACE
interface Props {
  saveComment: (comment: string) => void;
  defaultValue?: string;
  isSaving?: boolean;
}
//#endregion

export default function AddYourComment({
  defaultValue = "",
  isSaving = false,
  saveComment,
}: Props) {
  //#region USEFORM
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ mode: "all" });
  //#endregion

  //#region LOGIC
  // Fill defaultvalue with provided value
  useEffect(() => {
    if (defaultValue) reset({ comment: defaultValue });
  }, [defaultValue, reset]);

  // Save comment
  const submitForm = (data: FieldValues) => {
    !isSubmitting && saveComment(data.comment);
  };
  //#endregion

  //#region RENDER
  return (
    <div>
      <label
        htmlFor="comment"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Add your comment
      </label>
      <form
        className="mt-2 flex items-end flex-col gap-2"
        onSubmit={handleSubmit(submitForm)}
      >
        <textarea
          rows={4}
          id="comment"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
          {...register("comment")}
        />
        <input
          aria-disabled={isSaving}
          disabled={isSaving}
          type="submit"
          className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-amber-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600 sm:mt-0 sm:w-auto"
          value={isSaving ? "Saving comment..." : "Save comment"}
        />
      </form>
    </div>
  );
  //#endregion
}
