import { Icon } from "../../models/icon";

function PencilIcon({ color = "#FFFFFF", width = 24 }: Icon) {
  return (
    <svg viewBox="0 0 80.17 80" style={{ width: width + "px" }}>
      <path
        fill={color}
        d="m40.09,0C17.99,0,0,17.91,0,39.92s17.99,40.08,40.09,40.08,40.08-17.98,40.08-40.08S62.19,0,40.09,0Zm0,76.28c-20.05,0-36.37-16.32-36.37-36.37S20.03,3.72,40.09,3.72s36.37,16.23,36.37,36.18-16.31,36.37-36.37,36.37Z"
      />
      <path
        fill={color}
        d="m60.47,40.86c-.6-.6-1.59-.6-2.19,0l-7.22,7.22c-2.61.05-4.53-.59-5.73-1.9-1.73-1.9-1.63-4.83-1.51-6.02l12.33-12.33c.6-.6.6-1.59,0-2.19-.6-.6-1.59-.6-2.19,0l-12.32,12.32c-3.09.23-5.32-.39-6.62-1.86-1.6-1.8-1.54-4.55-1.38-5.98l17.29-17.29c.6-.6.61-1.58,0-2.19-.6-.6-1.59-.6-2.19,0l-22.33,22.33c-1.1,1.1-2.3,3.14-2.74,4.64l-6.6,22.88c-.33,1.15-.12,2.23.59,2.95,0,0,0,0,.01.01.71.71,1.78.94,2.93.62l23.2-6.33c1.54-.42,3.54-1.58,4.67-2.71l4.15-4.15s.06,0,.09,0v-.07s7.75-7.76,7.75-7.76c.6-.6.6-1.59,0-2.19Zm-17.48,13.9l-16.82,4.59-4.3-4.3,4.78-16.58c.29-1.01,1.2-2.56,1.95-3.31l2.15-2.15c.2,1.66.76,3.5,2.09,5,1.77,2,4.46,2.97,8.03,2.89-.06,1.92.25,4.94,2.3,7.21,1.28,1.41,3.01,2.3,5.18,2.67l-2.06,2.06c-.73.73-2.3,1.64-3.3,1.91Z"
      />
    </svg>
  );
}

export default PencilIcon;
