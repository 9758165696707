//#region IMPORT
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import FilterBarSelectDropDown from "./FilterBarSelectDropDown";
import CrossIcon from "../icons/CrossIcon";
//#endregion

//#region INTERFACE
interface Props {
  filterTitle: string;
  selectedValue?: string;
  values: any;
  open: boolean;
  directTo?: string;
  openFilterHandler: () => void;
  closeFilterHandler: () => void;
  changeParams: (value: string) => void;
}
//#endregion

export default function FilterBarSelect({
  filterTitle,
  values,
  selectedValue,
  open,
  directTo,
  openFilterHandler,
  closeFilterHandler,
  changeParams,
}: Props) {
  // Router
  const history = useHistory();

  // Select handler
  const handleSelectFilter = (value: string) => {
    changeParams(value);
    !open ? openFilterHandler() : closeFilterHandler();
  };

  // FramerMotion variant
  const filterbarItemVariants = {
    open: { y: 0, opacity: 1 },
    closed: { y: -10, opacity: 0 },
  };

  //#region RENDER
  return (
    <motion.div variants={filterbarItemVariants} className="filterbar__select">
      <div className="filterbar__select__cta">
        <h2 className="font-semibold text-gray-900">{filterTitle}</h2>

        <button
          onClick={open ? closeFilterHandler : openFilterHandler}
          className={
            "filterbar__select__cta__button" +
            (open ? " filterbar__select__cta__button--open" : "")
          }
        >
          <CrossIcon width={16} />
        </button>
      </div>

      {/* Overview selected filter */}
      <div>
        <span
          className="filterbar__select__selected"
          key={selectedValue}
          onClick={() => {
            directTo && history.push(directTo);
            changeParams("");
          }}
        >
          {selectedValue}
        </span>
      </div>

      {/* Drop down menu */}
      {open && (
        <FilterBarSelectDropDown
          selectedValue={selectedValue}
          values={values}
          onChange={(value) => handleSelectFilter(value)}
        />
      )}
    </motion.div>
  );
  //#endregion
}