import { motion } from "framer-motion";
import { useState } from "react";
import Identicon from "react-identicons";
import "./Avatar.css"

interface Props {
  name: string;
  clickHandler: () => void;
}

const Avatar = ({ name, clickHandler }: Props) => {
  const [color, setColor] = useState("red");

  return (
    <motion.div
      className="avatar__icon"
      onClick={clickHandler}
      whileHover={{
        scale: 1.3,
      }}
      whileTap={{ scale: 0.9 }}
    >
      <div
        className="avatar__icon__circle"
        style={{ borderColor: `#${color}`, backgroundColor: `#${color}25` }}
      >
        <Identicon
          string={name}
          size="24"
          getColor={(value: string) => setColor(value)}
        />
      </div>
    </motion.div>
  );
};
export default Avatar;
