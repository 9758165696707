//#region IMPORT
import { useAppSelector } from "../../store/configureStore";
import { Fragment } from "react";
import SubOrderDetailHeader from "./SubOrderDetailHeader";
import SubOrderDetailList from "./SubOrderDetailList";
//#endregion

export default function SubOrderDetail() {
  //#region SETUP
  const { selectedOrder, selectedSubOrder } = useAppSelector(
    (state) => state.orders
  );
  //#endregion

  //#region RENDER
  return (
    selectedOrder &&
    selectedSubOrder && (
      <Fragment>
        <SubOrderDetailHeader
          order={selectedOrder}
          subOrder={selectedSubOrder}
        />
        <SubOrderDetailList
          order={selectedOrder}
          subOrderId={selectedSubOrder.id}
        />
      </Fragment>
    )
  );
  //#endregion
}
