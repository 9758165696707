//#region IMPORTS
import ToggleButton from "../genericButtons/toggleButton/ToggleButton";
//#endregion

//#region INTERFACE
interface Props {
  toggleHandler: () => void;
  refresh: boolean;
  value: boolean;
  title: string;
}
//#endregion

export default function FilterBarToggle({
  toggleHandler,
  refresh,
  value = false,
  title,
}: Props) {
  return (
    <div className="my-4 pl-8 item-center justify-between">
      <ToggleButton
        refresh={refresh}
        toggleHandler={toggleHandler}
        startValue={value}
        label={title}
      />
    </div>
  );
}
