//#region IMPORTS
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//#endregion

//#region INTERFACE
interface Props {
  setSearchParamHandler: (values: any) => void;
  lastValue?: string;
  title: string;
}
//#endregion

export default function FilterBarDatePicker({
  setSearchParamHandler,
  lastValue,
  title,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Local state
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  //#endregion

  //#region LOGIC
  const clearSearhHandler = () => {
    setStartDate("");
    setEndDate("");
    setSearchParamHandler("");
  };

  useEffect(() => {
    if (lastValue) {
      setStartDate(JSON.parse(lastValue).from);
      setEndDate(JSON.parse(lastValue).to);
    }
  }, [lastValue]);

  const searchHandler = (e: any) => {
    e.preventDefault();
    const searchObject = {
      from: startDate,
      to: endDate,
    };
    setSearchParamHandler(JSON.stringify(searchObject));
  };
  //#endregion

  return (
    <section className="filterbar__content__section">
      <header className="filterbar__content__section__header">
        <h2 className="font-semibold text-gray-900 text-sm">{title}</h2>
        {startDate && endDate && (
          <button
            onClick={clearSearhHandler}
            className="filterbar__content__section__header__clearbutton"
          >
            {t("rituals.clearAll")}
          </button>
        )}
      </header>
      <form
        onSubmit={searchHandler}
        className="mb-4 pl-8 sm:flex flex-col gap-4"
      >
        <input
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-500 sm:text-sm sm:leading-6"
          type="date"
          name={title}
          id={title}
          value={startDate || ""}
          onChange={(e: any) => {
            setStartDate(e.target.value);
          }}
        />
        <input
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-500 sm:text-sm sm:leading-6"
          type="date"
          name={title}
          id={title}
          value={endDate || ""}
          onChange={(e: any) => {
            setEndDate(e.target.value);
          }}
        />
        <button
          type="submit"
          disabled={!startDate || !endDate}
          className="flex items-center"
        >
          <MagnifyingGlassIcon className="w-6 inline h-6 mr-2 text-gray-500 hover:text-gray-900" />
          <span className="text-xs">Search between selected dates</span>
        </button>
      </form>
    </section>
  );
}
