//#region IMPORTS
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Promo } from "../../models/promo";
import LoadingData from "../loading/LoadingData";
import agent from "../../api/agent";
import { useState } from "react";
//#endregion

//#region INTERFACE
interface Props {
  setSavingHandler: (value: boolean) => void;
  cancelCUD: () => void;
  saving?: boolean;
  promo?: Promo;
}
//#endregion

export default function PromosDataUpload({
  setSavingHandler,
  cancelCUD,
  saving = false,
  promo,
}: Props) {
  //#region SETUP
  // Local state
  const [uploadError, setUploadError] = useState<{
    message: string;
    missing: string[];
  } | null>();

  // UseForm
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({ mode: "all" });

  //#endregion

  //#region LOGIC
  async function submitForm(data: FieldValues) {
    let formData = new FormData();
    // VERY IMPORTANT - only way to acces the FileList in data object
    formData.append("file", data.excellFile[0]);

    if (promo) {
      try {
        setSavingHandler(true);
        await agent.Promos.createFromDataSource(formData, promo.id);
        toast.success("Data imported");
        setSavingHandler(false);
        cancelCUD();
      } catch (error: any) {
        setUploadError(error.response.data);
        setSavingHandler(false);
        toast.error(`${error}`);
      }
    } else toast.warning("no promo selected");
  }
  //#endregion

  //#region RENDER
  return (
    <div className="px-4 py-5 sm:p-6">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {saving ? "The data is uploading..." : "Upload promo data"}
      </h3>
      <div className="mt-2 max-w-xl text-sm text-gray-500">
        <p>
          {saving
            ? "This can take a few moments..."
            : "This wil upload the entire original order data."}
        </p>
      </div>

      {!saving && !uploadError ? (
        <form
          className="mt-5 flex justify-between sm:items-center"
          onSubmit={handleSubmit(submitForm)}
        >
          <div className="w-full sm:max-w-xs">
            <label htmlFor="excellFile" className="sr-only">
              {errors.excellFile
                ? errors?.excellFile?.message
                : "choose excell file"}
            </label>
            <input
              className="block w-full rounded-md pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none"
              type="file"
              autoFocus
              placeholder="choose main promo excell"
              {...register("excellFile", {
                required: "excell file is required",
              })}
            />
          </div>
          <div>
            <button
              onClick={cancelCUD}
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 sm:ml-3 sm:mt-0 sm:w-auto"
            >
              {isSubmitting ? "importing..." : "import"}
            </button>
          </div>
        </form>
      ) : saving && !uploadError ? (
        <div className="flex items-center justify-center mb-8">
          <LoadingData />
        </div>
      ) : uploadError ? (
        <div>
          <button onClick={cancelCUD}>close</button>
          <h2 className="text-lg">{uploadError.message}</h2>
          <table>
            {uploadError.missing.map((missingStoreId: string) => (
              <tr>
                <td className="text-xs text-gray-600"> {missingStoreId}</td>
              </tr>
            ))}
          </table>
        </div>
      ) : null}
    </div>
  );
  //#endregion
}
