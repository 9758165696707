import { Icon } from "../../models/icon";

function CancelIcon({ color = "#FFFFFF", width = 24 }: Icon) {
  return (
    <svg viewBox="0 0 79.85 79.85" style={{ width: width + "px" }}>
      <polygon
        fill={color}
        points="79.85 72.59 47.19 39.93 79.85 7.26 72.59 0 39.93 32.67 7.26 0 0 7.26 32.67 39.93 0 72.59 7.26 79.85 39.93 47.19 72.59 79.85 79.85 72.59"
      />
    </svg>
  );
}

export default CancelIcon;
