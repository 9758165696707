//#region IMPORTS
import {
  fetchFilters,
  setOrderParams,
  ordersSelectors,
  fetchOrdersAsync,
  updateOrderAsync,
  setSelectedOrders,
  setSelectedSubOrder,
} from "../../features/orders/ordersSlice";
import {
  openModal,
  closeFilterBar,
} from "../../features/dashBoard/dashboardSlice";
import {
  CANCEL_ORDER_SURE,
  SHOW_SUBORDER_INFO,
  CHOOSE_PICKLIST_SUPPLIER,
} from "../../models/_consts";
import { ORDERSTATUS, Order, OrderParams } from "../../models/order";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import OrdersCustomFilters from "./OrdersCustomFilters";
import MainMotionSection from "../../components/_layout/MainMotionSection";
import SupplierSelect from "../modalTypes/SupplierSelect";
import useFilterCount from "../../hooks/useFilterCount";
import SubOrderDetail from "../suborderDetail/SubOrderDetail";
import LoadingSpinner from "../loading/LoadingSpinner";
import MainContainer from "../../components/_layout/MainContainer";
import OrdersTable from "../../components/orders/OrdersTable";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import AreYouSure from "../modalTypes/AreYouSure";
import FilterBar from "../../components/filterBar/FilterBar";
import MainModal from "../_layout/MainModal";
import agent from "../../api/agent";
//#endregion

//#region INTERFACE
interface Props {
  title?: string;
}
//#endregion

export default function OrdersContainer({
  title = "rituals.orderHistory",
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const orders = useAppSelector(ordersSelectors.selectAll);
  const {
    filters_loaded,
    selectedOrders,
    orders_loaded,
    selectedOrder,
    metaData,
    filters
  } = useAppSelector((state) => state.orders);

  // Local state
  const [orderToCancel, setOrderToCancel] = useState<Order | null>(null);

  // Set Filters
  const {
    page,
    sort,
    limit,
    include_sub_orders,
    ...selectedFilters
  }: OrderParams = useAppSelector((state) => state.orders.order_params);
  const filterCount = useFilterCount(selectedFilters);
  //#endregion

  //#region LOGIC
  // Load filters
  useEffect(() => {
    if (!filters_loaded) dispatch(fetchFilters());
  }, [filters_loaded, dispatch]);


  // Close filterbar onleave
  useEffect(() => {
    return () => {
      dispatch(closeFilterBar());
    };
  }, [dispatch]);

  // Check for warning when changing orderstatus
  const checkIfWarningIsNeeded = async (order: Order, order_status: string) => {
    if (order_status === ORDERSTATUS.cancelled) {
      setOrderToCancel(order);
      dispatch(openModal(CANCEL_ORDER_SURE));
    } else {
      changeOrderStatusHandler(order, order_status);
    }
  };

  // Cancel order handler
  const changeOrderStatusHandler = async (
    order: Order,
    order_status: string
  ) => {
    try {
      await dispatch(
        updateOrderAsync({
          orderId: order.id,
          values: {
            buyer_id: order.buyer_id,
            comment: order.comment,
            order_code: order.order_code,
            promo_id: order.promo_id,
            order_status,
            order_type: order.order_type,
          },
        })
      );

      // Change status of suborders
      const updateValues = {
        sub_orders: order.sub_orders.map((subOrder) => subOrder.id.toString()),
        order_status,
      };

      // Check if suborder can be changed
      if (
        order.sub_orders.some(
          (subOrder) => subOrder.order_status === ORDERSTATUS.shipped
        )
      ) {
        toast.warning("Suborders that are shipped can not be changed anymore");
      } else {
        await agent.Orders.updateMultiSubOrderStatus(updateValues);
      }

      // Show message
      toast.success(
        `Order ${order.order_code} is now ${order_status.toLowerCase()}`
      );
    } catch (error) {}
    cancelChangeOrder();
  };

  // Cancel changing an order
  const cancelChangeOrder = () => {
    setOrderToCancel(null);
    dispatch(openModal(false));
  };

  // Cancel changing an order
  const cancelShowSubOrder = () => {
    dispatch(setSelectedSubOrder({ order: null, subOrder: null }));
    dispatch(openModal(false));
  };

  // Request lists in bulk
  const bulkGenerateListsAndEmailHandler = async (supplier?: string) => {
    try {
      const { message } = await agent.Reports.emailMeListsForSelectedOrders({
        orders: selectedOrders.map((order) => order.id),
        sub_orders: true,
        supplier,
      });
      toast.success(message, { autoClose: 2000 });
    } catch (error) {
      toast.error(`${error}`);
    }
    dispatch(fetchOrdersAsync());
    dispatch(setSelectedOrders([]));
    dispatch(openModal(false));
  };
  //#endregion

  //#region FAIL SAFE
  if (!orders_loaded && !filters_loaded) return <LoadingSpinner />;
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* MODALS */}
      <MainModal type={CANCEL_ORDER_SURE} closeHandler={cancelChangeOrder}>
        <AreYouSure
          closeHandler={cancelChangeOrder}
          confirmHandler={() =>
            orderToCancel &&
            changeOrderStatusHandler(orderToCancel, "Cancelled")
          }
          title="Cancel order?"
          message={`Are you sure you want to cancel order ${orderToCancel?.order_code}`}
        />
      </MainModal>
      <MainModal
        type={CHOOSE_PICKLIST_SUPPLIER}
        closeHandler={cancelChangeOrder}
      >
        <SupplierSelect
          stock_portal={filters.stock_portal}
          closeHandler={cancelChangeOrder}
          confirmHandler={(supplier: string) =>
            bulkGenerateListsAndEmailHandler(supplier)
          }
          title="Choose Supplier?"
          message={`Are you sure you want to choose this supplier`}
        />
      </MainModal>

      <MainModal
        type={SHOW_SUBORDER_INFO}
        closeHandler={cancelShowSubOrder}
        extraTopMargin={selectedOrder && selectedOrder?.order_items.length > 10}
      >
        <SubOrderDetail />
      </MainModal>

      {/* BODY */}
      <MainContainer>
        {/* Filter bar */}
        <FilterBar
          selectedFilters={selectedFilters}
          setParamsHandler={(values: any) => {
            dispatch(setOrderParams(values));
          }}
          directTo="/orders"
        >
          <OrdersCustomFilters />
        </FilterBar>

        <MainMotionSection>
          <PageHeader title={t(title)} filter filterCount={filterCount} />

          <OrdersTable
            changeOrderStatusHandler={(order: Order, status: string) =>
              checkIfWarningIsNeeded(order, status)
            }
            filtersLoaded={filters_loaded}
            ordersLoaded={orders_loaded}
            metaData={metaData}
            orders={orders}
          />
        </MainMotionSection>
      </MainContainer>
    </Fragment>
  );
  //#endregion
}
