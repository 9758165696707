//#region IMPORTS
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { useAppSelector } from "../../store/configureStore";
//#endregion

//#region INTERFACE
interface Props {
  extraTopMargin?: boolean | null;
  closeHandler: () => void;
  children?: string | JSX.Element | JSX.Element[];
  type: string;
}
//#endregion

export default function MainModal({
  extraTopMargin = false,
  closeHandler,
  children,
  type,
}: Props) {
  //#region SETUP
  const { modalOpen, modalType } = useAppSelector((state) => state.dashboard);
  const cancelButtonRef = useRef(null);
  //#endregion

  //#region RENDER
  return (
    <Transition.Root show={modalOpen && modalType === type} as={Fragment}>
      <Dialog
        initialFocus={cancelButtonRef}
        className="relative z-50"
        onClose={() => {
          closeHandler();
        }}
        as="div"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={
                  !extraTopMargin
                    ? "relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6"
                    : "relative transform overflow-hidden top-32 rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6"
                }
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
  //#endregion
}
