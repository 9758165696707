//#region IMPORTS
import { Fragment } from "react";
import StoresTableControlePanelSortActions from "./StoresTableControlePanelSortActions";
//#endregion

export default function StoresTableControlePanel() {
  //#region RENDER
  return (
    <Fragment>
      <div className="flex h-12 items-center justify-end space-x-3 sm:left-12">
        <StoresTableControlePanelSortActions />
      </div>
    </Fragment>
  );
  //#endregion
}
