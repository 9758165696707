//#region IMPORTS
import { Order, ORDERSTATUS, ORDERTYPE } from "../../models/order";
import { updateApproveStateOfOrder } from "../../features/orders/ordersSlice";
import { useAppDispatch } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";
import ToggleButton from "../genericButtons/toggleButton/ToggleButton";
//#endregion

//#region INTERFACE
interface Props {
  clearSelectedOrdersHandler: () => void;
  approveOrderHandler: () => void;
  cancelOrderHandler: () => void;
  shipOrderHandler: () => void;
  isHQTeam: boolean;
  isPicker: boolean;
  isAdmin: boolean;
  order: Order;
}
//#endregion

export default function OrdersTableRowActions({
  clearSelectedOrdersHandler,
  approveOrderHandler,
  cancelOrderHandler,
  shipOrderHandler,
  isHQTeam,
  isPicker,
  isAdmin,
  order,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();
  //#endregion

  //#region LOGIC
  // Redirect to detail page
  const redirectToDetailPage = () => {
    history.push(`/orders/${order.id}`, true);
  };

  // Toggle approve or decline in batch
  const toggleItemInBatchHandler = () => {
    const orderToUpdate = { ...order };
    orderToUpdate.approved = !order.approved;
    dispatch(updateApproveStateOfOrder(orderToUpdate));
    clearSelectedOrdersHandler();
  };
  //#endregion

  return (
    <Fragment>
      <td className="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-500">
        <div className="flex gap-4">
          {/* See detail buton */}
          <button
            onClick={redirectToDetailPage}
            className="inline-flex items-center rounded-full bg-amber-200 px-2 py-1 text-xs font-medium text-amber-900"
          >
            {t("rituals.detail").toUpperCase()}
          </button>

          {/* {To Ship button} */}
          {(isPicker || isAdmin) &&
            order.order_type !== ORDERTYPE.original &&
            (order.order_status === ORDERSTATUS.ordered ||
              order.order_status === ORDERSTATUS.inProgress) && (
              <button
                onClick={shipOrderHandler}
                className="inline-flex items-center rounded-full bg-sky-200 px-2 py-1 text-xs font-medium text-sky-900"
              >
                {t("rituals.shipOrder").toUpperCase()}
              </button>
            )}

          {/* To Cancel button Inhouse */}
          {(isAdmin || isPicker) &&
            order.order_type !== ORDERTYPE.original &&
            order.order_status !== ORDERSTATUS.cancelled &&
            order.order_status !== ORDERSTATUS.shipped && (
              <button
                onClick={cancelOrderHandler}
                className="inline-flex items-center rounded-full bg-red-200 px-2 py-1 text-xs font-medium text-red-900"
              >
                {t("rituals.cancel").toUpperCase()}
              </button>
            )}

          {/* To Cancel button extern */}
          {isHQTeam &&
            order.order_type !== ORDERTYPE.original &&
            order.order_status !== ORDERSTATUS.cancelled &&
            order.order_status !== ORDERSTATUS.shipped &&
            order.order_status !== ORDERSTATUS.ordered && (
              <button
                onClick={cancelOrderHandler}
                className="inline-flex items-center rounded-full bg-red-200 px-2 py-1 text-xs font-medium text-red-900"
              >
                {t("rituals.cancel").toUpperCase()}
              </button>
            )}

          {/* To Approve handling */}
          {(isHQTeam || isAdmin) &&
            order.order_type !== ORDERTYPE.original &&
            order.order_status === ORDERSTATUS.pending && (
              <Fragment>
                {/* Approve button */}
                <button
                  disabled={!order.approved}
                  onClick={approveOrderHandler}
                  style={{ opacity: order.approved ? 1 : 0.5 }}
                  className="inline-flex items-center rounded-full bg-indigo-200 px-2 py-1 text-xs font-medium text-indigo-900"
                >
                  {t("rituals.approve").toUpperCase()}
                </button>

                {/* Toggle approve or decline for batch */}
                <div className="w-32">
                  <ToggleButton
                    changeColorByState={true}
                    labelRight={true}
                    startValue={order.approved}
                    label={`${order.approved ? "OK" : "NOT OK"} `}
                    toggleHandler={toggleItemInBatchHandler}
                  />
                </div>
              </Fragment>
            )}
        </div>
      </td>
    </Fragment>
  );
}
