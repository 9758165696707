import { Icon } from "../../models/icon";

function OverViewIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#9eab80",
  outline = "#3e5902",
  width = 24,
}: Icon) {
  return (
    <svg viewBox="0 0 80 80" style={{ width: width + "px" }}>
      <path
        fill={selected ? outline : "white"}
        d="M65.6,19.9l-2.1,3.7C58.8,32.2,49.8,37.5,40,37.5c-9.8,0-18.8-5.3-23.6-13.9l-2.1-3.7L10.7,22l-4.9,2.7L2,26.8
	l2.1,3.7c0.7,1.3,1.5,2.5,2.3,3.7L3,37.6l-3,3l3,3l3.9,3.9l3,3l3-3l3.4-3.4c0.6,0.4,1.1,0.8,1.7,1.1l-1.8,4.4l-1.6,3.9l3.9,1.6
	l5.1,2.1l3.9,1.6l1.6-3.9l1.8-4.4c0.7,0.2,1.3,0.3,2,0.4v4.8v4.2h4.2h5.5H47v-4.2V51c0.7-0.1,1.3-0.2,2-0.4l1.8,4.4l1.6,3.9l3.9-1.6
	l5.1-2.1l3.9-1.6l-1.6-3.9L62,45.3c0.6-0.4,1.1-0.7,1.7-1.1l3.4,3.4l3,3l3-3l3.9-3.9l3-3l-3-3l-3.4-3.4c0.8-1.2,1.6-2.4,2.3-3.7
	l2-3.6l-3.5-2.1L69.3,22L65.6,19.9L65.6,19.9z"
      />
      <g>
        <path
          fill={selected ? selectedColor : color}
          d="M37.2,47.3c-3-0.2-6-0.8-8.8-1.7l-3.3,7.9l-5.1-2.1l3.3-7.9c-2.7-1.4-5.2-3-7.4-5l-6,6L6,40.6l6-6
		c-1.6-1.9-3.1-3.9-4.3-6.1l0,0l4.9-2.7c5.3,9.6,15.5,16,27.3,16c11.7,0,21.9-6.5,27.3-16l4.9,2.7l0,0c-1.2,2.2-2.7,4.2-4.3,6.1l6,6
		l-3.9,3.9l-6-6c-2.3,1.9-4.7,3.6-7.4,5l3.3,7.9l-5.1,2.1l-3.3-7.9c-2.8,0.9-5.7,1.5-8.8,1.7v8.5h-5.5V47.3L37.2,47.3z"
        />
      </g>
    </svg>
  );
}

export default OverViewIcon;
