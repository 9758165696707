//#region IMPORTS
import { FieldValues, useForm } from "react-hook-form";
import { useAppDispatch } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { signInUser } from "./accountSlice";
import { useState } from "react";
import { motion } from "framer-motion";
import RitualsLogo from "../../components/logos/RitualsLogo";
import AdcLogo from "../../components/logos/AdcLogo";
import "./Account.css";
//#endregion

export default function LoginPage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();

  // Local State
  const [adminFieldOpen, setAdminFieldOpen] = useState(false);

  // Form setup
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });
  //#endregion

  //#region FRAMER MOTION
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.15,
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  //#endregion

  //#region LOGIC
  async function submitForm(data: FieldValues) {
    await dispatch(signInUser(data));
    history.push("/");
  }

  const toggleAdminFieldOpenHandler = () => {
    setAdminFieldOpen((prev) => !prev);
  };
  //#endregion

  //#region RENDER
  return (
    <main className="account-container">
      <div
        className="account-container__image"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/login_image.jpg"
          })`,
        }}
      />

      <div className="account-container__login">
        <RitualsLogo className="account-container__login__logo" />
        <motion.div
          className="account-container__login__modal"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          <motion.h1
            variants={item}
            className="account-container__login__modal__title serif"
          >
            {t("rituals.welcome")}
            <span className="account-container__login__modal__title__point">
              ...
            </span>
          </motion.h1>

          {!adminFieldOpen ? (
            <>
              <motion.div variants={item}>
                <button className="flex w-full justify-center rounded-md bg-slate-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-800 my-4">
                  <a
                    style={{ display: "block", textAlign: "center" }}
                    href="https://rituals.robinapp.be/auth/azure/redirect"
                  >
                    {t("rituals.loginShopUser")}
                  </a>
                </button>
              </motion.div>
              <motion.div variants={item}>
                <button
                  onClick={toggleAdminFieldOpenHandler}
                  className="flex w-full justify-center rounded-md bg-amber-500 px-3 py-1.5 text-sm font-semibold leading-6 text-slate-800 shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500"
                >
                  {t("rituals.loginExternalOrAdminUser").toUpperCase()}
                </button>
              </motion.div>
            </>
          ) : (
            <form onSubmit={handleSubmit(submitForm)}>
              <motion.fieldset variants={item}>
                {/* #EMAIL */}
                <fieldset className="my-4">
                  <label
                    htmlFor="email"
                    style={{ color: errors.email ? "red" : "inherit" }}
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {errors.email ? errors?.email?.message : "email"}
                  </label>
                  <input
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-800 sm:text-sm sm:leading-6"
                    type="text"
                    id="email"
                    // autoFocus
                    {...register("email", { required: "email is required" })}
                  />
                </fieldset>

                {/* PASSWORD */}
                <fieldset className="my-4">
                  <label
                    htmlFor="password"
                    style={{ color: errors.password ? "red" : "inherit" }}
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {errors.password ? errors?.password?.message : "password"}
                  </label>
                  <input
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-800 sm:text-sm sm:leading-6"
                    type="password"
                    id="password"
                    {...register("password", {
                      required: "password is required",
                    })}
                  />
                </fieldset>
              </motion.fieldset>
              <motion.fieldset variants={item}>
                <button
                  className="flex w-full justify-center rounded-md bg-slate-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-800 my-4 disabled:opacity-10"
                  type="submit"
                  disabled={!isValid}
                >
                  {isSubmitting ? "WAIT FOR IT..." : "LOGIN"}
                </button>
                <button
                  className="flex w-full justify-center rounded-md bg-amber-500 px-3 py-1.5 text-sm font-semibold leading-6 text-slate-800 shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500"
                  onClick={toggleAdminFieldOpenHandler}
                >
                  {t("rituals.back").toUpperCase()}
                </button>
              </motion.fieldset>
            </form>
          )}
        </motion.div>

        <a
          href="https://www.albedecoker.com"
          target="_blank"
          rel="noopener noreferrer"
          className="account-container__footer"
        >
          <p>powered by</p>
          <AdcLogo className="account-container__footer__logo" />
        </a>
      </div>
    </main>
  );
  //#endregion
}
